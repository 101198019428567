import React ,{ useEffect, useState,useRef, useContext} from 'react';
import {Modal,ActivityIndicator, StyleSheet, View, Text, Pressable, TextInput, ScrollView, SafeAreaView,Animated ,Platform,TouchableOpacity,Image} from 'react-native';
import AutocompleteCity from './AutocompleteCity';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import { useMyContext } from './MyContext';
import { Feather } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import NetInfo from '@react-native-community/netinfo';
import MenuLoginUser from './menuLoginForUser';
import Checkbox from 'expo-checkbox';
import * as Haptics from 'expo-haptics';
import axios from 'axios';
import Typewriter from "./typeWriter";
import TermAndConditions from "./termAndConditions";
const RadioButton = ({ value, selected, onSelect }) => (
  <TouchableOpacity onPress={onSelect} style={{ flexDirection: 'row', alignItems: 'center' }}>
    <View style={{ height:28, width:27, borderRadius:15, borderWidth: 3, borderColor: selected ? '#5cb85c' : 'black', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white' ,marginBottom:-10, }}>
      {selected ? <View style={{ height: 12, width: 12, borderRadius: 6, backgroundColor: '#5cb85c' }} /> : null}
    </View>
    <Text style={{ marginLeft: "5%",marginTop:10}}>{value}</Text>
  </TouchableOpacity>
);



const CheckBox = ({ value1, value,readTermAndConditions, checked, onSelect }) => (
  <TouchableOpacity onPress={onSelect} style={{ flexDirection: 'row', alignItems: 'center' }}>
    <View style={{ height: 24, width: 24, borderRadius: 4, borderWidth: 3, borderColor: checked ? '#5cb85c' : 'black', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', marginLeft: '5%', marginTop: 20 }}>
      {checked ? <View style={{ height: 12, width: 12, borderRadius: 6, backgroundColor: '#5cb85c' }} /> : null}
    </View>
    <Text style={{ marginLeft: '5%', marginTop: 20 }}>{!readTermAndConditions ? (value1) : (checked ? 'lu et accepté' : value)
}</Text>
  </TouchableOpacity>
);



const Checkout  = ({ navigation, route }) => {


 //hold pyementData when a new user do not register, orde first and should be sent after mail verification to payementgate
 const {payementDataNewUser , setpayementDataNewUser}=useMyContext({});
  //hold payment method
  const [payementMethod, setpayementMethod]=useState('');
  const [totalToPay, settotalToPay]=useState(0);

  
const [textForNewUser, settextForNewUser]=useState("Vous n'êtes pas connecté à un compte client. Si vous avez un compte client, connectez-vous.Sinon, remplissez  tous les champs pour passer votre commande. Un compte client sera automatiquement créé. Notez qu'une commande sans compte client n'est pas possible \u{1F910}, car vous n'aurez pas la possibilité de retourner, réclamer ou être remboursé \u{1F644}" );

const [textInfo, settextInfo]=useState("");

    //hide button when finish
    const [hideButton, sethideButton]=useState(true);  


  //var for modal menu
  const [showMenu, setshowMenu] = useState(false);
  //delay type writer
  const [showTypewriter, setShowTypewriter] = useState(false);
  const [showTypewriterRadioButtons,setshowTypewriterRadioButtons]=useState(false);

//function to show menu
const toggleMenu= () => {
  triggerHapticFeedback();

setshowMenu(!showMenu);

};






      //const while loading
      const [loading, setLoading] = useState(false);
      //Error holding and displaying vars
      const [Error, setError]=useState('');
      //hold success
      const [Success, setSuccess]=useState('');

//trigger haptic feedback
const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
  Haptics.notificationAsync(
    Haptics.NotificationFeedbackType.Success);}
}

//unset error 
const unsetError = () => {
  setError();
};
  
//radio butoon
  const [selectedValue, setSelectedValue] = useState(null);
  const calculateMandatCost=(price)=>{
  let cost =0;
  if(price<=250){

cost=5;

  }
  else if(price>250&&price<=1000){
    cost=10;
  }

  else if(price>1000&&price<=2000){
    cost=15;
  }
  else if(price>2000&&price<=3000){
    cost=10;
  }


    return cost;
  }





  const handleRadioSelectTextFloomy= (value, showInfo) =>{
if(selectedValue!==value){

  setSelectedValue(value);
}
    setshowTypewriterRadioButtons(false);
    setTimeout(() => {
let totalWeight=calculateTotalWeight();
let delivery_costs =calculateDeliveryCostForAllSeller();
let total= totalPrice+delivery_costs;
let Mandatcost=calculateMandatCost(total);
let totalWithMandat=totalPrice+delivery_costs+Mandatcost;

      switch (value){
      case 'd17':
        settotalToPay(total.toFixed(2));
        setpayementMethod('d17');
       if(showInfo){
       settextInfo(`Vous voulez payer avec D17, pas de problèmes. Vous payez un total de ${total.toFixed(2)} TND, comprenant les produits pour un montant de ${totalPrice.toFixed(2)}  TND et les frais de livraison de ${delivery_costs.toFixed(2)} TND. Une fois que vous avez rempli tous les champs appuyez sur Passer ma commande, et la page avec notre code 3D de D17 apparaîtra, accompagnée des instructions de paiement \u{1F609}`);
      }
      break;
      case 'mandat minute':
        settotalToPay(totalWithMandat.toFixed(2));
        setpayementMethod('mandat minute');
        if(showInfo){
        settextInfo(`Vous voulez payer avec mandat minute, pas de problèmes. Vous payez un total de ${totalWithMandat.toFixed(2)} TND, comprenant les produits pour un montant de ${totalPrice.toFixed(2)} TND et les frais de livraison de ${delivery_costs.toFixed(2)} TND et les frais de mandat ${Mandatcost.toFixed(2)} TND. Une fois que vous avez rempli tous les champs, appuyez sur Passer ma commande, et la page avec nos coordonnées et instructions de paiement apparaîtra \u{1F60A}`);
      }
        break;
        case 'virement bancaire':
          settotalToPay(total.toFixed(2));
          setpayementMethod('virement bancaire');
          if(showInfo){
          settextInfo(`Vous voulez payer avec virement bancaire, pas de problèmes. Vous payez un total de ${total.toFixed(2)} TND, comprenant les produits pour un montant de ${totalPrice.toFixed(2)} TND et les frais de livraison de ${delivery_costs.toFixed(2)} TND. Une fois que vous avez rempli tous les champs, appuyez sur Passer ma commande, et la page avec nos coordonnées et instructions de paiement apparaîtra \u{1F60B}`);
        }
        break;
        default:


    }
    if(showInfo){
    setshowTypewriterRadioButtons(true);
    }

    }, 300);


  }
  const handleRadioSelect = (value) => {
    setSelectedValue(value);
    setshowTypewriterRadioButtons(false);
    settextInfo('');
    let delivery_costs =calculateDeliveryCostForAllSeller();
    let total= totalPrice+delivery_costs;
    let Mandatcost=calculateMandatCost(total);
    let totalWithMandat=totalPrice+delivery_costs+Mandatcost;

    
  };


//hide and show modal for term and conditions
const[showTermAndConditions, setshowTermAndConditions]=useState(false);


const showAndHideTermAndCondition = () => {
  triggerHapticFeedback();
  setshowTermAndConditions(!showTermAndConditions);
};

   //cehckbox
   const [checked, setChecked] = useState(false);
   const [readTermAndConditions,setreadTermAndConditions]=useState(false);


   //show term and condtions first, than set checked and if clicked again rerstart the hole process
   const handleCheck = () => {

    if(!checked){
    if(readTermAndConditions){
    triggerHapticFeedback();
    setChecked(!checked);
  }
  else {
    setshowTermAndConditions(true);
    setreadTermAndConditions(true);

  }

    }
    else{
      setChecked(!checked);
      setreadTermAndConditions(false);

    }


  };



  const closeTermAndConditions=()=>{
triggerHapticFeedback();
    setshowTermAndConditions(false);
  }



  const{User, setUser}= useMyContext({});
  const{UserIsLoggedIn, setUserIsLoggedIn}=useMyContext();
    const { cart, setCart } = useMyContext();
    const {countProductCart, setCount} = useMyContext();
    const {sellersSku, setsellersSku} = useMyContext([]);
    const{totalPrice, settotalPrice} = useMyContext();
    //vaiable that hold the ref 

const NameInputRef=useRef();
const emailInputRef=useRef();
const TelInputRef=useRef();
const AdressInputRef=useRef();
const CityInputRef=useRef();
const TippPassword1=useRef();
const TippPassword2=useRef();
const PasswordInputRef1=useRef();
const PasswordInputRef2=useRef();
const  ScrollViewRef=useRef();
const BottomViewRef= useRef();

 //variables to hold the input values
    const [Name, setName]=useState('');
    const [Email, setEmail]=useState('');
    const [Adress, setAdress]=useState('');
    const [City, setCity]=useState('');
    const [Tel, setTel]=useState('');
    const [userId, setUserId]=useState("new user");

    

    useEffect(() => {
if(User && Object.entries(User).length !== 0){

  setUserId(User[0].user_id);
setName(User[0].name);
setEmail(User[0].email);
setAdress(User[0].adress);
setCity(User[0].city);
setTel(User[0].user_tel_number);
seteveryThingOkName(1);
seteveryThingOkEmail(1);
seteveryThingOkAdress(1);
seteveryThingOkTel(1);
seteveryThingOkPassword1(1);
seteveryThingOkPassword2(1);
}else{

settextInfo(textForNewUser);
setName('');
setEmail('');
setAdress('');
setCity('');
setTel('');
seteveryThingOkName(false);
seteveryThingOkEmail(false);
seteveryThingOkAdress(false);
seteveryThingOkTel(false);
seteveryThingOkPassword1(false);
seteveryThingOkPassword2(false);


}

    }, []);

    





  const [password1, setPassword1]=useState('');
  const [password2, setPassword2]=useState('');

// variable to hold the check vaue for each input
const [everyThingOkName, seteveryThingOkName]=useState(0);
const [everyThingOkEmail, seteveryThingOkEmail]=useState(0);
const [everyThingOkAdress, seteveryThingOkAdress]=useState(0);
  const [everyThingOkTel, seteveryThingOkTel]=useState(0);
  const [everyThingOkCity, seteveryThingOkCity]=useState(0);

  const [everyThingOkPassword1, seteveryThingOkPassword1]=useState(0);
  const [everyThingOkPassword2, seteveryThingOkPassword2]=useState(0);

//function to check only numbers, letters, letters and numbers...
  //check only letters and numbers in the input
  const onlyLettersAndNumbers = (str) => {
    return /^[A-Za-z0-9'’èéäêöàùâêîôûçëïü\s\-.,?!]*$/.test(str);
  };
  
  
//check only letters in the  input
const onlyLetters = (str) => {
return /^[A-Za-z'’èéäêöàùâêîôûçëïü\s]*$/.test(str);
};

//check only numbers in the comment input
 //check only numbers in the comment input
 const onlyNumber = (str) => {
  return /^[0-9]{8}$/.test(str);
};

  
//chek MailFormat
const onlyMailFormat = (str) => {
  const mailFormatRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  
  return mailFormatRegex.test(str);
};




   //Name Change
   const handleNameChange= (name) => {
    
    if (onlyLetters(name)  && name.length>0&& name.length<30) {
      setName(name);
      seteveryThingOkName(1);
      NameInputRef.current.setNativeProps({
    
        style: {
          borderColor:"#5cb85c"
        }
      });
      setError();
    }
    else
    {
      seteveryThingOkName(0);
      setName(name);
      NameInputRef.current.setNativeProps({
    
        style: {
          borderColor:"red"
        }
      });
      setError("le nom ne peut contenir que des lettres et des espace, min 1 et max 30 \u{1F922}");

    }


    if(name.length===0){
      setName(name);
      NameInputRef.current.setNativeProps({
    
        style: {
          borderColor:"rgba(0, 0, 0, 0.4)",
        }
      });
      seteveryThingOkName(0);
      setError("");
    }




  };

   

    //Email change
    const handleEmailChange= (mail) => {

  

      if (onlyMailFormat(mail)) {
        setEmail(mail);
        seteveryThingOkEmail(1);
        emailInputRef.current.setNativeProps({
      
          style: {
            borderColor:"#5cb85c"
          }
        });
        setError();
      }
      else
      {
        seteveryThingOkEmail(0);
        setEmail(mail);
        emailInputRef.current.setNativeProps({
      
          style: {
            borderColor:"red"
          }
        });


      }


      if(mail.length===0){
        setEmail(mail);
        emailInputRef.current.setNativeProps({
      
          style: {
            borderColor:"rgba(0, 0, 0, 0.4)",
          }
        });
        seteveryThingOkEmail(0);
        setError("");
      }
  

    };
//handle email blur
    const handleEmailBlur = () => {
      if (!everyThingOkEmail) {
        setError("L'adresse Email n'est pas encore valide \u{1F923}");
      }
    };


    //handle adress change
    const handleAdressChange= (adress) => {
  
      if (onlyLettersAndNumbers(adress)&& adress.length>0&& adress.length<300) {
        setAdress(adress);
        seteveryThingOkAdress(1);
        AdressInputRef.current.setNativeProps({
      
          style: {
            borderColor:"#5cb85c"
          }
        });
        setError();
      }
      else
      {
        seteveryThingOkAdress(0);
        setAdress(adress);
        AdressInputRef.current.setNativeProps({
      
          style: {
            borderColor:"red"
          }
        });

        setError("l'adresse ne peut contenir que des lettres, espace, nombres, points et virgules, min 1 et max 300 \u{1F92C}");
      }
    };



       // handle tel change


      const handleTelChange = (tel) => {


        if (onlyNumber(tel)) {
          setTel(tel);
          seteveryThingOkTel(1);

          TelInputRef.current.setNativeProps({
      
            style: {
              borderColor:"#5cb85c"
            }
          });
          setError();
    
        } else {
          seteveryThingOkTel(0);
          setTel(tel);
          TelInputRef.current.setNativeProps({
            style: {
              borderColor: "red",
            },
          });
       
      
        }
      };
//handle Tel blur
const handleTelBlur = () => {
  if (!everyThingOkTel) {
    setError("le numéro de téléphone doit contenir 8 chiffres \u{1F925}");
  }
};


   //Password 1 change
   const handlePassword1Change= (password) => {
    
    if (onlyLettersAndNumbers(password)) {
      setPassword1(password);
      seteveryThingOkPassword1(1);
      PasswordInputRef1.current.setNativeProps({
    
        style: {
          borderColor:"#5cb85c"
        }
      });
  
      setError();
    }
    else
    {
        seteveryThingOkPassword1(0);
      setPassword1(password);
      PasswordInputRef1.current.setNativeProps({
    
        style: {
          borderColor:"red"
        }
  
      });
      setError("le mot de passe  ne peut contenir que des chiffres et des lettres et ? !  min 1 et max 30\u{1F9D0}");

    }
  };


     //Password 2 change
     const handlePassword2Change= (password) => {
    
      if (onlyLettersAndNumbers(password) && password===password1) {
        setPassword2(password);
        seteveryThingOkPassword2(1);
        PasswordInputRef2.current.setNativeProps({
      
          style: {
            borderColor:"#5cb85c"
          }
        });
        setError();
      
      }
      else
      {
          seteveryThingOkPassword2(0);
        setPassword2(password);
        PasswordInputRef2.current.setNativeProps({
      
          style: {
            borderColor:"red"
          }
        });

  
      }
    };

    //handle Tel blur
const handlePassword2Blur = () => {
  if (!everyThingOkPassword2) {
    setError("le deux mots de passe ne sont pas encore compatibles \u{1F92F}");
  }
};


// Function to calculate the total weight for all sellers

// Function to calculate and return the modified value of the weight in cart foreach seller
const calculateTotalWeight = () => {
  let totalWeight = 0;
  if (sellersSku.length) {
    sellersSku.forEach((seller) => {
      const sellerProductsWeightInCart = parseFloat(seller[Object.keys(seller)[0]].productWeightProSeller) || 0;
      totalWeight += sellerProductsWeightInCart;
    });
  }
  return totalWeight;
};

const totalWeight = calculateTotalWeight();

// Function to calculate the total delivery price for order

function calculateDeliveryCost(totalWeight) {
  let totalDeliverySeller;
  if (totalWeight < 2001) {
    totalDeliverySeller = 4.2;
  } else {
    const totalWeightProductSeller = totalWeight / 1000;
    const totalWeightProductSellerRounded = Math.ceil(totalWeightProductSeller) - 2;
    totalDeliverySeller = 4.2 + (totalWeightProductSellerRounded * 0.3);

  }
  return  Number(totalDeliverySeller.toFixed(2));
}



//
const calculateDeliveryCostForAllSeller=()=>{
  let totalWeight = 0;
  let totalDeliveryPriceForAll = 0;
  if (sellersSku.length) {
    sellersSku.forEach((seller) => {
      const sellerProductsWeightInCart = parseFloat(seller[Object.keys(seller)[0]].productWeightProSeller) || 0;
      totalWeight += sellerProductsWeightInCart;
      totalDeliveryPriceForAll+=calculateDeliveryCost(sellerProductsWeightInCart); 
    });
   
  }
  return totalDeliveryPriceForAll;
};

//preparing cart an seller json strings

const [cartString, setCartString] = useState('');
const [sellersSkuString, setSellersSkuString] = useState('');


  // Update cartString and sellersSkuString whenever cart or sellersSku change
+ useEffect(() => {
    setCartString(JSON.stringify(cart));
    setSellersSkuString(JSON.stringify(sellersSku));

    setTimeout(() => {
      if(User && Object.entries(User).length !== 0){
}
else {

  setShowTypewriter(true);
}
  },1); // Simulate a 1-second delay

  }, []);






//insert order

  function insertOrder() {
    setLoading(true);
    triggerHapticFeedback();
    const user_id = userId;

    const user_name = Name;
    const user_email = Email;
    const user_address = Adress; // Fixed a typo here. It should be `user_address` instead of `user_adress`.
    const user_city = City;
    const user_tel = Tel;

    const user_password1 = password1;
    const user_password2 = password2;
    const totalOrder = totalPrice;

const totalWeightForOrder=calculateTotalWeight();
const totalDeliveryOrder = calculateDeliveryCostForAllSeller();


    axios.post('https://3oula.tn/appgate/checkoutApp.php?action=checkout', {
      user_id,
      user_name,
      user_email,
      user_address,
      user_tel,
      user_city,
      payement_method: selectedValue,
      user_password1,
      user_password2,
    
      cart: cartString,
      sku_sellers: sellersSkuString,
      totalPrice: totalOrder,
      totalOrderWeight: totalWeightForOrder,
      totalDelivery: totalDeliveryOrder,
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => {
        if (!response.data.success) {
          setLoading(false);
      
          setError(response.data.error);
        }
        else {
          sethideButton(false);
          setLoading(false);
          //navigate to paiment instruction after success
          triggerHapticFeedback(); 
          setShowTypewriter(false);


  
   
        let user_name;
        let user_id;
        const totalToBePayed=totalPrice+totalDeliveryOrder;
if(!User || Object.entries(User).length === 0){
setUser(response.data.user);
setUserIsLoggedIn(true);
user_name=response.data.user.name;
user_id=response.data.user.user_id;


setpayementDataNewUser({
  Status: "not confirmed",
  TotalToPay: totalToBePayed,
  PayementMethod: payementMethod,
  orderId: response.data.order_id,

});


setSuccess(`c'est bon t3adet el commande ya ${Name}, Le compte client a été créé aussi, tawa bech n3addik vérification lil Email 9bal koll chay. ba3d n3addik lil page bech t5alles w marhbe bik ba7thena tochrob 7aja?...\u{1F600}`);
setTimeout(() => {
  navigation.navigate('confirmUserMail', {UserMail:Email});

}, 5000);
} 
else {

  user_name=User[0].name;
  user_id=User[0].user_id;
  setSuccess(`c'est bon t3adet el commande ya ${user_name}, Tawa mazel ken paiment hani bech n3addik lil page bech t5alles...\u{1F60E}`);
  setTimeout(() => {
navigation.navigate('PayementGate', {PayementData:{Status:"not confirmed",TotalToPay:totalToBePayed, PayementMethod:payementMethod, orderId:response.data.order_id,

    }});}, 5000);

}
       



        }





        // go to confirmation page and pass user id as prop
      }
      )
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      
      });
  }
  




  
    return (
        <SafeAreaView   style={StyleCheckout.androidSafeArea}>

{/*term and condiotions */}

<Modal
          animationType={'slide'}
          transparent={true}
          visible={showTermAndConditions}
          onRequestClose={() => {
         
          }}
>

<TermAndConditions  closeTermAndConditions={closeTermAndConditions} />
</Modal>

     {/*menu login */}

<Modal
          animationType={'slide'}
          transparent={true}
          visible={showMenu}
          onRequestClose={() => {
            console.log('Modal has been closed.');
          }}
>

<MenuLoginUser  Customnavigation={navigation}  toggle={toggleMenu} />

</Modal>
 <ScrollView ref={ScrollViewRef}  style={StyleCheckout.root} >
               
                

  {/*the container for the logo and the button to go to the login menu*/}
  <View  style={StyleCheckout.logoAndButtonContainer}>

  <Pressable style={StyleCheckout.BackButton} onPress={() => { triggerHapticFeedback(); 
  
  navigation.navigate('home');
  
  }}>
  <Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
  </Pressable>
    <TouchableOpacity  onPress={toggleMenu}  style={StyleCheckout.loginButton}><Text><Feather name="menu" size={40} color="black" /></Text></TouchableOpacity >

</View>



{showTypewriter?(

<View style={StyleCheckout.FloomyRadioView}>

{showTypewriter?( 
<Typewriter text={textInfo}
delay={10} color={"black"}/>
):null}
<Pressable style={StyleCheckout.ErrorViewButton} onPress={()=>{setShowTypewriter(false)}}><Text style={StyleCheckout.ErrorViewText}>ok</Text></Pressable>

</View>


):null}
<View style={{width:Platform.OS==="web"?"100vw":"100%",maxWidth:Platform.OS==="web"?450:"100%", alignSelf:"center" }}>










 <Text style={StyleCheckout.Label}>Votre Nom et prénom</Text>
   <TextInput
   
   ref={NameInputRef}

              style={StyleCheckout.inputText}
              placeholder="Votre nom"
              onChangeText={handleNameChange}
              value={Name}
   />

   <Text style={StyleCheckout.Label}>Votre adresse Email *</Text>
   <TextInput
   
   ref={emailInputRef}

              style={StyleCheckout.inputText}
              placeholder="email"
              onChangeText={handleEmailChange}
              onBlur={handleEmailBlur}
              value={Email}
   />

<Text style={StyleCheckout.Label}>Votre ville *</Text>
<View   ref={CityInputRef} style={StyleCheckout.CityView}
>
<AutocompleteCity  onCitySelected={setCity}/></View>


<Text style={StyleCheckout.Label}>Votre adresse (Rue et numéro de la maison)  *</Text>
   <TextInput
   
   ref={AdressInputRef}

              style={StyleCheckout.inputText}
              placeholder="Votre adresse"
              onChangeText={handleAdressChange}
              value={Adress}
   />




<Text style={StyleCheckout.Label}>Votre numéro de Tel *</Text>
   <TextInput
   
   ref={TelInputRef}


              style={StyleCheckout.inputText}
              placeholder="Votre Tel"
              onChangeText={handleTelChange}
              onBlur={handleTelBlur}
              value={Tel}
   />



{/**check if user logged in not needed */}

{Object.entries(User).length === 0? (

<>
<Text style={StyleCheckout.Label}>Votre mot de passe *</Text>
   <TextInput
   
   ref={PasswordInputRef1}
  
   style={StyleCheckout.inputText}
   secureTextEntry={true}
   placeholder="Enter password"
   onChangeText={handlePassword1Change}
   value={password1}
   />


   <Text ref={TippPassword1} style={StyleCheckout.Passwordtipp}>Le mot de passe ne peut contenir que des lettres, des espaces, ainsi que les caractères . , ?!</Text>


   <Text style={StyleCheckout.Label}>Repetez votre mot de passe *</Text>
   <TextInput
   
   ref={PasswordInputRef2}

   style={StyleCheckout.inputText}
   secureTextEntry={true}
   placeholder="Enter password"
   onChangeText={handlePassword2Change}
   onBlur={handlePassword2Blur}
   value={password2}
   />

   <Text ref={TippPassword2} style={StyleCheckout.Passwordtipp}>Les deux mots de passe ne sont pas encore compatibles. Ce texte disparaîtra lorsque les deux mots de passe seront compatibles.</Text>

   </>

):null}

<View   style={StyleCheckout.radioButtonsView}>
<Text style={StyleCheckout.Label}>Sélectionnez un moyen de paiement *</Text>

<View style={StyleCheckout.RadioView}>
    <RadioButton value="Paiment par virement bancaire" selected={selectedValue === 'virement bancaire'} onSelect={() => {triggerHapticFeedback();handleRadioSelectTextFloomy('virement bancaire',false);}} />
     <TouchableOpacity onPress={()=>{handleRadioSelectTextFloomy('virement bancaire',true);}} style={StyleCheckout.RadioViewButton}>
     <Text><FontAwesome name="question" size={16} color="black" /></Text>
     </TouchableOpacity>
</View>


{showTypewriterRadioButtons&&selectedValue==="virement bancaire"?(

<View style={StyleCheckout.FloomyRadioView}>

{showTypewriterRadioButtons?( 
<Typewriter text={textInfo}
delay={10} color={"black"}/>
):null}
<Pressable style={StyleCheckout.ErrorViewButton} onPress={()=>{setshowTypewriterRadioButtons(false)}}><Text style={StyleCheckout.ErrorViewText}>ok</Text></Pressable>

</View>


):null}


      <View style={StyleCheckout.RadioView}>
        <RadioButton value="Paiment par mandat minute" selected={selectedValue === 'mandat minute'} onSelect={() => {handleRadioSelectTextFloomy('mandat minute',false);triggerHapticFeedback();}} />
         <TouchableOpacity onPress={()=>{handleRadioSelectTextFloomy('mandat minute',true);}} style={StyleCheckout.RadioViewButton}>
        <Text><FontAwesome name="question" size={16} color="black" /></Text>
         </TouchableOpacity>
      </View>

      {showTypewriterRadioButtons&&selectedValue==="mandat minute"?(

<View style={StyleCheckout.FloomyRadioView}>

{showTypewriterRadioButtons?( 
<Typewriter text={textInfo}
delay={10} color={"black"}/>
):null}
<Pressable style={StyleCheckout.ErrorViewButton} onPress={()=>{setshowTypewriterRadioButtons(false)}}><Text style={StyleCheckout.ErrorViewText}>ok</Text></Pressable>

</View>


):null}

      <View style={StyleCheckout.RadioView}>
           <RadioButton value="Paiment par D17" selected={selectedValue === 'd17'} onSelect={() => {triggerHapticFeedback();handleRadioSelectTextFloomy('d17',false);}} />
          <TouchableOpacity  onPress={()=>{handleRadioSelectTextFloomy('d17',true);}} style={StyleCheckout.RadioViewButton}>
            <Text><FontAwesome name="question" size={16} color="black" /></Text>
           </TouchableOpacity>
      </View>
      {showTypewriterRadioButtons&&selectedValue==="d17"?(

<View style={StyleCheckout.FloomyRadioView}>

{showTypewriterRadioButtons?( 
<Typewriter text={textInfo}
delay={10} color={"black"}/>
):null}
<Pressable style={StyleCheckout.ErrorViewButton} onPress={()=>{setshowTypewriterRadioButtons(false);}}><Text style={StyleCheckout.ErrorViewText}>ok</Text></Pressable>

</View>


):null}
  
    </View>

<View style={StyleCheckout.checkboxView}>
<Text style={StyleCheckout.Label}>Veuillez accepter les conditions d'utilisation *</Text>

<CheckBox value1="Lire les conditions d'utilisation" value="J'ai lu et j'accepte les conditions d'utilisation"  checked={checked} readTermAndConditions={readTermAndConditions} onSelect={handleCheck}/>

      </View>






  
  </View>

  

<View ref={BottomViewRef} style={StyleCheckout.bottomView}>



</View>


</ScrollView>
{/**check if everything os ok than give the pressable free to use*/}
{hideButton?(

everyThingOkName===1 && everyThingOkEmail===1&&everyThingOkAdress===1&& everyThingOkTel===1&&
   everyThingOkPassword1===1&&everyThingOkPassword2===1&&selectedValue&&checked? (
   <Pressable  style={StyleCheckout.loginButtonSuccess} onPress={insertOrder}>
   <Text style={StyleCheckout.loginButtonText}>passer ma commande</Text>
  </Pressable>
  ) : (   <Pressable  style={StyleCheckout.loginButtonFail}>
      <Text style={StyleCheckout.loginButtonTextFail}>Ce bouton disparaîtra lorsque tous les champs seront correctement remplis</Text>
    </Pressable>)
    
    ):null
    }


    

    {loading?(<View style={StyleCheckout.LoadingView}>
    <ActivityIndicator size="large" color="white" style={{ alignSelf: 'center' }} />

</View>):null}









          {/*error and loding handling*/}

          {Success?(
 <View style={StyleCheckout.SuccessView}>
 <Typewriter text={Success} 
delay={10} color={"white"}/>
     <TouchableOpacity onPress={()=>{triggerHapticFeedback();setSuccess();}}style={StyleCheckout.ErrorViewButton}><Text  style={StyleCheckout.ErrorViewText}>OK</Text></TouchableOpacity>

   </View>):null
    }
          {Error? (
  <View style={StyleCheckout.ErrorView}>
 <Typewriter text={Error} 
delay={10} />
    <TouchableOpacity onPress={unsetError} style={StyleCheckout.ErrorViewButton}>
      <Text style={StyleCheckout.ErrorViewText}>OK</Text>
    </TouchableOpacity>

  </View>
) : null}

    {loading?(<View style={StyleCheckout.LoadingView}>
    <ActivityIndicator size="large" color="white" style={{ alignSelf: 'center' }} />

</View>):null}



        </SafeAreaView>
    )
  };
  const StyleCheckout =StyleSheet.create({
    androidSafeArea: {
  

      paddingTop: Platform.OS === 'android' ? 25 : 0,
      backgroundColor:"#fcb103",
  
      flex: 1,
 
  
  
  
  },
  
  root:{
   flex:1,
    zIndex:-2,
    backgroundColor:"#fcb103",
  
  
  },
  headtext: {
    fontWeight: "bold",
    paddingHorizontal: 20,
    flex: 1, // This will make the text take all available space

  },
  inputText:{
  
    backgroundColor:"white", height:50, width:"90%", marginLeft:"5%", marginRight:"5%", borderWidth:2,borderColor:"rgba(0, 0, 0, 0.4)", borderRadius:4,paddingLeft:10
    
        },
        Label:{
  
          marginLeft:"5%", marginBottom:15, marginTop:15, fontSize:16, fontWeight:"bold"
      },


      CityView:{

        position:"relative",zIndex:1
    
      },
    

      Passwordtipp:{
        color:"red", alignSelf:"center", marginLeft:"5%", marginRight:"5%", fontSize:13,marginTop:-10, display:"none"
        
        
          },




          loginButtonFail:{
            paddingHorizontal:20,
  

            height: Platform.OS === 'ios' ? 90 : 60,
    justifyContent:"center", alignItems:"center",
            flex:1,
            flexDirection:"row",
          
            backgroundColor:"grey",
            width:"100%",
          
              alignItems: 'center',
              position: Platform.OS==="web"?"fixed":"absolute",
          bottom:0,
          
           left:0,
              zIndex:5,
              borderTopWidth:1,
              borderColor:"rgba(0, 0, 0, 0.4)",    
        },
        loginButtonSuccess:{
    


          justifyContent:"center", alignItems:"center",
   
          height: Platform.OS === 'ios' ? 90 : 60,
  
          flex:1,
          flexDirection:"row",
        
          backgroundColor:"#5cb85c",
          width:"100%",
        
            alignItems: 'center',
            position: Platform.OS==="web"?"fixed":"absolute",
        bottom:0,
        
         left:0,
            zIndex:5,
            borderTopWidth:1,
            borderColor:"rgba(0, 0, 0, 0.4)",
            
        },
    
    
    
     
      
        loginButtonTextFail:{
          fontSize: 14, fontWeight: "bold", color: "white", justifyContent: "center", alignItems: "center", alignSelf: "center", textAlign: "center"

        },
        loginButtonText:{
    
            fontSize:18, fontWeight:"bold", color:"white",justifyContent:"center", alignItems:"center"
        }
    ,





//radio buttons view
radioButtonsView:{
  backgroundColor:"#fcb103",paddingVertical:10,
},







  //checkbox
    checkboxView:{

    },

    checkbox: {
      marginLeft: "5%",backgroundColor:"white", height:20, width:20
    },
    checkboxText:{
      marginLeft:10
    },
      bottomView:{minHeight:500},
      LoadingView:{
        position:Platform.OS!=="web"?"absolute":"fixed", top:0,left:0,height:"115%",width:"100%", 
        backgroundColor:"rgba(0, 0, 0, 0.7)", justifyContent:"center", alignItems:"center"
      },
    

logoAndButtonContainer:{
  flex:1,
  flexDirection:"row",
  
  backgroundColor:"#fcb103",
  height:"auto",
  paddingVertical:20,
  width:"100%",
  justifyContent:"space-between"
  
  
  
  
  },
  
  logoImage:{
  
    height:60, width:60,margin:5,
    
      },
      loginButton :{
  
       
        justifyContent: 'center',
        alignItems: 'center',
    
    backgroundColor:"#fcb103",
  
    height:50,
    width:50,
    marginTop:10,
    marginBottom:10,
    marginRight:20,
  
  
  
  
    
    
    
    
    
    
    
      },

      BackButton:{

        height:50, width:50, backgroundColor:"#fcb103", alignItems:"center", justifyContent:"center", margin:15, borderRadius:50, 
      
       
      },
 //error and loading view
 //error and loading view
 LoadingView:{
  position:Platform.OS==="web"?"fixed":"absolute", top:0,left:0,height:Platform.OS==="web"?"100vh":"100%",width:"100%", zIndex:99,
  backgroundColor:"rgba(0, 0, 0, 0.7)", justifyContent:"center", alignItems:"center"
},
ErrorView:{flex:1, flexDirection:"column",
position:Platform.OS==="web"?"fixed":"absolute",  top:0,left:0,height:"auto",width:"100%", backgroundColor:"#DE2C2C", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:30,
},
SuccessView:{flex:1, flexDirection:"column",
position:Platform.OS==="web"?"fixed":"absolute",  top:0,left:0,height:"auto",width:"100%", backgroundColor:"#5cb85c", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:30,
},
ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
 marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",borderRadius:4,},

   //radio buttons
   RadioView:{width:"100%", height:40,maxHeight:40, marginBottom:10,paddingLeft:"5%",paddingBottom:10,
   flex:1, flexDirection:"row", overflow:"hidden", backgroundColor:"#fcb103", alignItems:"center", },
   RadioViewButton:{backgroundColor:"#bccc8f",
   width:28,height:28,borderRadius:28,
   justifyContent:"center",
  alignItems:"center",marginBottom:-10,
  
  },
  FloomyRadioView:{

backgroundColor:"white",width:"90%", marginHorizontal:"5%",paddingBottom:10,
borderColor:"rgba(0, 0, 0, 0.4)", borderRadius:4,borderWidth:1,
  }

})
export default  Checkout;
