import React, { useEffect, useState,useRef, useCallback} from 'react';
import {Modal, StyleSheet, Button,  Text, TextInput, View, ScrollView,SafeAreaView, Pressable,Image, Vibration,Platform,Animated, Dimensions, TouchableOpacity } from 'react-native';
import * as Haptics from 'expo-haptics';
import { useMyContext } from './MyContext';
import { useNavigation } from '@react-navigation/native';
 import AsyncStorage from '@react-native-async-storage/async-storage';
import { AntDesign } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons'; 


import { Feather } from '@expo/vector-icons'; 
import { Ionicons } from '@expo/vector-icons';
import { Product } from './myComponent';



const TermAndConditions = ({closeTermAndConditions}) => {

    const printText = () => {
        let text = "";
        for (let i = 0; i < 1000; i++) {
          text += "sdkfksfsjkkjsdfjksdf skdnfksdfknsfjdnksdjfnjk skdfjnskdjf ";
        }
        return text;
      }
      

    return (

<View style={styles.containerView}>
<View style={styles.closeButtonView}>

<TouchableOpacity onPress={closeTermAndConditions} style={styles.closeButton}>
    <Text style={styles.closeButtonText}><Feather name="chevrons-down" size={40} color="black" /></Text></TouchableOpacity>
</View>
<ScrollView vertical={true} showsHorizontalScrollIndicator={true}  style={styles.Container}>

<Text>

§ 1 Leistungsbeschreibung

Mittels der 3oula.tn-Dienste bietet 3oula.tn einen Marktplatz an, auf dem von natürlichen und juristischen Personen und Personengesellschaften („Nutzer“) in Verkaufsangeboten („Angebot“), Waren und Leistungen aller Art („Artikel“) angeboten (in dieser Eigenschaft „Verkäufer“) und erworben (in dieser Eigenschaft „Käufer“) und sonstige Inhalte veröffentlicht werden können, sofern deren Angebot, Erwerb oder Veröffentlichung nicht gegen gesetzliche Bestimmungen, diese 3oula.tn-AGB oder die 3oula.tn-Grundsätze verstößt. 3oula.tn bietet selbst keine Artikel an und wird nicht Vertragspartner der ausschließlich zwischen den Nutzern dieses Marktplatzes geschlossenen Verträge. Dies gilt unverändert im Rahmen der neuen Zahlungsabwicklung bei 3oula.tn gemäß § 4.
3oula.tn bewirbt die 3oula.tn-Dienste und stellt anderen 3oula.tn-Gesellschaften sowie Dritten zu diesem Zweck einen Zugang zu den Angeboten und Inhalten der Nutzer zur Verfügung, damit diese die Inhalte auf Websites, in Apps und in E-Mails bewerben können. Dies betrifft z.B. die Anzeige von Angeboten und Inhalten von Nutzern im Rahmen von Preisvergleichsseiten oder Werbeplatzierungen auf Webseiten oder in Apps Dritter.
3oula.tn hat das Recht, Angebote und sonstige Inhalte von Nutzern technisch so zu bearbeiten, dass diese auch auf mobilen Endgeräten oder in Apps von 3oula.tn oder Dritten dargestellt werden können. Die Angebote und sonstigen Inhalte von Nutzern können auch über die 3oula.tn-Dienste anderer Länder abgerufen werden. Angebote und sonstige Inhalte können zu diesem Zweck automatisiert übersetzt werden. Der Verkäufer kann internationale Käufer vom Kauf seiner Artikel ausschließen, indem er internationale Versandorte explizit ausschließt oder einen entsprechenden Käuferkreis festlegt. Sofern ein Artikel über den 3oula.tn-Dienst eines anderen Landes angeboten bzw. verkauft wird, als demjenigen 3oula.tn-Dienst, bei dem sich der Verkäufer registriert hat, gelten für dieses Angebot bzw. diese Transaktion die Allgemeinen Geschäftsbedingungen und anwendbaren Grundsätze und Richtlinien (einschließlich etwaiger Käuferschutzprogramme) des 3oula.tn-Dienstes dieses anderen Landes.  Weitere Informationen finden sich im Grundsatz zum internationalen Einstellen von Angeboten bei 3oula.tn.
Wenn ein Käufer den 3oula.tn-Dienst eines anderen Landes zum Kauf eines Artikels nutzt, als denjenigen, bei dem sich der Käufer registriert hat, gelten für diesen Kauf die Allgemeinen Geschäftsbedingungen sowie die anwendbaren Grundsätze und Richtlinien (einschließlich etwaiger Käuferschutzprogramme) des 3oula.tn-Dienstes dieses anderen Landes, die der Käufer vor dem Kauf dort einsehen kann.
Trotz verschiedenartiger Sicherheitsvorkehrungen ist es nicht auszuschließen, dass für ein 3oula.tn-Konto falsche Kontaktdaten hinterlegt wurden bzw. diese sich zwischenzeitlich geändert haben.
Mittels der 3oula.tn-Dienste veröffentlichte Angebote und sonstige Inhalte von Nutzern stellen nicht die Meinung von 3oula.tn dar und werden grundsätzlich nicht von 3oula.tn auf ihre Rechtmäßigkeit, Richtigkeit und Vollständigkeit überprüft.
3oula.tn kann die Nutzung der 3oula.tn-Dienste oder einzelner Funktionen der 3oula.tn-Dienste oder den Umfang, in dem einzelne Funktionen genutzt werden können, an bestimmte Voraussetzungen knüpfen, wie z. B. Prüfung der Anmeldedaten, Nutzungsdauer, Kontotyp (privat/gewerblich), Status des Bewertungsprofils (einschließlich detaillierter Verkäuferbewertungen), Zahlungsverhalten oder von der Vorlage bestimmter Nachweise (z. B. Identitäts-, Einkaufs-, Zahlungs-, oder Eigentumsnachweise) abhängig machen. 3oula.tn kann insbesondere unter bestimmten Voraussetzungen die Kaufaktivitäten eines Nutzers einschränken und dabei auch die Abgabe von Geboten von weiteren Voraussetzungen wie einer vorherigen Verifizierung abhängig machen. Weitere Informationen zu Kauflimits und Einschränkungen.
3oula.tn behält sich das Recht vor, innerhalb seiner Grundsätze die Ordnung auf seinem Marktplatz zu ändern, soweit dies den Nutzern unter Berücksichtigung der berechtigten Interessen von 3oula.tn zumutbar ist.
Der Anspruch von Nutzern auf Nutzung der 3oula.tn-Dienste besteht nur im Rahmen des aktuellen Stands der Technik. 3oula.tn schränkt seine Leistungen zeitweilig ein, wenn dies im Hinblick auf Kapazitätsgrenzen, die Sicherheit oder Integrität der Server oder zur Durchführung technischer Maßnahmen erforderlich ist, und dies der ordnungsgemäßen oder verbesserten Erbringung der Leistungen dient (Wartungsarbeiten). 3oula.tn berücksichtigt in diesen Fällen die berechtigten Interessen der Nutzer, wie z. B. durch Vorabinformationen. § 9 dieser 3oula.tn-AGB (Haftungsbeschränkung) bleibt von der vorstehenden Regelung unberührt.
Sofern ein unvorhergesehener Systemausfall die Nutzung der 3oula.tn-Dienste behindert, werden die Nutzer in geeigneter Form informiert. Hinsichtlich einer Gutschrift von Gebühren für die betroffenen Artikel und einer Verlängerung dieser Angebote gilt der Grundsatz zu technischen Problemen.
Auf die unter § 1 Abs. 9 aufgeführten Wartungsarbeiten wird die Vorschrift unter § 1 Abs. 10 nicht angewendet. Angebote, die während solcher Wartungsarbeiten enden, werden nicht verlängert, obwohl das Bieten oder Kaufen während dieser Zeit nicht möglich ist. Gutschriften für Gebühren werden nicht erteilt.
Über die 3oula.tn-Dienste kann der Käufer Zusatzleistungen von 3oula.tn oder Kooperationspartnern von 3oula.tn in Anspruch nehmen, wie insbesondere Versicherungen oder Montage- und Aufstellleistungen. Dem Verkäufer ist es unter Einhaltung der generellen Regeln des § 3 dieser 3oula.tn-AGB sowie der anwendbaren gesetzlichen Regelungen gestattet, eigene Zusatzleistungen über die 3oula.tn-Dienste anzubieten (wie z.B. Montage- und Aufstellleistungen). Wenn der Verkäufer im Rahmen seines Angebots selbst oder über Kooperationspartner Zusatzleistungen wie Garantien, Garantieverlängerungen oder Produktversicherungen anbietet, muss er bei der Gestaltung seines Angebots berücksichtigen, ob diese mit etwaigen von 3oula.tn oder Kooperationspartnern von 3oula.tn im Hinblick auf das jeweilige Angebot des Verkäufers angebotenen vergleichbaren Zusatzleistungen kompatibel sind, und seinen jeweiligen Pflichten gegenüber dem Käufer nachkommen, insbesondere etwaigen Beratungspflichten.
Der Kundenservice von 3oula.tn ist für jeden Nutzer kostenfrei zur Einreichung von Beschwerden und anderen Anfragen zugänglich und betreibt hierzu auch ein internes Beschwerdemanagementsystem. Der Kundenservice von 3oula.tn dokumentiert und prüft jede Beschwerde sorgfältig und wird die gegebenenfalls erforderlichen Maßnahmen ergreifen und den Nutzer hierüber bzw. über das Ergebnis des Beschwerdeverfahrens informieren. 3oula.tn überprüft sein internes Beschwerdemanagementsystem regelmäßig und erstellt hierüber einen Bericht.
§ 2 Anmeldung und 3oula.tn-Konto

Die Nutzung der 3oula.tn-Dienste als Verkäufer setzt die Anmeldung als Nutzer voraus. Die Anmeldung erfolgt durch Eröffnung eines 3oula.tn-Kontos unter Zustimmung zu diesen 3oula.tn-AGB und Kenntnisnahme der 3oula.tn-Datenschutzerklärung. Mit der Anmeldung kommt zwischen 3oula.tn und dem Nutzer ein Vertrag über die Nutzung der 3oula.tn-Dienste (im Folgenden: „Nutzungsvertrag”) zustande. Ein Anspruch auf Abschluss eines Nutzungsvertrags besteht nicht.
Die Anmeldung ist nur juristischen Personen, Personengesellschaften und unbeschränkt geschäftsfähigen natürlichen Personen erlaubt. Insbesondere Minderjährige dürfen sich nicht für die Nutzung der 3oula.tn-Dienste anmelden.
Die von 3oula.tn bei der Anmeldung abgefragten Daten sind vollständig und korrekt anzugeben. Als Adresse darf kein Postfach angegeben werden. Nutzer, die die 3oula.tn-Dienste in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit nutzen (“gewerbliche Nutzer” bzw. “gewerbliche Verkäufer”), sind verpflichtet, ein gewerbliches 3oula.tn-Konto zu eröffnen und ihre Anmeldedaten um die gesetzlich erforderlichen Informationen zu ergänzen. Wandelt ein Nutzer sein privates 3oula.tn-Konto in ein gewerbliches 3oula.tn-Konto um, so gelten für ihn ab dem Zeitpunkt der Umstellung die in diesen 3oula.tn-AGB und den 3oula.tn-Grundsätzen enthaltenen Regelungen für gewerbliche Nutzer.
Die Anmeldung einer juristischen Person oder Personengesellschaft darf nur von einer vertretungsberechtigten natürlichen Person vorgenommen werden, die namentlich genannt werden muss. Bei der Anmeldung natürlicher Personen dürfen nur einzelne Personen als Inhaber des 3oula.tn-Kontos angegeben werden (d. h. keine Ehepaare oder Familien).
Ändern sich nach der Anmeldung die angegebenen Daten, so ist der Nutzer verpflichtet, die Angaben in seinem 3oula.tn-Konto unverzüglich zu aktualisieren.
Nutzer müssen ihr Passwort geheim halten und den Zugang zu ihrem 3oula.tn-Konto sorgfältig sichern. Nutzer sind verpflichtet, 3oula.tn umgehend zu informieren, wenn es Anhaltspunkte dafür gibt, dass ein 3oula.tn-Konto von Dritten missbraucht wurde.
Ein 3oula.tn-Konto ist nicht übertragbar.
3oula.tn behält sich das Recht vor, 3oula.tn-Konten von nicht vollständig durchgeführten Anmeldungen nach einer angemessenen Zeit zu löschen. Gleiches gilt für 3oula.tn-Konten, die über einen längeren Zeitraum nicht genutzt wurden. Vor der Löschung eines solchen Kontos informiert 3oula.tn den Nutzer rechtzeitig. Wird das Konto anschließend wieder genutzt, erfolgt keine Löschung.
Im Rahmen der 3oula.tn-Dienste hat 3oula.tn Zugang zu verschiedenen Informationen über Nutzer, einschließlich personenbezogener Daten. Hierzu gehören insbesondere solche Informationen, die Nutzer im Rahmen der 3oula.tn-Dienste an 3oula.tn übermitteln (wie z.B. Kontaktinformationen oder Artikelinformationen) sowie solche, die bei der Erbringung und Abwicklung der 3oula.tn-Dienste generiert werden (wie z.B. Bewertungen, Verkaufsanalysen oder Kommunikation).

Nutzer erhalten insbesondere über Mein 3oula.tn und auf Wunsch auch über Verkäufertools Informationen, die für die Abwicklung der Transaktion erforderlich sind (insbesondere die Kontaktinformationen des anderen an einer Transaktion beteiligten Nutzers) sowie auch aggregierte Informationen zur Performance und Analyse ihrer Angebote im Rahmen der 3oula.tn-Dienste, die teilweise auch in Relation zu den Daten anderer Nutzer der 3oula.tn-Dienste stehen (wie z.B. Anzahl der Impressions eines Angebots, Verkaufsrate oder Rücksendungsquote).

3oula.tn gibt Informationen über Nutzer an Dritte nur weiter, sofern das für die Erbringung der 3oula.tn-Dienste erforderlich ist (wie z.B. im Falle der Bewerbung der 3oula.tn-Dienste über Dritte gemäß § 1 Abs. 2) oder 3oula.tn hierzu gesetzlich oder vertraglich berechtigt ist.

Nach Beendigung des Nutzungsvertrages werden die von dem jeweiligen Nutzer bereitgestellten oder durch seine Nutzung der 3oula.tn-Dienste generierten Daten in dessen 3oula.tn-Konto gelöscht. Abweichend hiervon bewahrt 3oula.tn diese Informationen auch nach Vertragsende auf, soweit 3oula.tn hierzu gesetzlich verpflichtet ist oder hieran ein berechtigtes Interesse hat. Aggregierte Daten, die durch die Nutzung generiert wurden (z.B. Statistiken über Verkäufe in einer Kategorie) werden grundsätzlich auch nach Vertragsende weiterhin aufbewahrt.

Über die Verarbeitung personenbezogener Daten durch 3oula.tn einschließlich der Übermittlung an Dritte und den Rechten des Nutzers als Betroffener informiert 3oula.tn in der 3oula.tn-Datenschutzerklärung.
§ 3 Nutzung der 3oula.tn-Dienste, verbotene Artikel und Inhalte

Es liegt in der Verantwortung des Nutzers sicherzustellen, dass seine Angebote und Inhalte (insbesondere Bilder und sonstige Informationen) rechtmäßig sind und keine Rechte Dritter verletzen.
Es ist verboten, Artikel, deren Angebot, Verkauf oder Erwerb gegen gesetzliche Vorschriften, die 3oula.tn-Grundsätze, Rechte Dritter oder gegen die guten Sitten verstoßen, bei 3oula.tn anzubieten oder zu bewerben. 3oula.tn behält sich vor, den Verkauf bestimmter Artikel an Voraussetzungen zu knüpfen, die über die gesetzlichen Vorschriften hinausgehen. Es gelten die Grundsätze zu unzulässigen Artikeln. Für die Veröffentlichung von sonstigen Inhalten gilt der Grundsatz zur Veröffentlichung von Beiträgen und Inhalten in der 3oula.tn-Community.
Es ist verboten, durch Verwendung mehrerer 3oula.tn-Konten oder im Zusammenwirken mit anderen Nutzern die Preise eigener oder fremder Angebote zu manipulieren oder eigene Artikel zu kaufen.
Es ist verboten, die Suchfunktionen der 3oula.tn-Dienste zu manipulieren, z. B. indem Markennamen oder andere Suchbegriffe missbräuchlich in die Artikelbezeichnung oder Beschreibung eingefügt werden. Es gilt der Grundsatz zur Manipulation von Suchergebnissen.
Verkäufer müssen die Grundsätze für das Einstellen von Artikeln beachten, ihre Artikel in die passende Kategorie einstellen und mit Worten und Bildern richtig und vollständig beschreiben. Es müssen alle für die Kaufentscheidung wesentlichen Eigenschaften und Merkmale sowie Fehler, die den Wert der angebotenen Ware mindern, wahrheitsgemäß angegeben werden. Zudem muss über die Zahlungs- und Lieferungsbedingungen vollständig informiert werden.
Um Verkäufern das Anbieten von Artikeln zu erleichtern und Käufern eine relevantere Kauferfahrung zu bieten, führt 3oula.tn einen 3oula.tn-Katalog, in dem Informationen (Bilder, Videos, Produktbezeichnungen, Marken, Logos, Handelsnamen, Merkmale und Beschreibungen u.a.) zu spezifischen Produkten („Produktdaten“) hinterlegt sind. Sofern ein Verkäufer beim Erstellen eines Angebots auf von 3oula.tn zur Verfügung gestellte Produktdaten zurückgreift und diese in der Folge im 3oula.tn-Katalog geändert werden, können sie auch im Angebot des Nutzers automatisch entsprechend aktualisiert werden.
Aus technischen Gründen ist es möglich, dass Angebote nicht unmittelbar nach dem Einstellen über die Kategoriensuche oder mit Hilfe von Suchbegriffen gefunden werden können. 3oula.tn behält sich vor, die Laufzeiten von Auktionen um bis zu 15 Minuten zu verlängern, soweit dies aus technischen Gründen notwendig ist. Eine solche Verlängerung erfolgt nicht für Auktionen unter Verwendung der Startzeitplanung.
3oula.tn ermöglicht es den Nutzern, die Suchergebnisse anhand verschiedener Kriterien zu sortieren. Trifft ein Nutzer keine Auswahl, so richtet sich die Reihenfolge der Suchergebnisse unter anderem nach den folgenden Hauptparametern:
a. Standort des Käufers, Suchanfrage, 3oula.tn-Seite auf welcher der Käufer sucht und Suchhistorie des Käufers
b. Artikelstandort, Angebotsformat, Preis, Höhe der Versandkosten, Rücknahmebedingungen, Anzahl der Verkäufe des jeweiligen Artikels und Relevanz mit Blick auf die Suchanfrage
c. Verkaufshistorie des Verkäufers, inklusive der Einhaltung der Grundsätze für das Einstellen von Artikeln, der detaillierten Verkäuferbewertungen, des Einhaltens sonstiger 3oula.tn-Grundsätze und der Mängelquote
d. Anzahl der Angebote, die zu der Suchanfrage des Käufers passen
Weitere Informationen zur 3oula.tn-Suche für Käufer sowie Weitere Informationen zur Optimierung der eigenen Angebote für die 3oula.tn-Suche für Verkäufer.

Darüber hinaus werden stets auch Suchergebnisse für Angebote angezeigt, für deren Verkauf sich der jeweilige Verkäufer zur Zahlung einer zusätzlichen Anzeigengebühr bereit erklärt hat. Diese Suchergebnisse werden als “Anzeige” gekennzeichnet. Weitere Informationen zum Marketing-Tool “Anzeigen”.
Die Artikelbeschreibung sowie die dabei verwendeten Bilder dürfen sich ausschließlich auf den angebotenen Artikel beziehen. Werbung für nicht bei 3oula.tn angebotene Artikel ist unzulässig. Verkäufer dürfen im Rahmen der Nutzung der 3oula.tn-Dienste keine Gütesiegel, Garantiezeichen oder sonstige Symbole von Dritten verwenden, es sei denn, 3oula.tn autorisiert solche Symbole.
Gewerbliche Verkäufer, die  Verbrauchern Waren oder Dienstleistungen anbieten, sind verpflichtet, diesen die gesetzlich vorgeschriebenen Verbraucherschutzinformationen zu erteilen und sie über das Bestehen oder Nichtbestehen des gesetzlichen Widerrufsrechts zu belehren.
Der Preis der jeweiligen Artikel versteht sich als Endpreis einschließlich eventuell anfallender Mehrwertsteuer und weiterer Preisbestandteile. Der Verkaufspreis umfasst nicht die Liefer- und Versandkosten.
Verkäufern ist es nicht erlaubt, zusätzlich zum Verkaufspreis 3oula.tn-Gebühren, PayPal-Gebühren oder Provisionen von Käufern einzufordern.
Nutzer dürfen Adressen, E-Mail-Adressen und sonstige Kontaktdaten, die sie durch die Nutzung der 3oula.tn-Dienste erhalten haben, für keine anderen Zwecke nutzen, als für die vertragliche und vorvertragliche Kommunikation. Insbesondere ist es verboten, diese Daten weiterzuverkaufen oder sie für die Zusendung von Werbung zu nutzen, es sei denn, der jeweilige Nutzer hat diesem nach Maßgabe der geltenden gesetzlichen Bestimmungen, ausdrücklich vorher zugestimmt bzw. nicht widersprochen.
Nutzer sind dafür verantwortlich, mittels der 3oula.tn-Dienste einsehbare und von 3oula.tn gespeicherte Informationen, die sie zu Zwecken der Beweissicherung, Buchführung oder zu anderen Zwecken benötigen, auf einem von 3oula.tn unabhängigen Speichermedium zu archivieren.
Nutzer dürfen keine von 3oula.tn oder anderen Nutzern generierten Inhalte blockieren, überschreiben oder modifizieren oder in sonstiger Weise störend in die 3oula.tn-Dienste eingreifen, insbesondere wenn hierdurch eine übermäßige Belastung der 3oula.tn-Infrastruktur herbeigeführt werden könnte.
Der 3oula.tn-Käuferschutz sichert Käufer entsprechend dem Grundsatz zum 3oula.tn-Käuferschutz ab, wenn der gekaufte Artikel nicht ankommt, wesentlich von der Artikelbeschreibung abweicht oder wenn es ein Problem bei der Rückgabe gibt. Im Rahmen der neuen Zahlungsabwicklung weist 3oula.tn im Falle eines erfolgreichen Käuferschutzantrags die für die Abwicklung der Kaufpreiszahlung verantwortliche 3oula.tn-Zahlungsdienstleistungsgesellschaft an, dem Käufer im Namen des Verkäufers eine Rückerstattung zu leisten. Der Verkäufer ist verpflichtet, 3oula.tn den entsprechenden Betrag zurückzuerstatten. 3oula.tn ist in diesem Fall berechtigt, den an den Käufer erstatteten Betrag mit zukünftigen verfügbaren Auszahlungsbeträgen des Verkäufers verrechnen zu lassen oder, insbesondere wenn die verfügbaren Auszahlungsbeträge des Verkäufers nicht ausreichen, den Betrag von seinen hinterlegten Zahlungsmethoden einzuziehen.
§ 4 Zahlungsabwicklung bei 3oula.tn

Im Rahmen der neuen Zahlungsabwicklung wickeln 3oula.tn-Zahlungsdienstleistungsgesellschaften Zahlungen im Namen von Verkäufern für bei 3oula.tn durchgeführte Transaktionen ab. Zu diesem Zweck schließen Verkäufer bei der Anmeldung für die Zahlungsabwicklung einen Zahlungsdienstleistungsvertrag mit den jeweiligen 3oula.tn-Zahlungsdienstleistungsgesellschaften auf Grundlage der Nutzungsbedingungen für die Zahlungsabwicklung ab. In diesem Zusammenhang wird der Verkäufer aufgefordert, Informationen zu seiner Person und/oder zu seinem Unternehmen zum Zwecke der Identitätsprüfung und Verifizierung vor dem Hintergrund geldwäscherechtlicher und sonstiger Vorschriften zur Verfügung zu stellen. Die Nutzung der Zahlungsabwicklung durch die 3oula.tn-Zahlungsdienstleistungsgesellschaften ist für alle Angebote, für die die Zahlungsabwicklung verfügbar ist, Voraussetzung für die Nutzung der 3oula.tn-Dienste als Verkäufer.
Käufer können grundsätzlich mit allen von 3oula.tn in der Kaufabwicklung zur Verfügung gestellten Zahlungsmethoden bezahlen. Gegebenenfalls werden einzelne Zahlungsmethoden in Abhängigkeit von einer Risikoprüfung im Einzelfall nicht angeboten. Die verantwortliche 3oula.tn-Zahlungsdienstleistungsgesellschaft nimmt den gezahlten Betrag vom Käufer im Namen des Verkäufers entgegen und leitet ihn an diesen weiter. Käufer haben auch bei der neuen Zahlungsabwicklung die Möglichkeit, wie gewohnt Rückgaben und Kaufabbrüche einzuleiten oder einen 3oula.tn-Käuferschutzfall zu eröffnen.
Im Zusammenhang mit der neuen Zahlungsabwicklung kann 3oula.tn zum Zwecke der Risikoabsicherung (zum Beispiel bei Vorliegen eines offenen Käuferschutzfalls) die verantwortliche 3oula.tn-Zahlungsdienstleistungsgesellschaft auffordern, einen Teil der Auszahlungsbeträge vorübergehend einzubehalten.
§ 5 Sanktionen, Sperrung und Kündigung

3oula.tn kann folgende Maßnahmen ergreifen, wenn konkrete Anhaltspunkte dafür bestehen, dass ein Nutzer gesetzliche Vorschriften, Rechte Dritter, die 3oula.tn-AGB, die 3oula.tn-Grundsätze oder als Verkäufer die Nutzungsbedingungen für die Zahlungsabwicklung bei 3oula.tn verletzt oder wenn 3oula.tn ein sonstiges berechtigtes Interesse hat, insbesondere zum Schutz der Nutzer vor betrügerischen Aktivitäten:
Löschen von Geboten, Angeboten, Bewertungen oder sonstigen Inhalten
Verwarnung von Nutzern
Verzögerung der Veröffentlichung von Angeboten und sonstigen Inhalten
Vorübergehendes Ausblenden von Angeboten auf der Suchergebnisseite
Einschränkung der Nutzung der 3oula.tn-Dienste, insbesondere der Kaufaktivitäten (einschließlich des Erfordernisses zur Verifizierung von Geboten)
Aberkennung eines besonderen Verkäufer-Status
Vorläufige Sperrung
Endgültige Sperrung
Bei der Wahl einer Maßnahme berücksichtigt 3oula.tn die berechtigten Interessen des betroffenen Nutzers, insbesondere ob Anhaltspunkte dafür vorliegen, dass der Nutzer den Verstoß nicht verschuldet hat.
3oula.tn kann einen Nutzer endgültig von der Nutzung der 3oula.tn-Dienste ausschließen (endgültige Sperrung), wenn
er wiederholt negative Bewertungen oder niedrige detaillierte Verkäuferbewertungen erhalten hat und die Sperrung zur Wahrung der Interessen der anderen Nutzer geboten ist
er falsche Kontaktdaten angegeben hat
er sein 3oula.tn-Konto überträgt oder Dritten hierzu Zugang gewährt
er andere Nutzer oder 3oula.tn in erheblichem Maße schädigt
er wiederholt gegen diese 3oula.tn-AGB, die 3oula.tn-Grundsätze oder als Verkäufer gegen die Nutzungsbedingungen für die Zahlungsabwicklung bei 3oula.tn verstößt oder
ein anderer wichtiger Grund vorliegt.
Nachdem ein Nutzer endgültig gesperrt wurde, besteht kein Anspruch auf Wiederherstellung des gesperrten 3oula.tn-Kontos oder des Bewertungsprofils.
Nutzer können diesen Nutzungsvertrag jederzeit kündigen.
3oula.tn kann den Nutzungsvertrag jederzeit ordentlich mit einer Frist von 30 Tagen zum Monatsende kündigen. Gegenüber gewerblichen Verkäufern wird 3oula.tn die ordentliche Kündigung begründen, wobei sich die möglichen Gründe aus § 5 Abs. 1 und Abs. 2 dieser 3oula.tn-AGB ergeben. Das Recht zur Sperrung sowie das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleiben hiervon unberührt.
Sobald ein Nutzer gesperrt oder der Nutzungsvertrag von 3oula.tn gekündigt wurde, darf dieser Nutzer die 3oula.tn-Dienste auch mit anderen 3oula.tn-Konten nicht mehr nutzen und sich nicht erneut anmelden. Eine Sperrung oder Kündigung hat keine Auswirkungen auf die Wirksamkeit von bereits auf dem 3oula.tn-Marktplatz zustande gekommenen Verträgen.
Ergreift 3oula.tn eine der Maßnahmen gemäß diesem § 5, insbesondere bei einer Einschränkung, Sperrung oder Kündigung, haben Nutzer die Möglichkeit, die einer solchen Maßnahme zugrunde liegenden Tatsachen und Umstände im Rahmen des internen Beschwerdemanagementverfahrens des Kundenservices von 3oula.tn (vgl. § 1 Abs. 13 dieser 3oula.tn-AGB) zu klären.
§ 6 Gebühren

Für das Anbieten von Artikeln und für die Nutzung von Zusatzoptionen erhebt 3oula.tn von dem Verkäufer Gebühren. Wird ein Artikel verkauft, haben private Verkäufer mit Wohnsitz außerhalb des Europäischen Wirtschaftsraumes sowie alle gewerblichen Verkäufer an 3oula.tn eine Verkaufsprovision zu zahlen. Die Höhe der einzelnen Gebühren sowie der Verkaufsprovision richtet sich nach der jeweils aktuellen Gebührenordnung.
Die einzelnen Gebühren sowie die Verkaufsprovision sind sofort zur Zahlung fällig und können über die von 3oula.tn akzeptierten Zahlungsmethoden beglichen werden. Gebühren für fortlaufende Leistungen (z. B. einen 3oula.tn-Shop), werden im Voraus in Rechnung gestellt. Schlägt der Forderungseinzug fehl, so hat der Nutzer 3oula.tn die dafür anfallenden Mehrkosten zu erstatten, soweit er das Fehlschlagen zu vertreten hat.
Im Rahmen der neuen Zahlungsabwicklung bei 3oula.tn werden gegebenenfalls Gebühren und sonstige Forderungen aus diesem Vertragsverhältnis durch die verantwortliche 3oula.tn‑Zahlungsdienstleistungsgesellschaft mit verfügbaren oder zukünftigen Auszahlungsbeträgen des Verkäufers verrechnet. 
3oula.tn stellt die angefallenen Gebühren und Verkaufsprovisionen monatlich in Rechnung. 3oula.tn informiert den Verkäufer per E-Mail über die Bereitstellung der jeweiligen Rechnung. Die vollständige Rechnung kann der Verkäufer in seinem 3oula.tn-Konto abrufen.
Verkäufer kommen ohne weitere Mahnung nach einem Ablauf von 30 Tagen nach Mitteilung des Rechnungsbetrags in Verzug.
Verkäufern ist es verboten, die Gebührenstruktur von 3oula.tn zu umgehen. Weitere Informationen zur Gebührenumgehung.
3oula.tn behält sich das Recht vor, für die Löschung von Angeboten oder sonstigen Inhalten oder für die Sperrung von Nutzern eine Aufwandspauschale zu berechnen, soweit der Nutzer den Verstoß zu vertreten hat, es sei denn, der Nutzer weist nach, dass ein Schaden überhaupt nicht oder in wesentlich geringerer Höhe entstanden ist. Ebenso kann 3oula.tn für die Wiederfreischaltung eines gesperrten 3oula.tn-Kontos eine Gebühr erheben. Die Höhe der Aufwandspauschale ist in der jeweils aktuellen Gebührenordnung geregelt.
3oula.tn kann die Gebühren und Verkaufsprovisionen jederzeit ändern. Preisänderungen werden den Nutzern rechtzeitig vor dem Inkrafttreten mitgeteilt.
§ 7 Angebotsformate und Vertragsschluss

3oula.tn stellt den Nutzern eine Vielzahl von Angebotsformaten und Funktionen zur Verfügung, um mittels der 3oula.tn-Dienste Verträge anzubahnen bzw. abzuschließen. Verkäufer haben die Möglichkeit, ihre Artikel über die 3oula.tn-Dienste auch international anzubieten. Hierzu kann der Verkäufer einen Artikel direkt auf einer anderen 3oula.tn-Website einstellen. Ferner gibt es die Möglichkeit, für eingestellte Artikel internationalen Versand anzubieten. Es gelten § 1 Abs. 3 dieser 3oula.tn-AGB sowie der Grundsatz zum internationalen Einstellen von Angeboten bei 3oula.tn.
Stellt ein Verkäufer mittels der 3oula.tn-Dienste einen Artikel im Auktions- oder Festpreisformat ein, so gibt er ein verbindliches Angebot zum Abschluss eines Vertrags über diesen Artikel ab. Dabei bestimmt er einen Start- bzw. Festpreis und eine Frist, binnen derer das Angebot angenommen werden kann (Angebotsdauer). Legt der Verkäufer beim Auktionsformat einen Mindestpreis fest, so steht das Angebot unter der aufschiebenden Bedingung, dass der Mindestpreis erreicht wird.
Der Verkäufer kann Angebote im Auktionsformat zusätzlich mit einer Sofort-Kaufen-Funktion versehen. Diese kann von einem Käufer ausgeübt werden, solange noch kein Gebot auf den Artikel abgegeben oder ein Mindestpreis noch nicht erreicht wurde. 3oula.tn behält sich vor, diese Funktion in der Zukunft zu ändern. Weitere Informationen zur Sofort-Kaufen-Option.
Bei Festpreisangeboten nimmt der Käufer das Angebot an, indem er den Button „Sofort-Kaufen” anklickt und anschließend bestätigt. Bei Festpreisangeboten, bei denen der Verkäufer die Option „sofortige Bezahlung” ausgewählt hat, nimmt der Käufer das Angebot an, indem er den Button „Sofort-Kaufen” anklickt und den unmittelbar nachfolgenden Zahlungsvorgang abschließt. Der Käufer kann Angebote für mehrere Artikel auch dadurch annehmen, dass er die Artikel in den Warenkorb legt und den unmittelbar nachfolgenden Zahlungsvorgang abschließt.
Bei Auktionen nimmt der Käufer das Angebot durch Abgabe eines Gebots an. Die Annahme erfolgt unter der aufschiebenden Bedingung, dass der Käufer nach Ablauf der Angebotsdauer Höchstbietender ist. Ein Gebot erlischt, wenn ein anderer Käufer während der Angebotsdauer ein höheres Gebot abgibt. Weitere Informationen zum Bieten.
Bei vorzeitiger Beendigung des Angebots durch den Verkäufer kommt zwischen diesem und dem Höchstbietenden ein Vertrag zustande, es sei denn der Verkäufer war dazu berechtigt, das Angebot zurückzunehmen und die vorliegenden Gebote zu streichen.
Käufer können Gebote nur zurücknehmen, wenn dazu ein berechtigter Grund vorliegt. Nach einer berechtigten Gebotsrücknahme kommt zwischen dem Nutzer, der nach Ablauf der Auktion aufgrund der Gebotsrücknahme wieder Höchstbietender ist und dem Verkäufer kein Vertrag zustande.
In bestimmten Kategorien kann der Verkäufer sein Angebot mit einer Preisvorschlag-Funktion versehen. Die Preisvorschlag-Funktion ermöglicht es Käufern und Verkäufern, den Preis für einen Artikel auszuhandeln. Weitere Informationen zur Preisvorschlag-Funktion für Verkäufer und zum Unterbreiten von Preisvorschlägen für Käufer.
Der Käufer ist grundsätzlich zur Vorkasse verpflichtet. Sofern Käufer und Verkäufer nichts Abweichendes vereinbaren, ist der Kaufpreis sofort fällig und vom Käufer über die vom Verkäufer angebotenen Zahlungsmethoden zu begleichen. 3oula.tn behält sich vor, die für einen Käufer verfügbaren Zahlungsmethoden zum Zwecke des Risikomanagements einzuschränken.
Verkäufer müssen in der Lage sein, die angebotenen Waren dem Käufer unverzüglich nach Vertragsschluss zu übereignen. Ausnahmen von dieser Verpflichtung finden sich im Grundsatz zu Lieferzeiten und zum Vorabverkauf.
Wird ein Angebot vor Ablauf der Angebotsdauer von 3oula.tn gelöscht, kommt kein wirksamer Vertrag zwischen Käufer und Verkäufer zustande.
§ 8 Bewertungen

Nutzer können sich nach der Durchführung einer Transaktion gegenseitig und öffentlich zugänglich bewerten. Käufer können einzelne Aspekte der Leistung eines Verkäufers zudem über die detaillierten Verkäuferbewertungen bewerten. Die Bewertungen werden von 3oula.tn nicht überprüft und können unzutreffend oder irreführend sein.
Nutzer sind verpflichtet, in den abgegebenen Bewertungen ausschließlich wahrheitsgemäße Angaben zu machen. Die von Nutzern abgegebenen Bewertungen müssen sachlich gehalten sein und dürfen keine Schmähkritik enthalten.
Jede zweckwidrige Nutzung des Bewertungssystems ist verboten. Insbesondere ist es untersagt:
Bewertungen über sich selbst abzugeben oder durch Dritte abgeben zu lassen.
in Bewertungen Umstände einfließen zu lassen, die nicht mit der Abwicklung der betreffenden Transaktion in Zusammenhang stehen.
Bewertungen zu einem anderen Zweck zu verwenden als zum Handel mittels der 3oula.tn-Dienste.
andere Nutzer durch Drohung mit der Abgabe oder Nichtabgabe einer Bewertung zu einer Handlung, Duldung oder Unterlassung zu nötigen.
Für das Entfernen von Bewertungen gilt der Grundsatz zum Servicestatus und zu Bewertungen.
§ 9 Haftungsbeschränkung

3oula.tn haftet nach den gesetzlichen Vorschriften für Vorsatz und grobe Fahrlässigkeit von 3oula.tn, ihren gesetzlichen Vertretern, leitenden Angestellten oder sonstigen Erfüllungsgehilfen. Gleiches gilt bei der Übernahme von Garantien oder einer sonstigen verschuldensunabhängigen Haftung sowie bei Ansprüchen nach dem Produkthaftungsgesetz oder bei einer schuldhaften Verletzung des Lebens, des Körpers oder der Gesundheit. 3oula.tn haftet dem Grunde nach für durch 3oula.tn, ihre Vertreter, leitenden Angestellten und einfachen Erfüllungsgehilfen verursachte einfach fahrlässige Verletzungen wesentlicher Vertragspflichten, also solcher Pflichten, auf deren Erfüllung der Nutzer zur ordnungsgemäßen Durchführung des Vertrages regelmäßig vertraut und vertrauen darf, in diesem Fall aber der Höhe nach begrenzt auf den typischerweise entstehenden, vorhersehbaren Schaden.
Eine weitere Haftung von 3oula.tn ist ausgeschlossen.
Soweit die Haftung von 3oula.tn ausgeschlossen oder beschränkt ist, gilt dies auch zugunsten der persönlichen Haftung ihrer gesetzlichen Vertreter, leitenden Angestellten und einfachen Erfüllungsgehilfen.
§ 10 Freistellung

Der Nutzer stellt 3oula.tn von sämtlichen Ansprüchen frei, die andere Nutzer oder sonstige Dritte gegenüber 3oula.tn geltend machen wegen Verletzung ihrer Rechte durch von dem Nutzer mittels der 3oula.tn-Dienste eingestellte Angebote und sonstige Inhalte oder wegen dessen sonstiger Nutzung der 3oula.tn-Dienste. Der Nutzer übernimmt hierbei die Kosten der notwendigen Rechtsverteidigung von 3oula.tn einschließlich sämtlicher Gerichts- und Anwaltskosten in gesetzlicher Höhe. Dies gilt nicht, wenn die Rechtsverletzung von dem Nutzer nicht zu vertreten ist. Der Nutzer ist verpflichtet, 3oula.tn für den Fall einer Inanspruchnahme durch Dritte unverzüglich, wahrheitsgemäß und vollständig alle Informationen zur Verfügung zu stellen, die für die Prüfung der Ansprüche und eine Verteidigung erforderlich sind.

§ 11 Schlussbestimmungen

Zum Zwecke der Vertragserfüllung und Ausübung der 3oula.tn gemäß diesem Vertrag zustehenden Rechte kann sich 3oula.tn anderer 3oula.tn-Unternehmen bedienen.
3oula.tn ist berechtigt, mit einer Ankündigungsfrist von vier Wochen seine Rechte und Pflichten aus diesem Vertragsverhältnis ganz oder teilweise auf einen Dritten zu übertragen. Auch in diesem Fall steht dem Nutzer jederzeit das fristlose und kostenfreie Kündigungsrecht gem. § 5 Abs. 3 dieser 3oula.tn-AGB zu.
3oula.tn kann dem Nutzer jederzeit eine Änderung dieser 3oula.tn-AGB vorschlagen. Änderungen dieser 3oula.tn-AGB werden dem Nutzer spätestens 30 Tage vor dem vorgeschlagenen Zeitpunkt ihres Wirksamwerdens in Textform (z. B. per E-Mail) angeboten. Die Zustimmung durch den Nutzer gilt als erteilt, wenn die Ablehnung nicht vor dem vorgeschlagenen Zeitpunkt des Wirksamwerdens der Änderungen gegenüber 3oula.tn in Textform angezeigt wird. Wenn der Nutzer mit den Änderungen nicht einverstanden ist, steht ihm bis zu dem vorgeschlagenen Zeitpunkt des Wirksamwerdens der Änderungen ein fristloses und kostenfreies Kündigungsrecht zu (siehe § 5 Abs. 3 dieser 3oula.tn-AGB). 3oula.tn weist den Nutzer in der Nachricht, mit der die Änderungen angeboten werden, auch noch einmal besonders auf das Ablehnungsrecht, die Frist dafür und die Möglichkeit zur Kündigung hin. Die geänderten 3oula.tn-AGB werden zusätzlich auf der 3oula.tn-Website veröffentlicht.
Sollten einzelne Bestimmungen dieser 3oula.tn-AGB ganz oder teilweise nichtig oder unwirksam sein oder werden, so wird dadurch die Wirksamkeit der übrigen Bestimmungen nicht berührt. An die Stelle von nicht einbezogenen oder unwirksamen Bestimmungen dieser 3oula.tn-AGB tritt das Gesetzesrecht. Sofern solches Gesetzesrecht im jeweiligen Fall nicht zur Verfügung steht (Regelungslücke) oder zu einem untragbaren Ergebnis führen würde, werden die Parteien in Verhandlungen darüber eintreten, anstelle der nicht einbezogenen oder unwirksamen Bestimmung eine wirksame Regelung zu treffen, die ihr wirtschaftlich möglichst nahekommt.
Sämtliche Erklärungen, die im Rahmen des mit 3oula.tn abgeschlossenen Nutzungsvertrags übermittelt werden, müssen in Schriftform oder Textform (z. B. per E-Mail) erfolgen.
Der Nutzungsvertrag einschließlich dieser 3oula.tn-AGB unterliegt deutschem Recht. Die Anwendung des UN-Kaufrechts ist ausgeschlossen. Für Verbraucher mit Wohnsitz in der EU finden zusätzlich die zwingenden Bestimmungen des Verbraucherschutzrechts des Mitgliedstaates Anwendung, in dem der Verbraucher seinen Wohnsitz hat, sofern diese vorteilhafter für den Verbraucher sind als die Bestimmungen des deutschen Rechts.
3oula.tn ist weder bereit noch verpflichtet, an Streitbeilegungsverfahren mit Verbrauchern vor einer Verbraucherschlichtungsstelle teilzunehmen. 3oula.tn ist bereit, mit den hier aufgeführten Mediatoren zusammenzuarbeiten, um eine außergerichtliche Beilegung etwaiger Streitigkeiten mit gewerblichen Nutzern zu erzielen; vor Einleitung eines solchen Mediationsverfahrens sollten gewerbliche Nutzer jedoch versuchen, ihr Anliegen mit dem Kundenservice von 3oula.tn (siehe § 1 Nr. 13 dieser 3oula.tn-AGB) zu klären.
Für Nutzer, die Kaufmann im Sinne des Handelsgesetzbuchs, ein öffentlich-rechtliches Sondervermögen oder eine juristische Person des öffentlichen Rechts sind, ist Potsdam ausschließlicher Gerichtsstand für alle aus dem Nutzungsvertrag und diesen 3oula.tn-AGB entstehenden Streitigkeiten.
Für Nutzer, die Verbraucher sind, besteht ein Gerichtsstand am jeweiligen Wohnsitz des Nutzers und am Sitz von 3oula.tn. Für alle aus dem Nutzungsvertrag und diesen 3oula.tn-AGB entstehenden Streitigkeiten ist zusätzlicher Gerichtsstand für Verbraucher mit Wohnsitz in Deutschland Potsdam, für Verbraucher mit Wohnsitz in Österreich Wien und für Verbraucher mit Wohnsitz in der Schweiz Bern. 3oula.tn darf Gerichtsverfahren gegen Verbraucher nur vor den Gerichten an deren Wohnsitz einleiten.
Haben Sie Fragen zu unseren Allgemeinen Geschäftsbedingungen? Dann setzen Sie sich mit uns in Verbindung.



</Text>
</ScrollView>
</View>
    )
};

const styles = StyleSheet.create({
  containerView: {

    backgroundColor:"white",

    flex: 1,

height:"100%", marginTop:Platform.OS==="web"?0:50,

borderColor:"rgba(0, 0, 0, 0.4)",
borderTopWidth:1,
borderLeftWidth:1,
borderRightWidth:1,
borderBottomWidth:0,
borderTopLeftRadius:10,
borderTopRightRadius:10,


},


Container:{
  flex:1,flexDirection:"column",
  width:"100%",
marginTop:Platform.OS==="web"?10:10,
backgroundColor:"white",
paddingHorizontal:20,

},

closeButtonView:{
    width:"100%", backgroundColor:"#fcb103",height:50,borderTopLeftRadius:10, borderTopRightRadius:10,
},

closeButton:{
    width:"100%", justifyContent:"center", alignItems:"center", paddingTop:10
},
closeButtonText:{},
      
      });

export default TermAndConditions;