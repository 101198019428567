

import React, { useEffect, useState,useRef, useCallback, useContext} from 'react';
import { Modal,Alert,TouchableOpacity, ActivityIndicator, StyleSheet, Button,  Text, TextInput, View, ScrollView,SafeAreaView, Pressable,Image, Vibration,Platform,Animated, KeyboardAvoidingView} from 'react-native';
import { Product, Category, CartButton, stylesComponent} from './myComponent';
import * as Haptics from 'expo-haptics';
import { Audio} from 'expo-av'; 
import { Ionicons } from '@expo/vector-icons';
import MenuLoginUser from './menuLoginForUser';
import SplashScreen from './splashScreen';
import Autocomplete from './Autocomplete';
import Cart from './cart';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AntDesign } from '@expo/vector-icons';
import { Octicons } from '@expo/vector-icons';

import { SimpleLineIcons } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons'; 

import { Feather } from '@expo/vector-icons'; 
import { LoadingView, SocialMediaContainer} from './myComponent';
import StarRatinginput from './starRatingInput'

import { useMyContext } from './MyContext';
import { useFocusEffect } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';
import * as Clipboard from 'expo-clipboard';
import Typewriter from "./typeWriter";
import axios from 'axios';



const isWeb = Platform.OS === 'web';

const SellerPageScreen = ({ navigation, route }) => {









//hide virtualezed list while leaving the page 
const {VirtualizedShownSellerPage,setVirtualizedShownSellerPage}=useMyContext();
  useFocusEffect(
    React.useCallback(() => {
  
      // allow virtualized list to rerender
      setVirtualizedShownSellerPage(true);
   
      return () => {
            // disallow virtualized list to rerender
            setVirtualizedShownSellerPage(false);
  
      };
    }, [])
  );









  //var to control modal menu
  const [showMenu, setshowMenu] = useState(false);
  //var to trigger a fetch for notification when back to homepgae
  const {contextTrigger, setcontextTrigger}=useMyContext();
//function to show menu
const toggleMenu= () => {
  triggerHapticFeedback();

setshowMenu(!showMenu);

};

//contain product details
const { propName } = route.params;
//contain comment id details
const { CommentId } = route.params;






//handle sharing 
const handleShare = async (linkToCopy) => {
  Alert.alert(
    'Hi',
    `Lien copié dans votre clipboard`,
    [
      {
        text: 'OK',
        onPress: async () => {
          await Clipboard.setStringAsync(linkToCopy);
      
        },
      },
    ]
  );
};

  //Error holding and displaying vars
const [Error, setError]=useState();
//const while loading
const [loading, setLoading] = useState(false);
  //trigger haptic feedback
//trigger haptic feedback
const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
  Haptics.notificationAsync(
    Haptics.NotificationFeedbackType.Success);}
}

//user and seller Objects
const {sellersArray, setsellersArray}=useMyContext();
const{User, setUser}= useMyContext({});
const{userLikeSeller, setuserLikeSeller}= useMyContext([]);
const{userDislikeSeller, setuserDislikeSeller}= useMyContext([]);



//const using useEffect and State to show and hide the scrollView for cart
const menuRef = useRef(null);
const headPageRef = useRef(null);
const commentBoxRef=useRef();

//const for the scrollspy function nb all ref are created with loop and stored in arrays
const ViewRefs = useRef([]);
const ButtonsRefs=useRef([]);
const SellerDetailInCartRef=useRef([]);

const TextButtonsMenuRef=useRef([]);
const ScrollSpyRef=useRef(null);

const [data, setData] = useState([]);
const [data2, setData2] = useState([]);
const [userId, setUserId] = useState('');
const [userSku, setUserSku] = useState('');
const [name, setname] = useState('');
//const to store the sugesstions and than pass them to const Array
 const [suggestions1, setSuggestions1] = useState([]);

//products data an categories
const [ProductCategoriesData, setProductCategoriesData] = useState('');
const [ProductData, setProductData] = useState('');

//seller data
const [seller, setSeller]=useState();
const [everyThingOkReply, seteveryThingOkReply]=useState(false);
const [Reply, setReply]=useState();
const [Comments, setComments]=useState('');
const[CommentsCopie, setCommentsCopie]=useState();
const[CommentsInitial, setCommentsInitial]=useState(5);
//like and dislike seller
const [isLiked, setIsLiked] = useState(false);

const[isDisliked, setisDisliked]=useState(false);

//scrolling to bottom to comment


const handleScrollToBottomSeller = () => {
  if(!isWeb){
  triggerHapticFeedback();
  scrollViewRef.current.scrollToEnd({ animated: true });
}else {
  window.scrollTo(0, document.body.scrollHeight);
}
};


  //comment text to database
  const [commentTextToDb, setCommentTextToDb] = useState('');
  const [replyText, setreplyText]=useState('');
  const commentInputRef= useRef();
  const replyInputRef = useRef();
  const [everyThingOkComment,seteveryThingOkComment]=useState(false);



//comment text handling
//check only letters and numbers in the comment input
 
//check only letters and numbers in the comment input
 //check only letters and numbers in the input
 const onlyLettersAndNumbers = (str) => {
  return /^[A-Za-z0-9'’èéäêöàùâêîôûçëïü\s.,?!]*$/.test(str);
};
  

  
//rreply show and hdie
const ReplyBoxRef=useRef([]);
const [ShownReply, setShownReply]=useState(0);



const showReplyAndInsertSeenUser = (commentid) => {
  triggerHapticFeedback();
  ReplyBoxRef.current[commentid].setNativeProps({ style: { display: 'flex' } });

setShownReply(commentid);

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded', // Set the header to application/x-www-form-urlencoded
  // Add any other headers you need
};

// Construct the form data
const formData = new FormData();
formData.append('comment_id', commentid);

// Use Axios to call the API
axios.post('https://3oula.tn/appgate/userNotification.php?action=SetSeenUserReplySellerShop', formData, {
  headers: headers, // Add headers here
})

  .then(response => {
    if (response.data.success) {
      setcontextTrigger(!contextTrigger);
    }
  
  })
  .catch(error => {
    console.error('API error:', error);
  });


}

const hideReply = (commentid) => {
  triggerHapticFeedback();
  ReplyBoxRef.current[commentid].setNativeProps({ style: { display: 'none' } });

setShownReply(0);

}

//setting the user stars input into state, use it later to insert to db
const [ratingValue, setRatingValue] = useState(0);

const handleCommentTextChange = (text) => {

  if(User!==undefined&&Object.entries(User).length > 0){
  if (onlyLettersAndNumbers(text)&& commentTextToDb.length<600) {
    setCommentTextToDb(text);
  
    commentInputRef.current.setNativeProps({
   style: {
        borderColor:"#5cb85c",color:"black"
      }
    });
    seteveryThingOkComment(true);
    setError('');
  }
  else
  {
    setCommentTextToDb(text);
commentInputRef.current.setNativeProps({
      style: {
        borderColor:"red",    color:"red"
      }
    });

    seteveryThingOkComment(false);
    setError("le commentaire ne peut contenir que des lettres, espaces, et nombres et  ? ! . , min 1 et max 600 \u{1F624}");
  }

 } else{
  setCommentTextToDb('');

  seteveryThingOkComment(false);
  setError('');
  setTimeout(() => {
    setError("pour pouvoir écrire un commentaire connectez vous a votre compte Client \u{1F625}");

  }, 200);
 }
};





//rplytext handling
const handleReplyTextChange = (text) => {
  if(User!==undefined&&Object.entries(User).length > 0){
  if (onlyLettersAndNumbers(text) && text.length<600) {
    setreplyText(text);
    replyInputRef.current.setNativeProps({
      style: {
        borderColor:"#5cb85c", color:"black"
      }
    });
    seteveryThingOkReply(true);
    setError('');

  }
  else
  {
    setreplyText(text);
    replyInputRef.current.setNativeProps({

      style: {
        borderColor:"red"  , color:"red"
      }
    });
    seteveryThingOkReply(false);
    setError("la réponse ne peut contenir que des lettres et des espaces, nombres et  ? ! . , min 1 et max 600 \u{1F62D}");
  }
} else{
  setreplyText('');
  seteveryThingOkComment(false);
  setError('');
  setTimeout(() => {
    setError("pour pouvoir écrire une réponse connectez vous a votre compte Client \u{1F631}");

  }, 200);
 }
};



//handle like
const handleLikeSeller=()=>{
  if(User && Object.entries(User).length !== 0){
  triggerHapticFeedback();
  const user_name=User[0].name;
  const user_image=User[0].user_image;
  const user_id=User[0].user_id;
const seller_sku= propName.seller_sku;
  const user_like_number = seller[0].user_like;
  
  
  
  if(!userLikeSeller.includes(seller_sku)){
  userLikeSeller.push(seller_sku);
  
    
    fetch('https://3oula.tn/appgate/likeandcommentapp.php?action=likeSeller', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `seller_sku=${seller_sku}&user_like_number=${user_like_number}&user_id=${user_id}&user_name=${user_name}&user_image=${user_image}`,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })



      .then((data) => {
        if (data.success) {
      
          myFetchDataFunction();
        
          setIsLiked(true);
    
        } else {
          setError(data.error);
          
  
        }
      })
  


      .catch((error) => {
       setError('There was a problem with the fetch operation:', error);
      });
    }
      else{
  
        setIsLiked(true);
       setError("Vous avez déjà aimé le Shop \u{1F610}");
      }
    
    }
    else {
  setError('');
  setTimeout(() => {
    setError("Pour pouvoir  aimer le Shop, connectez-vous à votre compte client \u{1F614}");
  }, 200);

  
    }


}

//handle dislike
const handleDisLikeSeller=()=>{
  if(User && Object.entries(User).length !== 0){
  triggerHapticFeedback();
  const seller_sku= propName.seller_sku;
  const seller_dislike_number = seller[0].seller_dislike_number;
  const user_name=User[0].name;
  const user_image=User[0].user_image;
  const user_id=User[0].user_id;
  if(!userDislikeSeller.includes(seller_sku)){
  
    userDislikeSeller.push(seller_sku);

    fetch('https://3oula.tn/appgate/likeandcommentapp.php?action=dislikeSeller', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `seller_sku=${seller_sku}&seller_dislike_number=${seller_dislike_number}&user_id=${user_id}&user_name=${user_name}&user_image=${user_image}`,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
  
      .then((data) => {
        if (data.success) {
      
          myFetchDataFunction();
          setisDisliked(true);
    
        } else {
          setError(data.error);
          
    
        }
      })
    
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
    }
    else{
  
      setisDisliked(true);
      setError("Vous n'aimez pas le Shop déjà...");
    }
  




  }
  else {
setError('');
setTimeout(() => {
  setError("Pour pouvoir ne pas aimer le Shop, connectez-vous à votre compte client \u{1F60E}");

}, 200);

  }









}


//handle comments
const insertComment=()=>{
  setLoading(true);
  triggerHapticFeedback();
const seller_sku= seller[0].sku_seller;
const seller_score = seller[0].seller_score;
const seller_review_number = seller[0].user_review_number;
const user_name = User[0].name;
const  user_image = User[0].user_image;
 const user_id = User[0].user_id;

fetch('https://3oula.tn/appgate/likeandcommentapp.php?action=insertCommentSeller', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  body: `commentText=${commentTextToDb}&ratingValue=${ratingValue}&seller_review_number=${seller_review_number}&seller_score=${seller_score}&user_name=${user_name}&user_image=${user_image}&sku_seller=${seller_sku}&user_id=${user_id}`,
})
  .then((response) => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then((data) => {
    if (data.success) {
      setLoading(false);
      myFetchDataFunction();
      handleScrollToBottomSeller();
    } else {
   
      setError(data.message);
      setLoading(false);
    }
  })
  .catch((error) => {
    setLoading(false);
    setError(data.message);
 
  });


};






//star rating score user input getting
const handleRatingChange = (value) => {
  triggerHapticFeedback();
  setRatingValue(value);

};



//sortby comments


//sorting comments by 



//sorting comments by 

//show more comment

const [sortedBy, setSortedBy]=useState('');
const handleShowMoreComment=()=>{
  setCommentsInitial(CommentsInitial+5);
}

const SortByDateDsd=(SortedByWhat)=>{
  triggerHapticFeedback();
  setSortedBy(SortedByWhat);
setLoading(true);
  //setCommentsInitial(CommentsInitial+5);
  const sortedData = [...CommentsCopie].sort((a, b) => {
    const dateA = new Date(a.datum); // Assuming the date property is in a valid date format
    const dateB = new Date(b.datum); // Assuming the date property is in a valid date format
    
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  });


  setCommentsInitial(sortedData.length);
    // Update the state or variable holding the sorted data
    setComments(sortedData);

setTimeout( ()=>{setLoading(false) }, 200);
   
}

const SortByDateAsd=(SortedByWhat)=>{
  triggerHapticFeedback();
  setSortedBy(SortedByWhat);
  setLoading(true);
  //setCommentsInitial(CommentsInitial+5);
  const sortedData = [...CommentsCopie].sort((a, b) => {
    const dateA = new Date(a.datum); // Assuming the date property is in a valid date format
    const dateB = new Date(b.datum); // Assuming the date property is in a valid date format
    
    if (dateA < dateB) {
      return 1;
    }
    if (dateA > dateB) {
      return -1;
    }
    return 0;
  });


  setCommentsInitial(sortedData.length);
    // Update the state or variable holding the sorted data
    setComments(sortedData);
    setTimeout( ()=>{setLoading(false) }, 200);

}





//sort by score
const SortByScoreDsd=(SortedByWhat)=>{
  setSortedBy(SortedByWhat);
  triggerHapticFeedback();
  setLoading(true);
  const sortedData = [...CommentsCopie].sort((a, b) => {
    const ratingA = parseFloat(a.user_stars);
    const ratingB = parseFloat(b.user_stars);
    
    if (ratingA < ratingB) {
      return 1;
    }
    if (ratingA > ratingB) {
      return -1;
    }
    return 0;
  });

  setCommentsInitial(sortedData.length);
    // Update the state or variable holding the sorted data
    setComments(sortedData);
    setTimeout( ()=>{setLoading(false) }, 200);
  };


//sort by score
const SortByScoreAsd=(SortedByWhat)=>{
  setSortedBy(SortedByWhat);

  triggerHapticFeedback();
  setLoading(true);
  const sortedData = [...CommentsCopie].sort((a, b) => {
    const ratingA = parseFloat(a.user_stars);
    const ratingB = parseFloat(b.user_stars);
    
    if (ratingA < ratingB) {
      return -1;
    }
    if (ratingA > ratingB) {
      return 1;
    }
    return 0;


  });

  setCommentsInitial(sortedData.length);
    // Update the state or variable holding the sorted data
    setComments(sortedData);
    setTimeout( ()=>{setLoading(false) }, 200);
  };




//handle reply
const insertReply=(commentId)=>{
  setLoading(true);
  triggerHapticFeedback();
  const seller_sku= seller[0].sku_seller;
  const seller_name= seller[0].name;
  const seller_image=seller[0].image;
  
  const user_name = User[0].name;
  const  user_image = User[0].user_image;
   const user_id = User[0].user_id;



  fetch('https://3oula.tn/appgate/likeandcommentapp.php?action=insertReplySeller', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `reply_text=${replyText}&comment_id=${commentId}&seller_sku=${seller_sku}&seller_name=${seller_name}&seller_image=${seller_image}&user_name=${user_name}&user_image=${user_image}&user_id=${user_id}`,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.success) {
      
        setLoading(false);
        myFetchDataFunction();
        handleScrollToBottomSeller();
      } else {
        setLoading(false);
        setError(data.message);
      }
    })
    .catch((error) => {
      setLoading(false);
      setError('There was a problem with the fetch operation:', error);
    });




};



//show more comments




const handleCommentShowMore=()=>{
  triggerHapticFeedback();
  setCommentsInitial(CommentsInitial+5);
}






//const to save the products, count, sellers, and total price when added 

const { cart, setCart } = useMyContext();
const{totalPrice, settotalPrice} = useMyContext();
const{totalDeliveryForAll, setTotalDeliveryForAll} = useMyContext();

const {countProductCart, setCount} = useMyContext();


const {sellersSku, setsellersSku} = useMyContext([]);

//const using useEffect and State to show and hide the scrollView for cart


const [showScrollViewRef, setShowScrollViewRef] = useState(false);

const [showSellerInfoCartViewRef, setshowSellerInfoCartViewRef]=useState(false);


const ScrollViewRef = useRef(null);




//when aDD to button is pressed feedback
const [pressedButton, setPressedButton] = useState(null);







const handlePressIn = (productSku) => {
setPressedButton(productSku);


};

const handlePressOut = () => {
setPressedButton(null);
};









///scrolling to comment box

const handleScrollToRefComment = () => {


  if(Platform.OS!=="web"){
 triggerHapticFeedback();
  commentBoxRef.current.measureLayout(
    ScrollViewRef.current,
    (x, y) => {
      ScrollViewRef.current.scrollTo({y: y-100, animated: true});
    }
  );

}else{

  const { top, bottom } = commentBoxRef.current.getBoundingClientRect();
      // Scroll the window to the desired position
      window.scrollTo({
        top: top,
        left: 0,
        behavior: 'auto', // 'auto' for instant scroll, 'smooth' for animated scroll
      });
    }


};







//setCount(countProductCart - 1);

const handleClickButtonsAddToCart = (productSku, sellerSku, productWeight, sellerToCart, productToCart) => {

triggerHapticFeedback();

try {
 
 
  const existingProductIndex = cart.findIndex((item) => item[productSku] !== undefined);
  const existingSellerIndex = sellersSku.findIndex((item) => item[sellerSku] !== undefined);
  if (existingProductIndex >= 0) {
    const existingCartItem = cart[existingProductIndex][productSku];
    existingCartItem.productQuantity += 1;
    const existingSellerItem = sellersSku[existingSellerIndex][sellerSku];
    existingSellerItem.productQuantityProSeller +=1;
    //updating the total price in cart
    existingSellerItem.productWeightProSeller = parseFloat(existingSellerItem.productWeightProSeller) + parseFloat(productWeight);
    const price= (existingCartItem.productPrice*1);
    existingSellerItem.totalSeller = parseFloat(existingSellerItem.totalSeller) + parseFloat(price);
  


    settotalPrice(prevtotalPrice => {
      const totalPrice = prevtotalPrice + price;
      return Number(totalPrice.toFixed(2));
    }
      );

   

  
  } 
  else
   {
  
    const newCartItem = {};
    newCartItem[productSku] = productToCart[productSku];
    newCartItem[productSku].productQuantity = 1;
    //cart.push(newCartItem);
    setCart(prevCart => [...prevCart, newCartItem]);


   const price= (newCartItem[productSku].productPrice*1);
   settotalPrice(prevtotalPrice => {
    const totalPrice = prevtotalPrice + price;
    return Number(totalPrice.toFixed(2));
  }
    );

  
    const existingSellerSku= sellersSku.findIndex((item) => item[sellerSku] !== undefined);

    if (existingSellerSku >= 0) {
      const existingSellerItem = sellersSku[existingSellerSku][sellerSku];
      existingSellerItem.productQuantityProSeller +=1;

      existingSellerItem.productWeightProSeller = parseFloat(existingSellerItem.productWeightProSeller) + parseFloat(productWeight);
      existingSellerItem.totalSeller = parseFloat(existingSellerItem.totalSeller) + parseFloat(price);

      }
      else {
        const newSeller = {};
        newSeller[sellerSku] =  sellerToCart[sellerSku];
        setsellersSku(prevsellersSku => [...prevsellersSku, newSeller]);

     
      }
    
  }
  

    setCount(countProductCart + 1);
    




  
} catch (error) {

  //console.log(error);
  
}

};

// Function to calculate and return the modified value of the weight in cart foreach seller
const calculateWeight = (weight) => {
if (weight >= 1000) {
  weight /= 1000;

}
return Number(weight.toFixed(3));
};









//source={{uri:`https://3oula.tn/${seller[0].image}?t=${Date.now()}`}}




//adjust input height

useEffect(() => {
  adjustInputHeight();
}, [commentTextToDb]);

const adjustInputHeight = () => {
  if (commentInputRef.current) {
    commentInputRef.current.setNativeProps({
      height: commentInputRef.current.scrollHeight,
    });
  }
};







// Function that construct the stars for rating 
function StarRating({ score, color }) {
  const filledStars = Array(parseInt(score)).fill().map((_, index) => (
    <AntDesign key={`filled-${index}`} name="star" size={14} color={color} />
  ));
  const emptyStars = Array(5 - parseInt(score)).fill().map((_, index) => (
    <AntDesign key={`empty-${index}`} name="star" size={14} color="grey" />
  ));
  const stars = [...filledStars, ...emptyStars];
  return <Text>{stars}</Text>;
}


//function to fetch data from 3oula.tn



 //function to fetch data from 3oula.tn
 const myFetchDataFunction= () => {
  const seller_sku= propName.seller_sku;
  // fetch data when component is mounted
  fetch('https://3oula.tn/appgate/likeandcommentapp.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `sku_seller_to_show=${seller_sku}`
  })
    .then((response) => response.json())
    .then((json) => {
      setProductData(json.Products_data); // set product data state
     
      setProductCategoriesData(Array.from(new Set(json.Products_data.map((product) => product.product_category))));
    setSeller(json.seller);
    setComments(json.comments);
    setCommentsCopie(json.comments);
    setReply(json.reply);


    })
   
    .catch((error) => {
      console.error(error);
    });
  };


  useEffect(() => {
    myFetchDataFunction();
  }, []);


















//Function when a button clicked jump to the section that have the category

//Function when a button clicked jump to the section that have the category
const jumpToCategory = (Category) => {
  if (Platform.OS!=="web") {
  triggerHapticFeedback();
  const ViewRef = ViewRefs.current[Category];
  if (ViewRef && ScrollViewRef.current) {
  ViewRef.measureLayout(
    ScrollViewRef.current,
    (x, y) => {
      ScrollViewRef.current.scrollTo({y: y-150, animated: true});
    },
 
 
  );
  }
}

else {
  const container = ScrollViewRef.current;
  const ViewRef = ViewRefs.current[Category];
  if (ViewRef) {
    const { top, bottom } = ViewRef.getBoundingClientRect();
    
    // Calculate the desired scroll position based on the element's position
    const scrollX = 0; // Horizontal position (0 for the left)
    const scrollY = top+ window.scrollY -60; // Vertical position
    
    // Scroll the window to the desired position
    window.scrollTo({
      top: scrollY,
      left: scrollX,
      behavior: 'auto', // 'auto' for instant scroll, 'smooth' for animated scroll
    });
  }
  
}




}


//show detail of seller in cart, wieght price...
const showDetailSellerCartText=(targetView)=>{
  const targetViewToChange = SellerDetailInCartRef.current[targetView];


  if (targetViewToChange!= null) {
 
    targetViewToChange.setNativeProps({
      style: {
       display:showSellerInfoCartViewRef? 'none' :'flex'
      }
    });

setshowSellerInfoCartViewRef(!showSellerInfoCartViewRef);
 
}

}



//Function to  imlement the ScrollSpy behavior to the menu of categories when scrolled

//Function to  imlement the ScrollSpy behavior to the menu of categories when scrolled

//Function to  imlement the ScrollSpy behavior to the menu of categories when scrolled
//on screen to get wich list are actually on screen

const [prevHighlightedButton, setPrevHighlightedButton] = useState(null);
let highlightedButton="";
const onScroll = (event, ProductCatDataForWeb) => {
  


//for web
// Web version
if (Platform.OS === 'web') {



 ProductCategoriesData&&ProductCategoriesData.map((ContainerCategory, index) => {
 
  const element = ViewRefs.current[`${ContainerCategory}q`];
  const container = ScrollSpyRef.current;
  const Button = ButtonsRefs.current[`${ContainerCategory}`];

  // Get the bounding box of the element's content relative to the viewport
  const { top, bottom,left} = element.getBoundingClientRect();

  if (top<70  && top>=-285) {
    // Setting the Buttons Background when the View that has the category is on the Top of Scroll Container
    ButtonsRefs.current[`${ContainerCategory}`]?.setNativeProps({
      style: { backgroundColor: 'black', borderRadius: 18 },
    });



     // console.log(prevHighlightedButton === ContainerCategory,prevHighlightedButton !== ContainerCategory);
    
     if (highlightedButton !== ContainerCategory)  {
  
      // Scroll the container to bring the element into view
      const containerLeft = container.getBoundingClientRect().left;
      const ButtonLeft = Button.getBoundingClientRect().left;
      // Button.innerHTML = ButtonLeft.toString();
      container.scrollLeft = ButtonLeft;
      highlightedButton=ContainerCategory;
 


    }



    // Setting the text of the buttons color when the View that has the category is on the Top of Scroll Container
    TextButtonsMenuRef.current[`${ContainerCategory}`]?.setNativeProps({
      style: { color: 'white' },
    });
  } else {
    // Setting the Buttons Background when the View that has the category is not more on the first position
    ButtonsRefs.current[`${ContainerCategory}`]?.setNativeProps({
      style: { backgroundColor: '#fffbe6' },
    });

    // Setting the text of the buttons color when the View that has the is not more on the first position
    TextButtonsMenuRef.current[`${ContainerCategory}`]?.setNativeProps({
      style: { color: 'black' },
    });
  }
});
}


//for mobile native
if (Platform.OS !== 'web') {

  ProductCategoriesData.map((ContainerCategory, index) => {
    ViewRefs.current[`${ContainerCategory}q`].measure((x, y, width, height, pageX, pageY) => {

if(pageY<70  && pageY>=-285){
      
//Setting the Buttons Background when the View that have the category is on the Top ofScroll Container

 ButtonsRefs.current[`${ContainerCategory}`]?.setNativeProps({style: {backgroundColor: 'black', borderRadius:18} });

ButtonsRefs.current[`${ContainerCategory}`].measureLayout(
  ScrollSpyRef.current,
  (x, y, width, height) => {
    ScrollSpyRef.current.scrollTo({ x, animated: false });
  }
);

//Setting the text of the buttons color when the View that have the category is on the Top ofScroll Container
TextButtonsMenuRef.current[`${ContainerCategory}`]?.setNativeProps({style: {color: 'white', } });
}
else 
{
      //Setting the Buttons Background when the View that have the category is not more on the first position

      ButtonsRefs.current[`${ContainerCategory}`]?.setNativeProps({ style: { backgroundColor: '#fffbe6' } });
      //Setting the text of the buttons color when the View that have the is not more on the first position

      TextButtonsMenuRef.current[`${ContainerCategory}`]?.setNativeProps({style: { color: 'black', }});
}

   
});
  

});

}



};




useEffect(() => {
  const handleScroll = (event) => {
    if(ProductCategoriesData!==undefined){
    onScroll(event, ProductCategoriesData);
    }
  };

  if (isWeb) {
    window.addEventListener('scroll', handleScroll);
  }

  return () => {
    if (isWeb) {
      window.removeEventListener('scroll', handleScroll);
    }
  };
}, [isWeb, ProductCategoriesData]);







if (ProductData === undefined || ProductCategoriesData === undefined  || seller === undefined || Reply === undefined|| Comments === undefined) {
  return <LoadingView/>
}



return (



//the Safe Area View


<>

<SafeAreaView    style={Platform.OS!=="web"?styles.androidSafeArea:styles.WebSafeArea}  >



<KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 100}
      style={{ flex: 1 }}
    >


{/*menu login */}

<Modal
          animationType={'slide'}
          transparent={true}
          visible={showMenu}
          onRequestClose={() => {
        
          }}
>

<MenuLoginUser  Customnavigation={navigation}  toggle={toggleMenu} />

</Modal>






{/*only for web*/}



{isWeb?(
  <>

<View ref={headPageRef} style={styles.logoAndButtonContainer}>

<Pressable style={styles.BackButton} onPress={() => {{setcontextTrigger(!contextTrigger);triggerHapticFeedback();} navigation.navigate('home'); }}>
  <Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
  </Pressable>


  <Pressable 
  

  
  onPress={toggleMenu}  style={styles.loginButton}><Text><Feather name="menu" size={40} color="black" /></Text></Pressable>

</View>
<View>
<Image style={styles.sellerImage} source={{uri:`https://3oula.tn/${seller[0].image}`}}/>
<Text style={styles.sellerName}>{seller[0].name} shop</Text>
<View style={styles.sellerStarsOnHeadPage}>
<StarRating score={seller[0].seller_score} color={"#FFFA61"}/><Text style={styles.scroeTextHead}> score: {seller[0].seller_score}</Text>
</View>
</View>
<View></View>
</>

):null}









{isWeb?(
<ScrollView ref={ScrollSpyRef} horizontal={true} showsHorizontalScrollIndicator={false} style={styles.scrollSpyMenuStickyWeb}>



{/*The loop through ProductCategoriesData to create the Buttons foreach category */}
{ProductCategoriesData.length > 0 &&
  ProductCategoriesData.map((ContainerCategory, index) => (


    <View key={index}>
{/*The Category Buttons each point to View in the next section */}
<Pressable
ref={(ref) => {ButtonsRefs.current[ContainerCategory] = ref}} 
onPress={() => jumpToCategory(`${ContainerCategory}q`)}
style={styles.buttonsScrollSpy}>
<Text ref={(ref) => {TextButtonsMenuRef.current[ContainerCategory] = ref}} style={styles.textButtonsScrollSpy}>{ContainerCategory}</Text>
</Pressable>

    </View>
  ))}

</ScrollView>

):null}
































{/*the Body of the page that contain all products sorted in different categories */}

<ScrollView stickyHeaderIndices={[1]}
ref={ScrollViewRef} veritcal={true} showsVerticalScrollIndicator={false} 
 style={styles.root} scrollEventThrottle={20} 
onScroll={onScroll}>

 
{/*the container for the logo and the button to show the login menu*/}
{!isWeb?(<View  >
<View ref={headPageRef} style={styles.logoAndButtonContainer}>

<Pressable style={styles.BackButton} onPress={() => {{setcontextTrigger(!contextTrigger);triggerHapticFeedback();} navigation.navigate('home'); }}>
  <Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
  </Pressable>


  <Pressable 
  

  
  onPress={toggleMenu}  style={styles.loginButton}><Text><Feather name="menu" size={40} color="black" /></Text></Pressable>

</View>

<View
>
<Image style={styles.sellerImage} source={{uri:`https://3oula.tn/${seller[0].image}`}}/>
<Text style={styles.sellerName}>{seller[0].name} shop</Text>
<View style={styles.sellerStarsOnHeadPage}>
<StarRating score={seller[0].seller_score} color={"#FFFA61"}/><Text style={styles.scroeTextHead}> score: {seller[0].seller_score}</Text>
</View>
</View>
</View>):null}




<View>

{/*Scrollspymenu*/}
{!isWeb?(
<ScrollView ref={ScrollSpyRef} horizontal={true} showsHorizontalScrollIndicator={false} style={styles.scrollSpyMenu}>



{/*The loop through ProductCategoriesData to create the Buttons foreach category */}
{ProductCategoriesData.length > 0 &&
  ProductCategoriesData.map((ContainerCategory, index) => (


    <View key={index}>
{/*The Category Buttons each point to View in the next section */}
<Pressable
ref={(ref) => {ButtonsRefs.current[ContainerCategory] = ref}} 
onPress={() => jumpToCategory(`${ContainerCategory}q`)}
style={styles.buttonsScrollSpy}>
<Text ref={(ref) => {TextButtonsMenuRef.current[ContainerCategory] = ref}} style={styles.textButtonsScrollSpy}>{ContainerCategory}</Text>
</Pressable>

    </View>
  ))}

</ScrollView>

):null}



</View>











{/*feedback section seller*/}


    <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}  style={styles.feedbackContainerSellerPage}>
<TouchableOpacity style={styles.LikeDislikeButton}
onPress={handleLikeSeller} disabled={isLiked}>
<Text style={{ color: 'black',fontSize:16}}>{isLiked ? 'Liked' : <Text>{seller[0].user_like}  <Feather name="thumbs-up" size={24} color="black" /></Text>}
</Text>
</TouchableOpacity>
 <TouchableOpacity style={styles.LikeDislikeButton}
  onPress={handleDisLikeSeller}disabled={isDisliked}>
 <Text style={{ color: 'black',fontSize:16}}>
    {isDisliked ? 'Disliked' : <Text>{seller[0].seller_dislike_number}  <Feather name="thumbs-down" size={24} color="black" /></Text>}
 </Text>
</TouchableOpacity>
<TouchableOpacity  onPress={() =>handleShare(`http://3oula.tn/seller_shop.php?seller125467juv895487=${seller[0].sku_seller}`)} style={styles.LikeDislikeButton}>
 <Text style={{ color: 'black',fontSize:16 }}>
 <AntDesign name="sharealt" size={24} color="black" />
 </Text>
</TouchableOpacity>
<TouchableOpacity onPress={handleScrollToRefComment} style={styles.LikeDislikeButton}>
 <Text style={{ color: 'black',fontSize:16}}>
 commenter   <FontAwesome5 name="comment-dots" size={24} color="black" /> </Text>
</TouchableOpacity>

</ScrollView>







 
{/*the View that contains product for each category */}

{/*/ the loop to get, container categories*/}
{ProductCategoriesData.length > 0 &&
ProductCategoriesData.map((ContainerCategory, index) => (
  <View  ref={(ref) => {ViewRefs.current[`${ContainerCategory}q`] = ref}}    style={styles.productViewConatiner} key={index}>

{/*the head of the product container */}
    <View style={styles.productViewContainerHead}>
  

    <Category name={ContainerCategory}/>

    </View>


    {/*the body of the product container */}
    <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} style={styles.productViewConatinerBody}>

{/*/ the loop to get,the products foreach category*/}
{VirtualizedShownSellerPage&&ProductData.length > 0 && ProductData.map((item, index) => (
<View key={index}>
  {item.product_category === ContainerCategory ? (
    //the products views
    <View style={styles.wrapperProductView} >



<View style={styles.productView} >
   
    <Pressable style={styles.wrapperProductViewPressable} onPress={() => {triggerHapticFeedback();navigation.navigate('productPage', {propName: item});}} >
 

   <Image source={{ uri: `https://3oula.tn/${item.product_image0}` }} style={styles.productImage} />


  <View style={styles.productScoreView}>

  <StarRating  score={item.product_score} color={"#fcb103"} />


  </View>

<View style={styles.productnameView}>


<Text style={styles.productName}>{item.product_name}</Text>
</View>


<View style={styles.productDescriptionView}>

<Text style={styles.productDescritption}>{item.product_description.slice(0, 10)+ '...'}    </Text>
</View>

</Pressable>



{sellersArray.length > 0 && sellersArray.map((seller, indexSeller) => (


<View key={indexSeller}>
{seller.sku_seller===item.seller_sku? ( 

<View>

<Pressable
       //onPressIn={() => handlePressIn(product.product_sku)}
      // onPressOut={handlePressOut}
      
    onPress={() => {
      const sellerToCart={
        [`${item.seller_sku}`]:{
          productSellerName: `${item.seller_name}`,
          productSellerSku: `${item.seller_sku}`,
          productSellerEmail: `${item.seller_email}`,
          productSellerScore:`${seller.seller_score}`,
          productSellerImage: `${item.seller_image}`,
          productQuantityProSeller:1,
          productWeightProSeller:`${item.product_weight}`,
          totalSeller:item.sale ?  `${((item.product_price / 100) * (100-item.sale)).toFixed(2)}`:`${item.product_price}`,
        }
      };

const productToCart = {
  [`${item.product_sku}`]: {
    productSku: `${item.product_sku}`,
    productName: `${item.product_name}`,
    productScore:`${item.product_score}`,
    productImage: `${item.product_image0}`,
    productSellerName: `${item.seller_name}`,
    productSellerSku: `${item.seller_sku}`,
    productSellerEmail: `${item.seller_email}`,
    productSellerTel: `${item.seller_tel}`,
    productSellerAdress: `${item.seller_adress}`,
    productSellerImage: `${item.seller_image}`,
    productPrice: item.sale ?  `${((item.product_price / 100) * (100-item.sale)).toFixed(2)}`:`${item.product_price}`,

    productWeight: `${item.product_weight}`,
    productQuantity:1,
  }

}


; 

handleClickButtonsAddToCart(item.product_sku, item.seller_sku,item.product_weight, sellerToCart, productToCart);
}}


      

style={({pressed}) => [
  {
    opacity: pressed ? 0.5 : 1,
  },
  styles.productPriceButton,
]}
    >
      
      <Text style={styles.productPriceTextSaleOldPrice}>{item.sale?item.product_price +' Tnd ':null}</Text>
      <Text style={styles.productPriceText}>
 {item.sale?((item.product_price/100)*(100-item.sale)).toFixed(2)+' Tnd ':item.product_price+' Tnd '}<AntDesign name="shoppingcart" size={16} color="black" />
  </Text>
  
     
     </Pressable>
     </View>):(null)}
     </View>
     
))}



    </View>
    <View style={styles.sellerNameAndLink}>
    <Image source={{ uri: `https://3oula.tn/${item.seller_image}` }} style={styles.sellerImageProductView} ></Image>
    <Pressable onPress={() => navigation.navigate('sellerPage', {propName:item})}  style={styles.sellerNameProductView}><Text style={styles.sellerNameProductViewText}>{item.seller_name}</Text></Pressable>
   </View>
   {item.sale?(<Pressable style={styles.productSale}><Text style={styles.productSaleText}>sale {item.sale}%</Text></Pressable>):null}
   </View>
  ) : (
null
  )}


</View>
))}



</ScrollView>


  </View>
))
}










   
 

{/* comments input */}
{/* comments input */}
<View style={StyleProduct.CommentsContainerBoxInput} ref={commentBoxRef}>
  <View style={StyleProduct.ratingInput}>

    <StarRatinginput onRatingChange={handleRatingChange} />
  

  </View>
  <TextInput ref={commentInputRef}   
            style={StyleProduct.commentInput}
            placeholder="entrez votre commentaire ici"
            onChangeText={handleCommentTextChange}
            multiline
            value={commentTextToDb}
          />
<Text style={{ color:everyThingOkComment?"green":"red",display:commentTextToDb.length>0?"flex":"none", fontSize:12}}>{commentTextToDb.length} / 600</Text>

{everyThingOkComment?( <Pressable onPress={insertComment} style={StyleProduct.commenterButton}>
            <Text style={StyleProduct.commenterButtonText}>commenter</Text>
            </Pressable>):( <Pressable style={StyleProduct.commenterButtonNotReady}>
            <Text style={StyleProduct.commenterButtonText}>commenter</Text>
            </Pressable>)}
         




</View>
     

{/*sortby*/}

<ScrollView  horizontal={true} showsHorizontalScrollIndicator={false} style={StyleProduct.SortByComments}>
<Pressable onPress={()=>{SortByScoreDsd('ScoreDsd')}} 
 style={() => [  {backgroundColor:sortedBy==="ScoreDsd"?"#E8E8E8":"white"}, StyleProduct.SortByButton, ]}>
  <Text style={StyleProduct.SortByButtonText}> Meilleurs avis  <SimpleLineIcons name="like" size={14} color="black" /></Text>
  </Pressable>
  <Pressable onPress={()=>{SortByScoreAsd('ScoreAsd')}}
   style={() => [  {backgroundColor:sortedBy==="ScoreAsd"?"#E8E8E8":"white"}, StyleProduct.SortByButton, ]}>
  <Text style={StyleProduct.SortByButtonText}> Plus mauvais avis  <SimpleLineIcons name="dislike" size={14} color="black" /></Text>
  </Pressable>
 
  <Pressable onPress={()=>{SortByDateDsd('DateDsd')}} 
   style={() => [  {backgroundColor:sortedBy==="DateDsd"?"#E8E8E8":"white"}, StyleProduct.SortByButton, ]}>
  <Text style={StyleProduct.SortByButtonText}>Plus anciens  <Octicons name="sort-asc" size={14} color="black" /></Text>
  </Pressable>
  <Pressable onPress={()=>{SortByDateAsd('DateAsd')}} 
   style={() => [  {backgroundColor:sortedBy==="DateAsd"?"#E8E8E8":"white"}, StyleProduct.SortByButton, ]}>
  <Text style={StyleProduct.SortByButtonText}>Plus nouveau  <Octicons name="sort-asc" size={14} color="black" /></Text>
  </Pressable>


  </ScrollView>


{/*comments text*/}



{Comments.length >0 &&  Comments.slice(0,CommentsInitial).map((item ,index) => (
  <View  style={{height:"auto",backgroundColor:"#fcb103"}}key={index}>


<View style={[{ backgroundColor: CommentId&&CommentId===item.comment_id ? "#D3D3D3" : "white" }, StyleProduct.CommentsContainerBox]}>
 {CommentId&&CommentId===item.comment_id ?(<Text style={StyleProduct.newReply}>Nouvelle Réponses</Text>):null}
<View style={StyleProduct.commentBoxNameFotoAndStars}>
  <View style={StyleProduct.commentBoxNameFotoAndStarsViews1}>


<Image source={{uri:`https://3oula.tn/${item.user_foto}`}} style={StyleProduct.userImage}></Image>
<Text style={StyleProduct.username}>{item.user_name}</Text></View>
<View style={StyleProduct.commentBoxNameFotoAndStarsViews2}>
<Text>{item.datum}</Text>
<View >
<StarRating score={item.user_stars}  color={"#fcb103"} /></View>
</View>
</View >

<View style={StyleProduct.CommentTextView}>
<Text style={StyleProduct.CommentText}>{item.comment_text.trim()}</Text>
</View>
{ShownReply!==item.comment_id? (
<Pressable style={StyleProduct.ShowReplyButton} onPress={() => showReplyAndInsertSeenUser(item.comment_id)}>
   <Text>
  {Reply.filter(reply => reply.comment_id === item.comment_id).length>0?
  (<Text> voir {Reply.filter(reply => reply.comment_id === item.comment_id).length} r&#233;ponse</Text>):(<Text>r&#233;pondre</Text>)}
  </Text>
</Pressable>)
:
(
<Pressable style={StyleProduct.ShowReplyButton} onPress={() => hideReply(item.comment_id)}><Text>masquer</Text></Pressable>
)}



</View>

  




<View  ref={(ref) => {ReplyBoxRef.current[item.comment_id] = ref}}  style={StyleProduct.replyBox}>


<View style={StyleProduct.ReplyContainerBoxInputInputAndReply}>
<Text style={{ color:everyThingOkReply?"green":"red",display:replyText.length>0?"flex":"none", fontSize:12}}>{replyText.length} / 600</Text>

  <TextInput ref={replyInputRef}  User

            style={StyleProduct.ReplyInput}
            placeholder="entrez votre r&#233;ponse ici"
            onChangeText={handleReplyTextChange}
            value={replyText}
          />

{everyThingOkReply?(  <Pressable onPress={() => insertReply(item.comment_id)} style={StyleProduct.commenterButton}>
            <Text style={StyleProduct.commenterButtonText}>reply</Text>
            </Pressable>):(  <Pressable  style={StyleProduct.commenterButtonNotReady}>
            <Text style={StyleProduct.commenterButtonText}>reply</Text>
            </Pressable>)}
</View>





{Reply.length > 0 &&  Reply.map((reply, indexReply) => (
reply.comment_id===item.comment_id? ( 
<View key={indexReply} style={StyleProduct.ReplyContainerBoxInputInputAndReply}>
<View style={StyleProduct.commentBoxNameFotoAndStars}>
{reply.user_name!=="1"?(<View style={StyleProduct.commentBoxNameFotoAndStarsViews1}><Image source={{uri:`https://3oula.tn/${reply.user_image}`}} style={StyleProduct.userImage}></Image>
<Text style={StyleProduct.username}>{reply.user_name}</Text></View>):(
  <View style={StyleProduct.commentBoxNameFotoAndStarsViews1}><Image source={{uri:`https://3oula.tn/${reply.seller_image}`}} style={StyleProduct.userImage}></Image>
  <Text style={StyleProduct.username}>{reply.seller_name} shop</Text></View>
)}
<View style={StyleProduct.commentBoxNameFotoAndStarsViews2}>
<Text>{reply.date}</Text>

</View>
</View >

<View style={StyleProduct.CommentTextView}>
<Text style={StyleProduct.CommentText}>{reply.comment_text.trim()}</Text>
</View>
</View>

):
(null)
))}





</View>
</View>





))}
















{Comments.length-CommentsInitial>0?(
<Pressable onPress={handleShowMoreComment} style={StyleProduct.ShowMoreCommentButton}>
  <Text style={StyleProduct.ShowMoreCommentButtonText}> voir plus {Comments.length-CommentsInitial}</Text>
</Pressable> 
  ):null}

    </ScrollView>






  </KeyboardAvoidingView>

  <Cart/>
  </SafeAreaView>
  {Error?(    <View style={styles.ErrorView}>
    <Typewriter text={Error} 
delay={10} />
      <TouchableOpacity onPress={()=>{triggerHapticFeedback();setError();}}style={styles.ErrorViewButton}><Text  style={styles.ErrorViewText}>OK</Text></TouchableOpacity>

    </View>):null}




    {loading?(<View style={styles.LoadingView}>
    <ActivityIndicator size="large" color="white" style={{ alignSelf: 'center' }} />

</View>):null}
  </>

);
};




  

const StyleProduct = StyleSheet.create({

  CommentsContainerBox:{
    height: "auto", width:"90%", marginLeft:"1%", marginRight:"9%", borderRadius: 30, borderWidth:1,
    borderColor:"rgba(0, 0, 0, 0.4)",
    justifyContent:"center", alignItems:"center", overflow:"hidden",paddingTop:20, paddingBottom:20, marginBottom:10,
      },
    
    
      replyBox:{flex:1,
        height: "auto", marginBottom:20, width:"98%", marginLeft:"1%", marginRight:"1%",
     
        justifyContent:"center", alignItems:"center", paddingTop:10, paddingBottom:20,
    display:"none"
    
      },
      CommentsContainerBoxInput: {
        height: 'auto',
        backgroundColor: 'white',
        marginBottom: 10,
        width: '98%',
        marginLeft: '1%',
        marginRight: '1%',
        borderRadius: 30,
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.4)',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        paddingTop: 0,
        paddingBottom: 10,
      },
    
     ReplyContainerBoxInputInputAndReply: {
        height: 'auto',
        backgroundColor: "rgb(224, 230, 207)",
        width: '90%',
        marginLeft: '9%',
        marginRight: '1%',
        borderRadius: 30,
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.4)',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    paddingVertical:20,
        marginBottom:10,
    
      },
    
    
    
    
    
    
    
      ratingInput:{
    
        width:"100%", flex:1, flexDirection:"row",paddingLeft:20,paddingTop:10
         },
         commentInput:{
        borderWidth:1,width:"90%",marginTop:10,borderRadius:10, padding:10,borderColor:"rgba(0, 0, 0, 0.4)",
        
          
         },
    
         ReplyInput:{
    
          borderWidth:1,width:"90%",marginTop:10,borderRadius:10, padding:10,borderColor:"rgba(0, 0, 0, 0.4)",backgroundColor:"white"
    
         },
    
         commenterButton:{
    
    backgroundColor:"#a7bf60", height:35, justifyContent:"center", alignItems:"center" ,paddingHorizontal:10, marginTop:10, borderRadius:10, marginLeft:"auto",
    marginRight:20
         },
         commenterButtonNotReady:{
            
          backgroundColor:"grey", height:35, justifyContent:"center", alignItems:"center" ,paddingHorizontal:10, marginTop:10, borderRadius:10, marginLeft:"auto",
          marginRight:20
               },
         commenterButtonText:{
    fontWeight:"bold", color:"white"
    
         },
    
    
    
    
    
    
    
      commentBoxNameFotoAndStars: {
        width: '90%',
        maxHeight: 50,
        backgroundColor: 'transparent',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
      },
      commentBoxNameFotoAndStarsViews1: {
        width: 'auto',
        flex: 1,
        flexDirection: 'row',
      },
      commentBoxNameFotoAndStarsViews2: {
        width: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
      },
      
      userImage:{
    
        height:40, width:40, borderRadius:40, justifyContent:"center", alignItems:"center"
      },
      username:{
    fontWeight:"bold", marginTop:10, marginLeft:5
    
      },
    
      CommentTextView:{
    
        width:"100%", paddingLeft:20,paddingTop:10, paddingRight:20
          },
      CommentText:{
      
      },
    
    
      
      ShowMoreCommentButton:{
        marginRight:"8%", marginLeft:"auto",
            height:40, width:120, paddingHorizontal:10, 
            backgroundColor:"white",justifyContent:"center", 
            alignItems:"center", borderRadius:15,
          
            
            shadowColor: 'rgba(0, 0, 0, 0.8)',
            shadowOffset: { width: 2, height: 4 },
            shadowOpacity: 0.7,
            shadowRadius: 4,
            elevation: 5
          },

      ShowReplyButton:{

        height:40, width:"auto", paddingHorizontal:10, 
        backgroundColor:"#a7bf60",justifyContent:"center", 
        alignItems:"center", borderRadius:10, marginLeft:"auto",marginRight:20,
      
        
        shadowColor: 'rgba(0, 0, 0, 0.8)',
        shadowOffset: { width: 2, height: 4 },
        shadowOpacity: 0.7,
        shadowRadius: 4,
        elevation: 5

      },
    
      ShowMoreCommentButtonText:{
        color:"black", fontWeight:"bold"
      },
    
    
    
      SortByComments:{
        height:52, 
        backgroundColor:"#fcb103", marginLeft:"1%", marginRight:"1%", marginBottom:10
        
        
          },
    


          commentLength:{
            fontSize:12, fontWeight:"bold", marginLeft:20, marginRight:"auto"
           },
          
           SortByButton:{
            margin:5,
            height:40, width:"auto", paddingHorizontal:10, 
          justifyContent:"center", 
            alignItems:"center", borderRadius:15,
          
            
            shadowColor: 'rgba(0, 0, 0, 0.8)',
            shadowOffset: { width: 2, height: 4 },
            shadowOpacity: 0.7,
            shadowRadius: 4,
            elevation: 5},
            
            SortByButtonText:{
              color:"black", fontWeight:"bold"
            },
            newReply:{

fontWeight:"bold", color:"black", 

            }

});































const styles = StyleSheet.create({
  androidSafeArea: {
  
    paddingTop: Platform.OS === 'android' ? 25 : 0,
    backgroundColor:"#fcb103",

    flex: 0,

    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    paddingBottom:Platform.OS!=="android"?500:0,



},





//only for web
WebSafeArea: {
  width:"100%", 
     paddingTop: Platform.OS === 'android' ? 25 : 0,
     backgroundColor:"#fcb103",
    flex:1,
    paddingBottom:500,

},
root:{
flex:1,
zIndex:-2,
backgroundColor:"#fcb103",

height:"100%"




},
sellerStarsOnHeadPage:{backgroundColor:"#fcb103", justifyContent:"center", alignItems:"center", height:30,flex:1, flexDirection:"row"


},
scrollSpyMenu :{
zIndex:-1,


maxHeight:46,
padding:2,
width:"98%",
marginLeft:"1%",
marginRight:"1%",
backgroundColor:"white",
borderRadius:4,
borderWidth:1,
borderColor:"rgba(0, 0, 0, 0.4)",
backgroundColor:"#fffbe6",
marginBottom:5,


},


//only for web

scrollSpyMenuStickyWeb:{

  zIndex:10,


  maxHeight:46,
  padding:2,
  width:"98%",
  marginLeft:"1%",
  marginRight:"1%",
  backgroundColor:"white",
  borderRadius:4,
  borderWidth:1,
  borderColor:"rgba(0, 0, 0, 0.4)",
  backgroundColor:"#fffbe6",
  marginBottom:5,
  position:"sticky",
  top:0,


},


buttonsScrollSpy:{
  height:40,
  width:"auto",
  minWidth:100,
  backgroundColor:"#fffbe6",
  padding:10,


},
textButtonsScrollSpy: {

fontWeight:"bold",
textAlign:"center",
},
productViewConatiner:{
width:"98%",
marginLeft:"1%",
marginRight:"1%",
borderRadius:4,
marginBottom:15,
height:350,
borderWidth:1,
borderColor:"rgba(0, 0, 0, 0.4)",
backgroundColor:"#fffbe6", 
},

feedbackContainerSellerPage:{
paddingHorizontal:"auto",
marginBottom:5,
marginHorizontal:"1%"
},
LikeDislikeButton:{
  height: 40,
  width: "auto",
  backgroundColor: 'white',
  borderRadius: 4,
margin:5,
  shadowColor: 'rgba(0, 0, 0, 0.5)',
  shadowOffset: { width: 0, height: 1 },
  shadowOpacity: 1,
  shadowRadius: 2,
  elevation: 2,
  justifyContent:"center", alignItems:"center",  
  paddingVertical: 0,
  paddingHorizontal: 5,

},


productViewContainerHead:{
height:40,
borderRadius:4,
backgroundColor:"#fffbe6",
justifyContent: 'center',
alignItems: 'center',

},
productViewConatinerBody:{

},
productView:{
height:290,
width:160,
backgroundColor:"#C8C8C8",

borderRadius:4,

overflow:"hidden"



},


wrapperProductViewPressable:{

  height:247,
marginBottom:3,
  width:160,
  backgroundColor:"white",
  overflow:"hidden"},


wrapperProductView:{
borderRadius:4,
height:290,
marginLeft:10,
width:160,
backgroundColor:"white",
 shadowColor: 'rgba(0, 0, 0, 0.8)',
shadowOffset: { width: 2, height: 4 },
shadowOpacity: 0.7,
shadowRadius: 4,
elevation: 5,},

productImage:{
height:160,
width:160,
},


productScoreView:{
flex:1,
flexDirection:"row",
height:20,
backgroundColor:"white",
paddingLeft:10,


},
productnameView:{
backgroundColor:"white",
height:30,


},
productName:{
fontWeight:"bold",
marginLeft:10,

},

productDescriptionView:{
backgroundColor:"white",
height:37,
marginBottom:3,


padding:5,
},
productPriceButton:{
height:40,
backgroundColor:"#a7bf60",

width:"100%",


justifyContent: 'center',
alignItems: 'center',



},




productPriceText:{
fontWeight:"bold",

marginTop:-3,
},
Footer:{
minHeight:500,
backgroundColor:"#fcb103",
},







menuLogin:{
  height:"100%",
  backgroundColor:"white",
  display:"none",




},


sellerNameAndLink:{
flexDirection:"row",

alignItems:"space-between",
height:40,
width:"100%",
backgroundColor:"rgba(0,0,0,0.3)",
marginTop:-270


},
sellerImageProductView:{
height:60,
width:60,
borderRadius:60,
marginLeft:5,
marginTop:Platform.OS==="web"?-10:0,
marginBottom:Platform.OS==="web"?0:-10,
borderWidth:1,  borderColor: "rgba(0, 0, 0, 0.3)"

},
sellerNameProductViewButton:{
flexWrap:"wrap",
alignItems:"flex-start",
overflow:"hiddden",

},
sellerNameProductView:{
  height:"100%", justifyContent:"center",alignItems:"center",  backgroundColor:"transparent",width:95,
},


sellerNameProductViewText:{
fontSize:12,
color:"white",
fontWeight:"bold",
margin:5,

},

textProductNameCart:{
fontSize:17,
fontWeight:"bold",

},
textProductQuantityCart:{
marginTop:5,
fontWeight:"bold",
fontSize:15,

},
textProductPriceCart:{
marginTop:10,
fontWeight:"bold",
fontSize:15,

},
splashScreenWrapper:{width:"100%", height:"110%",  top:0, left:0, zIndex: 99,justifyContent:"center", alignItems:"center"},
logoAndButtonContainerMenuLogin:{
flex: 1,
flexWrap: 'wrap',

maxHeight:70,
width:"100%",
alignContent:"space-between",

},






logoImage:{
height:60, width:60,margin:5,

},
loginButton :{

justifyContent: 'center',
alignItems: 'center',

backgroundColor:"#fcb103",
maxWidth:50,
height:50,


marginRight:20,
marginBottom:10,
marginTop:10,







},


logoAndButtonContainer:{
flex:1,
flexDirection:"row",

backgroundColor:"#fcb103",
minHeight:70, maxHeight:70,
paddingVertical:20,
width:"100%",
justifyContent:"space-between"




},

logoImage:{

height:60, width:60,margin:5,

  },
  loginButton :{

   
    justifyContent: 'center',
    alignItems: 'center',

backgroundColor:"#fcb103",

height:50,
width:50,
marginTop:10,
marginBottom:10,
marginRight:20,











  },


headText1View:{

  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
height:"auto",
  backgroundColor:"#fcb103",
 

},
headText2View:{

  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
height:50,
  backgroundColor:"#fcb103",
zIndex:-1,

},
headTextText1:{

  fontWeight:"bold",
  fontSize:16,
  //fontFamily: Platform.OS === 'ios' ? 'Chalkduster' : 'Chalkduster-Regular',
},


headTextText2:{

fontWeight:"bold",
fontSize:13,
alignSelf:"center",
// fontFamily: Platform.OS === 'ios' ? 'Chalkduster' : 'Chalkduster-Regular',
},

wrapperCustom: {
  borderRadius: 8,
  padding: 6,
},




//menu login
menuLoginButtonsContainer:{
flex:1, flexDirection:"row",  alignItems:"center", justifyContent: "space-between",marginTop:30

},
menuLoginButtons:{

  backgroundColor:"#0059ba", height:40, alignItems:"center",margin:10, width:150, borderRadius:4, justifyContent:"center"

},
menuLoginButtonsText:{

color:"white", fontWeight:"bold" 
},
userImageAndName:{
flexDirection:"column",paddingHorizontal:20


},
userImage:{
  height:70, width:70,marginBottom:10, borderRadius:70
},


sellerImage:{  height:150, width:150,marginBottom:10, borderRadius:150,marginLeft:"auto", marginRight:"auto", marginTop:Platform.OS==="web"?0:-70,
borderWidth:1,  borderColor: "rgba(0, 0, 0, 0.5)"
},
userName:{
fontWeight:"bold",
fontSize:18, alignSelf:"center"
},

sellerName:{
fontSize:20, fontWeight:"bold", alignSelf:"center",paddingBottom:3

},
BackButton:{

  height:50, width:50, backgroundColor:"#fcb103", alignItems:"center", justifyContent:"center", margin:15, borderRadius:50, 

 
},

 //error and loading view
 LoadingView:{
  position:Platform.OS!=="web"?"absolute":"fixed", top:0,left:0,height:"100%",width:"100%", 
  backgroundColor:"rgba(0, 0, 0, 0.7)", justifyContent:"center", alignItems:"center"
},
ErrorView:{flex:1, flexDirection:"column",
position:Platform.OS!=="web"?"absolute":"fixed", top:0,left:0,height:"auto",width:"100%", backgroundColor:"rgba(222, 44, 44, 0.92)", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:60,
},
ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
 marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",borderRadius:4,},

productSale:{

  backgroundColor:"rgba(218, 41, 28, 0.8)",width:80, height:30,marginLeft:10,marginTop:50,justifyContent:"center", alignItems:"center", borderRadius:20
},
productPriceTextSaleOldPrice:{color:"red",fontWeight:"bold",marginTop:-5,  textDecorationLine: "line-through",fontSize:10},

productSaleText:{fontWeight:"bold", color:"white"},


});

export default SellerPageScreen;
