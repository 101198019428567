import React , {useState, useEffect,useRef,useContext} from 'react';
import { Modal,Alert ,ActivityIndicator,ScrollView,StyleSheet, View, Text,TextInput, Pressable, Image, Dimensions ,Button,TouchableOpacity, Platform,Animated, KeyboardAvoidingView} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Audio} from 'expo-av'; 
import { AntDesign } from '@expo/vector-icons';
import MenuLoginUser from './menuLoginForUser';
import { useMyContext } from './MyContext';
import { Ionicons } from '@expo/vector-icons';
import { Octicons } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
import { SimpleLineIcons } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import Cart from './cart';
import StarRatinginput from './starRatingInput'
import { SafeAreaView } from 'react-native-safe-area-context';
import * as Haptics from 'expo-haptics';
import {  } from './myComponent';
import { LoadingView,Category, SocialMediaContainer} from './myComponent';
import NetInfo from '@react-native-community/netinfo';
import Typewriter from "./typeWriter";
import * as Clipboard from 'expo-clipboard';

import axios from 'axios';
const isWeb = Platform.OS === 'web';
const ProductPageScreen = ({ navigation, route }) => {



  //updating notification trigger
  const {contextTrigger, setcontextTrigger}=useMyContext();
  //var to control modal menu
  const [showMenu, setshowMenu] = useState(false);

//function to show menu
const toggleMenu= () => {
  triggerHapticFeedback();

setshowMenu(!showMenu);

};
//handle sharing 
const handleShare = async (linkToCopy) => {
  Alert.alert(
    'Hi',
    `Lien copié dans votre clipboard`,
    [
      {
        text: 'OK',
        onPress: async () => {
          await Clipboard.setStringAsync(linkToCopy);
      
        },
      },
    ]
  );
};




// Function to calculate and return the modified value of the weight in cart foreach seller
const calculateWeight = (weight) => {
  weight = parseFloat(weight); // Convert to a floating-point number

  let unit = "g"; // Default unit is grams

  if (weight >= 1000) {
    weight /= 1000; // Convert to kilograms
    unit = "kg"; // Update the unit to kilograms
  }

  const formattedWeight = Number(weight.toFixed(2));
  return `${formattedWeight} ${unit}`;
};




    const [isConnected, setIsConnected] = useState(true);
  
    useEffect(() => {
      const checkConnection = async () => {
        const state = await NetInfo.fetch();
        setIsConnected(state.isConnected);
  
        if (!state.isConnected) {
        
         // navigation.navigate('RegisterUser');
        }
      };
  
      checkConnection();
    }, [navigation]);
  
    // Render nothing if there is no internet connection
    if (!isConnected) {
      return null;
    }


//Error holding and displaying vars
const [Error, setError]=useState();
//const while loading
const [loading, setLoading] = useState(false);
  //trigger haptic feedback
//trigger haptic feedback
const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
  Haptics.notificationAsync(
    Haptics.NotificationFeedbackType.Success);}
}
   
  //data.sort((a, b) => a.name.localeCompare(b.name));
  //data.sort((a, b) => new Date(a.date) - new Date(b.date));
  const {sellersArray, setsellersArray}=useMyContext();
  const{User, setUser}= useMyContext({});
  const{userLikeProduct, setuserLikeProduct}= useMyContext([]);
  const{userDislikeProduct, setuserDislikeProduct}= useMyContext([]);

//setting the user stars input into state, use it later to insert to db
  const [ratingValue, setRatingValue] = useState(0);
  //comment text to database
  const [commentTextToDb, setCommentTextToDb] = useState('');
  const [everyThingOkReply, seteveryThingOkReply]=useState(false);
  const [replyText, setreplyText]=useState('');
  const commentInputRef= useRef();
  const replyInputRef = useRef();
  const [everyThingOkComment,seteveryThingOkComment]=useState(false);

  const commentBoxRef = useRef();
















//star rating score user input getting
  const handleRatingChange = (value) => {
    setRatingValue(value);

  };
//check only letters and numbers in the comment input
 //check only letters and numbers in the input
 const onlyLettersAndNumbers = (str) => {
  return /^[A-Za-z0-9'’èéäêöàùâêîôûçëïü\s.,?!]*$/.test(str);
};
  







const handleCommentTextChange = (text) => {

 if(User!==undefined&&Object.entries(User).length > 0){


  if (onlyLettersAndNumbers(text)&& commentTextToDb.length<600) {
    setCommentTextToDb(text);
  
    commentInputRef.current.setNativeProps({
   style: {
        borderColor:"#5cb85c",color:"black"
      }
    });
    seteveryThingOkComment(true);
    setError();
  }
  else
  {
    setCommentTextToDb(text);
commentInputRef.current.setNativeProps({
      style: {
        borderColor:"red",    color:"red"
      }
    });

    seteveryThingOkComment(false);
    setError('');
    setTimeout(() => {
      setError("le commentaire ne peut contenir que des lettres, éspaces, et nombres et  ? ! . , min 1 et max 600 \u{1F624}");

    }, 200);
  }
 }else{
  setCommentTextToDb('');
  seteveryThingOkComment(false);
  setError('');
  setTimeout(() => {
    setError("Pour pouvoir écrire un commentaire connectez vous a votre compte Client \u{1F621}");
  }, 200);
 
 }



};

//rplytext handling
const handleReplyTextChange = (text) => {
  if(User!==undefined&&Object.entries(User).length > 0){


  if (onlyLettersAndNumbers(text) && text.length<600) {
    setreplyText(text);
    replyInputRef.current.setNativeProps({
      style: {
        borderColor:"#5cb85c", color:"black"
      }
    });
    seteveryThingOkReply(true);
    setError();

  }
  else
  {
    setreplyText(text);
    replyInputRef.current.setNativeProps({

      style: {
        borderColor:"red"  , color:"red"
      }
    });
    seteveryThingOkReply(false);
    setError('');
    setTimeout(() => {
      setError("la réponse ne peut contenir que des lettres et des espaces, nombres et  ? ! . , min 1 et max 600 \u{1F625}");

    }, 200);
  }
}
else {
  setreplyText('');
  seteveryThingOkReply(false);
  setError('');
  setTimeout(() => {
    setError("pour pouvoir écrire une réponse connectez vous a votre compte Client \u{1F629}");

  }, 200);


}
};




//adjust input height

useEffect(() => {
  adjustInputHeight();
}, [commentTextToDb]);

const adjustInputHeight = () => {
  if (commentInputRef.current) {
    commentInputRef.current.setNativeProps({
      height: commentInputRef.current.scrollHeight,
    });
  }
};





//the fetch function to insert comments
const insertComment = () => {
  setLoading(true);
  const product_sku = propName.product_sku;
  const user_review_number = propName.user_review_number;
  const product_score = propName.product_score;
  const sku_seller = propName.seller_sku;

    const user_name = User[0].name;
   const  user_image = User[0].user_image;
    const user_id = User[0].user_id;


  fetch('https://3oula.tn/appgate/likeandcommentapp.php?action=insertComment', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `commentText=${commentTextToDb}&ratingValue=${ratingValue}&sku_product=${product_sku}&user_review_number=${user_review_number}&product_score=${product_score}&user_name=${user_name}&user_image=${user_image}&sku_seller=${sku_seller}&user_id=${user_id}`,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.success) {
        setLoading(false);
        myFetchDataFunction();
        handleScrollToBottom();
      } else {
        setLoading(false);
        setError(data.error);
     
      }
    })
    .catch((error) => {
      setLoading(false);
   
    });
};


//
const insertReply=(commentId)=>{
  triggerHapticFeedback();
  const sku_product = propName.product_sku;
  const user_review_number = propName.user_review_number;
  const product_score = propName.product_score;
  const sku_seller = propName.seller_sku;
  const seller_image =propName.seller_image;
  const seller_name=propName.seller_name;
  const comment_id=commentId;

  const user_name = User[0].name;
  const user_image = User[0].user_image;
  const user_id = User[0].user_id;


  fetch('https://3oula.tn/appgate/likeandcommentapp.php?action=reply', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `sku_product=${sku_product}&sku_seller=${sku_seller}&reply_text=${replyText}&user_name=${user_name}&user_id=${user_id}&user_image=${user_image}&comment_id=${comment_id}&seller_image=${seller_image}&seller_name=${seller_name}&product_score=${product_score}&user_review_number=${user_review_number}`,
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then((data) => {
    if (data.success) {
      setLoading(false);
      myFetchDataFunction();
      handleScrollToBottom();
    } else {
      setLoading(false);
      setError(data.error);
   
    }
  })
  .catch((error) => {
    setLoading(false);
    //console.error('There was a problem with the fetch operation:', error);
  });
};

















//contain product details
  const { propName } = route.params;
  //contain comment id details
  const { CommentId } = route.params;







//product object
  const [ProductData, setProductData] = useState();
//comments And Reply
const[Comments, setComments]=useState();
const[CommentsCopie, setCommentsCopie]=useState();

const[CommentsInitial, setCommentsInitial]=useState(5);
const[Reply, setReply]=useState();
const ReplyBoxRef=useRef([]);
const [ShownReply, setShownReply]=useState(0);




const showReplyAndInsertSeenUser = (commentid) => {
  triggerHapticFeedback();
  ReplyBoxRef.current[commentid].setNativeProps({ style: { display: 'flex' } });

setShownReply(commentid);

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded', // Set the header to application/x-www-form-urlencoded
  // Add any other headers you need
};

// Construct the form data
const formData = new FormData();
formData.append('comment_id', commentid);

// Use Axios to call the API
axios.post('https://3oula.tn/appgate/userNotification.php?action=SetSeenUserReplyProduct', formData, {
  headers: headers, // Add headers here
})

  .then(response => {
    if (response.data.success) {
      setcontextTrigger(!contextTrigger);
    }
  
  })
  .catch(error => {
    console.error('API error:', error);
  });


}





const hideReply = (commentid) => {
    triggerHapticFeedback();
  ReplyBoxRef.current[commentid].setNativeProps({ style: { display: 'none' } });

setShownReply(0);

}





const handleCommentShowMore=()=>{
triggerHapticFeedback();
  setCommentsInitial(CommentsInitial+5);
}
//similar product
const[SimilarProduct, setSimilarProduct]=useState();
//bought together
const[boughtTogether, setboughtTogether]=useState();
const[ProductChangeTrigger,setProductChangeTrigger]=useState(false);
const[UpdateTypeWriter,setUpdateTypeWriter]=useState(true);
 //function to fetch data from 3oula.tn
 const myFetchDataFunction= () => {
  const product_sku = propName.product_sku; 
const product_name=propName.product_name; 
const product_category=propName.product_category; 
  // fetch data when component is mounted
  fetch('https://3oula.tn/appgate/likeandcommentapp.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `sku_product_to_show=${product_sku}&product_name=${product_name}&product_category=${product_category}`,

  })
    .then((response) => response.json())
    .then((json) => {
      setProductData(json.Products_data); // set product data state
      setComments(json.comments);
      setCommentsCopie(json.comments);
      setSimilarProduct(json.similarProduct);

      setReply(json.reply);
      
      setboughtTogether(json.boughtTogether);



    })
   
    .catch((error) => {
      //console.error(error);
    });
    
  };


  useEffect(() => {
    myFetchDataFunction();

    setTimeout(() => {
      setUpdateTypeWriter(true);

    }, 600);


  }, [ProductChangeTrigger, countProductCart]);

  







  const { cart, setCart } = useMyContext();
  const {countProductCart, setCount} = useMyContext();
  
  const {sellersSku, setsellersSku} = useMyContext([]);
  const{totalPrice, settotalPrice} = useMyContext();
  const{totalDeliveryForAll, setTotalDeliveryForAll} = useMyContext();

  //images array constructing 
  const images = [];

  for (let i = 0; i <= 4; i++) {
    const image =propName[`product_image${i}`];
    if (image) {
      images.push(`https://3oula.tn/${image}`);
    }
  }

//images array length set and reset when changing products

const[Length, setLength] = useState(0);
useEffect(()=>{

  setLength(images.length);

},[images]);





const [position, setPosition] = useState(1);

// handle image scroll to show index from full length while scrolling 
const screenWidth = Dimensions.get('window').width;

const [screenWidthToSnap, setscreenWidthToSnap] = useState(0);


  
useEffect(() => {
if(screenWidth<768){

setscreenWidthToSnap(screenWidth);

}
else{
  setscreenWidthToSnap(400);
}
}, []);


const handleScroll = (event) => {
  
  const currentScrollPosition = event.nativeEvent.contentOffset.x;
  const halfScreenWidth = screenWidth / 2;
  let newPosition;
if(screenWidth<768){
 newPosition = Math.floor((currentScrollPosition + halfScreenWidth) / screenWidth) + 1;
} else {
 newPosition = Math.floor((currentScrollPosition + 200) / 400) + 1;
 
}


  setPosition(newPosition);
};
  




// Function that construct the stars for rating 
function StarRating({ score }) {
  const filledStars = Array(parseInt(score)).fill().map((_, index) => (
    <AntDesign key={`filled-${index}`} name="star" size={18} color="#fcb103" />
  ));
  const emptyStars = Array(5 - parseInt(score)).fill().map((_, index) => (
    <AntDesign key={`empty-${index}`} name="star" size={16} color="grey" />
  ));
  const stars = [...filledStars, ...emptyStars];
  return <Text>{stars}</Text>;
}











//sorting comments by 




//sorting comments by 

//show more comment

const [sortedBy, setSortedBy]=useState('');
const handleShowMoreComment=()=>{
  setCommentsInitial(CommentsInitial+5);
}

const SortByDateDsd=(SortedByWhat)=>{
  triggerHapticFeedback();
  setSortedBy(SortedByWhat);
setLoading(true);
  //setCommentsInitial(CommentsInitial+5);
  const sortedData = [...CommentsCopie].sort((a, b) => {
    const dateA = new Date(a.datum); // Assuming the date property is in a valid date format
    const dateB = new Date(b.datum); // Assuming the date property is in a valid date format
    
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  });


  setCommentsInitial(sortedData.length);
    // Update the state or variable holding the sorted data
    setComments(sortedData);

setTimeout( ()=>{setLoading(false) }, 200);
   
}

const SortByDateAsd=(SortedByWhat)=>{
  triggerHapticFeedback();
  setSortedBy(SortedByWhat);
  setLoading(true);
  //setCommentsInitial(CommentsInitial+5);
  const sortedData = [...CommentsCopie].sort((a, b) => {
    const dateA = new Date(a.datum); // Assuming the date property is in a valid date format
    const dateB = new Date(b.datum); // Assuming the date property is in a valid date format
    
    if (dateA < dateB) {
      return 1;
    }
    if (dateA > dateB) {
      return -1;
    }
    return 0;
  });


  setCommentsInitial(sortedData.length);
    // Update the state or variable holding the sorted data
    setComments(sortedData);
    setTimeout( ()=>{setLoading(false) }, 200);

}





//sort by score
const SortByScoreDsd=(SortedByWhat)=>{
  setSortedBy(SortedByWhat);
  triggerHapticFeedback();
  setLoading(true);
  const sortedData = [...CommentsCopie].sort((a, b) => {
    const ratingA = parseFloat(a.user_stars);
    const ratingB = parseFloat(b.user_stars);
    
    if (ratingA < ratingB) {
      return 1;
    }
    if (ratingA > ratingB) {
      return -1;
    }
    return 0;
  });

  setCommentsInitial(sortedData.length);
    // Update the state or variable holding the sorted data
    setComments(sortedData);
    setTimeout( ()=>{setLoading(false) }, 200);
  };


//sort by score
const SortByScoreAsd=(SortedByWhat)=>{
  setSortedBy(SortedByWhat);

  triggerHapticFeedback();
  setLoading(true);
  const sortedData = [...CommentsCopie].sort((a, b) => {
    const ratingA = parseFloat(a.user_stars);
    const ratingB = parseFloat(b.user_stars);
    
    if (ratingA < ratingB) {
      return -1;
    }
    if (ratingA > ratingB) {
      return 1;
    }
    return 0;


  });

  setCommentsInitial(sortedData.length);
    // Update the state or variable holding the sorted data
    setComments(sortedData);
    setTimeout( ()=>{setLoading(false) }, 200);
  };





///scrolling to comment box

const handleScrollToRefComment = () => {


  if(Platform.OS!=="web"){
 triggerHapticFeedback();
  commentBoxRef.current.measureLayout(
    scrollViewRef.current,
    (x, y) => {
      scrollViewRef.current.scrollTo({y: y-100, animated: true});
    }
  );

}else{

  const { top, bottom } = commentBoxRef.current.getBoundingClientRect();
      // Scroll the window to the desired position
      window.scrollTo({
        top: top,
        left: 0,
        behavior: 'auto', // 'auto' for instant scroll, 'smooth' for animated scroll
      });
    }


};











  //like is liked
 const [isLiked, setIsLiked] = useState(false);

 const[isDisliked, setisDisliked]=useState(false);
//handle like   const [isLiked, setIsLiked] = useState(false);
const handleLike = () => {



  if(User && Object.entries(User).length !== 0){
  triggerHapticFeedback();

  const user_name=User[0].name;
  const user_image=User[0].user_image;
  const user_id=User[0].user_id;
  const product_sku = ProductData[0].product_sku; // replace with your desired SKU
const user_like_number= ProductData[0].user_like_number;
const sku_seller=ProductData[0].seller_sku;

const product_name=ProductData[0].product_name;

if(!userLikeProduct.includes(product_sku)){
userLikeProduct.push(product_sku);

  // Insert user into MySQL database
  fetch('https://3oula.tn/appgate/likeandcommentapp.php?action=like', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `sku_product=${product_sku}&product_name=${product_name}&user_like_number=${user_like_number}&user_id=${user_id}&user_name=${user_name}&user_image=${user_image}&sku_seller=${sku_seller}`,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
 
    .then((data) => {
      if (data.success) {
    
        myFetchDataFunction();
      
        setIsLiked(true);
  
      } else {
        setError(data.error);
        

      }
    })

    .catch((error) => {

      setError('There was a problem with the fetch operation:', error);
    });
  }
    else{


      setError("Vous avez déjà aimé le produit \u{1F60F}");
    }
  

  }
  else {
    setError('');
    setTimeout(() => {
      setError("Pour pouvoir aimer le produit, connectez-vous à votre compte client \u{1F614}");

    }, 200);
  }


};

//handle like   const [isLiked, setIsLiked] = useState(false);
const handleDisLike = () => {

  if(User && Object.entries(User).length !== 0){
  triggerHapticFeedback();

  const user_name=User[0].name;
  const user_image=User[0].user_image;
  const user_id=User[0].user_id;
  const product_sku = ProductData[0].product_sku; // replace with your desired SKU
const user_dislike_number= ProductData[0].user_dislike_number;
const sku_seller=ProductData[0].seller_sku;
const product_name=ProductData[0].product_name;
if(!userDislikeProduct.includes(product_sku)){

userDislikeProduct.push(product_sku);

  fetch('https://3oula.tn/appgate/likeandcommentapp.php?action=dislike', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `sku_product=${product_sku}&product_name=${product_name}&user_dislike_number=${user_dislike_number}&user_id=${user_id}&user_name=${user_name}&user_image=${user_image}&sku_seller=${sku_seller}`,
  })



  .then((response) => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })

  .then((data) => {
    if (data.success) {
  
      myFetchDataFunction();
      setisDisliked(true);

    } else {
      setError(data.error);
      

    }
  })



    .catch((error) => {
      setError('There was a problem with the fetch operation:', error);

    });
  }
  else{

setError("Vous n'aimez pas le produit déjà...\u{1F614}");
  }
  }
  else {
setError('');
setTimeout(() => {
  setError("Pour pouvoir ne pas aimer le produit, connectez-vous à votre compte client \u{1F616}");
}, 200);


  }

};


//handle scroll to bottom
const scrollViewRef = useRef();

  const handleScrollToBottom = () => {
    if(!isWeb){
    triggerHapticFeedback();
    scrollViewRef.current.scrollToEnd({ animated: true });
  }else {

    window.scrollTo(0, document.body.scrollHeight);
  }
  };


//handle scroll to top
const scrollToTop = () => {
  if (!isWeb) {
    // Check if scrollViewRef is a valid reference to your ScrollView
    if (scrollViewRef && scrollViewRef.current) {
      // Scroll to the top of the ScrollView
      scrollViewRef.current.scrollTo({ x:0, y:0 , animated: true});
    }
  } else {
    // Scroll to the top of the web page
    window.scrollTo(0, 0);
  }
}











//when aDD to button is pressed feedback
const [pressedButton, setPressedButton] = useState(null);
//function to add products in the cart
//const updatedCart = cart.filter((item) => item[productSku] === undefined);
//setCart(updatedCart);
//setCount(countProductCart - 1);

const handleClickButtonsAddToCart = (productSku, sellerSku, productWeight, sellerToCart, productToCart) => {
 
triggerHapticFeedback();

  try {
   
   
    const existingProductIndex = cart.findIndex((item) => item[productSku] !== undefined);
    const existingSellerIndex = sellersSku.findIndex((item) => item[sellerSku] !== undefined);
    if (existingProductIndex >= 0) {
      const existingCartItem = cart[existingProductIndex][productSku];
      existingCartItem.productQuantity += 1;
      const existingSellerItem = sellersSku[existingSellerIndex][sellerSku];
      existingSellerItem.productQuantityProSeller +=1;
      //updating the total price in cart
      existingSellerItem.productWeightProSeller = parseFloat(existingSellerItem.productWeightProSeller) + parseFloat(productWeight);
      const price= (existingCartItem.productPrice*1);
      existingSellerItem.totalSeller = parseFloat(existingSellerItem.totalSeller) + parseFloat(price);
    


      settotalPrice(prevtotalPrice => {
        const totalPrice = prevtotalPrice + price;
        return Number(totalPrice.toFixed(2));
      }
        );



    
    } 
    else
     {
     
      const newCartItem = {};
      newCartItem[productSku] = productToCart[productSku];
      newCartItem[productSku].productQuantity = 1;
     
     
      setCart(prevCart => [...prevCart, newCartItem]);


     const price= (newCartItem[productSku].productPrice*1);
  
     settotalPrice(prevtotalPrice => {
      const totalPrice = prevtotalPrice + price;
      return Number(totalPrice.toFixed(2));

    }
   
      );

    
      const existingSellerSku= sellersSku.findIndex((item) => item[sellerSku] !== undefined);

      if (existingSellerSku >= 0) {
        const existingSellerItem = sellersSku[existingSellerSku][sellerSku];
        existingSellerItem.productQuantityProSeller +=1;

        existingSellerItem.productWeightProSeller = parseFloat(existingSellerItem.productWeightProSeller) + parseFloat(productWeight);
        existingSellerItem.totalSeller = parseFloat(existingSellerItem.totalSeller) + parseFloat(price);
  
        }
        else {
          const newSeller = {};
          newSeller[sellerSku] =  sellerToCart[sellerSku];
  
          setsellersSku(prevsellersSku => [...prevsellersSku, newSeller]);

       
        }
      
      
    }
    

    setCount((prevCount) => prevCount + 1);



    
 triggerHapticFeedback();
  

    
  } catch (error) {

    //console.log(error);
    
  }

};

//add all bought together only on one click
const addAllBoughtTogether = () => {

  boughtTogether.forEach((product) => {
    const sellerToCart = {
      [`${product.seller_sku}`]: {
        productSellerName: `${product.seller_name}`,
        productSellerSku: `${product.seller_sku}`,
        productSellerEmail: `${product.seller_email}`,
        productSellerScore:5,
        productSellerImage: `${product.seller_image}`,
        productQuantityProSeller: 1,
        productWeightProSeller: `${product.product_weight}`,
        totalSeller: product.sale ? `${((product.product_price / 100) * (100 - product.sale)).toFixed(2)}` : `${product.product_price}`,
      }
    };

    const productToCart = {
      [`${product.product_sku}`]: {
        productSku: `${product.product_sku}`,
        productName: `${product.product_name}`,
        productScore: `${product.product_score}`,
        productImage: `${product.product_image0}`,
        productSellerName: `${product.seller_name}`,
        productSellerSku: `${product.seller_sku}`,
        productSellerEmail: `${product.seller_email}`,
        productSellerTel: `${product.seller_tel}`,
        productSellerAdress: `${product.seller_adress}`,
        productSellerImage: `${product.seller_image}`,
        productPrice: product.sale ? `${((product.product_price / 100) * (100 - product.sale)).toFixed(2)}` : `${product.product_price}`,
        productWeight: `${product.product_weight}`,
        productQuantity: 1,
      }
    };

 handleClickButtonsAddToCart(product.product_sku, product.seller_sku, product.product_weight, sellerToCart, productToCart);

 });




};


const calculateBoughtTogetherPrice = () => {
  let Price = 0; // Initialize Price

  boughtTogether.forEach((product) => {
    if (product.sale) {
      // Use parseFloat to ensure correct addition of numbers
      Price += parseFloat(((product.product_price / 100) * (100 - product.sale)).toFixed(2));
    } else {
      // Use parseFloat to ensure correct addition of numbers
      Price += parseFloat(product.product_price);
    }
  });

  return Price.toFixed(2);
};





const snapOffsets = Array.from({ length: images.length }, (v, i) => i * screenWidth);



if (ProductData === undefined || Comments === undefined  || SimilarProduct === undefined || Reply === undefined || boughtTogether=== undefined) {
  return <SafeAreaView style={StyleProduct.androidSafeArea}>

<LoadingView/>

  </SafeAreaView>; // or any other loading indicator
}
  return (
<>
<SafeAreaView    style={Platform.OS!=="web"?StyleProduct.androidSafeArea:StyleProduct.WebSafeArea}  >
<KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 100}
      style={{ flex: 1 }}
    >



      {/*menu login */}

<Modal
          animationType={'slide'}
          transparent={true}
          visible={showMenu}
          onRequestClose={() => {
            //console.log('Modal has been closed.');
          }}
>

<MenuLoginUser  Customnavigation={navigation}  toggle={toggleMenu} />

</Modal>


{/*header only for web*/}




{isWeb?(     <View style={StyleProduct.HeadSticky}>
<Pressable style={StyleProduct.BackButton} onPress={() => { setcontextTrigger(!contextTrigger); triggerHapticFeedback();navigation.navigate('home');}}>

  <Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
  </Pressable>
  <Pressable  onPress={toggleMenu}  style={StyleProduct.loginButton}><Text><Feather name="menu" size={40} color="black" /></Text></Pressable>

 
 </View>):null}












    <ScrollView   showsVerticalScrollIndicator={false}
   ref={scrollViewRef} style={{backgroundColor:"#fcb103",marginTop: screenWidth <800 && Platform.OS === "web"? -90 : 0}} stickyHeaderIndices={[0]}>
 


{!isWeb?(     <View><View style={StyleProduct.Head}>
<Pressable style={StyleProduct.BackButton} onPress={() => { setcontextTrigger(!contextTrigger); triggerHapticFeedback();navigation.navigate('home');}}>

  <Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
  </Pressable>
  <Pressable  onPress={toggleMenu}  style={StyleProduct.loginButton}><Text><Feather name="menu" size={40} color="black" /></Text></Pressable>

 </View>
 </View>):null}





 
      <ScrollView horizontal={true} onScroll={handleScroll} scrollEventThrottle={100} decelerationRate={"fast"} 


      snapToInterval={screenWidthToSnap}
  
      pagingEnabled={true}
      scrollsToTop={false}
 
      automaticallyAdjustContentInsets={false}
      directionalLockEnabled={true}
snapToAlignment={"center"}

 contentInset={{top: 0,left: 0,bottom: 0,right: 0,}} showsHorizontalScrollIndicator={false}  
 
 

 
 style={{
  height: screenWidth < 800? Platform.OS === "web" ? 350 : 320: Platform.OS === "web" ? 400 : 320,
  width: screenWidth < 800?screenWidth:400,


alignSelf:"center",
marginTop:!isWeb? -70 :0,

    flexDirection:"row",



 
}}>



    {images.map((image, index) => (
 image!=='https://3oula.tn/null'?(
 <View key={index} ><Image source={{uri: image}} style={{ 
  
  height:screenWidth< 800?(Platform.OS==="web"?350:320):(Platform.OS==="web"?400:320),
 
 
 width:screenWidth< 800?screenWidth:400}}/></View>
 )
 :(null)))}
    </ScrollView>

{/*The View where the nuber of picture is */}
 <View style={StyleProduct.SlideShowGalleryIndex} ><Text style={StyleProduct.SlideShowGalleryIndexText} >{position +" / "+ Length}</Text>
 
 
 
 </View>
 {ProductData[0].sale ? (<View style={StyleProduct.SaleStamp}>
 <Text style={StyleProduct.SaleStampText}>sale {ProductData[0].sale} %</Text>
  </View>):null}


{/*The View where the product details are */}
<View style={{backgroundColor:"white", width:"100%",  borderTopWidth:1, borderBottomWidth:1,
marginTop:screenWidth< 800?20:50,paddingBottom:20}}>
<View style={StyleProduct.ProductPageProductDetail} >

 {/*The View where the product title, scroe and review number  are */} 
  <View style={StyleProduct.ProductDetailtitleView}>
  <Text style={StyleProduct.title} > {ProductData[0].product_name}</Text>
<View style={StyleProduct.starRatingView}>
<StarRating  score={ProductData[0].product_score} /></View>
<Text style={StyleProduct.RestTitleText}>Nombre des Avis: {ProductData[0].user_review_number}</Text>

    </View>  
    
    <View style={StyleProduct.ProductDetailSellerView}>
      <Image source={{ uri: `https://3oula.tn/${propName.seller_image}` }} style={StyleProduct.sellerImage} />
      <Pressable style={StyleProduct.SellerLinkDetailView} onPress={() =>{ triggerHapticFeedback(); navigation.navigate('sellerPage', {propName:propName});}} ><Text>{ProductData[0].seller_name} shop</Text></Pressable>

    </View>

    </View>
    <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}  style={StyleProduct.containerLikeDislike}>
<TouchableOpacity style={StyleProduct.LikeDislikeButton}
onPress={handleLike} disabled={isLiked}>
<Text style={{ color: 'black',fontSize:16}}>{isLiked ? 'Liked' :
 <Text>{ProductData[0].user_like_number} <Feather name="thumbs-up" size={24} color="black" /></Text>}
</Text>
</TouchableOpacity>
 <TouchableOpacity style={StyleProduct.LikeDislikeButton}
  onPress={handleDisLike} disabled={isDisliked}>
 <Text style={{ color: 'black',fontSize:16}}>
    {isDisliked ? 'Disliked' : <Text>{ProductData[0].user_dislike_number} <Feather name="thumbs-down" size={24} color="black" /></Text>}
 </Text>
</TouchableOpacity>
<TouchableOpacity   onPress={() =>handleShare(`http://3oula.tn/product.php?product125467juv895487=${ProductData[0].product_sku}`)} style={StyleProduct.LikeDislikeButton}>
 <Text style={{ color: 'black',fontSize:16 }}>
<AntDesign name="sharealt" size={24} color="black" />
 </Text>
</TouchableOpacity>
<TouchableOpacity onPress={handleScrollToRefComment} style={StyleProduct.LikeDislikeButton}>
 <Text style={{ color: 'black',fontSize:16}}>
 commenter <FontAwesome5 name="comment-dots" size={24} color="black" /> </Text>
</TouchableOpacity>


<TouchableOpacity style={StyleProduct.LikeDislikeButton}>
 <Text style={{ color: 'black',fontSize:16}}>
   <Text>{ProductData[0].seen} <Feather name="eye" size={24} color="black" /></Text></Text>
</TouchableOpacity>




</ScrollView>


<View style={{padding:20}}>
<Text>
<Text style={StyleProduct.boldText}>Description:</Text>
<Text>{'\n'}{ProductData[0].product_description}</Text>
</Text>

<Text>
<Text style={StyleProduct.boldText}>Poids:{'\n'}</Text>
  <Text>
   {calculateWeight(ProductData[0].product_weight)}
   </Text>
  
</Text>


<Text>
  <Text style={StyleProduct.boldText}>Composants alimentaires:</Text>
  <Text>{'\n'}{ProductData[0].composants}</Text>
  </Text>
<Text>
  <Text style={StyleProduct.boldText}>Note:</Text>
<Text>{'\n'}{ProductData[0].note}</Text>
</Text>


</View>




{sellersArray.length > 0 && sellersArray.map((seller, indexSeller) => (


<View key={indexSeller}>
{seller.sku_seller===ProductData[0].seller_sku? ( 

<View>

    <Pressable
       //onPressIn={() => handlePressIn(product.product_sku)}
      // onPressOut={handlePressOut}
      
    onPress={() => {
      const sellerToCart={
        [`${ProductData[0].seller_sku}`]:{
          productSellerName: `${ProductData[0].seller_name}`,
          productSellerSku: `${ProductData[0].seller_sku}`,
          productSellerEmail: `${ProductData[0].seller_email}`,
          productSellerScore:`${seller.seller_score}`,
          productSellerImage: `${ProductData[0].seller_image}`,
          productQuantityProSeller:1,
          productWeightProSeller:`${ProductData[0].product_weight}`,
          totalSeller: ProductData[0].sale ?  `${((ProductData[0].product_price / 100) * (100-ProductData[0].sale)).toFixed(2)}`:`${ProductData[0].product_price}`,
        }
      };

const productToCart = {
  [`${ProductData[0].product_sku}`]: {
    productSku: `${ProductData[0].product_sku}`,
    productName: `${ProductData[0].product_name}`,
    productScore:`${ProductData[0].product_score}`,
    productImage: `${ProductData[0].product_image0}`,
    productSellerName: `${ProductData[0].seller_name}`,
    productSellerSku: `${ProductData[0].seller_sku}`,
    productSellerEmail: `${ProductData[0].seller_email}`,
    productSellerTel: `${ProductData[0].seller_tel}`,
    productSellerAdress: `${ProductData[0].seller_adress}`,
    productSellerImage: `${ProductData[0].seller_image}`,
    productPrice: ProductData[0].sale ?  `${((ProductData[0].product_price / 100) * (100-ProductData[0].sale)).toFixed(2)}`:`${ProductData[0].product_price}`,

  
    productWeight: `${ProductData[0].product_weight}`,
    productQuantity:1,
  }

}


; 

handleClickButtonsAddToCart(ProductData[0].product_sku,ProductData[0].seller_sku,ProductData[0].product_weight, sellerToCart, productToCart);
}}


      
style={({pressed}) => [
  {
    opacity: pressed ? 0.5 : 1,
  },
  StyleProduct.productPriceButton,
]}
    >       
    
       <Text style={StyleProduct.productPriceTextSaleOldPrice}>{ProductData[0].sale?ProductData[0].product_price +' Tnd ':null}</Text>
    <Text style={StyleProduct.productPriceText}>
{ProductData[0].sale?((ProductData[0].product_price/100)*(100-ProductData[0].sale)).toFixed(2)+' Tnd ':ProductData[0].product_price+' Tnd '}<AntDesign name="shoppingcart" size={16} color="black" />
</Text>
     </Pressable>
     </View>):(null)}
     </View>
     
))}




</View>

<View style={{width:"100%", paddingVertical:20,justifyContent:"center", alignItems:"center"}}>

{UpdateTypeWriter? (<Typewriter noHaptics={true} text={`${SimilarProduct.length} ${SimilarProduct.length===1?"produit similaire qui peut aussi":"produits similaires qui peuvent aussi"} aussi vous intéresser \u{1F609}`} 
    delay={10} />):null}


</View>

{/*similar Product section */}
<ScrollView horizontal={true} showsHorizontalScrollIndicator={false} style={StyleProduct.SimilarproductViewConatinerBody}>

{SimilarProduct.length > 0  &&  SimilarProduct.map((product ,index) => (



<View key={index}>

      <View style={StyleProduct.wrapperProductView}>
        <Pressable onPress={() => {
          triggerHapticFeedback();
          setLoading(true);
          setUpdateTypeWriter(false);
       
setTimeout(() => {
  navigation.navigate('productPage', {propName: product});
  setProductChangeTrigger(!ProductChangeTrigger);
  setLoading(false);
  scrollToTop();
},500);
      
          
          
          }}>

      <View style={StyleProduct.productView}>
     


     <Image source={{ uri: `https://3oula.tn/${product.product_image0}` }} style={StyleProduct.productImage} />

  


    <View style={StyleProduct.productScoreView}>



  
    </View>
<View style={StyleProduct.productnameView}>
  <Text style={StyleProduct.productName}>{product.product_name}</Text>
  </View>
  <View style={StyleProduct.starRatingView}>
  <StarRating  score={product.product_score} /></View>
  <View style={StyleProduct.productDescriptionView}>
  <Text style={StyleProduct.productDescritption}>{product.product_description.slice(0, 10)+ '...'} </Text>
  </View>

  {sellersArray.length > 0 && sellersArray.map((seller, indexSeller) => (


<View key={indexSeller}>
{seller.sku_seller===product.seller_sku? ( 

<View>

    <Pressable
    
      
    onPress={() => {
      const sellerToCart={
        [`${product.seller_sku}`]:{
          productSellerName: `${product.seller_name}`,
          productSellerSku: `${product.seller_sku}`,
          productSellerEmail: `${product.seller_email}`,
          productSellerScore:`${seller.seller_score}`,
          productSellerImage: `${product.seller_image}`,
          productQuantityProSeller:1,
          productWeightProSeller:`${product.product_weight}`,
          totalSeller:product.sale?  `${((product.product_price / 100) * (100-product.sale)).toFixed(2)}`:`${product.product_price}`,
        }
      };

const productToCart = {
  [`${product.product_sku}`]: {
    productSku: `${product.product_sku}`,
    productName: `${product.product_name}`,
    productScore:`${product.product_score}`,
    productImage: `${product.product_image0}`,
    productSellerName: `${product.seller_name}`,
    productSellerSku: `${product.seller_sku}`,
    productSellerEmail: `${product.seller_email}`,
    productSellerTel: `${product.seller_tel}`,
    productSellerAdress: `${product.seller_adress}`,
    productSellerImage: `${product.seller_image}`,

    productPrice: product.sale?  `${((product.product_price / 100) * (100-product.sale)).toFixed(2)}`:`${product.product_price}`,


    productWeight: `${product.product_weight}`,
    productQuantity:1,
  }

}


; 

handleClickButtonsAddToCart(product.product_sku, product.seller_sku,product.product_weight, sellerToCart, productToCart);
}}


      

style={({pressed}) => [
  {
    opacity: pressed ? 0.5 : 1,
  },
  StyleProduct.productPriceButtonSimilar,
]}
    >


<Text style={StyleProduct.productPriceTextSaleOldPrice}>{product.sale?product.product_price +' Tnd ':null}</Text>
    <Text style={StyleProduct.productPriceText}>
{product.sale?((product.product_price/100)*(100-product.sale)).toFixed(2)+' Tnd ':product.product_price+' Tnd '}<AntDesign name="shoppingcart" size={16} color="black" />
</Text>


    </Pressable>
     </View>):(null)}
     </View>
     
))}


  
      </View>
      <View style={StyleProduct.sellerNameAndLink}>
      <Image source={{ uri: `https://3oula.tn/${product.seller_image}` }} style={StyleProduct.sellerImageProductView} ></Image>
      <Pressable onPress={() => {triggerHapticFeedback();navigation.navigate('sellerPage', {propName:product});}}  style={StyleProduct.sellerNameProductView}><Text style={StyleProduct.sellerNameProductViewText}>{product.seller_name}</Text></Pressable>
     </View>
     </Pressable>
     {product.sale?(<Pressable style={StyleProduct.productSale}><Text style={StyleProduct.productSaleText}>sale {product.sale}%</Text></Pressable>):null}

</View>


</View>
))}


</ScrollView>



{/*bought together section */}





<View style={{width:"100%", paddingVertical:20,justifyContent:"center", alignItems:"center"}}>

{UpdateTypeWriter? (<Typewriter noHaptics={true} text={`${boughtTogether.length-1} ${boughtTogether.length===1?" autre produit":"autres produits"} que des utilisateurs ont également achetés avec ce produit \u{1F605}`} 
    delay={10} />):null}
 


</View>


<View style={StyleProduct.SimilarproductViewConatinerBodyView}>

<View style={StyleProduct.productBoutghtTogetherView} >

{boughtTogether.length > 0  &&  boughtTogether.map((product ,index) => (


<View  style={{backgroundColor:"white",height:290,
minWidth:160,maxWidth:160, margin:10,}}key={index}>
<View >


      <View style={StyleProduct.wrapperProductView}>
        <Pressable onPress={() => {
          triggerHapticFeedback();
          setLoading(true);
          setUpdateTypeWriter(false);
       
setTimeout(() => {
  navigation.navigate('productPage', {propName: product});
  setProductChangeTrigger(!ProductChangeTrigger);
  setLoading(false);
  scrollToTop();
},500);
      
          
          
          }}>

      <View style={StyleProduct.productView}>
     


     <Image source={{ uri: `https://3oula.tn/${product.product_image0}` }} style={StyleProduct.productImage} />

  


    <View style={StyleProduct.productScoreView}>



  
    </View>
<View style={StyleProduct.productnameView}>
  <Text style={StyleProduct.productName}>{product.product_name}</Text>
  </View>
  <View style={StyleProduct.starRatingView}>
  <StarRating  score={product.product_score} /></View>
  <View style={StyleProduct.productDescriptionView}>
  <Text style={StyleProduct.productDescritption}>{product.product_description.slice(0, 10)+ '...'} </Text>
  </View>

  {sellersArray.length > 0 && sellersArray.map((seller, indexSeller) => (


<View key={indexSeller}>
{seller.sku_seller===product.seller_sku? ( 

<View>

    <Pressable
    
      
    onPress={() => {
      const sellerToCart={
        [`${product.seller_sku}`]:{
          productSellerName: `${product.seller_name}`,
          productSellerSku: `${product.seller_sku}`,
          productSellerEmail: `${product.seller_email}`,
          productSellerScore:`${seller.seller_score}`,
          productSellerImage: `${product.seller_image}`,
          productQuantityProSeller:1,
          productWeightProSeller:`${product.product_weight}`,
          totalSeller:product.sale?  `${((product.product_price / 100) * (100-product.sale)).toFixed(2)}`:`${product.product_price}`,
        }
      };

const productToCart = {
  [`${product.product_sku}`]: {
    productSku: `${product.product_sku}`,
    productName: `${product.product_name}`,
    productScore:`${product.product_score}`,
    productImage: `${product.product_image0}`,
    productSellerName: `${product.seller_name}`,
    productSellerSku: `${product.seller_sku}`,
    productSellerEmail: `${product.seller_email}`,
    productSellerTel: `${product.seller_tel}`,
    productSellerAdress: `${product.seller_adress}`,
    productSellerImage: `${product.seller_image}`,

    productPrice: product.sale?  `${((product.product_price / 100) * (100-product.sale)).toFixed(2)}`:`${product.product_price}`,


    productWeight: `${product.product_weight}`,
    productQuantity:1,
  }

}


; 

handleClickButtonsAddToCart(product.product_sku, product.seller_sku,product.product_weight, sellerToCart, productToCart);
}}


      

style={({pressed}) => [
  {
    opacity: pressed ? 0.5 : 1,
  },
  StyleProduct.productPriceButtonSimilar,
]}
    >


<Text style={StyleProduct.productPriceTextSaleOldPrice}>{product.sale?product.product_price +' Tnd ':null}</Text>
    <Text style={StyleProduct.productPriceText}>
{product.sale?((product.product_price/100)*(100-product.sale)).toFixed(2)+' Tnd ':product.product_price+' Tnd '}<AntDesign name="shoppingcart" size={16} color="black" />
</Text>


    </Pressable>
     </View>):(null)}
     </View>
     
))}


  
      </View>
      <View style={StyleProduct.sellerNameAndLink}>
      <Image source={{ uri: `https://3oula.tn/${product.seller_image}` }} style={StyleProduct.sellerImageProductView} ></Image>
      <Pressable onPress={() => {triggerHapticFeedback();navigation.navigate('sellerPage', {propName:product});}}  style={StyleProduct.sellerNameProductView}><Text style={StyleProduct.sellerNameProductViewText}>{product.seller_name}</Text></Pressable>
     </View>
     </Pressable>
     {product.sale?(<Pressable style={StyleProduct.productSale}><Text style={StyleProduct.productSaleText}>sale {product.sale}%</Text></Pressable>):null}


</View>

</View>



{/** <View style={StyleProduct.plusView}>

{index<boughtTogether.length-1?(
<View style={StyleProduct.plusView}>
<Text><AntDesign name="plus" size={20} color="grey" /></Text>

</View>):null}
</View> */}


</View>

))}


</View>

<TouchableOpacity  style={StyleProduct.buyAllTogether}onPress={addAllBoughtTogether}>
      <Text  style={StyleProduct.productPriceText}>Acheter tous {calculateBoughtTogetherPrice()} Tnd <AntDesign name="shoppingcart" size={16} color="black" /></Text>
      
      
      </TouchableOpacity>


</View>





   
 




{/*comments text*/}
{/* comments input */}
<View style={StyleProduct.CommentsContainerBoxInput} ref={commentBoxRef}>
  <View style={StyleProduct.ratingInput}>
  <StarRatinginput onRatingChange={handleRatingChange} />
  </View>
  <TextInput ref={commentInputRef}  
            style={StyleProduct.commentInput}
            placeholder="entrez votre commentaire ici"
            onChangeText={handleCommentTextChange}
            multiline
            value={commentTextToDb}
          />
<Text style={{ color:everyThingOkComment?"green":"red",display:commentTextToDb.length>0?"flex":"none", fontSize:12}}>{commentTextToDb.length} / 600</Text>

{everyThingOkComment?( <Pressable onPress={insertComment} style={StyleProduct.commenterButton}>
            <Text style={StyleProduct.commenterButtonText}>commenter</Text>
            </Pressable>):( <Pressable style={StyleProduct.commenterButtonNotReady}>
            <Text style={StyleProduct.commenterButtonText}>commenter</Text>
            </Pressable>)}
         




</View>

{/*Srting commenst by date score */}



{/*Sorting commenst by date score */}


<ScrollView  horizontal={true} showsHorizontalScrollIndicator={false} style={StyleProduct.SortByComments}>
<Pressable onPress={()=>{SortByScoreDsd('ScoreDsd')}} 
 style={() => [  {backgroundColor:sortedBy==="ScoreDsd"?"#E8E8E8":"white"}, StyleProduct.SortByButton, ]}>
  <Text style={StyleProduct.SortByButtonText}> Meilleurs avis  <SimpleLineIcons name="like" size={14} color="black" /></Text>
  </Pressable>
  <Pressable onPress={()=>{SortByScoreAsd('ScoreAsd')}}
   style={() => [  {backgroundColor:sortedBy==="ScoreAsd"?"#E8E8E8":"white"}, StyleProduct.SortByButton, ]}>
  <Text style={StyleProduct.SortByButtonText}> Plus mauvais avis  <SimpleLineIcons name="dislike" size={14} color="black" /></Text>
  </Pressable>
 
  <Pressable onPress={()=>{SortByDateDsd('DateDsd')}} 
   style={() => [  {backgroundColor:sortedBy==="DateDsd"?"#E8E8E8":"white"}, StyleProduct.SortByButton, ]}>
  <Text style={StyleProduct.SortByButtonText}>Plus anciens  <Octicons name="sort-asc" size={14} color="black" /></Text>
  </Pressable>
  <Pressable onPress={()=>{SortByDateAsd('DateAsd')}} 
   style={() => [  {backgroundColor:sortedBy==="DateAsd"?"#E8E8E8":"white"}, StyleProduct.SortByButton, ]}>
  <Text style={StyleProduct.SortByButtonText}>Plus nouveau  <Octicons name="sort-asc" size={14} color="black" /></Text>
  </Pressable>


  </ScrollView>
  {Comments.length >0 &&  Comments.slice(0,CommentsInitial).map((item ,index) => (
<View  style={{height:"auto",backgroundColor:"#fcb103"}} key={index}>

<View style={[{ backgroundColor: CommentId&&CommentId===item.comment_id ? "#D3D3D3" : "white" }, StyleProduct.CommentsContainerBox]}>
{CommentId&&CommentId===item.comment_id ?(<Text style={StyleProduct.newReply}>Nouvelle Réponses</Text>):null}
<View style={StyleProduct.commentBoxNameFotoAndStars}>

<View style={StyleProduct.commentBoxNameFotoAndStarsViews1}>

<Image source={{uri:`https://3oula.tn/${item.user_foto}`}} style={StyleProduct.userImage}></Image>
<Text style={StyleProduct.username}>{item.user_name}</Text>
</View>

<View style={StyleProduct.commentBoxNameFotoAndStarsViews2}>
<Text>{item.datum}</Text> 
<View>

<StarRating score={item.user_stars} />
</View>

</View>


</View >



<View style={StyleProduct.CommentTextView}>
<Text style={StyleProduct.CommentText}>{item.comment_text.trim()}</Text>
</View>


{ShownReply!==item.comment_id? (
<Pressable style={StyleProduct.ShowReplyButton} onPress={() => showReplyAndInsertSeenUser(item.comment_id)}>
   <Text>
  {Reply.filter(reply => reply.comment_id === item.comment_id).length>0?
  (<Text> voir {Reply.filter(reply => reply.comment_id === item.comment_id).length} r&#233;ponse</Text>):(<Text>r&#233;pondre</Text>)}
  </Text>
</Pressable>)
:
(
<Pressable style={StyleProduct.ShowReplyButton} onPress={() => hideReply(item.comment_id)}><Text>masquer</Text></Pressable>
)}



</View>


  




<View  ref={(ref) => {ReplyBoxRef.current[item.comment_id] = ref}}  style={StyleProduct.replyBox}>


<View style={StyleProduct.ReplyContainerBoxInputInputAndReply}>
<Text style={{ color:everyThingOkReply?"green":"red",display:replyText.length>0?"flex":"none", fontSize:12}}>{replyText.length} / 600</Text>

  <TextInput ref={replyInputRef}  

            style={StyleProduct.ReplyInput}
            placeholder="entrez votre r&#233;ponse ici"
            onChangeText={handleReplyTextChange}
            value={replyText}
          />
      
{everyThingOkReply?(  <Pressable onPress={() => insertReply(item.comment_id)} style={StyleProduct.commenterButton}>
            <Text style={StyleProduct.commenterButtonText}>reply</Text>
            </Pressable>):(  <Pressable  style={StyleProduct.commenterButtonNotReady}>
            <Text style={StyleProduct.commenterButtonText}>reply</Text>
            </Pressable>)}
</View>





{Reply.length > 0 &&  Reply.map((reply, indexReply) => (
reply.comment_id===item.comment_id? ( 
<View key={indexReply} style={StyleProduct.ReplyContainerBoxInputInputAndReply}>
<View style={StyleProduct.commentBoxNameFotoAndStars}>
{reply.user_name!=="1"?(<View style={StyleProduct.commentBoxNameFotoAndStarsViews1}><Image source={{uri:`https://3oula.tn/${reply.user_image}`}} style={StyleProduct.userImage}></Image>
<Text style={StyleProduct.username}>{reply.user_name}</Text></View>):(
  <View style={StyleProduct.commentBoxNameFotoAndStarsViews1}><Image source={{uri:`https://3oula.tn/${reply.seller_image}`}} style={StyleProduct.userImage}></Image>
  <Text style={StyleProduct.username}>{reply.seller_name} shop</Text></View>
)}
<View style={StyleProduct.commentBoxNameFotoAndStarsViews2}>
<Text>{reply.date}</Text>

</View>
</View >

<View style={StyleProduct.CommentTextView}>
<Text style={StyleProduct.CommentText}>{reply.comment_text.trim()}</Text>
</View>
</View>

):
(null)
))}





</View>
</View>





))}



{Comments.length-CommentsInitial>0?(
<Pressable onPress={handleShowMoreComment} style={StyleProduct.ShowMoreCommentButton}>
  <Text style={StyleProduct.ShowMoreCommentButtonText}> voir plus {Comments.length-CommentsInitial}</Text>
</Pressable> 
  ):null}



    </ScrollView>

 
    </KeyboardAvoidingView>
    <Cart/>


    </SafeAreaView>

    {/*error and loding handling*/}
    {Error?(   <View style={StyleProduct.ErrorView}>
      <Typewriter text={Error} 
    delay={10} />
          <TouchableOpacity onPress={()=>{triggerHapticFeedback();setError();}}style={StyleProduct.ErrorViewButton}><Text  style={StyleProduct.ErrorViewText}>OK</Text></TouchableOpacity>
    
        </View>):null}
    
    
    
    
        {loading?(<View style={StyleProduct.LoadingView}>
        <ActivityIndicator size="large" color="white" style={{ alignSelf: 'center' }} />
    
    </View>):null}
</>
  );
};





const StyleProduct =StyleSheet.create({
  androidSafeArea: {
  

    paddingTop: Platform.OS === 'android' ? 0 : 0,
    backgroundColor:"#fcb103",

    flex: 1,

    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

},


//only for web
WebSafeArea: {
  width:"100%", 
    
     backgroundColor:"#fcb103",
    flex:1,
    paddingBottom:2000,


},


Loadingview:{
  backgroundColor:"#fcb103",
  height:"100%", width:"100%", justifyContent:"center", alignItems:"center"


},




pressab:{
  backgroundColor:"#fcb103",
  height:60, marginTop:100,
  
  },
  SlideShowGallery:{




  },
  SlideShowGalleryIndex:{
height:30,
width:50,
justifyContent:"center", alignItems:"center",
backgroundColor:"rgba(0,0,0,0.7)",
borderRadius:9,
zIndex:2,
marginTop:-40,
alignSelf:"center",marginRight:-300

  },
  SlideShowGalleryIndexText:{
fontSize:14,

color:"white"
  },
  Head:{
flex:1, flexDirection:"row",justifyContent:"space-between",
    height:70,width:"100%",backgroundColor:"transparent"
  },


  //only for web

  HeadSticky:{
flex:1, flexDirection:"row",justifyContent:"space-between",
   minHeight:70, maxHeight:70,width:"100%",backgroundColor:"transparent", position:"sticky", top:0,zIndex:20,backgroundColor:"transparent"

  }
,


  BackButton:{

    height:50, width:50, backgroundColor:"white", alignItems:"center", justifyContent:"center", margin:15, borderRadius:50, 

    shadowColor: 'rgba(0, 0, 0, 0.5)',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 1,
    shadowRadius: 2,
    elevation: 2,
    justifyContent:"center", alignItems:"center",  
    paddingVertical: 0,
    paddingHorizontal: 5,
  
  },
  loginButton:{
    height:50, width:50, backgroundColor:"white", alignItems:"center", justifyContent:"center", margin:15, borderRadius:50, 

    shadowColor: 'rgba(0, 0, 0, 0.5)',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 1,
    shadowRadius: 2,
    elevation: 2,
    justifyContent:"center", alignItems:"center",  
    paddingVertical: 0,
    paddingHorizontal: 5,
  },


ProductPageProductDetail:{
  width:"100%",



marginTop:30,
flex:1, 
flexDirection:"row"


},

ProductDetailtitleView :{
backgroundColor:"white",
width:"50%", justifyContent:"center", alignItems:"center", 



},
ProductDetailSellerView:{


  width:"50%",justifyContent:"center", alignItems:"center"
},


containerLikeDislike:{
  paddingLeft:20,
height:60,
flex:1,
flexDirection:'row',
marginTop:20,
width:420,

alignSelf:"center"




},
LikeDislikeButton:{
  height: 40,
  width: "auto",
  backgroundColor: 'white',
  borderRadius: 4,
margin:5,
  shadowColor: 'rgba(0, 0, 0, 0.5)',
  shadowOffset: { width: 0, height: 1 },
  shadowOpacity: 1,
  shadowRadius: 2,
  elevation: 2,
  justifyContent:"center", alignItems:"center",  
  paddingVertical: 0,
  paddingHorizontal: 5,

},

sellerImage:{

height:60,width:60, borderRadius:60,
borderWidth:1,  borderColor: "rgba(0, 0, 0, 0.3)"

},
SellerLinkDetailView:{

height:40,width:"100%", backgroundColor:"white", justifyContent:"center", alignItems:"center"

},

title:{

  fontWeight:"bold", fontSize:18,
  marginBottom:10
},
RestTitleText:{

  fontSize:14,
  marginTop:10

},


boldText:{

  fontWeight:"bold",lineHeight:20
},
productPriceButton:{


  height:40,
backgroundColor:"#a7bf60",

width:"95%",
maxWidth:1000,
alignSelf:"center",

marginTop:20,
justifyContent: 'center',
alignItems: 'center',
borderRadius:4,
shadowColor: 'rgba(0, 0, 0, 0.5)',
shadowOffset: { width: 0, height: 2 },
shadowOpacity: 1,
shadowRadius: 2,
elevation: 3,

},

productPriceText:{
  fontWeight:"bold",
  marginTop:-3,
  },

  CommentsContainerBox:{
height: "auto", width:"90%", marginLeft:"1%", marginRight:"9%", borderRadius: 30, borderWidth:1,
borderColor:"rgba(0, 0, 0, 0.4)",
justifyContent:"center", alignItems:"center", overflow:"hidden",paddingTop:20, paddingBottom:20, marginBottom:10,
  },


  replyBox:{flex:1,flexDirection:"column",
    height: "auto", marginBottom:20, width:"98%", marginLeft:"1%", marginRight:"1%",
 
    justifyContent:"center", alignItems:"center", paddingTop:10, paddingBottom:20,
display:"none",

  },
  CommentsContainerBoxInput: {
    height: 'auto',
    backgroundColor: 'white',
    marginBottom: 10,
    width: '90%',
    marginLeft: '1%',
    marginRight: '9%',
    marginTop:20,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    paddingTop: 0,
    paddingBottom: 10,
  },

 ReplyContainerBoxInputInputAndReply: {
    height: 'auto',
    backgroundColor: "rgb(224, 230, 207)",
    width: '90%',
    marginLeft: '9%',
    marginRight: '1%',
    borderRadius: 30,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
paddingVertical:20,
    marginBottom:10,

  },







  ratingInput:{

    width:"100%", flex:1, flexDirection:"row",paddingLeft:20,paddingTop:10
     },
     commentInput:{
    borderWidth:1,width:"90%",marginTop:10,borderRadius:10, padding:10,borderColor:"rgba(0, 0, 0, 0.4)",
    
      
     },

     ReplyInput:{

      borderWidth:1,width:"90%",marginTop:10,borderRadius:10, padding:10,borderColor:"rgba(0, 0, 0, 0.4)",backgroundColor:"white"

     },

     commenterButton:{

backgroundColor:"#a7bf60", height:35, justifyContent:"center", alignItems:"center" ,paddingHorizontal:10, marginTop:10, borderRadius:10, marginLeft:"auto",
marginRight:20
     },
     commenterButtonNotReady:{
            
      backgroundColor:"grey", height:35, justifyContent:"center", alignItems:"center" ,paddingHorizontal:10, marginTop:10, borderRadius:10, marginLeft:"auto",
      marginRight:20
           },
     commenterButtonText:{
fontWeight:"bold", color:"white"

     },







  commentBoxNameFotoAndStars: {
    width: '90%',
    maxHeight: 50,
    backgroundColor: 'transparent',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  commentBoxNameFotoAndStarsViews1: {
    width: 'auto',
    flex: 1,
    flexDirection: 'row',
  },
  commentBoxNameFotoAndStarsViews2: {
    width: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  
  userImage:{

    height:40, width:40, borderRadius:40, justifyContent:"center", alignItems:"center"
  },
  username:{
fontWeight:"bold", marginTop:10, marginLeft:5

  },

  CommentTextView:{

    width:"100%", paddingLeft:20,paddingTop:10, paddingRight:20,
      },
  CommentText:{
  
  },


  SortByComments:{
height:52, marginBottom:10,
backgroundColor:"#fcb103", marginLeft:"1%", marginRight:"1%", 


  },

  ShowMoreCommentButton:{
marginRight:"8%", marginLeft:"auto",
    height:40, width:120, paddingHorizontal:10, 
    backgroundColor:"white",justifyContent:"center", 
    alignItems:"center", borderRadius:15,
  
    
    shadowColor: 'rgba(0, 0, 0, 0.8)',
    shadowOffset: { width: 2, height: 4 },
    shadowOpacity: 0.7,
    shadowRadius: 4,
    elevation: 5
  },

  ShowReplyButton:{

    height:40, width:"auto", paddingHorizontal:10, 
    backgroundColor:"#a7bf60",justifyContent:"center", 
    alignItems:"center", borderRadius:10, marginLeft:"auto",marginRight:20,
  
    
    shadowColor: 'rgba(0, 0, 0, 0.8)',
    shadowOffset: { width: 2, height: 4 },
    shadowOpacity: 0.7,
    shadowRadius: 4,
    elevation: 5

  },
  ShowMoreCommentButtonText:{
    color:"black", fontWeight:"bold"
  },







  SimilarproductViewConatinerBody:{
    width:"100%",

   
     marginBottom:0,
   
      borderWidth:1,
      borderColor:"rgba(0, 0, 0, 0.4)",
      backgroundColor:"white", 
paddingVertical:30,
paddingLeft:25,


  }
,



SimilarproductViewConatinerBodyView:{
  flex:1, flexDirection:"column",
  width:"100%",
  minHeight:"auto",
   marginBottom:0,
   justifyContent: 'center', // Center content vertically
   alignItems: 'center',
 
    borderWidth:1,
    borderColor:"rgba(0, 0, 0, 0.4)",
    backgroundColor:"white", 
paddingVertical:30,


}
,
productBoutghtTogetherView:{
  flex: 1,
  flexDirection: 'row',
  flexWrap: 'wrap',
height:"auto",
  justifyContent: 'center', // Center content vertically
  alignItems: 'center',


width:400,
marginBottom:0,
backgroundColor:"white", 

   

},








  wrapperProductView:{ 
    borderRadius:4,
    height:290,
  
marginRight:20,
    width:160,
    backgroundColor:"white",
     shadowColor: 'rgba(0, 0, 0, 0.8)',
  shadowOffset: { width: 2, height: 4 },
  shadowOpacity: 0.7,
  shadowRadius: 4,
  elevation: 5},
productImage:{
height:160,
width:160,

},
productView:{
height:290,overflow:"hidden",borderRadius:4, backgroundColor:"#C8C8C8",
},
starRatingView:{
backgroundColor:"white",paddingLeft:10, 

},


productnameView:{
  backgroundColor:"white",
  height:30,
  paddingLeft:10,paddingTop:10, paddingBottom:5


},
productName:{
fontWeight:"bold",

},

productDescriptionView:{
  backgroundColor:"white",
  height:37,
  marginBottom:3,



  padding:10,
},

productPriceButtonSimilar:{

  height:40,
backgroundColor:"#a7bf60",

width:"100%",



justifyContent: 'center',
alignItems: 'center',
borderRadius:4,
shadowColor: 'rgba(0, 0, 0, 0.5)',
shadowOffset: { width: 0, height: 2 },
shadowOpacity: 1,
shadowRadius: 2,
elevation: 3,



},




productPriceText:{
fontWeight:"bold",


},

sellerNameAndLink:{
  flexDirection:"row",
 
   alignItems:"space-between",
 height:40,
 width:"100%",
   backgroundColor:"rgba(0,0,0,0.3)",
 marginTop:-250,
 },
 sellerImageProductView:{
   height:60,
   width:60,
   borderRadius:60,
   marginLeft:5,
   marginTop:Platform.OS==="web"?-10:0,
   marginBottom:Platform.OS==="web"?0:-10,
   borderWidth:1,  borderColor: "rgba(0, 0, 0, 0.3)"
 },
 sellerNameProductViewButton:{
   flexWrap:"wrap",
   alignItems:"flex-start",
 
 
 },
 sellerNameProductView:{
  height:"100%", justifyContent:"center",alignItems:"center",  backgroundColor:"transparent",width:95,
},


sellerNameProductViewText:{
fontSize:12,
color:"white",
fontWeight:"bold",
margin:5,

},
 commentLength:{
  fontSize:12, fontWeight:"bold", marginLeft:20, marginRight:"auto"
 },
 //error and loading view
 LoadingView:{
  position:Platform.OS!=="web"?"absolute":"fixed", top:0,left:0,height:"115%",width:"100%", 
  backgroundColor:"rgba(0, 0, 0, 0.7)", justifyContent:"center", alignItems:"center"
},
ErrorView:{flex:1, flexDirection:"column",
  position:Platform.OS!=="web"?"absolute":"fixed",
   top:0,left:0,height:"auto",width:"100%", 
   backgroundColor:"rgba(222, 44, 44, 0.92)", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:60,


},
ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
 marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",borderRadius:4,},

 productSale:{

  backgroundColor:"rgba(218, 41, 28, 0.8)",width:80, height:30,marginLeft:10,marginTop:40,justifyContent:"center", alignItems:"center", borderRadius:20,
},
productSaleText:{fontWeight:"bold", color:"white"},
SortByButton:{
  margin:5,
  height:40, width:"auto", paddingHorizontal:10, 
justifyContent:"center", 
  alignItems:"center", borderRadius:15,

  
  shadowColor: 'rgba(0, 0, 0, 0.8)',
  shadowOffset: { width: 2, height: 4 },
  shadowOpacity: 0.7,
  shadowRadius: 4,
  elevation: 5},
  
  SortByButtonText:{
    color:"black", fontWeight:"bold"
  },
  newReply:{

    fontWeight:"bold", color:"black", 
    
                },


  productPriceTextSaleOldPrice:{color:"red",fontWeight:"bold",marginTop:-5,  textDecorationLine: "line-through",fontSize:10},
  SaleStamp:{height:50,width:110,borderRadius:110, 
    marginTop:-50,zIndex:10, marginLeft:30,marginRight:"auto",
  justifyContent:"center",alignItems:"center", 
  backgroundColor:"rgba(255, 0, 0, 0.5)"},
  SaleStampText:{fontWeight:"bold", fontSize:16,color:"white"},

  plusView:{

  height:"100%",width:30, backgroundColor:"transparent",marginLeft:-13,justifyContent:"center"
  },
  buyAllTogether:{
    width:"90%", height:40,  maxWidth:800, alignSelf:"center",
    backgroundColor:"#a7bf60", justifyContent:"center", alignItems:"center", borderRadius:4,marginTop:20,

  
    shadowColor: 'rgba(0, 0, 0, 0.5)',
    shadowOffset: { width: 2, height: 4 },
    shadowOpacity: 0.7,
    shadowRadius: 4,
    elevation: 5},


  

})
export default ProductPageScreen;