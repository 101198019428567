import React ,{ useEffect, useState,useRef, useContext} from 'react';
import { ActivityIndicator, StyleSheet, View, Text, Pressable, TextInput, ScrollView, SafeAreaView, Vibration,Platform, TouchableOpacity} from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import { Keyboard } from 'react-native';
import { KeyboardAvoidingView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useMyContext } from './MyContext';
import * as Haptics from 'expo-haptics';
import Typewriter from "./typeWriter";
const UserOrSellerLogin = ({ navigation, route }) => {

  const {contextTrigger, setcontextTrigger}=useMyContext();


    //const while loading
    const [loading, setLoading] = useState(false);
    //hold errors
      const[Error, setError]=useState('');


    //trigger haptic feedback
//trigger haptic feedback
const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
  Haptics.notificationAsync(
    Haptics.NotificationFeedbackType.Success);}
}

const [textTop, settextTop]=useState('');


    
useEffect(() => {
    
settextTop("Bienvenue sur 3oula.tn  voulez vous connecter a votre compte, hetha bil f8ansay bel3arbi e5tar client walla vendeur w taw n3addik lil login...w rodd belek tansa el mot de passe  w nebdouha 3ad...\u{1F600} ");

}, []);



  return (


<SafeAreaView style={StyleLoginUser.androidSafeArea}>
      
{/*the container for the backbutton*/}
<View  style={StyleLoginUser.BackButtonContainer}>

<Pressable style={StyleLoginUser.BackButton} onPress={() => { triggerHapticFeedback();navigation.navigate('home'); }}>
  <Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
  </Pressable></View>

<View style={{flex:1, flexDirection:"column", height:"100%", backgroundColor:"white"}}>






<View style={StyleLoginUser.Container}>
<TouchableOpacity  onPress={() => {
   
   triggerHapticFeedback();

   navigation.navigate('loginUser');
      }}

      
      style={StyleLoginUser.Button}>
<Text style={StyleLoginUser.ButtonText}>login client</Text>
</TouchableOpacity>
<TouchableOpacity  onPress={() => {
   
   triggerHapticFeedback();
  
   navigation.navigate('loginSeller');
      }}
      
      
      style={StyleLoginUser.Button}>
<Text style={StyleLoginUser.ButtonText}>login vendeur</Text>
</TouchableOpacity>


</View>
<View style={StyleLoginUser.typeWriterTopContainer}>
<Typewriter text={textTop} 
delay={10} color={"black"}/>
</View>


</View>



     {/*error and loding handling*/}
{Error?(<View style={StyleLoginUser.ErrorView}>


  <Typewriter text={Error} 
delay={10} />
<TouchableOpacity onPress={()=>{triggerHapticFeedback();setError();}}style={StyleLoginUser.ErrorViewButton}><Text  style={StyleLoginUser.ErrorViewText}>OK</Text></TouchableOpacity>
 </View>):null}

 {loading?(<View style={StyleLoginUser.LoadingView}>
    <ActivityIndicator size="large" color="white" style={{ alignSelf: 'center' }} />

</View>):null}

    </SafeAreaView>

  );
};
const StyleLoginUser = StyleSheet.create({
  androidSafeArea: { height: "100%",paddingTop: Platform.OS === 'android' ? 25 : 0, backgroundColor: "#fcb103",





},

typeWriterTopContainer:{flex:1,
  height:"auto",width:"100%", backgroundColor:"white", paddingBottom:20,

},
BackButtonContainer: { height: 70, backgroundColor: "#fcb103" },
BackButton: { height: 30, marginVertical: 20, marginLeft: 20 },


  Container: {flex:1,backgroundColor:"white",flexDirection:"column", paddingHorizontal:"5%",maxHeight:160,

},

Button:{height:50, width:"auto",paddingHorizontal:10, justifyContent:"center", alignItems:"center",backgroundColor:"#0059ba", borderRadius:4,marginTop:20},
ButtonText:{fontWeight:"bold", color:"white"},



 //error and loading view
 LoadingView:{
  position:Platform.OS!=="web"?"absolute":"fixed",top:0,left:0,height:"100%",width:"100%", 
  backgroundColor:"rgba(0, 0, 0, 0.7)", justifyContent:"center", alignItems:"center"
},
ErrorView:{flex:1, flexDirection:"column",
position:Platform.OS!=="web"?"absolute":"fixed", top:0,left:0,height:"auto",width:"100%", backgroundColor:"rgba(222, 44, 44, 0.92)", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:60,
},
ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
 marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",borderRadius:4,},



});

export default UserOrSellerLogin;
