import React, { useState, useEffect, useRef, useContext } from 'react';
import { Modal,ActivityIndicator, ScrollView, StyleSheet, View, Text, TextInput, Pressable, Image, Dimensions, Button, TouchableOpacity, Platform, Animated, KeyboardAvoidingView} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';

import { Fontisto } from '@expo/vector-icons';
import { Octicons } from '@expo/vector-icons';
import MenuLoginUser from './menuLoginForUser';

import Cart from './cart';
import StarRatinginput from './starRatingInput';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useMyContext } from './MyContext';
import * as Haptics from 'expo-haptics';
import { AntDesign } from '@expo/vector-icons';
import { LoadingView} from './myComponent';
import { Feather } from '@expo/vector-icons';
import axios from 'axios';
const OrdersUserAll = ({ navigation, route}) => {



  const{UserIsLoggedIn, setUserIsLoggedIn}=useMyContext();

  const {contextTrigger, setcontextTrigger}=useMyContext();

//if user not set throw the user out
  useEffect(() => {
if(!UserIsLoggedIn){
    navigation.navigate('home');}
    
      }, [UserIsLoggedIn]);








  //var to control modal menu
  const [showMenu, setshowMenu] = useState(false);

//function to show menu
const toggleMenu= () => {
  triggerHapticFeedback();

setshowMenu(!showMenu);

};

    //const while loading
const [loading, setLoading] = useState(false);
//Error holding and displaying vars
const [Error, setError]=useState('');
    //trigger haptic feedback
//trigger haptic feedback
const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
  Haptics.notificationAsync(
    Haptics.NotificationFeedbackType.Success);}
}
//contain product details
const {propName} = route.params;

const [OrdersIdData ,setOrdersIdData]=useState();
const [OrdersIdDataCopie ,setOrdersIdDataCopie]=useState();
const [OrdersData ,setOrdersData]=useState();

//user object 
const{User, setUser}= useMyContext({});

//menu login things
const ScrollViewRef = useRef(null);

const [showMenuRef, setshowMenuRef] = useState(false);

const [showScrollViewRef, setShowScrollViewRef] = useState(false);

//sortby menu 


const[showMore, setshowMore]=useState(5);


//sorted by
const [SortedBy, setSortedBy]=useState('');



const SortByDateAsd = (sortedByWhat) => {
  setLoading(true);
  triggerHapticFeedback();

  setTimeout(() => {
    const sortedData = [...OrdersIdDataCopie].sort((a, b) => {
      const dateA = new Date(a.datum); 
      const dateB = new Date(b.datum); 

      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    });

    // Update the state or variable holding the sorted data
    setOrdersIdData(sortedData);
    setSortedBy(sortedByWhat);
    setLoading(false);
  }, 100); // 2000 milliseconds (2 seconds)
};



const SortByDateDsd=(sortedByWhat)=>{
  setLoading(true);
  triggerHapticFeedback();
  setLoading(true);
  triggerHapticFeedback();

  setTimeout(() => {
    const sortedData = [...OrdersIdDataCopie].sort((a, b) => {
      const dateA = new Date(a.datum); 
      const dateB = new Date(b.datum); 

      if (dateA < dateB) {
        return 1;
      }
      if (dateA > dateB) {
        return -1;
      }
      return 0;
    });

    // Update the state or variable holding the sorted data
    setOrdersIdData(sortedData);
    setLoading(false);
    setSortedBy(sortedByWhat);
  }, 100); // 2000 milliseconds (2 seconds)

};

//findConfirmed
const findConfirmed = (sortedByWhat) => {
  setLoading(true);
  triggerHapticFeedback();

 

  setTimeout(() => {
    const filteredData = OrdersIdDataCopie.filter(Order => Order.Status === "confirmed");

    // Update the state or variable holding the filtered data
    setOrdersIdData(filteredData);
    setLoading(false);
    setSortedBy(sortedByWhat);
  }, 100); // 2000 milliseconds (2 seconds)
};

//findNotConfirmed
const findNotConfirmed=(sortedByWhat)=>{
  setLoading(true);
  triggerHapticFeedback();

  

  setTimeout(() => {
    const filteredData = OrdersIdDataCopie.filter(Order => Order.Status === "not confirmed");

    // Update the state or variable holding the filtered data
    setOrdersIdData(filteredData);
    setLoading(false);
    setSortedBy(sortedByWhat);
  }, 100); // 2000 milliseconds (2 seconds)

}
//findWithRetour
const findWithRetour=(sortedByWhat)=>{
  triggerHapticFeedback();
  setLoading(true);

setTimeout(() => {
  const filteredData = OrdersIdDataCopie.filter(Order => Order.sendbackask=== "1");

  // Update the state or variable holding the filtered data
 setOrdersIdData(filteredData);
 setLoading(false);
 setSortedBy(sortedByWhat);
}, 100); // 2000 milliseconds (2 seconds)
}

//for the number search
const SearchByNumberRef = useRef(null);



const[NumberInput, setNumberInput]=useState('');

 //check only numbers in the search input
 const onlyNumber = (str) => {
  return /^[0-9]+$/.test(str);
};
const[NumberInputOk, setNumberInputOk]=useState(true);
      //handle number change if not empty

      const handleNumberChange= (Number) => {
    


        if (onlyNumber(Number)) {
          setNumberInput(Number);
        
          SearchByNumberRef.current.setNativeProps({
        
            style: {
              borderColor:"#5cb85c",borderWidth:2,
            }
          });
          setNumberInputOk(true);
          setError("");
        }
        else
        {
   
          setNumberInput(Number);
          SearchByNumberRef.current.setNativeProps({
        
            style: {
              borderColor:"red",borderWidth:2
            }
          });
          setNumberInputOk(false);
          if(Number.length>0){
          setError("le champs ne peut accepter que des nombres \u{1F922}");}
        }

        if(Number.length===0){
          setNumberInput(Number);
          SearchByNumberRef.current.setNativeProps({
        
            style: {
              borderColor:"transparent",borderWidth:2
            }
          });
          setNumberInputOk(true);
          setError("");
        }

      

      };
//find with number 
const FindByNumber=()=>{
  if(NumberInput!==""&&onlyNumber(NumberInput)){
 setLoading(true);
  triggerHapticFeedback();
  setTimeout(() => {
  const filteredData = OrdersIdDataCopie.filter(Order => Order.order_id=== NumberInput);

  // Update the state or variable holding the filtered data
  setOrdersIdData(filteredData);
  setLoading(false);
 }, 200); // 2000 milliseconds (2 seconds)
}
else {
  setError("Le nombre ne peut pas être vide et doit contenir uniquement des chiffres \u{1F92C} ");
}
}



  //function to fetch data from 3oula.tn

  const myFetchDataFunction = () => {
    const key = "10876767594ewjhe";
    const user_id = propName[0].user_id;

    axios.post('https://3oula.tn/appgate/userOrders.php', `user_id=${user_id}&Key=${key}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((response) => {
      const json = response.data;
      setOrdersIdData(json.Orders_id);
      setOrdersData(json.Orders);
      setOrdersIdDataCopie(json.Orders_id);
    })
    .catch((error) => {
      setError(error);
    });
  };

  useEffect(() => {
    myFetchDataFunction();
  }, []);


// date formatting function
function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}















  if (OrdersData === undefined ||  OrdersIdData=== undefined ) {
    return <SafeAreaView style={StyleOrders.androidSafeArea}>

<LoadingView/>

  
    </SafeAreaView>; // or any other loading indicator
  }

  return (

<SafeAreaView   style={StyleOrders.androidSafeArea}>







{/*menu login */}

<Modal
          animationType={'slide'}
          transparent={true}
          visible={showMenu}
          onRequestClose={() => {
            console.log('Modal has been closed.');
          }}
>

<MenuLoginUser  Customnavigation={navigation}  toggle={toggleMenu} />

</Modal>






  <ScrollView stickyHeaderIndices={[1]}
 ref={ScrollViewRef} vertical={true} showsVerticalScrollIndicator={false} 
   style={StyleOrders.root} nestedScrollEnabled={true} 
>

<View >

  {/*the container for the logo and the button to show the login menu*/}
  <View style={StyleOrders.logoAndButtonContainer}>

  <Pressable style={StyleOrders.BackButton} onPress={() => {triggerHapticFeedback();navigation.navigate('home'); setcontextTrigger(true);}}>
  <Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
  </Pressable>

    <Pressable    onPress={toggleMenu} style={StyleOrders.loginButton}><Text><Feather name="menu" size={40} color="black" /></Text></Pressable>
</View>
</View>

{/*Scrollspymenu*/}



<View>
<View style={StyleOrders.sortByMenu}>
 

<ScrollView horizontal={true} showsHorizontalScrollIndicator={false} style={StyleOrders.sortByScrollView}>


<View ref={SearchByNumberRef}  style={ StyleOrders.searchByNumberView}>

<TextInput
   
 style={StyleOrders.inputTextSearchByNumber}
              placeholder="Chercher par numéro"
              onChangeText={handleNumberChange}
              value={NumberInput}
   />
{NumberInputOk?(
   <Pressable onPress={FindByNumber} style={StyleOrders.searchNumberButton}><Text style={StyleOrders.searchNumberButtonText} > <AntDesign name="search1" size={18} color="black" /></Text></Pressable>):(
     <Pressable style={StyleOrders.searchNumberButton}><Text style={StyleOrders.searchNumberButtonText}> <AntDesign name="search1" size={18} color="red" /></Text></Pressable>
     )}
  </View>

  <Pressable  onPress={()=>{SortByDateDsd("dateDsd")}}   
  
  style={() => [  {backgroundColor:SortedBy==="dateDsd"?"#E8E8E8":"white"}, StyleOrders.ButtonSortBy, ]}>
 
  <Text style={StyleOrders.TextButtonSortBy}>plus r&#233;cente  <Fontisto name="date" size={18} color="black" /></Text>
  </Pressable>
  <Pressable  onPress={()=>{SortByDateAsd("dateAsd")}}   
 style={() => [  {backgroundColor:SortedBy==="dateAsd"?"#E8E8E8":"white"}, StyleOrders.ButtonSortBy, ]}>
  <Text style={StyleOrders.TextButtonSortBy}>plus anciennes  <Fontisto name="date" size={18} color="black" /></Text>
  </Pressable>
  
  <Pressable   onPress={()=>{findConfirmed("confirmed")}}  
   style={() => [  {backgroundColor:SortedBy==="confirmed"?"#E8E8E8":"white"}, StyleOrders.ButtonSortBy, ]}>
  <Text style={StyleOrders.TextButtonSortBy}>commande confirm&#233;  <AntDesign name="checkcircleo" size={18} color="black" /></Text>
  </Pressable>

  <Pressable    onPress={()=>{findNotConfirmed("notConfirmed")}}  
   style={() => [  {backgroundColor:SortedBy==="notConfirmed"?"#E8E8E8":"white"}, StyleOrders.ButtonSortBy, ]}>
  <Text style={StyleOrders.TextButtonSortBy}>commande pas confirm&#233;  <AntDesign name="exclamationcircleo" size={18} color="black" /></Text>
  </Pressable>
  <Pressable  onPress={()=>{findWithRetour("withRetour")}}  
   style={() => [  {backgroundColor:SortedBy==="withRetour"?"#E8E8E8":"white"}, StyleOrders.ButtonSortBy, ]}>
  <Text style={StyleOrders.TextButtonSortBy}>retour  <Octicons name="package-dependencies" size={18} color="black" /></Text>
  </Pressable>
</ScrollView>



</View>













</View>

<View style={StyleOrders.BigContainerOrderButton}>
  {OrdersIdData.length > 0 &&
    OrdersIdData.slice(0, showMore).map((OrderId, index) => (
      <Pressable style={StyleOrders.OrderView}
        onPress={() =>{triggerHapticFeedback(); setcontextTrigger(true);navigation.navigate('OrderUser', { Orderdata: OrderId });}
          
        }
        key={index}
      >
        <View>
       
          <View style={StyleOrders.OrderViewHead}>
            <Text style={StyleOrders.OrderViewHeadText}>
              Nr- {OrderId.order_id} 
            </Text>
            <Text style={StyleOrders.OrderViewHeadText}> <AntDesign name="calendar" size={14} color="black" /> {formatDate(OrderId.datum)} 
            </Text>
             <Text style={StyleOrders.OrderViewHeadText}>
             <Feather name="watch" size={14} color="black" /> {OrderId.uhrzeit}
            </Text>
          </View>

          <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} style={StyleOrders.imageAndProductNameContainerScrollView}>

          {OrdersData.length > 0 &&
 OrdersData.map((order, number) => {

          // Define a variable to track image loading status
          let imageLoadError = false;


          return order.order_id === OrderId.order_id ? (
            <View key={number}>
              <View style={StyleOrders.imageAndProductNameContainer}>
    

<Image source={{ uri: `https://3oula.tn/${order.product_image}` }}
              
                  style={StyleOrders.productImage}
                />
                {/* Other components related to the order */}

<Text style={StyleOrders.productName}> {order.price} Tnd</Text>
<Text style={StyleOrders.productName}> {order.product_name.slice(0,9)}..</Text>
              </View>
            </View>
          ) : null;
        })}
              </ScrollView>
        </View>
             <Text style={StyleOrders.OrderPrice}>Prix total: {OrderId.order_price} Tnd</Text>
      </Pressable>
    ))}




    {OrdersIdData.length>=showMore? (    <TouchableOpacity style={StyleOrders.showMoreButton}
        onPress={()=>{triggerHapticFeedback(); setshowMore((prevshowMore)=>prevshowMore+5);}}
        ><Text style={StyleOrders.showMoreButtonText}>voir plus</Text></TouchableOpacity>):
        null
        }
    

    </View>

</ScrollView>


{/*error and loading handling*/}
{Error?(    <View style={StyleOrders.ErrorView}>
  <Typewriter text={Error} 
delay={10} />
      <TouchableOpacity onPress={()=>{triggerHapticFeedback();setError();}}style={StyleOrders.ErrorViewButton}><Text  style={StyleOrders.ErrorViewText}>OK</Text></TouchableOpacity>

    </View>):null}


{loading?(<View style={StyleOrders.LoadingView}>
    <ActivityIndicator size="large" color="white" style={{ alignSelf: 'center' }} />

</View>):null}


</SafeAreaView>
  
  );
};

const StyleOrders = StyleSheet.create({
  androidSafeArea: {
  

    paddingTop: Platform.OS === 'android' ? 25 : 0,
    backgroundColor:"#fcb103",

    flex: 1,

    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,



},

root:{
flex:1,
   zIndex:-2,
   backgroundColor:"#fcb103",
 width:"100%",
   height:"100%"
   
 },
 BigContainerOrderButton:{
backgroundColor:"white", paddingTop:20, paddingBottom:150,borderRadius:4,
borderWidth:1,width:"98%", marginLeft:"1%", marginRight:"1%",
borderColor:"rgba(0, 0, 0, 0.4)",

 },

OrderView:{
 marginBottom:20, width:"98%", marginLeft:"1%", marginRight:"1%", borderRadius:4,
  overflow:"hidden",padding:10,  shadowColor: 'rgba(0, 0, 0, 0.8)',
 borderWidth:1,  
 
},
OrderViewHead:{flex:1, flexDirection:"row",justifyContent:"space-between" ,backgroundColor:"white",minHeight:20},


OrderViewHeadText:{
  fontSize:14, fontWeight:"bold"
},
imageAndProductNameContainerScrollView:{
  zIndex:-1,
maxHeight:"auto",
padding:2,
width:"98%",
marginLeft:"1%",
marginRight:"1%",

},
imageAndProductNameContainer:{
  height:120,width:70,  margin:2, borderRadius:4, overflow:"hidden", alignItems:"center"
},
productImage:{
  height:70,width:70,borderRadius:4
},
productName:{
  fontSize:13, fontWeight:"bold"
},
OrderPrice:{

marginRight:20, marginLeft:"auto" , fontSize:16, fontWeight:"bold"


},



  //menu login
  menuLogin:{
    height:"100%",
    backgroundColor:"white",
    display:"none",


  },

  menuLoginButtonsContainer:{
    flex:1, flexDirection:"row",  alignItems:"center", justifyContent: "space-between",marginTop:30
    
      },
      menuLoginButtons:{
    
        backgroundColor:"#0059ba", height:40, alignItems:"center",margin:10, width:150, borderRadius:4, justifyContent:"center"
     
      },
      menuLoginButtonsText:{
    
    color:"white", fontWeight:"bold" 
      },
      userImageAndName:{
     flexDirection:"column",paddingHorizontal:20
    
    
      },
      userImage:{
        height:70, width:70,marginBottom:10, borderRadius:70
      },
      userName:{
    fontWeight:"bold",
    fontSize:18, alignSelf:"center"
      },
      BackButton:{
    
        height:50, width:50, backgroundColor:"#fcb103", alignItems:"center", justifyContent:"center", margin:15, borderRadius:50, 
      
       
      },
      logoAndButtonContainer:{
  
flex:1, flexDirection:"row",justifyContent:"space-between",
        backgroundColor:"#fcb103",
       height:80,
         marginBottom:20
    
        },


        
        ImageUserAndButtonContainer:{
          flex:1,
          flexDirection:"row",
          
          backgroundColor:"#fcb103",
          height:"auto",
          
          width:"100%",
          justifyContent:"space-between",paddingVertical:20
          
          
          },
        loginButton :{

     
          justifyContent: 'center',
          alignItems: 'center',
      
      backgroundColor:"#fcb103",
    
      height:50,
      width:50,
      marginTop:10,
      marginBottom:10,
      marginRight:20,
    
    
      
      
        },
        //sortby menu


sortByMenu :{
  flex:1, flexDirection:"row",
  zIndex:-1,
height:50,
width:"98%",
marginLeft:"1%",
marginRight:"1%",
backgroundColor:"white",
borderRadius:4,
borderWidth:1,
borderColor:"rgba(0, 0, 0, 0.4)",
backgroundColor:"#fffbe6",
overflow:"hidden",marginBottom:10

},


ButtonSortByFirst:{
  height:50, width:"auto", paddingHorizontal:10,
   justifyContent:"center", alignItems:"center", 
  backgroundColor:"#fffbe6",   shadowOffset: { width: 1, height: 0 },
  shadowOpacity: 0.6,
  shadowRadius: 2,
  elevation: 0,
  
  
    },
    ButtonSortBy:{
      height:36, marginTop:7, marginBottom:7,  borderRadius:30, width:"auto", paddingHorizontal:10, 
      justifyContent:"center", alignItems:"center",  shadowColor: 'rgba(0, 0, 0, 0.8)',marginHorizontal:5,
      shadowOffset: { width: 2, height: 2 },
      shadowOpacity: 0.7,
      shadowRadius: 3,
      
    },
  
    TextButtonSortBy:{
      fontWeight:"bold", fontSize:13
    },

//searchby number

 //searchby number

 searchByNumberView:{
  backgroundColor:"white",flex:1, flexDirection:"row",height:36, marginTop:7, marginBottom:7,  borderRadius:30, width:"auto",paddingHorizontal:15,
  justifyContent:"center", alignItems:"center",  shadowColor: 'rgba(0, 0, 0, 0.8)',marginHorizontal:5,
  shadowOffset: { width: 2, height: 2 },
  shadowOpacity: 0.7,
  shadowRadius: 3,
  
  
      },
      inputTextSearchByNumber:{
        height:40,width:200, 
      },
      searchNumberButton:{
        height:36,width:30, backgroundColor:"white", justifyContent:"center",alignItems:"center"
      },
    inputText:{

      backgroundColor:"white", height:"auto", width:"50%",

        paddingLeft:10
      
          },
        
          searchNumberButtonFail:{
            height:40,width:"50%", backgroundColor:"red",justifyContent:"center", alignItems:"center"
,   paddingHorizontal:10
          },
          searchNumberButtonText:{
            fontWeight:"bold", fontSize:12, color:"white"
          },
    



logoAndButtonContainer:{
  flex:1,
  flexDirection:"row",
  
  backgroundColor:"#fcb103",
  height:"auto",
  paddingVertical:20,
  width:"100%",
  justifyContent:"space-between"
  
  
  
  
  },
  
  logoImage:{
  
    height:60, width:60,margin:5,
    
      },
      loginButton :{
  
       
        justifyContent: 'center',
        alignItems: 'center',
    
    backgroundColor:"#fcb103",
  
    height:50,
    width:50,
    marginTop:10,
    marginBottom:10,
    marginRight:20,
  
  
  
  
    
    
    
    
    
    
    
      },
    
showMoreButton:{height:40, width:100, backgroundColor:"#fcb103", justifyContent:"center", alignItems:"center", marginRight:"1%", marginLeft:"auto", borderRadius:10,},
showMoreButtonText:{fontWeight:"bold", },
     
 //error and loading view
 LoadingView:{
  position:"absolute", top:0,left:0,height:"100%",width:"100%", 
  backgroundColor:"rgba(0, 0, 0, 0.7)", justifyContent:"center", alignItems:"center"
},
ErrorView:{flex:1, flexDirection:"column",
  position:"absolute", top:0,left:0,height:"auto",width:"100%", backgroundColor:"#DE2C2C", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:60,
},
ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
 marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",borderRadius:4,marginTop:10},
});

export default OrdersUserAll;
