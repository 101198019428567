import React, { useRef, useEffect } from 'react';
import { View, Image, StyleSheet, Animated, Platform } from 'react-native';

export const SplashScreen = () => {
  const slideAnim = useRef(new Animated.Value(-200)).current;
  const AnimatedViewRef = useRef();


  useEffect(() => {
    slideAnim.setValue(-200);
    const animationDuration = 500;

    if (Platform.OS === 'web') {
      // For web, use JavaScript-based animations
      const slideInAnimation = (from, to) => {
        const startTime = Date.now();
        const animate = () => {
          const currentTime = Date.now();
          const progress = (currentTime - startTime) / animationDuration;
          if (progress < 1) {
            const newPosition = from + (to - from) * progress;
            slideAnim.setValue(newPosition);
            requestAnimationFrame(animate);
          } else {
            slideAnim.setValue(to);
          }
        };
        requestAnimationFrame(animate);
      };

      slideInAnimation(-200, 0);
    } else {
      // For mobile, use Animated API with useNativeDriver
      Animated.timing(slideAnim, {
        toValue: 0,
        duration: animationDuration,
        useNativeDriver: true,
      }).start();
    }
  }, []);

  return (
    <View style={stylesSplashScreen.container}>
      <Animated.View
        ref={AnimatedViewRef}
        style={[
          stylesSplashScreen.imageContainer,
          { transform: [{ translateY: slideAnim }] },
        ]}
      >
        <Image
          source={{ uri: 'https://3oula.tn/image_hamhama/3oul.jpg' }}
          style={stylesSplashScreen.splashScreenImage}
        />
      </Animated.View>
    </View>
  );
};

export const stylesSplashScreen = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
   


  },
  imageContainer: {
  
    position:Platform.OS==="web"?'fixed' :'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',

  },
  splashScreenImage: {
    width: 150,
    height: 150,
    resizeMode: 'contain',
  },
});

export default SplashScreen;
