

import React, { useEffect, useState,useRef, useCallback, useContext} from 'react';
import { Modal, TouchableOpacity,ActivityIndicator, StyleSheet, Button,  Text, TextInput, View, ScrollView,SafeAreaView, Pressable,Image, Vibration,Platform,Animated, KeyboardAvoidingView} from 'react-native';
import { Product, Category, CartButton, stylesComponent} from '../myComponent';

import { Audio} from 'expo-av'; 
import { Ionicons } from '@expo/vector-icons';
import SplashScreen from '../splashScreen';
import Autocomplete from '../Autocomplete';
import { LoadingView} from '../myComponent';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AntDesign } from '@expo/vector-icons';
import { Octicons } from '@expo/vector-icons';
import MenuLoginForSeller from './menuLoginForSeller';
import { SimpleLineIcons } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons'; 
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import { Feather } from '@expo/vector-icons'; 
import { useFocusEffect } from '@react-navigation/native';
import StarRatinginput from '../starRatingInput'
import { useMyContext } from '../MyContext';
import * as Haptics from 'expo-haptics';
import { useNavigation } from '@react-navigation/native';
import Typewriter from "../typeWriter";
import NotificationSeller from './notificationSeller';
import axios from 'axios';
const isWeb = Platform.OS === 'web';
const SellerHomePage = ({ navigation}) => {

//context trigger used to update
  const {contextTrigger, setcontextTrigger}= useMyContext();
//hide virtualezed list while leaving the page focus effect


//hide virtualezed list while leaving the page 
const { VirtualizedShownSellerHomePage,setVirtualizedShownSellerHomePage}=useMyContext();
  useFocusEffect(
    React.useCallback(() => {
  
      // allow virtualized list to rerender
      setVirtualizedShownSellerHomePage(true);
   
      return () => {
            // disallow virtualized list to rerender
            setVirtualizedShownSellerHomePage(false);
  
      };
    }, [])
  );






//Notification seller
//seller notification
const {OrdersNotificationSeller , setOrdersNotificationSeller}= useMyContext({});
const {CommentNotificationSellerProduct , setCommentNotificationSellerProduct}= useMyContext({});
const {CommentNotificationSellerShop , setCommentNotificationSellerShop}= useMyContext({});
const {ReplyNotificationSellerProduct , setReplyNotificationSellerProduct}= useMyContext({});
const {ReplyNotificationSellerSeller , setReplyNotificationSellerSeller}= useMyContext({});
const {SellerNotificationCount , setSellerNotificationCount}= useMyContext(0);
const {OrdersNotificationsFramesSeller , setOrdersNotificationsFramesSeller}= useMyContext({});
const {likeOrDislikeProducts, setlikeOrDislikeProducts}= useMyContext({});
const {likeOrDislikeShop , setlikeOrDislikeShop}= useMyContext({});

//fetching seller Notifications 



useEffect(() => {
  if (contextTrigger) {
    if (Seller && Seller[0].length !== 0) {
      const key = "10876767594esellewjhe";
      const seller_sku=Seller[0].sku_seller
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded', // Set the header to application/x-www-form-urlencoded
    
      };

      // Construct the form data
      const formData = new FormData();
      formData.append('sku_seller', seller_sku);
      formData.append('Key', key);

  
      axios.post('https://3oula.tn/appgate/sellerNotification.php?action=fetchNotification', formData, {
        headers: headers, 
      })
      

        .then(response => {
          if (!response.data) {
            throw new Error(`No data received from the API`);
          }

          setOrdersNotificationSeller(response.data.Orders);
          setCommentNotificationSellerProduct(response.data.CommentProducts);
        setCommentNotificationSellerShop(response.data.CommentShop);
          setReplyNotificationSellerProduct(response.data.replyProduct);
          setReplyNotificationSellerSeller(response.data.replySellerShop);
          setSellerNotificationCount(response.data.countNotificationsSeller);
          setOrdersNotificationsFramesSeller(response.data.OrdersNotificationsFrames);
          setlikeOrDislikeProducts(response.data.likeOrDislikeProducts);
          setlikeOrDislikeShop(response.data.likeOrDislikeShop);
setcontextTrigger(false);
        })
        .catch(error => {
          console.error('API error:', error);
        });
    }
  }
}, [contextTrigger, Seller]);




  //if seller is not set throw the seller out
  const{sellerIsLoggedIn, setsellerIsLoggedIn}=useMyContext();

    useEffect(() => {
  if(!sellerIsLoggedIn){
      navigation.navigate('home');}
      
        }, [sellerIsLoggedIn]);



  //var for modal menu
  const [showMenu, setshowMenu] = useState(false);

//function to show menu
const toggleMenu= () => {
  triggerHapticFeedback();

setshowMenu(!showMenu);

};




//notification section

  //var fpr modal notification
  const [showNotification,setshowNotification]=useState(false);



//function to show menu
const toggleNotification= () => {

  triggerHapticFeedback();

  setshowNotification(!showNotification);

};































//error handling
  const [Error,setError]=useState("");
  //holdSuccess
const [Success, setSuccess]=useState('');
  //const while loading
const [loading, setLoading] = useState(false);
//trigger haptic feedback
const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
  Haptics.notificationAsync(
    Haptics.NotificationFeedbackType.Success);}
}
 

useEffect(() => {
  if (contextTrigger) {
    myFetchDataFunction();
    setcontextTrigger(false); // Update contextTrigger to false after fetching data
  }
}, [contextTrigger]); // Watch for changes in contextTrigger

const ScrollSpyRef=useRef(null);
//user and seller Objects
const {Seller, setSeller } = useMyContext([]);


const commentBoxRef=useRef();
//const for the scrollspy function nb all ref are created with loop and stored in arrays
const ViewRefs = useRef([]);
const ButtonsRefs=useRef([]);


const TextButtonsMenuRef=useRef([]);


const [data, setData] = useState([]);


const [name, setname] = useState('');


//products data an categories
const [ProductCategoriesData, setProductCategoriesData] = useState('');
const [ProductData, setProductData] = useState('');

//seller data

const [Reply, setReply]=useState();
const [everyThingOkReply, seteveryThingOkReply]=useState(false);
const [Comments, setComments]=useState();
const[CommentsCopie, setCommentsCopie]=useState();
const[CommentsInitial, setCommentsInitial]=useState(5);
const [everyThingOkComment,seteveryThingOkComment]=useState(false);




//function to fetch data from 3oula.tn



 //function to fetch data from 3oula.tn
 const myFetchDataFunction= () => {
  const seller_sku=Seller[0].sku_seller;
  // fetch data when component is mounted
  fetch('https://3oula.tn/appgate/likeandcommentappForSeller.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `sku_seller_to_show=${seller_sku}`
  })
    .then((response) => response.json())
    .then((json) => {
      setProductData(json.Products_data); // set product data state
     
      setProductCategoriesData(Array.from(new Set(json.Products_data.map((product) => product.product_category))));

    setSeller(json.seller);
    setComments(json.comments);
    setCommentsCopie(json.comments);
    setReply(json.reply);
    setcontextTrigger(false);
    if(json.Products_data.length===0){

      setSuccess("Bienvenue dans votre compte vendeur. Pour le moment, vous n'avez aucun produit répertorié, ce qui signifie que vous ne pouvez rien voir sur cette page. Cependant, à mesure que vous ajouterez davantage de produits, votre page se construira progressivement, et les fonctionnalités apparaîtront, bech tzid produit emchi lil bouton louta maktoub 3lih Ajouter un produit...\u{1F607}");
      
          }
   

    })
   
    .catch((error) => {
      console.error(error);
    });
  };



  useEffect(() => {


    myFetchDataFunction();

  }, [contextTrigger]);





  //update after waiting 60 second

  useEffect(() => {
    const seller_sku = Seller[0].sku_seller;
  
    const formData = new FormData();
    formData.append('sku_seller', seller_sku);
  
    const timer = setTimeout(() => {
      axios.post(
        'https://3oula.tn/appgate/sellerCommentSeenUpdate.php?action=setCommentsToSeenShop',
        formData
      )
        .then((response) => {
          if (response.data.success) {
            // further actions here
          } else {
            setError('An error occurred:', response.data.error);
            // Handle the error case
          }
        })
        .catch((error) => {
          setError('An error occurred:', error);
          // Handle the fetch error
        });
    }, 30000); // 60 seconds in milliseconds
  
    return () => clearTimeout(timer); // Clear the timer if the component unmounts or if the effect re-runs
  
  }, [contextTrigger]);
  
















const handleClickDeleteProduct=(ProductSku, SellerSku, Image0, Image1, Image2, Image3, Image4)=>{

 setLoading(true);
  triggerHapticFeedback();
  

 const productSku=ProductSku;
 const sellerSku=SellerSku;
 const image0=Image0;
 const image1=Image1;
 const image2=Image2;
 const image3=Image3;
 const image4=Image4;
 fetch('https://3oula.tn/appgate/AddAndDeleteProductSeller.php?action=deleteProduct', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  body: `product_sku=${productSku}&seller_sku=${sellerSku}&image0=${image0}&image1=${image1}&image2=${image2}&image3=${image3}&image4=${image4}`,

})

  .then((response) => response.json())
  
  .then((json) => {

    if (json.hasOwnProperty("error")) {
      setLoading(false);
      setError(json.error); // Output the error

    
  } else {
    setLoading(false);
  
    myFetchDataFunction();
    setSuccess(json.message);
  }


 
  })
 
  .catch((error) => {
    setLoading(false);
 
    console.error(error);
  });
  

}





















//scrolling to bottom to comment
const handleScrollToBottomSeller = () => {
  if(!isWeb){
  triggerHapticFeedback();
  ScrollViewRef.current.scrollToEnd({ animated: true });
}else {

  window.scrollTo(0, document.body.scrollHeight);
}
};

  //comment text to database
  const [commentTextToDb, setCommentTextToDb] = useState('');
  const [replyText, setreplyText]=useState('');
  const commentInputRef= useRef();
  const replyInputRef = useRef();



//comment text handling
//check only letters and numbers in the comment input
//check only letters and numbers in the comment input
 //check only letters and numbers in the input
 const onlyLettersAndNumbers = (str) => {
  return /^[A-Za-z0-9'’èéäêöàùâêîôûçëïü\s.,?!]*$/.test(str);
};
  



  
//rreply show and hdie
const ReplyBoxRef=useRef([]);
const [ShownReply, setShownReply]=useState(0);


//show reply and set on the ball to no one
const showReplyAndInsertSeenSeller = (commentid) => {
  triggerHapticFeedback();
  ReplyBoxRef.current[commentid].setNativeProps({ style: { display: 'flex' } });

setShownReply(commentid);

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded', // Set the header to application/x-www-form-urlencoded
  // Add any other headers you need
};

// Construct the form data
const formData = new FormData();
formData.append('comment_id', commentid);

// Use Axios to call the API
axios.post('https://3oula.tn/appgate/sellerNotification.php?action=SetSeenSellerReplySellerShop', formData, {
  headers: headers, // Add headers here
})

  .then(response => {
    if (response.data.success) {
      setcontextTrigger(true);
    }
  
  })
  .catch(error => {
    console.error('API error:', error);
  });


}

const hideReply = (commentid) => {
  ReplyBoxRef.current[commentid].setNativeProps({ style: { display: 'none' } });

setShownReply(0);

}

//setting the user stars input into state, use it later to insert to db
const [ratingValue, setRatingValue] = useState(0);
//lenght ref for comment

const handleCommentTextChange = (text) => {

 
  if (onlyLettersAndNumbers(text)&& commentTextToDb.length<600) {
    setCommentTextToDb(text);
  
    commentInputRef.current.setNativeProps({
   style: {
        borderColor:"#5cb85c",color:"black"
      }
    });
    seteveryThingOkComment(true);
    setError();
  }
  else
  {
    setCommentTextToDb(text);
commentInputRef.current.setNativeProps({
      style: {
        borderColor:"red",    color:"red"
      }
    });

    seteveryThingOkComment(false);
    setError("le commentaire ne peut contenir que des lettres, espaces, et nombres et  ? ! . , min 1 et max 600");
  }
};









//rplytext handling
const handleReplyTextChange = (text) => {

  if (onlyLettersAndNumbers(text) && text.length<600) {
    setreplyText(text);
    replyInputRef.current.setNativeProps({
      style: {
        borderColor:"#5cb85c", color:"black"
      }
    });
    seteveryThingOkReply(true);
    setError();

  }
  else
  {
    setreplyText(text);
    replyInputRef.current.setNativeProps({

      style: {
        borderColor:"red"  , color:"red"
      }
    });
    seteveryThingOkReply(false);
    setError("la réponse ne peut contenir que des lettres et des espaces, nombres et  ? ! . , min 1 et max 600");
  }
};


















//sorting comments by 

//show more comment

const [sortedBy, setSortedBy]=useState('');
const handleShowMoreComment=()=>{
  setCommentsInitial(CommentsInitial+5);
}

const SortByDateDsd=(SortedByWhat)=>{
  triggerHapticFeedback();
  setSortedBy(SortedByWhat);
setLoading(true);
  //setCommentsInitial(CommentsInitial+5);
  const sortedData = [...CommentsCopie].sort((a, b) => {
    const dateA = new Date(a.datum); // Assuming the date property is in a valid date format
    const dateB = new Date(b.datum); // Assuming the date property is in a valid date format
    
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  });


  setCommentsInitial(sortedData.length);
    // Update the state or variable holding the sorted data
    setComments(sortedData);

setTimeout( ()=>{setLoading(false) }, 200);
   
}

const SortByDateAsd=(SortedByWhat)=>{
  triggerHapticFeedback();
  setSortedBy(SortedByWhat);
  setLoading(true);
  //setCommentsInitial(CommentsInitial+5);
  const sortedData = [...CommentsCopie].sort((a, b) => {
    const dateA = new Date(a.datum); // Assuming the date property is in a valid date format
    const dateB = new Date(b.datum); // Assuming the date property is in a valid date format
    
    if (dateA < dateB) {
      return 1;
    }
    if (dateA > dateB) {
      return -1;
    }
    return 0;
  });


  setCommentsInitial(sortedData.length);
    // Update the state or variable holding the sorted data
    setComments(sortedData);
    setTimeout( ()=>{setLoading(false) }, 200);

}





//sort by score
const SortByScoreDsd=(SortedByWhat)=>{
  setSortedBy(SortedByWhat);
  triggerHapticFeedback();
  setLoading(true);
  const sortedData = [...CommentsCopie].sort((a, b) => {
    const ratingA = parseFloat(a.user_stars);
    const ratingB = parseFloat(b.user_stars);
    
    if (ratingA < ratingB) {
      return 1;
    }
    if (ratingA > ratingB) {
      return -1;
    }
    return 0;
  });

  setCommentsInitial(sortedData.length);
    // Update the state or variable holding the sorted data
    setComments(sortedData);
    setTimeout( ()=>{setLoading(false) }, 200);
  };


//sort by score
const SortByScoreAsd=(SortedByWhat)=>{
  setSortedBy(SortedByWhat);

  triggerHapticFeedback();
  setLoading(true);
  const sortedData = [...CommentsCopie].sort((a, b) => {
    const ratingA = parseFloat(a.user_stars);
    const ratingB = parseFloat(b.user_stars);
    
    if (ratingA < ratingB) {
      return -1;
    }
    if (ratingA > ratingB) {
      return 1;
    }
    return 0;


  });

  setCommentsInitial(sortedData.length);
    // Update the state or variable holding the sorted data
    setComments(sortedData);
    setTimeout( ()=>{setLoading(false) }, 200);
  };


//handle reply
const insertReply=(commentId, user_id)=>{

triggerHapticFeedback();
setLoading(true);
  const seller_sku= Seller[0].sku_seller;
  const seller_name= Seller[0].name;
  const seller_image=Seller[0].image;
  
  let user_name, user_image;


    user_name =1;
    user_image = 1;
 
 



  fetch('https://3oula.tn/appgate/likeandcommentappForSeller.php?action=insertReplySeller', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `reply_text=${replyText}&comment_id=${commentId}&seller_sku=${seller_sku}&seller_name=${seller_name}&seller_image=${seller_image}&user_name=${user_name}&user_image=${user_image}&user_id=${user_id}`,
  })
    .then((response) => {
      if (!response.ok) {
        setLoading(false);
        setError('Network response was not ok')
      
      }
      return response.json();
      setLoading(false);
    })
    .then((data) => {
      if (data.success) {

        myFetchDataFunction();
        setLoading(false);
      } else {
    
        setLoading(false);
      }
    })
    .catch((error) => {
      setLoading(false);
setError('There was a problem with the fetch operation:', error);

    });




};
















//const using useEffect and State to show and hide the scrollView for cart
const [showMenuRef, setshowMenuRef] = useState(false);

const [showScrollViewRef, setShowScrollViewRef] = useState(false);

const [showSellerInfoCartViewRef, setshowSellerInfoCartViewRef]=useState(false);

const ScrollViewRef = useRef(null);




//when aDD to button is pressed feedback
const [pressedButton, setPressedButton] = useState(null);













const handlePressIn = (productSku) => {
setPressedButton(productSku);


};

const handlePressOut = () => {
setPressedButton(null);
};








//animation to show View opaicty 0 to 1 menuLogin
const [animationMenuLogin] = useState(new Animated.Value(0));
const [showElementmenuLogin, setShowElementmenuLogin] = useState(false);



///scrolling to comment box
const handleScrollToRefComment = () => {
  triggerHapticFeedback();
  commentBoxRef.current.measureLayout(
    ScrollViewRef.current,
    (x, y) => {
      ScrollViewRef.current.scrollTo({y: y-100, animated: true});
    }
  );
};








//adjust input height

useEffect(() => {
  adjustInputHeight();
}, [commentTextToDb]);

const adjustInputHeight = () => {
  if (commentInputRef.current) {
    commentInputRef.current.setNativeProps({
      height: commentInputRef.current.scrollHeight,
    });
  }
};







// Function that construct the stars for rating 
function StarRating({ score, color }) {
  const filledStars = Array(parseInt(score)).fill().map((_, index) => (
    <AntDesign key={`filled-${index}`} name="star" size={14} color={color} />
  ));
  const emptyStars = Array(5 - parseInt(score)).fill().map((_, index) => (
    <AntDesign key={`empty-${index}`} name="star" size={14} color="grey" />
  ));
  const stars = [...filledStars, ...emptyStars];
  return <Text>{stars}</Text>;
}

















//Function when a button clicked jump to the section that have the category
const jumpToCategory = (Category) => {
  if (Platform.OS!=="web") {
  triggerHapticFeedback();
  const ViewRef = ViewRefs.current[Category];
  if (ViewRef && ScrollViewRef.current) {
  ViewRef.measureLayout(
    ScrollViewRef.current,
    (x, y) => {
      ScrollViewRef.current.scrollTo({y: y-150, animated: true});
    },
 
 
  );
  }
}

else {
  const container = ScrollViewRef.current;
  const ViewRef = ViewRefs.current[Category];
  if (ViewRef) {
    const { top } = ViewRef.getBoundingClientRect();
    const yOffset = window.scrollY + top - 60;
    
    console.log('top:', top);
    console.log('yOffset:', yOffset);
    
    container.scrollBy({ top: yOffset, behavior: 'smooth' });
   
  }
}




}







//Function to  imlement the ScrollSpy behavior to the menu of categories when scrolled

//Function to  imlement the ScrollSpy behavior to the menu of categories when scrolled

//Function to  imlement the ScrollSpy behavior to the menu of categories when scrolled
//on screen to get wich list are actually on screen

const [prevHighlightedButton, setPrevHighlightedButton] = useState(null);
const onScroll = (event) => {
  


//for web
// Web version
if (Platform.OS === 'web') {



ProductCategoriesData.map((ContainerCategory, index) => {
 
  const element = ViewRefs.current[`${ContainerCategory}q`];
  const container = ScrollSpyRef.current;
  const Button = ButtonsRefs.current[`${ContainerCategory}`];

  // Get the bounding box of the element's content relative to the viewport
  const { top, bottom,left} = element.getBoundingClientRect();

  if (top<70  && top>=-310) {
    // Setting the Buttons Background when the View that has the category is on the Top of Scroll Container
    ButtonsRefs.current[`${ContainerCategory}`]?.setNativeProps({
      style: { backgroundColor: 'black', borderRadius: 18 },
    });





if(prevHighlightedButton !== ContainerCategory){
    // Scroll the container to bring the element into view
    const containerLeft = container.getBoundingClientRect().left;
    const ButtonLeft = Button.getBoundingClientRect().left;
    //Button.innerHTML = ButtonLeft.toString();
   container.scrollLeft = ButtonLeft;
   setPrevHighlightedButton(ContainerCategory);
  }



    // Setting the text of the buttons color when the View that has the category is on the Top of Scroll Container
    TextButtonsMenuRef.current[`${ContainerCategory}`]?.setNativeProps({
      style: { color: 'white' },
    });
  } else {
    // Setting the Buttons Background when the View that has the category is not more on the first position
    ButtonsRefs.current[`${ContainerCategory}`]?.setNativeProps({
      style: { backgroundColor: '#fffbe6' },
    });

    // Setting the text of the buttons color when the View that has the is not more on the first position
    TextButtonsMenuRef.current[`${ContainerCategory}`]?.setNativeProps({
      style: { color: 'black' },
    });
  }
});
}


//for mobile native
if (Platform.OS !== 'web') {

  ProductCategoriesData.map((ContainerCategory, index) => {
    ViewRefs.current[`${ContainerCategory}q`].measure((x, y, width, height, pageX, pageY) => {

if(pageY<70  && pageY>=-285){
      
//Setting the Buttons Background when the View that have the category is on the Top ofScroll Container

 ButtonsRefs.current[`${ContainerCategory}`]?.setNativeProps({style: {backgroundColor: 'black', borderRadius:18} });

ButtonsRefs.current[`${ContainerCategory}`].measureLayout(
  ScrollSpyRef.current,
  (x, y, width, height) => {
    ScrollSpyRef.current.scrollTo({ x, animated: false });
  }
);

//Setting the text of the buttons color when the View that have the category is on the Top ofScroll Container
TextButtonsMenuRef.current[`${ContainerCategory}`]?.setNativeProps({style: {color: 'white', } });
}
else 
{
      //Setting the Buttons Background when the View that have the category is not more on the first position

      ButtonsRefs.current[`${ContainerCategory}`]?.setNativeProps({ style: { backgroundColor: '#fffbe6' } });
      //Setting the text of the buttons color when the View that have the is not more on the first position

      TextButtonsMenuRef.current[`${ContainerCategory}`]?.setNativeProps({style: { color: 'black', }});
}

   
});
  

});

}



};

if (ProductData === undefined || ProductCategoriesData === undefined  || Seller === undefined || Reply === undefined|| Comments === undefined) {
  return <SafeAreaView style={styles.androidSafeArea}>
<LoadingView/>

  </SafeAreaView>; // or any other loading indicator
}


return (



//the Safe Area View

<>

<SafeAreaView   style={styles.androidSafeArea}>

{/*notification section */}

<Modal
          animationType={'slide'}
          transparent={true}
          visible={showNotification}
          onRequestClose={() => {
         
          }}
>

<NotificationSeller
  Customnavigation={navigation}
 
  toggle={toggleNotification}

  scrollToBottom={handleScrollToBottomSeller}
  Data={ProductData} // Add the string prop here
/>
</Modal>

<KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 100}
      style={{ flex: 1 }}
    >




{/*menu login */}

<Modal
          animationType={'slide'}
          transparent={true}
          visible={showMenu}
          onRequestClose={() => {
            console.log('Modal has been closed.');
          }}
>

<MenuLoginForSeller  Customnavigation={navigation}  toggle={toggleMenu} />

</Modal>











{/*the Body of the page that contain all products sorted in different categories */}

<ScrollView stickyHeaderIndices={[2]}
ref={ScrollViewRef} veritcal={true} showsVerticalScrollIndicator={false} 
 style={styles.root} scrollEventThrottle={20} 
onScroll={onScroll}>

 
{/*the container for the logo and the button to show the login menu*/}
<View style={styles.logoAndButtonContainer}>


{SellerNotificationCount&&SellerNotificationCount>0?( 
  <Pressable style={styles.notificationButton} onPress={toggleNotification}>
    <Text><AntDesign name="bells" size={40} color="black"/></Text>
   
    <View style={styles.notificationButtonTextView}>
    <Text style={styles.notificationButtonText}>{SellerNotificationCount}</Text>
    </View>


    </Pressable>

):(


  <Pressable style={styles.notificationButton}>
  <Text><AntDesign name="bells" size={40} color="black"/></Text>

  </Pressable>
)}

    
  <Pressable onPress={toggleMenu}  style={styles.loginButton}><Text><Feather name="menu" size={40} color="black" /></Text></Pressable>

</View>
{Seller.length>0 ?(
<View>
<Image style={styles.sellerImage} source={{uri:`https://3oula.tn/${Seller[0].image}`}}/>

<Text style={styles.sellerName}>{Seller[0].name} shop</Text>
<View style={styles.sellerStarsOnHeadPage}>
<StarRating score={Seller[0].seller_score} color={"#FFFA61"}/><Text style={styles.scroeTextHead}> score: {Seller[0].seller_score}</Text>
</View>


</View>):null}

<View>

{/*Scrollspymenu*/}

<ScrollView  ref={ScrollSpyRef} horizontal={true} showsHorizontalScrollIndicator={false} style={styles.scrollSpyMenu}>



{/*The loop through ProductCategoriesData to create the Buttons foreach category */}
{ProductCategoriesData.length > 0 &&
  ProductCategoriesData.map((ContainerCategory, index) => (


    <View key={index}>
{/*The Category Buttons each point to View in the next section */}
<Pressable
ref={(ref) => {ButtonsRefs.current[ContainerCategory] = ref}} 
onPress={() => jumpToCategory(`${ContainerCategory}q`)}
style={styles.buttonsScrollSpy}>
<Text ref={(ref) => {TextButtonsMenuRef.current[ContainerCategory] = ref}} style={styles.textButtonsScrollSpy}>{ContainerCategory}</Text>
</Pressable>

    </View>
  ))}

</ScrollView>



</View>




{/*feedback section seller*/}


    <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}  style={styles.feedbackContainerSellerPage}>
   
    <Pressable style={styles.LikeDislikeButton} onPress={() => {triggerHapticFeedback();navigation.navigate('addProductSeller', {SellerProp:Seller});}}  >
  <Text>Ajouter un produit <AntDesign name="plus" size={14} color="black" /></Text></Pressable>
  <Pressable onPress={handleScrollToRefComment} style={styles.LikeDislikeButton}>
 <Text style={{ color: 'black',fontSize:16}}>
 commentaires  <FontAwesome5 name="comment-dots" size={24} color="black" /> </Text>
</Pressable>
   {Seller.length>0?(<>
<Pressable style={styles.LikeDislikeButton}>
<Text style={{ color: 'black',fontSize:16}}> <Text>{Seller[0].user_like}  <Feather name="thumbs-up" size={24} color="black" /></Text>
</Text>
</Pressable>
 <Pressable style={styles.LikeDislikeButton}>
 <Text style={{ color: 'black',fontSize:16}}>
    <Text>{Seller[0].seller_dislike_number}  <Feather name="thumbs-down" size={24} color="black" /></Text>
 </Text>
</Pressable></>
):null}


<Pressable style={styles.LikeDislikeButton}>
 <Text style={{ color: 'black',fontSize:16 }}>
 <AntDesign name="sharealt" size={24} color="black" />
 </Text>
</Pressable>





</ScrollView>







 
{/*the View that contains product for each category */}

{/*/ the loop to get, container categories*/}
{ProductCategoriesData.length > 0 &&
ProductCategoriesData.map((ContainerCategory, index) => (
  <View  ref={(ref) => {ViewRefs.current[`${ContainerCategory}q`] = ref}}    style={styles.productViewConatiner} key={index}>

{/*the head of the product container */}
    <View style={styles.productViewContainerHead}>
  

    <Category name={ContainerCategory}/>

    </View>


    {/*the body of the product container */}
    <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} style={styles.productViewConatinerBody}>

{/*/ the loop to get,the products foreach category*/}
{VirtualizedShownSellerHomePage&&ProductData.length > 0 && ProductData.map((product, index) => (
<View key={index}>
  {product.product_category === ContainerCategory ? (
    //the products views
    <View style={styles.wrapperProductView} >



      <Pressable style={styles.wrapperProductViewPressable} onPress={() => {triggerHapticFeedback();navigation.navigate('sellerProductPage', {propName: product});}} >

    <View style={styles.productView} key={index}>
   

   <Image source={{ uri: `https://3oula.tn/${product.product_image0}` }} style={styles.productImage} />


   {Seller.length>0?( <View style={styles.sellerNameAndLink}>
    <Image source={{ uri: `https://3oula.tn/${Seller[0].image}` }} style={styles.sellerImageProductView} ></Image>
    <Pressable  style={styles.sellerNameProductView}>
      <Text style={styles.sellerNameProductViewText}>{Seller[0].name}</Text></Pressable>
   </View>):null}


  <View style={styles.productScoreView}>

  <StarRating key={index} score={product.product_score} color={"#fcb103"} />

  </View>
<View style={styles.productDetailView}>

<View style={styles.tableRow}>
  <Text style={styles.label}>Nom:</Text>
  <Text style={styles.value}>{product.product_name}</Text>
</View>

<View style={styles.tableRow}>
  <Text style={styles.label}>Description:</Text>
  <Text style={styles.value}>{product.product_description.slice(0, 3)+ '...'}</Text>
</View>
<View style={styles.tableRow}>
  <Text style={styles.label}>Prix:</Text>
  <Text style={styles.value}>{product.product_price + ' Tnd'}</Text>
</View>



</View>




<View>


<View>


    <Pressable  onPress={()=>{handleClickDeleteProduct(product.product_sku, product.seller_sku, product.product_image0,product.product_image1,
      product.product_image2,product.product_image3,product.product_image4)}} style={styles.productDeleteButton} >

    <Text style={styles.productDeleteButtonText}>supprimer <Feather name="trash-2" size={16} color="white" /> </Text></Pressable>
     </View>

     </View>
   

    </View>
  
 
   </Pressable>
 
  

   </View>
  ) : (
null
  )}


</View>
))}



</ScrollView>


  </View>
))
}










   
 

     

{/*sortby*/}

<ScrollView ref={commentBoxRef} horizontal={true} showsHorizontalScrollIndicator={false} style={StyleProduct.SortByComments}>
<Pressable onPress={()=>{SortByScoreDsd('ScoreDsd')}} 
 style={() => [  {backgroundColor:sortedBy==="ScoreDsd"?"#E8E8E8":"white"}, StyleProduct.SortByButton, ]}>
  <Text style={StyleProduct.SortByButtonText}> Meilleurs avis  <SimpleLineIcons name="like" size={14} color="black" /></Text>
  </Pressable>
  <Pressable onPress={()=>{SortByScoreAsd('ScoreAsd')}}
   style={() => [  {backgroundColor:sortedBy==="ScoreAsd"?"#E8E8E8":"white"}, StyleProduct.SortByButton, ]}>
  <Text style={StyleProduct.SortByButtonText}> Plus mauvais avis  <SimpleLineIcons name="dislike" size={14} color="black" /></Text>
  </Pressable>
 
  <Pressable onPress={()=>{SortByDateDsd('DateDsd')}} 
   style={() => [  {backgroundColor:sortedBy==="DateDsd"?"#E8E8E8":"white"}, StyleProduct.SortByButton, ]}>
  <Text style={StyleProduct.SortByButtonText}>Plus anciens  <Octicons name="sort-asc" size={14} color="black" /></Text>
  </Pressable>
  <Pressable onPress={()=>{SortByDateAsd('DateAsd')}} 
   style={() => [  {backgroundColor:sortedBy==="DateAsd"?"#E8E8E8":"white"}, StyleProduct.SortByButton, ]}>
  <Text style={StyleProduct.SortByButtonText}>Plus nouveau  <Octicons name="sort-asc" size={14} color="black" /></Text>
  </Pressable>

  </ScrollView>


{/*comments text*/}



{Comments.length >0 &&  Comments.slice(0,CommentsInitial).map((item ,index) => (
<View key={index}>
<View style={[{ backgroundColor: item.seen_seller==="not seen" ? "#D3D3D3" : "white" }, StyleProduct.CommentsContainerBox]}>
  {item.seen_seller==="not seen" ?(<Text>Nouveau commentaire</Text>):null}
<View style={StyleProduct.commentBoxNameFotoAndStars}>
  <View style={StyleProduct.commentBoxNameFotoAndStarsViews1}>


<Image source={{uri:`https://3oula.tn/${item.user_foto}`}} style={StyleProduct.userImage}></Image>
<Text style={StyleProduct.username}>{item.user_name}</Text></View>



<View style={StyleProduct.commentBoxNameFotoAndStarsViews2}>
<Text>{item.datum}</Text>
<View >
<StarRating score={item.user_stars} color={"#fcb103"}  /></View>
</View>
</View >

<View style={StyleProduct.CommentTextView}>
<Text style={StyleProduct.CommentText}>{item.comment_text.trim()}</Text>
</View>
{ShownReply!==item.comment_id? (
<Pressable style={StyleProduct.ShowReplyButton} onPress={() => showReplyAndInsertSeenSeller(item.comment_id)}>
   <Text>
  {Reply.filter(reply => reply.comment_id === item.comment_id).length>0?
  (<Text> voir {Reply.filter(reply => reply.comment_id === item.comment_id).length} {item.new_reply==="yes"?"nuovelles ":null}{Reply.filter(reply => reply.comment_id === item.comment_id).length>1?"réponses":"réponse"} </Text>):(<Text>r&#233;pondre</Text>)}
  </Text>
</Pressable>)
:
(
<Pressable style={StyleProduct.ShowReplyButton} onPress={() => hideReply(item.comment_id)}><Text>masquer</Text></Pressable>
)}



</View>

  




<View  ref={(ref) => {ReplyBoxRef.current[item.comment_id] = ref}}  style={StyleProduct.replyBox}>


<View style={StyleProduct.ReplyContainerBoxInputInputAndReply}>

<Text style={{ color:everyThingOkReply?"green":"red",display:replyText.length>0?"flex":"none", fontSize:12}}>{replyText.length} / 600</Text>

  <TextInput ref={replyInputRef}  User
//onFocus={() => handleInputFocus(replyInputRef)}
            style={StyleProduct.ReplyInput}
            placeholder="entrez votre r&#233;ponse ici"
            onChangeText={handleReplyTextChange}
            value={replyText}
          />


{everyThingOkReply?(  <Pressable onPress={() => insertReply(item.comment_id, item.user_id)} style={StyleProduct.commenterButton}>
            <Text style={StyleProduct.commenterButtonText}>reply</Text>
            </Pressable>):(  <Pressable  style={StyleProduct.commenterButtonNotReady}>
            <Text style={StyleProduct.commenterButtonText}>reply</Text>
            </Pressable>)}
        
</View>





{Reply.length > 0 &&  Reply.map((reply, indexReply) => (
reply.comment_id===item.comment_id? ( 
<View key={indexReply} style={StyleProduct.ReplyContainerBoxInputInputAndReply}>
<View style={StyleProduct.commentBoxNameFotoAndStars}>
  

{reply.user_name!=="1"?(<View style={StyleProduct.commentBoxNameFotoAndStarsViews1}><Image source={{uri:`https://3oula.tn/${reply.user_image}`}} style={StyleProduct.userImage}></Image>
<Text style={StyleProduct.username}>{reply.user_name}</Text></View>):(
  <View style={StyleProduct.commentBoxNameFotoAndStarsViews1}><Image source={{uri:`https://3oula.tn/${reply.seller_image}`}} style={StyleProduct.userImage}></Image>
  <Text style={StyleProduct.username}>{reply.seller_name} shop</Text></View>
)}





<View style={StyleProduct.commentBoxNameFotoAndStarsViews2}>
<Text>{reply.date}</Text>

</View>
</View >

<View style={StyleProduct.CommentTextView}>
<Text style={StyleProduct.CommentText}>{reply.comment_text.trim()}</Text>
</View>
</View>

):
(null)
))}





</View>

</View>




))}










{Comments.length-CommentsInitial>0?(
<Pressable onPress={handleShowMoreComment} style={StyleProduct.ShowMoreCommentButton}>
  <Text style={StyleProduct.ShowMoreCommentButtonText}> voir plus {Comments.length-CommentsInitial}</Text>
</Pressable> 
  ):null}






    </ScrollView>






  </KeyboardAvoidingView>

  </SafeAreaView>
     {/*error and loading handling*/}
{Success?(
 <View style={styles.SuccessView}>
 <Typewriter text={Success} 
delay={10} color={"white"}/>
     <TouchableOpacity onPress={()=>{triggerHapticFeedback();setSuccess();}}style={styles.ErrorViewButton}><Text  style={styles.ErrorViewText}>OK</Text></TouchableOpacity>

   </View>):null
    }

{Error?(    <View style={styles.ErrorView}>
  <Typewriter text={Error} 
delay={10} />
 
      <TouchableOpacity onPress={()=>{triggerHapticFeedback();setError();}}style={styles.ErrorViewButton}><Text  style={styles.ErrorViewText}>OK</Text></TouchableOpacity>

    </View>):null}

    {loading?(<View style={styles.LoadingView}>
    <ActivityIndicator size="large" color="white" style={{ alignSelf: 'center' }} />

</View>):null}

  </>

);
};




  

const StyleProduct = StyleSheet.create({

  CommentsContainerBox:{
    height: "auto",width:"90%", marginLeft:"1%", marginRight:"9%", borderRadius: 30, borderWidth:1,
    borderColor:"rgba(0, 0, 0, 0.4)",
    justifyContent:"center", alignItems:"center", overflow:"hidden",paddingTop:20, paddingBottom:20, marginBottom:10,
      },
    
    
      replyBox:{flex:1,
        height: "auto", marginBottom:20, width:"98%", marginLeft:"1%", marginRight:"1%",
     
        justifyContent:"center", alignItems:"center", paddingTop:10, paddingBottom:20,
    display:"none"
    
      },
      CommentsContainerBoxInput: {
        height: 'auto',
        backgroundColor: 'white',
        marginBottom: 10,
        width: '98%',
        marginLeft: '1%',
        marginRight: '1%',
        borderRadius: 30,
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.4)',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        paddingTop: 0,
        paddingBottom: 10,
      },
    
     ReplyContainerBoxInputInputAndReply: {

        height: 'auto',
        backgroundColor: "rgb(224, 230, 207)",
        width: '90%',
        marginLeft: '9%',
        marginRight: '1%',
        borderRadius: 30,
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.4)',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    paddingVertical:20,
        marginBottom:10,
    
      },
    
    
    
    
    
    
    
      ratingInput:{
    
        width:"100%", flex:1, flexDirection:"row",paddingLeft:20,paddingTop:10
         },
         commentInput:{
        borderWidth:1,width:"90%",marginTop:10,borderRadius:10, padding:10,borderColor:"rgba(0, 0, 0, 0.4)",
        
          
         },
    
         ReplyInput:{
    
          borderWidth:1,width:"90%",marginTop:10,borderRadius:10, padding:10,borderColor:"rgba(0, 0, 0, 0.4)",backgroundColor:"white"
    
         },
    
         commenterButton:{
    
    backgroundColor:"#a7bf60", height:35, justifyContent:"center", alignItems:"center" ,paddingHorizontal:10, marginTop:10, borderRadius:10, marginLeft:"auto",
    marginRight:20
         },
         commenterButtonNotReady:{
            
    backgroundColor:"grey", height:35, justifyContent:"center", alignItems:"center" ,paddingHorizontal:10, marginTop:10, borderRadius:10, marginLeft:"auto",
    marginRight:20
         },
         commenterButtonText:{
    fontWeight:"bold", color:"white"
    
         },
    
    
    
    
    
    
    
      commentBoxNameFotoAndStars: {
        width: '90%',
        maxHeight: 50,
        backgroundColor: 'transparent',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
      },
      commentBoxNameFotoAndStarsViews1: {
        width: 'auto',
        flex: 1,
        flexDirection: 'row',
      },
      commentBoxNameFotoAndStarsViews2: {
        width: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
      },
      
      userImage:{
    
        height:40, width:40, borderRadius:40, justifyContent:"center", alignItems:"center"
      },
      username:{
    fontWeight:"bold", marginTop:10, marginLeft:5
    
      },
    
      CommentTextView:{
    
        width:"100%", paddingLeft:20,paddingTop:10, paddingRight:20
          },
      CommentText:{
      
      },
    
    
    SortByButton:{
    margin:5,
    height:40, width:"auto", paddingHorizontal:10, 
justifyContent:"center", 
    alignItems:"center", borderRadius:15,
  
    
    shadowColor: 'rgba(0, 0, 0, 0.8)',
    shadowOffset: { width: 2, height: 4 },
    shadowOpacity: 0.7,
    shadowRadius: 4,
    elevation: 5},
    
    SortByButtonText:{
      color:"black", fontWeight:"bold"
    },
      ShowMoreCommentButton:{
    
        marginLeft:"auto", marginRight:"5%",marginBottom:100,
        height:40,
         width:100, paddingHorizontal:10, 
        backgroundColor:"white",justifyContent:"center", 
        alignItems:"center", borderRadius:15,
      
        
        shadowColor: 'rgba(0, 0, 0, 0.8)',
        shadowOffset: { width: 2, height: 4 },
        shadowOpacity: 0.7,
        shadowRadius: 4,
        elevation: 5
      },

      ShowMoreCommentButtonText:{
        color:"black", fontWeight:"bold"
      },

      ShowReplyButton:{

        height:40, width:"auto", paddingHorizontal:10, 
        backgroundColor:"#a7bf60",justifyContent:"center", 
        alignItems:"center", borderRadius:10, marginLeft:"auto",marginRight:20,
      
        
        shadowColor: 'rgba(0, 0, 0, 0.8)',
        shadowOffset: { width: 2, height: 4 },
        shadowOpacity: 0.7,
        shadowRadius: 4,
        elevation: 5

      },
    
      ShowMoreCommentButtonText:{
        color:"black", fontWeight:"bold"
      },
    
    
    
      SortByComments:{
        height:52, 
        backgroundColor:"#fcb103", marginLeft:"1%", marginRight:"1%", marginBottom:10
        
        
          },
    


          commentLength:{
            fontSize:12, fontWeight:"bold", marginLeft:20, marginRight:"auto"
           },
          


});


const styles = StyleSheet.create({
  androidSafeArea: {
  

    paddingTop: Platform.OS === 'android' ? 25 : 0,
    backgroundColor:"#fcb103",

    flex: 1,

    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,



},

root:{
flex:1,
zIndex:-2,
backgroundColor:"#fcb103",

height:"100%"




},
scrollSpyMenu :{
zIndex:-1,


maxHeight:46,
padding:2,
width:"98%",
marginLeft:"1%",
marginRight:"1%",
backgroundColor:"white",
borderRadius:4,
borderWidth:1,
borderColor:"rgba(0, 0, 0, 0.4)",
backgroundColor:"#fffbe6",
marginBottom:5,


},
buttonsScrollSpy:{
height:40,
width:"auto",
minWidth:100,
backgroundColor:"#fffbe6",
padding:10,



},
textButtonsScrollSpy: {

fontWeight:"bold",
textAlign:"center",
},
productViewConatiner:{
  paddingVertical:10,
width:"98%",
marginLeft:"1%",
marginRight:"1%",
borderRadius:4,
marginBottom:15,
height:"auto",
borderWidth:1,
borderColor:"rgba(0, 0, 0, 0.4)",
backgroundColor:"#fffbe6", 
},

feedbackContainerSellerPage:{
paddingHorizontal:"auto",
marginBottom:5,
marginHorizontal:"1%"
},
LikeDislikeButton:{
  height: 40,
  width: "auto",
  backgroundColor: 'white',
  borderRadius: 4,
margin:5,
  shadowColor: 'rgba(0, 0, 0, 0.5)',
  shadowOffset: { width: 0, height: 1 },
  shadowOpacity: 1,
  shadowRadius: 2,
  elevation: 2,
  justifyContent:"center", alignItems:"center",  
  paddingVertical: 0,
  paddingHorizontal: 5,

},


productViewContainerHead:{
height:40,
borderRadius:4,
backgroundColor:"#fffbe6",
justifyContent: 'center',
alignItems: 'center',

},
productViewConatinerBody:{
  paddingVertical:10,

},
productView:{
  flex:1, justifyContent:"space-between", flexDirection:"column",
height:"auto",
width:160,
backgroundColor:"rgba(0, 0, 0, 0.4)",

borderRadius:4,

overflow:"hidden"



},

 
wrapperProductViewPressable:{

  height:"auto",
  width:160,
  overflow:"hidden"},


wrapperProductView:{

  borderRadius:4,

marginLeft:10,

  width:160,
  height:"auto",
  backgroundColor:"white",
   shadowColor: 'rgba(0, 0, 0, 0.8)',
shadowOffset: { width: 2, height: 4 },
shadowOpacity: 0.7,
shadowRadius: 4,
elevation: 5,},

productImage:{
height:160,
width:160,
},


productScoreView:{
flex:1,
flexDirection:"row",
height:30,
backgroundColor:"white",
padding:5,



},
productDetailView:{
backgroundColor:"white",
height:"auto",
paddingHorizontal:5,

},
productName:{
fontWeight:"bold",
marginLeft:10,

},

productDescriptionView:{
backgroundColor:"white",
height:37,
marginBottom:3,


padding:5,
},
productDeleteButton:{
  marginTop:3,
height:40,
backgroundColor:"#DA291C",

width:"100%",
marginBottom:0, 

justifyContent: 'center',
alignItems: 'center',



},




productDeleteButtonText:{
fontWeight:"bold",
alignSelf:"center", color:"white"

},
Footer:{
minHeight:500,
backgroundColor:"#fcb103",
},







menuLogin:{
  height:"100%",
  backgroundColor:"white",
  display:"none",




},


sellerNameAndLink:{

  flexDirection:"row",
  alignItems:"space-between",
 height:40,
 width:"100%",
 backgroundColor:"rgba(0,0,0,0.3)",
marginTop:-130,marginBottom:80,

 },
sellerImageProductView:{
height:60,
width:60,
borderRadius:60,
marginLeft:5,
marginTop:Platform.OS==="web"?-10:0,
marginBottom:Platform.OS==="web"?0:-10,
borderWidth:1,  borderColor: "rgba(0, 0, 0, 0.3)"
},
sellerNameProductViewButton:{
flexWrap:"wrap",
alignItems:"flex-start",
overflow:"hiddden",

},
sellerNameProductView:{
height:"100%", justifyContent:"center",alignItems:"center"
},


sellerNameProductViewText:{
fontSize:12,
color:"white",
fontWeight:"bold",
margin:5,

},

textProductNameCart:{
fontSize:17,
fontWeight:"bold",

},
textProductQuantityCart:{
marginTop:5,
fontWeight:"bold",
fontSize:15,

},
textProductPriceCart:{
marginTop:10,
fontWeight:"bold",
fontSize:15,

},
splashScreenWrapper:{width:"100%", height:"110%",  top:0, left:0, zIndex: 99,justifyContent:"center", alignItems:"center"},
logoAndButtonContainerMenuLogin:{
flex: 1,
flexWrap: 'wrap',

maxHeight:70,
width:"100%",
alignContent:"space-between",

},






logoImage:{
height:60, width:60,margin:5,

},
loginButton :{

justifyContent: 'center',
alignItems: 'center',

backgroundColor:"#fcb103",
maxWidth:50,
height:50,


marginRight:20,
marginBottom:10,
marginTop:10,







},


logoAndButtonContainer:{
flex:1,
flexDirection:"row",

backgroundColor:"#fcb103",
height:"auto",
paddingVertical:20,
width:"100%",
justifyContent:"space-between"




},

logoImage:{

height:60, width:60,margin:5,

  },
  loginButton :{

   
    justifyContent: 'center',
    alignItems: 'center',

backgroundColor:"#fcb103",

height:50,
width:50,
marginTop:10,
marginBottom:10,
marginRight:20,











  },


headText1View:{

  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
height:"auto",
  backgroundColor:"#fcb103",
 

},
headText2View:{

  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
height:50,
  backgroundColor:"#fcb103",
zIndex:-1,

},
headTextText1:{

  fontWeight:"bold",
  fontSize:16,
  //fontFamily: Platform.OS === 'ios' ? 'Chalkduster' : 'Chalkduster-Regular',
},


headTextText2:{

fontWeight:"bold",
fontSize:13,
alignSelf:"center",
// fontFamily: Platform.OS === 'ios' ? 'Chalkduster' : 'Chalkduster-Regular',
},

wrapperCustom: {
  borderRadius: 8,
  padding: 6,
},




//menu login
menuLoginButtonsContainer:{
flex:1, flexDirection:"row",  alignItems:"center", justifyContent: "space-between",marginTop:30

},
menuLoginButtons:{

  backgroundColor:"#0059ba", height:40, alignItems:"center",margin:10, width:150, borderRadius:4, justifyContent:"center"

},
menuLoginButtonsText:{

color:"white", fontWeight:"bold" 
},
userImageAndName:{
flexDirection:"column",paddingHorizontal:20


},
userImage:{
  height:70, width:70,marginBottom:10, borderRadius:70
},


sellerImage:{  height:150, width:150,marginBottom:10, 
  borderRadius:150,marginLeft:"auto",
   marginRight:"auto",marginTop:Platform.OS==="web"?0:-70,borderWidth:1,  borderColor: "rgba(0, 0, 0, 0.5)"
},
userName:{
fontWeight:"bold",
fontSize:18, alignSelf:"center"
},

sellerName:{
fontSize:20, fontWeight:"bold", alignSelf:"center",

},
BackButton:{

  height:50, width:50, backgroundColor:"#fcb103", alignItems:"center", justifyContent:"center", margin:15, borderRadius:50, 

 
},


tableRow: {
  flexDirection: 'row',
  marginBottom: 5,
},
label: {
  fontWeight: 'bold',
  marginRight: 5,
},
value: {
  flex: 1,
},

sellerStarsOnHeadPage:{backgroundColor:"#fcb103", justifyContent:"center", alignItems:"center", height:30,flex:1, flexDirection:"row"


},
scroeTextHead:{fontSize:12, fontWeight:"bold"},
ErrorView:{flex:1, flexDirection:"column",
position:"absolute", top:0,left:0,height:"auto",minHeight:100,width:"100%", backgroundColor:"rgba(222, 44, 44, 0.92)", alignItems:"center",paddingVertical:20
},



 //error and loading view
 LoadingView:{
  position:Platform.OS!=="web"?"absolute":"fixed",top:0,left:0,height:"100%",width:"100%", 
  backgroundColor:"rgba(0, 0, 0, 0.7)", justifyContent:"center", alignItems:"center"
},
ErrorView:{flex:1, flexDirection:"column",
position:Platform.OS!=="web"?"absolute":"fixed", top:0,left:0,height:"auto",width:"100%", backgroundColor:"rgba(222, 44, 44, 0.92)", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:60,
},
ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
 marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",borderRadius:4,},

 SuccessView:{flex:1, flexDirection:"column",
 position:Platform.OS==="web"?"fixed":"absolute",  top:0,left:0,height:"auto",width:"100%", backgroundColor:"#5cb85c", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:30,
},
  //notification section 


  notificationButton:{  
  flex:1,flexDirection:"row",marginLeft:10,
    maxWidth:70,
   height:70,
   borderRadius:70,
   justifyContent:"center",
      alignItems: 'center',

  
  
    },
    notificationButtonTextView:{
     marginLeft:-10,marginTop:15,backgroundColor:"rgba(255, 87, 34,0.7)", width:20, height:20,borderRadius:20, justifyContent:"center", alignItems:"center",
    },
  
    notificationButtonText:{
      fontSize:14, fontWeight:"bold",color:"white"
    },

});

export default SellerHomePage;
