import React, { useEffect, useState,useRef, useCallback} from 'react';
import {Modal, StyleSheet, Button,  Text, TextInput, View, ScrollView,SafeAreaView, Pressable,Image, Vibration,Platform, Dimensions, TouchableOpacity} from 'react-native';
import * as Haptics from 'expo-haptics';
import { useMyContext } from './MyContext';
import { useNavigation } from '@react-navigation/native';
 import AsyncStorage from '@react-native-async-storage/async-storage';
import { AntDesign } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons'; 

import { Feather } from '@expo/vector-icons'; 
import { Ionicons } from '@expo/vector-icons';
//converting dates to eu format
function convertToEUDate(usDate) {
  const parts = usDate.split("-");
  return `${parts[2]}-${parts[1]}-${parts[0]}`;
}

const NotificationUser = ({ Customnavigation, route, toggle }) => {
  const {contextTrigger, setcontextTrigger}=useMyContext();
//trigger haptic feedback
const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
  Haptics.notificationAsync(
    Haptics.NotificationFeedbackType.Success);}
}
//user and seller Objects
const{User, setUser}= useMyContext({});

//Notification user 
//user notification
const {OrdersNotificationUser , setOrdersNotificationUser}= useMyContext({});
const {ReplyNotificationUserProduct , setReplyNotificationUserProduct}= useMyContext({});
const {ReplyNotificationUserSeller , setReplyNotificationUserSeller}= useMyContext({});
const {userNotificationCount , setuserNotificationCount }= useMyContext();

const {OrdersNotificationsFrames , setOrdersNotificationsFrames}= useMyContext({});


return(
<SafeAreaView style={styles.androidSafeArea}>

  <View style={styles.Container}>
<TouchableOpacity style ={styles.CloseNotificationButton} onPress={toggle}><Text><AntDesign name="close" size={28} color="black" /></Text></TouchableOpacity>

  <ScrollView  veritcal={true} showsVerticalScrollIndicator={false} style={styles.ScrollViewContainer} >




  {OrdersNotificationUser && OrdersNotificationUser.length>0?(

<><Text style={styles.label}>Actions Requises pour des commandes</Text><View style={styles.SectionSeparator}></View></>):null}

{/*Order Actions Section */}

  {Object.entries(OrdersNotificationUser).length > 0&&OrdersNotificationUser && OrdersNotificationUser.map((Order, index) => {



// Filter OrdersNotificationsFrames based on matching order_id
const filteredFramesForNavigation = OrdersNotificationsFrames.filter(frame =>
  frame.order_id.includes(Order.order_id)
);
const NavigationUsedFrame = filteredFramesForNavigation[0];

    return (
<View key={index}>
<Text style={styles.notficationViewDate}>{convertToEUDate(Order.datum)}</Text>
  <TouchableOpacity  style={styles.notficationView} 
  onPress={() => {
    toggle(); // Hide the modal first
    triggerHapticFeedback();
    setcontextTrigger(!contextTrigger);
    Customnavigation.navigate('OrderUser', { Orderdata: NavigationUsedFrame});
 
  }}>

  <Image source={{uri:`https://3oula.tn/${Order.product_image}`}} style={styles.notificationImage}></Image>
  <View style={styles.notficationViewTextWrapper} >

    <Text style={styles.notficationViewText}><Text style={styles.TextBold}>Action Requise Commande Nr- {Order.order_id}{'\n'}</Text>

    {
    //if not confirmed , not payed user have to pay first
    Order.Status==="not confirmed"? (
    <Text>Votre commande n'est pas encore confirmé. Veuillez la confirmer en appuyant ici pour régler votre commande </Text>):

    //if confirmed , payed and the seller sent the package
    Order.Status==="confirmed" && Order.sent!==null&& Order.sendbackask!=="1"&&Order.sendbacknumber===null && Order.confirm_retour_reception_seller!=="1"?(
    <Text>{convertToEUDate(Order.sent_date)} Le vendeur {Order.seller_name} vient d'envoyer votre produit {Order.product_name}. Appuyez ici pour voir le numéro du colis et confirmer la réception</Text>):

    //if confirmed , payed and the seller sent the package user has recieved and asked for sendback, the seller has approved
    Order.sendbackask==="1"&& Order.sendbackapproved==="1"&& Order.sendbacknumber===null && Order.confirm_retour_reception_seller!=="1"?(
    <Text>{convertToEUDate(Order.sendbackapproved_date)}Le vendeur {Order.seller_name} vient d'accepter votre demande de retour pour le produit {Order.product_name}. S'il vous plaît, appuyez ici pour régler le retour</Text>):

    //if confirmed , payed and the seller sent the package user has recieved and asked for sendback, the seller has approved, user sent back, seller confirmed reception
    Order.sendbackask==="1"&& Order.sendbackapproved==="1" && Order.sendbacknumber!==null && Order.confirm_retour_reception_seller==="1"?(
    <Text>{convertToEUDate(Order.confirm_retour_reception_seller_date)}Le vendeur {Order.seller_name} a confirmé la réception de {Order.product_name}, et le remboursement est déjà sur votre compte, prêt à être utilisé pour de nouveaux achats ou un retrait</Text>):
    
    
    null}


</Text>






    </View>
  </TouchableOpacity>
  </View>

    );

})}









{Object.entries(ReplyNotificationUserProduct).length > 0&&ReplyNotificationUserProduct && ReplyNotificationUserProduct.length>0?(

<><Text style={styles.label}>Réponses a vos commentaires sur produits</Text><View style={styles.SectionSeparator}></View></>):null}

{/*Reply to commented Products Section */}
{Object.entries(ReplyNotificationUserProduct).length > 0&&ReplyNotificationUserProduct && ReplyNotificationUserProduct.map((item, index) => {

return (

  <View key={index}>
<Text style={styles.notficationViewDate}>{convertToEUDate(item.date)}</Text>
  <TouchableOpacity style={styles.notficationView}  onPress={() => {toggle();setcontextTrigger(!contextTrigger);
  triggerHapticFeedback();
  Customnavigation.navigate('productPage', {propName: item.product, CommentId:item.comment_id, replyId:item.reply_id});}} >

  <Image   source={{ uri: `https://3oula.tn/${item.product.product_image0}` }} style={styles.notificationImage}></Image>
  <View style={styles.notficationViewTextWrapper} >
  <Text style={styles.notficationViewText}><Text style={styles.TextBold}>{item.seller_name} </Text>shop a répondu le {convertToEUDate(item.date)} à votre commentaire sur son produit <Text style={styles.TextBold}> {item.product.product_name} </Text>et il a écrit:
{item.comment_text.slice(0,30)}...</Text>
    </View>
  </TouchableOpacity>
  </View>
);

})}






{Object.entries(ReplyNotificationUserSeller).length > 0&&ReplyNotificationUserSeller && ReplyNotificationUserSeller.length>0?(

<><Text style={styles.label}>Réponses a vos commetaire sur shops</Text>
<View style={styles.SectionSeparator}></View></>):null}

{/*Reply to commented Shops Section */}
{Object.entries(ReplyNotificationUserSeller).length > 0&&ReplyNotificationUserSeller && ReplyNotificationUserSeller.map((item, index) => {

return (
  <View key={index}>
  <Text style={styles.notficationViewDate}>{convertToEUDate(item.date)}</Text>
  <TouchableOpacity style={styles.notficationView}
  onPress={() => {
    setcontextTrigger(!contextTrigger);
    toggle();
    triggerHapticFeedback();
    Customnavigation.navigate('sellerPage', {propName:{seller_sku:item.seller.sku_seller}, CommentId:item.comment_id, replyId:item.reply_id});}} 
  
  >


  <Image source={{uri:`https://3oula.tn/${item.seller.image}`}} style={styles.notificationImage}></Image>
  <View style={styles.notficationViewTextWrapper} >
  <Text style={styles.notficationViewText}><Text style={styles.TextBold}>{item.seller_name} </Text>
  shop a répondu le {convertToEUDate(item.date)} à votre commentaire sur sa boutique et il a écrit:
 {item.comment_text.slice(0,30)}...</Text>

    </View>
  </TouchableOpacity>
  </View>
);

})}







  </ScrollView>
  </View>
</SafeAreaView>
    
    
    );
}

const styles = StyleSheet.create({
 androidSafeArea: {
        

          backgroundColor:"transparent",
      
          flex: 1,
      
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
      
      
      
      },


      Container:{
        flex:1,flexDirection:"column",
        width:"100%",
        height:Platform.OS==="web"?"100vw":"100%",
 
 marginTop:Platform.OS==="web"?30:30,
    backgroundColor:"#fcb103",
borderColor:"rgba(0, 0, 0, 0.4)",
borderTopWidth:1,
borderLeftWidth:1,
borderRightWidth:1,
borderBottomWidth:0,
borderTopLeftRadius:10,
borderTopRightRadius:10,
      },



ScrollViewContainer:{
flex:1,
flexDirection:"column",
width:"98%",
marginHorizontal:"1%",
height:"100%",
 backgroundColor:"#fffbe6",
 borderWidth:1,
borderRadius:4,
borderColor: "rgba(0, 0, 0, 0.5)"
},




 CloseNotificationButton:{  
marginRight:"2%", marginLeft:"auto",
backgroundColor:"#fcb103",
width:60,
height:60,
justifyContent:"center",
alignItems: 'center',
},
//section separator
SectionSeparator:{
width:Platform.OS==="web"?"90vw":"90%", backgroundColor:"rgba(0, 0, 0, 0.1)", height:2,marginBottom:10,marginHorizontal:"5%"

},
      //labesls
label:{fontWeight:"bold",fontSize:13,alignSelf:"center",
fontFamily:Platform.OS!=="android"?"Chalkduster":"Roboto",paddingVertical:10,paddingHorizontal:10,color:"rgba(0, 0, 0, 0.7)",},

//notification View
notficationView: {
  maxHeight:100,
  minHeight: 100,
  width: "98%",
  marginHorizontal:"1%",
  marginVertical: 2,
  borderRadius:4,
  flex: 1,
  flexDirection: "row",
  backgroundColor: "white",
  overflow: "hidden",


},

 notificationImage:{height:100,width:100,},

notficationViewTextWrapper:{padding:10,flex:1,},
TextBold:{color:"black", fontWeight:"bold"},
  notficationViewText:{fontSize:13,color:"black", 

},
 notficationViewDate:{fontSize:12,   fontFamily:Platform.OS!=="android"?"Chalkduster":"Roboto",fontWeight:"bold", 
      color:"black",alignSelf:"center"},

      
      });

export default NotificationUser;