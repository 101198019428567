
//const SearchPageScreen = ({ navigation, route }) => {

  //const {searchInput} = route.params;

import React, { useEffect, useState,useRef, useCallback, useContext} from 'react';
import { Modal,FlatList, StyleSheet, Button,  Text, TextInput, View, ScrollView,SafeAreaView, Pressable,Image, Vibration,Platform,Animated,TouchableOpacity,Dimensions} from 'react-native';
import { Category} from './myComponent';
import { Ionicons } from '@expo/vector-icons';
import { Audio} from 'expo-av'; 
import MenuLoginUser from './menuLoginForUser';
import * as Haptics from 'expo-haptics';
import Autocomplete from './Autocomplete';
import Cart from './cart';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AntDesign } from '@expo/vector-icons';
import { SimpleLineIcons } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons'; 
import { FontAwesome } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons'; 
import { Fontisto } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { LoadingView, SocialMediaContainer} from './myComponent';
import { useMyContext } from './MyContext';
import axios from 'axios';
import NetInfo from '@react-native-community/netinfo';

import Typewriter from "./typeWriter";

const isWeb = Platform.OS === 'web';
const SearchPageScreen = ({ navigation, route}) => {

  const {searchInput} = route.params;
  //updating notification trigger
  const {contextTrigger, setcontextTrigger}=useMyContext();
 
  //var to control modal menu
  const [showMenu, setshowMenu] = useState(false);

    // Extract the string prop from route.params
    const {FlatListNumberOfProducts} = route.params;
const{rerenderTrigger}=route.params;
//output a random text each time








//
const[Rerender, setRerender]=useState(false);

//function to show menu
const toggleMenu= () => {
  triggerHapticFeedback();

setshowMenu(!showMenu);

};
//trigger haptic feedback
//trigger haptic feedback
const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
  Haptics.notificationAsync(
    Haptics.NotificationFeedbackType.Success);}
}


//when aDD to button is pressed feedback
const [pressedButton, setPressedButton] = useState(null);




//user and seller Objects
  const{User, setUser}= useMyContext({});
  const{Seller, setSeller}= useMyContext([]);





const sortByScrollViewRef = useRef();
const PageIndexButtonsScrollViewTop=useRef();
const PageIndexButtonsScrollViewBottom=useRef();




const [updatedStyles, setUpdatedStyles] = useState(styles);




//const for the scrollspy function nb all ref are created with loop and stored in arrays
  const SellerDetailInCartRef=useRef([]);


  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [userId, setUserId] = useState('');
  const [userSku, setUserSku] = useState('');
  const [name, setname] = useState('');
  //const to store the sugesstions and than pass them to const Array
   const [suggestions1, setSuggestions1] = useState([]);
   const [controlMount, setcontrolMount]=useState(false);

  const [ProductData, setProductData] = useState({});
const [SimilarProducts, setSimilarProducts]=useState({});




  //user and seller objects


//const to save the products, count, sellers, and total price when added 
const {sellersArray, setsellersArray}=useMyContext();
const { cart, setCart } = useMyContext();
const{totalPrice, settotalPrice} = useMyContext();
const{totalDeliveryForAll, setTotalDeliveryForAll} = useMyContext();

const {countProductCart, setCount} = useMyContext();


const {sellersSku, setsellersSku} = useMyContext([]);

 //const using and State to show and hide the scrollView for cart
 const [showMenuRef, setshowMenuRef] = useState(false);

  const [showScrollViewRef, setShowScrollViewRef] = useState(false);

const [showSellerInfoCartViewRef, setshowSellerInfoCartViewRef]=useState(false);


  const ScrollViewRef = useRef(null);
 

















const handleClickButtonsAddToCart = (productSku, sellerSku, productWeight, sellerToCart, productToCart) => {
 


  try {
   
   
    const existingProductIndex = cart.findIndex((item) => item[productSku] !== undefined);
    const existingSellerIndex = sellersSku.findIndex((item) => item[sellerSku] !== undefined);
    if (existingProductIndex >= 0) {
      const existingCartItem = cart[existingProductIndex][productSku];
      existingCartItem.productQuantity += 1;
      const existingSellerItem = sellersSku[existingSellerIndex][sellerSku];
      existingSellerItem.productQuantityProSeller +=1;
      //updating the total price in cart
      existingSellerItem.productWeightProSeller = parseFloat(existingSellerItem.productWeightProSeller) + parseFloat(productWeight);
      const price= (existingCartItem.productPrice*1);
      existingSellerItem.totalSeller = parseFloat(existingSellerItem.totalSeller) + parseFloat(price);
    


      settotalPrice(prevtotalPrice => {
        const totalPrice = prevtotalPrice + price;
        return Number(totalPrice.toFixed(2));
      }
        );

     
   
    
    } 
    else
     {
    
      const newCartItem = {};
      newCartItem[productSku] = productToCart[productSku];
      newCartItem[productSku].productQuantity = 1;
      //cart.push(newCartItem);
      setCart(prevCart => [...prevCart, newCartItem]);


     const price= (newCartItem[productSku].productPrice*1);
     settotalPrice(prevtotalPrice => {
      const totalPrice = prevtotalPrice + price;
      return Number(totalPrice.toFixed(2));
    }
      );

    
      const existingSellerSku= sellersSku.findIndex((item) => item[sellerSku] !== undefined);

      if (existingSellerSku >= 0) {
        const existingSellerItem = sellersSku[existingSellerSku][sellerSku];
        existingSellerItem.productQuantityProSeller +=1;

        existingSellerItem.productWeightProSeller = parseFloat(existingSellerItem.productWeightProSeller) + parseFloat(productWeight);
        existingSellerItem.totalSeller = parseFloat(existingSellerItem.totalSeller) + parseFloat(price);
  
        }
        else {
          const newSeller = {};
          newSeller[sellerSku] =  sellerToCart[sellerSku];
          setsellersSku(prevsellersSku => [...prevsellersSku, newSeller]);

       
        }
      
      
    }
    

      setCount(countProductCart + 1);

   
  triggerHapticFeedback();

    
  } catch (error) {

    //console.log(error);
    
  }

};

// Function to calculate and return the modified value of the weight in cart foreach seller
const calculateWeight = (weight) => {
  if (weight >= 1000) {
    weight /= 1000;

  }
  return Number(weight.toFixed(3));
};

useEffect(() => {

  
},[Rerender])



//sorted by
const [SortedBy, setSortedBy]=useState('');


//price Asd
const sortByPriceAsd = (sortedByWhat) => {
  setTextTop('');
  setTextTextBottom('');
  setshowTypeWriter(false);
  setshowTypeWriter2(false);
  triggerHapticFeedback();
  const sortedData = [...ProductData].sort((a, b) => {
    const priceA = parseFloat(a.product_price);
    const priceB = parseFloat(b.product_price);
    
    if (priceA < priceB) {
      return -1;
    }
    if (priceA > priceB) {
      return 1;
    }
    return 0;
  });

  // Update the state or variable holding the sorted data
  setProductData(sortedData);
  setSlicedProductData(sortedData.slice(0, 10));
  setStartIndex(0);
  setEndIndex(10);
  setSortedBy(sortedByWhat);

  setRerender((prev) => !prev);


};


//price Dsd
const sortByPriceDsd = (sortedByWhat) => {
  setTextTop('');
  setTextTextBottom('');
  setshowTypeWriter(false);
  setshowTypeWriter2(false);
  triggerHapticFeedback();
  const sortedData = [...ProductData].sort((a, b) => {
    const priceA = parseFloat(a.product_price);
    const priceB = parseFloat(b.product_price);
    
    if (priceA < priceB) {
      return 1;
    }
    if (priceA > priceB) {
      return -1;
    }
    return 0;
  });

  // Update the state or variable holding the sorted data
  setProductData(sortedData);
  setSlicedProductData(sortedData.slice(0, 10));
  setStartIndex(0);
  setEndIndex(10);
  setSortedBy(sortedByWhat);
  setRerender((prev) => !prev); // Use functional update to toggle the state


};


const sortByDateAsd = (sortedByWhat) => {
  setTextTop('');
  setTextTextBottom('');
  setshowTypeWriter(false);
  setshowTypeWriter2(false);
  triggerHapticFeedback();
  const sortedData = [...ProductData].sort((a, b) => {
    const dateA = new Date(a.product_date); // Assuming the date property is in a valid date format
    const dateB = new Date(b.product_date); // Assuming the date property is in a valid date format
    
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  });

  // Update the state or variable holding the sorted data
  setProductData(sortedData);
  setSlicedProductData(sortedData.slice(0, 10));
  setStartIndex(0);
  setEndIndex(10);
  setSortedBy(sortedByWhat);
  setRerender((prev) => !prev); // Use functional update to toggle the state


};
const sortByDateDsd = (sortedByWhat) => {
  setTextTop('');
  setTextTextBottom('');
  setshowTypeWriter(false);
  setshowTypeWriter2(false);
  triggerHapticFeedback();
  const sortedData = [...ProductData].sort((a, b) => {
    const dateA = new Date(a.product_date); // Assuming the date property is in a valid date format
    const dateB = new Date(b.product_date); // Assuming the date property is in a valid date format
    
    if (dateA < dateB) {
      return 1;
    }
    if (dateA > dateB) {
      return -1;
    }
    return 0;
  });

  // Update the state or variable holding the sorted data
  setProductData(sortedData);
  setSlicedProductData(sortedData.slice(0, 10));
  setStartIndex(0);
  setEndIndex(10);
  setSortedBy(sortedByWhat);
  setRerender((prev) => !prev); // Use functional update to toggle the state


  // 
};
const sortByRatingAsd = (sortedByWhat) => {
  setTextTop('');
  setTextTextBottom('');
  setshowTypeWriter(false);
  setshowTypeWriter2(false);
  triggerHapticFeedback();
  const sortedData = [...ProductData].sort((a, b) => {
    const priceA = parseFloat(a.product_score);
    const priceB = parseFloat(b.product_score);
    
    if (priceA < priceB) {
      return 1;
    }
    if (priceA > priceB) {
      return -1;
    }
    return 0;
  });

  // Update the state or variable holding the sorted data
  setProductData(sortedData);
  setSlicedProductData(sortedData.slice(0, 10));
  setStartIndex(0);
  setEndIndex(10);
  setSortedBy(sortedByWhat);
  setRerender((prev) => !prev); // Use functional update to toggle the state

  // 
};


const sortByRatingDsd = (sortedByWhat) => {
  setTextTop('');
  setTextTextBottom('');
  setshowTypeWriter(false);
  setshowTypeWriter2(false);
  triggerHapticFeedback();
  const sortedData = [...ProductData].sort((a, b) => {
    const priceA = parseFloat(a.product_score);
    const priceB = parseFloat(b.product_score);
    
    if (priceA < priceB) {
      return -1;
    }
    if (priceA > priceB) {
      return 1;
    }
    return 0;
  });

  // Update the state or variable holding the sorted data
  setProductData(sortedData);
  setSlicedProductData(sortedData.slice(0, 10));
  setStartIndex(0);
  setEndIndex(10);
  setSortedBy(sortedByWhat);
  setRerender((prev) => !prev); // Use functional update to toggle the state

  // 
};






//only  verified sellers
const filterProductsByVerification = (sortedByWhat) => {

  setTextTop('');
  setTextTextBottom('');
  setshowTypeWriter(false);
  setshowTypeWriter2(false);
  triggerHapticFeedback();
  const filteredData = ProductData.filter(product => product.product_seller_verification=== 1);
  


  
  setSlicedProductData(filteredData.slice(0, 10));
    // Update the state or variable holding the filtered data
    setStartIndex(0);
    setEndIndex(10);
    setSortedBy(sortedByWhat);
    setRerender(!Rerender); 




};



















// Function that construct the stars for rating 
  function StarRating({ score }) {
    const filledStars = Array(parseInt(score)).fill().map((_, index) => (
      <AntDesign key={`filled-${index}`} name="star" size={14} color="#fcb103" />
    ));
    const emptyStars = Array(5 - parseInt(score)).fill().map((_, index) => (
      <AntDesign key={`empty-${index}`} name="star" size={14} color="grey" />
    ));
    const stars = [...filledStars, ...emptyStars];
    return <Text>{stars}</Text>;
  }
//slice data to show only 10 on each page
  const [SlicedProductData, setSlicedProductData]= useState({});
  const [StartIndex, setStartIndex]= useState(0);
  const [EndIndex, setEndIndex]= useState(10);
  const [PageIndex, setPageIndex]= useState(1);

  //function to fetch data from 3oula.tn
  useEffect(() => {

    // Fetch data when component is mounted or searchInput/rerenderTrigger changes
    const fetchData = async () => {
      try {
        const response = await axios.post(
          'https://3oula.tn/appgate/searchPageApp.php',
          `searchInput=${searchInput}`,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );

        const json = response.data;

        setProductData(json.Products_data);
        setSimilarProducts(json.Similar_products);
      


        setSlicedProductData(json.Products_data.slice(StartIndex, EndIndex));
setSortedBy('');

        // Simulate a 2-second delay before setting controlMount to true
        setTimeout(() => {
          setcontrolMount(true);
          setshowTypeWriter(false);
        }, 300);


        // Additional logic for pagination if needed
      } catch (error) {
       // navigation.navigate('registerUser');
      }
    };

    fetchData();

    
  }, [searchInput, rerenderTrigger]);


//funciton to slice,sort  and update  shown product or sliced  data

useEffect(() => {


  if (ProductData !== undefined && Object.keys(ProductData).length > 0) {
    setSlicedProductData(ProductData.slice(StartIndex, EndIndex));

  } 
}, [StartIndex, EndIndex]);


//funciton to change interval and show the next
const [scrollPositionPageIndex, setscrollPositionPageIndex] = useState(0);




//

const scrollingPageIndex = (direction) => {
  // Increment or decrement the scroll position based on the direction
  const increment = direction === 'plus' ? 30 : -30;
  const newScrollPosition = scrollPositionPageIndex + increment;
  setscrollPositionPageIndex(newScrollPosition);

  // Scroll to the new position
 PageIndexButtonsScrollViewTop.current.scrollTo({ x: newScrollPosition, y: 0, animated: true });
  // Scroll to the new position
  PageIndexButtonsScrollViewBottom.current.scrollTo({ x: newScrollPosition, y: 0, animated: true });
  

};

const scrollToTop=()=>{

  if(Platform.OS==="web"){

    // Scroll the window to the desired position
// Scroll the window to the top of the page
window.scrollTo({
top: 0, // Scroll to the top
behavior: 'auto', // 'auto' for instant scroll, 'smooth' for animated scroll
});
}
else {

  ScrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true });

}

}
  const seeNextPage = () => {
    triggerHapticFeedback();
    if(EndIndex<ProductData.length){
  if(EndIndex<=ProductData.length-10){
    setStartIndex((prevStartIndex) => prevStartIndex + 10);
    setEndIndex((prevEndIndex) => prevEndIndex + 10);
    setPageIndex((prevPageIndex) => prevPageIndex + 1);

    scrollingPageIndex('plus');

  } else {
    setEndIndex((prevEndIndex) => prevEndIndex +(ProductData.length-EndIndex));
    setStartIndex((prevStartIndex) => prevStartIndex +10);
    setPageIndex((prevPageIndex) => prevPageIndex + 1);
    scrollingPageIndex('plus');
  }



  scrollToTop();



}

  };
//funciton to change interval and show the last one
  const seeLastPage = () => {
    triggerHapticFeedback();
    if(StartIndex>=10){
      setEndIndex((prevEndIndex) => StartIndex);
    setStartIndex((prevStartIndex) => prevStartIndex - 10);

    setPageIndex((prevPageIndex) => prevPageIndex - 1);
    scrollingPageIndex('minus');
  }
  if(StartIndex<10&&StartIndex>0){
    setStartIndex((prevStartIndex) => prevStartIndex - StartIndex);
    setEndIndex(10);
    scrollingPageIndex('minus');

  }
  scrollToTop();
  };



  const jumpToPage=(index)=>{
    triggerHapticFeedback();
    let startindex=index * 10;
let endindex= (index*10)+10;
//inerval to the end general
let intervalToEnd= ProductData.length-endindex;
//interval to the end on the last click
let intervalToEndLastClick;


if(endindex+10>ProductData.length){
  intervalToEndLastClick=ProductData.length-endindex;
}
else
{
  intervalToEndLastClick=10;

}

if(startindex >=0&&startindex<ProductData.length&&endindex<ProductData.length){

    setStartIndex((prevStartIndex) =>startindex);
    setEndIndex((prevEndIndex) => endindex);
    setPageIndex((prevPageIndex) => index+1);
    scrollToTop();
  }
  else if(startindex >=0  && startindex<ProductData.length&&endindex>ProductData.length&&EndIndex<ProductData.length){

    setStartIndex((prevStartIndex) =>startindex);
    setEndIndex((prevEndIndex) => endindex+intervalToEndLastClick);
    setPageIndex((prevPageIndex) => index+1);
    scrollToTop();
  }



 

    }


 


 

 








//show detail of seller in cart, wieght price...
  const showDetailSellerCartText=(targetView)=>{
    const targetViewToChange = SellerDetailInCartRef.current[targetView];


    if (targetViewToChange!= null) {
   
      targetViewToChange.setNativeProps({
        style: {
         display:showSellerInfoCartViewRef? 'none' :'flex'
        }
      });

setshowSellerInfoCartViewRef(!showSellerInfoCartViewRef);
   
  }

}






  // state variables
  const [RandomTopText, setRandomTopText] = useState([]);
  const [RandomBottomText, setRandomBottomText] = useState([]);

  //useEffect to update the state when dependencies change
  useEffect(() => {

 
    // Update RandomTopText and RandomBottomText  based on conditions
    setRandomTopText([
      `résultat${ProductData.length<21?" correspond":"s correspondent"}  a votre recherche \u{1F600}`,
      `résultat${ProductData.length<2?"":"s"}  3andna  lil recherche mte3ek \u{1F601}`,
      `produit${ProductData.length<2?" l9itou":"s l9ithomlek"} bil ism hetha walla fi nafs el catégorie \u{1F607}`,
      
      `produit${ProductData.length<2?" mawjoud":"s mawjoudin"} fil site bil ism hetha walla fi nafs el catégorie \u{1F609}`,
      `résultat${ProductData.length<2?"":"s"} 3andi huni bil ism hetha walla ychabhou w fi nafs el groupe \u{1F600}`,
      `produit${ProductData.length<2?" l9itou":"s l9ithom"} tawa kif lawejt bil ism hetha walla fi nafs el catégorie \u{1F607}`,

    ]);
    setRandomBottomText([
      `produit${SimilarProducts.length<2?" similaire qui peut":" similaires qui peuvent"}  également vous interesser \u{1F60E}`,
`produit${SimilarProducts.length<2?" e5er ychabah":" o5rin ychabhou"} lelli tlawej 3lih \u{1F60B}`,
`produit${SimilarProducts.length<2?"":"s"} kif elli lawejt 3lih yomkin ${SimilarProducts.length<2?" yemchi":"yemchiw"} m3ak zeda \u{1F608}`,
`produit${SimilarProducts.length<2?" e5er":"s o5rin"} possible ${SimilarProducts.length>1?" yenj7ou":"yenja7"} m3ak \u{1F600}`,
`autre${SimilarProducts.length<2?" produit":"s produits"} kima elli fil recherche \u{1F609}`,
`produit${SimilarProducts.length<2?" yomkin ye3jbek":"s yomkin ye3jbouk"} \u{1F607}`
 
    ]);
    if(SortedBy!==""){


      switch(SortedBy){
case "priceAsd":
  setRandomTopText([`${ProductData.length<2?"seul produit, manajamch na3mil classement, Dsl\u{1F607} ":"produits classés par prix ascendant \u{1F609}"}`]);
break;
case "priceDsd":
  setRandomTopText([`${ProductData.length<2?"seul produit, manajamch na3mil classement \u{1F607} ":"produits classés par prix descendant \u{1F609}"}`]);
break;
case "dateAsd":
  setRandomTopText([`${ProductData.length<2?"seul produit, manajamch na3mil classement, rani Floomy \u{1F607} ":"produits classés par date, les plus récents au début \u{1F609}"}`]);
break;

case "dateDsd":
  setRandomTopText([`${ProductData.length<2?"seul produit, manajamch na3mil classement \u{1F607} ":"produits classés par date, les plus anciens au début \u{1F609}"}`]);
break;

case "RatingAsd":
  setRandomTopText([`${ProductData.length<2?"seul produit, manajamch na3mil classement \u{1F607} ":"produits classés par score, les meilleurs au début \u{1F609}"}`]);
break;


case "RatingDsd":
  setRandomTopText([`${ProductData.length<2?"seul produit, manajamch na3mil classement, rani Floomy w mazelt feye9 \u{1F607} ":"produits classés par score, les plus mauvais au début \u{1F609}"}`]);
break;

case "verified":
  setRandomTopText([`${ProductData.length<2?"produit":"produits"} filtrés et seuls les produits des vendeurs vérifiés sont affichés \u{1F609}`]);

break;

      }  





    }


  
  }, [ProductData, SimilarProducts, searchInput,SortedBy]);









  














const[TextTop, setTextTop]=useState('');
const[TextBottom, setTextTextBottom]=useState('');

const [randomNumber, setRandomNumber] = useState(0);
const [showTypeWriter, setshowTypeWriter] = useState(false);
const [showTypeWriter2, setshowTypeWriter2] = useState(false);

useEffect(() => {

setshowTypeWriter(false);
  setshowTypeWriter2(false);
  setTextTop('');
  setTextTextBottom('');

  const timeoutId = setTimeout(() => {

if(!SortedBy && randomNumber<5){

  setTextTop(RandomTopText[randomNumber+1]);
  setTextTextBottom(RandomBottomText[randomNumber+1]);
  setRandomNumber(randomNumber+1);


}else{
  setTextTop(RandomTopText[0]);
  setTextTextBottom(RandomBottomText[0]);
  setRandomNumber(0);

}
    
}, 300); 
// first variable to true immediately
setshowTypeWriter(true);

//variable to true 5 seconds later
setTimeout(function() {
  setshowTypeWriter2(true);
}, 2000); 


 return () => {
    clearTimeout(timeoutId);
  };
}, [showTypeWriter,RandomTopText,RandomBottomText,rerenderTrigger]);



  
if (!controlMount||SlicedProductData===undefined) {
  return <SafeAreaView style={styles.androidSafeArea}>

<LoadingView/>


  </SafeAreaView>; // or any other loading indicator
}




  return (







<SafeAreaView    style={Platform.OS!=="web"?styles.androidSafeArea:styles.WebSafeArea}  >


  
 



{/*menu login */}

<Modal
          animationType={'slide'}
          transparent={true}
          visible={showMenu}
          onRequestClose={() => {
  
          }}
>

<MenuLoginUser  Customnavigation={navigation}  toggle={toggleMenu} />

</Modal>





 
  {/*only for Web*/}


  {isWeb?(<>
  <View style={styles.logoAndButtonContainer}>

  <Pressable style={styles.BackButton} onPress={() => { {setcontextTrigger(!contextTrigger);triggerHapticFeedback();navigation.navigate('home');} }}>
  <Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
  </Pressable>
    <Pressable  onPress={toggleMenu}  style={styles.loginButton}><Text><Feather name="menu" size={40} color="black" /></Text></Pressable>
</View>


<View style={{position:"sticky", top:0}}>

    {/*the Head section that conatin the logo, loginButton and the search Field */}

  
{/*Impoting the Autocomlpete function from Autocomplete.js*/}

<Autocomplete navigation={navigation} suggestions={suggestions1}/>





{/*Scrollspymenu*/}
<View style={styles.separatorScrollspyTop}></View>

<View style={styles.sortByMenu}>

<ScrollView horizontal={true} showsHorizontalScrollIndicator={false} style={styles.sortByScrollView}  ref={sortByScrollViewRef}>

<Pressable onPress={()=>{sortByPriceAsd("priceAsd")}}   
 style={() => [  {marginLeft:10,backgroundColor:SortedBy==="priceAsd"?"#E8E8E8":"white"}, styles.ButtonSortBy, ]}>
  <Text style={styles.TextButtonSortBy}>prix croissant  <FontAwesome5 name="sort-amount-up" size={18} color="black" /></Text></Pressable>

<Pressable onPress={()=>{sortByPriceDsd("priceDsd")}} 
 style={() => [  {marginLeft:10,backgroundColor:SortedBy==="priceDsd"?"#E8E8E8":"white"}, styles.ButtonSortBy, ]}>
  <Text style={styles.TextButtonSortBy}>prix décroissant  <FontAwesome5 name="sort-amount-down-alt" size={18} color="black" /></Text></Pressable>

<Pressable onPress={()=>{sortByDateDsd("dateAsd")}} 
 style={() => [  {marginLeft:10,backgroundColor:SortedBy==="dateAsd"?"#E8E8E8":"white"}, styles.ButtonSortBy, ]}>
  <Text style={styles.TextButtonSortBy}>plus récente  <Fontisto name="date" size={18} color="black" /></Text></Pressable>

<Pressable onPress={()=>{sortByDateAsd("dateDsd")}} 
 style={() => [  {marginLeft:10,backgroundColor:SortedBy==="dateDsd"?"#E8E8E8":"white"}, styles.ButtonSortBy, ]}>
  <Text style={styles.TextButtonSortBy}>plus anciennes  <Fontisto name="date" size={18} color="black" /></Text></Pressable>

<Pressable onPress={()=>{sortByRatingAsd("RatingAsd")}} 
 style={() => [  {marginLeft:10,backgroundColor:SortedBy==="RatingAsd"?"#E8E8E8":"white"}, styles.ButtonSortBy, ]}>
  <Text style={styles.TextButtonSortBy}>meilleur évaluation <SimpleLineIcons name="like" size={18} color="black"/></Text></Pressable>
  <Pressable onPress={()=>{sortByRatingDsd("RatingDsd")}} 
style={() => [  {marginLeft:10,backgroundColor:SortedBy==="RatingDsd"?"#E8E8E8":"white"}, styles.ButtonSortBy, ]}>
  <Text style={styles.TextButtonSortBy}>mauvaise évaluation <SimpleLineIcons name="dislike" size={18} color="black" /></Text></Pressable>

<Pressable onPress={()=>{filterProductsByVerification("verified")}} 
style={() => [  {marginLeft:10,backgroundColor:SortedBy==="verified"?"#E8E8E8":"white"}, styles.ButtonSortBy, ]}>
  <Text style={styles.TextButtonSortBy}>vendeur vérifiés  <AntDesign name="checkcircleo" size={18} color="black" /></Text></Pressable>
</ScrollView>
</View>


</View>
</>

):null}












{/*the Body of the 

that contain all products sorted in different categories */}





  <ScrollView stickyHeaderIndices={[1]}
 ref={ScrollViewRef} vertical={true} showsVerticalScrollIndicator={false} 
   style={styles.root} nestedScrollEnabled={true} keyboardShouldPersistTaps='handled'
>


   
  {/*only for native*/}


  {!isWeb?(
  <View style={styles.logoAndButtonContainer}>

  <Pressable style={styles.BackButton} onPress={() => { {setcontextTrigger(!contextTrigger);triggerHapticFeedback();navigation.navigate('home');} }}>
  <Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
  </Pressable>
    <Pressable  onPress={toggleMenu}  style={styles.loginButton}><Text><Feather name="menu" size={40} color="black" /></Text></Pressable>
</View>
):null}
  {!isWeb?(
<View>

    {/*the Head section that conatin the logo, loginButton and the search Field */}

  
{/*Impoting the Autocomlpete function from Autocomplete.js*/}

<Autocomplete navigation={navigation} suggestions={suggestions1}/>





{/*Scrollspymenu*/}
<View style={styles.separatorScrollspyTop}></View>

<View style={styles.sortByMenu}>

<ScrollView horizontal={true} showsHorizontalScrollIndicator={false} style={styles.sortByScrollView}  ref={sortByScrollViewRef}>

<Pressable onPress={()=>{sortByPriceAsd("priceAsd")}}   
 style={() => [  {marginLeft:10,backgroundColor:SortedBy==="priceAsd"?"#E8E8E8":"white"}, styles.ButtonSortBy, ]}>
  <Text style={styles.TextButtonSortBy}>prix croissant  <FontAwesome name="sort-amount-asc" size={16} color="black" /></Text></Pressable>

<Pressable onPress={()=>{sortByPriceDsd("priceDsd")}} 
 style={() => [  {marginLeft:10,backgroundColor:SortedBy==="priceDsd"?"#E8E8E8":"white"}, styles.ButtonSortBy, ]}>
  <Text style={styles.TextButtonSortBy}>prix décroissant  <FontAwesome name="sort-amount-desc" size={16} color="black" /></Text></Pressable>

<Pressable onPress={()=>{sortByDateAsd("dateAsd")}} 
 style={() => [  {marginLeft:10,backgroundColor:SortedBy==="dateAsd"?"#E8E8E8":"white"}, styles.ButtonSortBy, ]}>
  <Text style={styles.TextButtonSortBy}>plus récente  <Fontisto name="date" size={18} color="black" /></Text></Pressable>

<Pressable onPress={()=>{sortByDateDsd("dateDsd")}} 
 style={() => [  {marginLeft:10,backgroundColor:SortedBy==="dateDsd"?"#E8E8E8":"white"}, styles.ButtonSortBy, ]}>
  <Text style={styles.TextButtonSortBy}>plus anciennes  <Fontisto name="date" size={18} color="black" /></Text></Pressable>

<Pressable onPress={()=>{sortByRatingAsd("RatingAsd")}} 
 style={() => [  {marginLeft:10,backgroundColor:SortedBy==="RatingAsd"?"#E8E8E8":"white"}, styles.ButtonSortBy, ]}>
  <Text style={styles.TextButtonSortBy}>meilleur évaluation <SimpleLineIcons name="like" size={18} color="black"/></Text></Pressable>
  <Pressable onPress={()=>{sortByRatingDsd("RatingDsd")}} 
style={() => [  {marginLeft:10,backgroundColor:SortedBy==="RatingDsd"?"#E8E8E8":"white"}, styles.ButtonSortBy, ]}>
  <Text style={styles.TextButtonSortBy}>mauvaise évaluation <SimpleLineIcons name="dislike" size={18} color="black" /></Text></Pressable>

<Pressable onPress={()=>{filterProductsByVerification("verified")}} 
style={() => [  {marginLeft:10,backgroundColor:SortedBy==="verified"?"#E8E8E8":"white"}, styles.ButtonSortBy, ]}>
  <Text style={styles.TextButtonSortBy}>vendeur vérifiés  <AntDesign name="checkcircleo" size={18} color="black" /></Text></Pressable>
</ScrollView>
</View>


</View>

):null}











<View style={styles.ResultCount}>


{showTypeWriter?(<Typewriter text={`${ProductData.length} ${TextTop}`}
delay={10} />):null}



  
  
  </View>
   
{/*the View that contains product for each category */}


    <View  style={styles.productViewConatiner}>
  











      {/*the body of the product container */}



    {SlicedProductData&& SlicedProductData.length>0?(


<View style={styles.pagesMenuBottomProducts}>

<TouchableOpacity activeOpacity={0.2} style={styles.pagesMenuBottomProductsButtons} onPress={seeLastPage}><Text style={styles.TextBoldBlack}><AntDesign name="stepbackward" size={24} color="grey" /></Text></TouchableOpacity>

<View style={styles.pagesMenuBottomActualPageView}>


  <ScrollView  ref={PageIndexButtonsScrollViewTop}style={styles.pagesMenuBottomActualPageIndexButtonsView} horizontal={true} showsHorizontalScrollIndicator={false} >
{[...Array(Math.ceil(ProductData.length/10))].map((_, index) => (

  <TouchableOpacity activeOpacity={0.2}
key={index}  style={[styles.pageButton,index + 1 === Math.ceil(EndIndex/10) ? styles.PageIndexButtonHighlighted:styles.PageIndexButtonNormal,]}
onPress={()=>{jumpToPage(index)}}
>
<Text style={[

  index + 1 === Math.ceil(EndIndex/10) ? styles.TextBoldWhite:styles.TextBoldBlack,
]}>{index+1}</Text>
  </TouchableOpacity>
 
      ))}

</ScrollView>



</View>




<TouchableOpacity style={styles.pagesMenuBottomProductsButtons} activeOpacity={0.2} onPress={seeNextPage}><Text style={styles.TextBoldBlack}><AntDesign name="stepforward" size={24} color="grey" /></Text></TouchableOpacity>

</View>

    ):null}





<View style={styles.renderContainer} >


      {SlicedProductData.length > 0 && SlicedProductData.map((item,index)=>(
  


<View style={styles.wrapperProductViewGrid}key={index} >


<View style={styles.productView} >

<Pressable style={styles.wrapperProductViewPressable} onPress={() => {triggerHapticFeedback();navigation.navigate('productPage', {propName: item});}} >


<Image source={{ uri: `https://3oula.tn/${item.product_image0}` }} style={styles.productImage} />


<View style={styles.productScoreView}>

<StarRating  score={item.product_score} />


</View>

<View style={styles.productnameView}>


<Text style={styles.productName}>{item.product_name}</Text>
</View>


<View style={styles.productDescriptionView}>

<Text style={styles.productDescritption}>{item.product_description.slice(0, 10)+ '...'}    </Text>
</View>

</Pressable>

{sellersArray.length > 0 && sellersArray.map((seller, indexSeller) => (


<View key={indexSeller}>
{seller.sku_seller===item.seller_sku? ( 

<View>
<Pressable
   //onPressIn={() => handlePressIn(product.product_sku)}
  // onPressOut={handlePressOut}
  
onPress={() => {
  const sellerToCart={
    [`${item.seller_sku}`]:{
      productSellerName: `${item.seller_name}`,
      productSellerSku: `${item.seller_sku}`,
      productSellerEmail: `${item.seller_email}`,
      productSellerScore:`${seller.seller_score}`,
      productSellerImage: `${item.seller_image}`,
      productQuantityProSeller:1,
      productWeightProSeller:`${item.product_weight}`,
      totalSeller: item.sale ?  `${((item.product_price / 100) * (100-item.sale)).toFixed(2)}`:`${item.product_price}`,
    }
  };

const productToCart = {
[`${item.product_sku}`]: {
productSku: `${item.product_sku}`,
productName: `${item.product_name}`,
productScore:`${item.product_score}`,
productImage: `${item.product_image0}`,
productSellerName: `${item.seller_name}`,
productSellerSku: `${item.seller_sku}`,
productSellerEmail: `${item.seller_email}`,
productSellerTel: `${item.seller_tel}`,
productSellerAdress: `${item.seller_adress}`,
productSellerImage: `${item.seller_image}`,
productPrice: item.sale ?  `${((item.product_price / 100) * (100-item.sale)).toFixed(2)}`:`${item.product_price}`,

productWeight: `${item.product_weight}`,
productQuantity:1,
}

}


; 

handleClickButtonsAddToCart(item.product_sku, item.seller_sku,item.product_weight, sellerToCart, productToCart);
}}


  

style={({pressed}) => [
  {
    opacity: pressed ? 0.5 : 1,
  },
  styles.productPriceButton,
]}
>

<Text style={styles.productPriceTextSaleOldPrice}>{item.sale?item.product_price +' Tnd ':null}</Text>
      <Text style={styles.productPriceText}>
 {item.sale?((item.product_price/100)*(100-item.sale)).toFixed(2)+' Tnd ':item.product_price+' Tnd '}<AntDesign name="shoppingcart" size={16} color="black" />
  </Text>


 </Pressable>

 </View>):(null)}
 </View>
 
))}


</View> 

<View style={styles.sellerNameAndLink}>
<Image source={{ uri: `https://3oula.tn/${item.seller_image}` }} style={styles.sellerImageProductView} ></Image>
<Pressable onPress={() => {triggerHapticFeedback();navigation.navigate('sellerPage', {propName:item});}}  style={styles.sellerNameProductView}>
  <Text style={styles.sellerNameProductViewText}>{item.seller_name}</Text></Pressable>
</View>

{item.sale?(<Pressable style={styles.productSale}><Text style={styles.productSaleText}>sale {item.sale}%</Text></Pressable>):null}

</View>


      ))}

</View>  
        




{SlicedProductData&& SlicedProductData.length>0?(
    
<View style={styles.pagesMenuBottomProducts}>

<TouchableOpacity activeOpacity={0.2} style={styles.pagesMenuBottomProductsButtons} onPress={seeLastPage}><Text style={styles.TextBoldBlack}><AntDesign name="stepbackward" size={24} color="grey" /></Text></TouchableOpacity>

<View style={styles.pagesMenuBottomActualPageView}>


  <ScrollView  ref={PageIndexButtonsScrollViewBottom}style={styles.pagesMenuBottomActualPageIndexButtonsView} horizontal={true} showsHorizontalScrollIndicator={false} >
{[...Array(Math.ceil(ProductData.length/10))].map((_, index) => (

  <TouchableOpacity  activeOpacity={0.2}
key={index}  style={[styles.pageButton,index + 1 === Math.ceil(EndIndex/10) ? styles.PageIndexButtonHighlighted:styles.PageIndexButtonNormal,]}
onPress={()=>{jumpToPage(index)}}
>
<Text style={[

  index + 1 === Math.ceil(EndIndex/10) ? styles.TextBoldWhite:styles.TextBoldBlack,
]}>{index+1}</Text>
  </TouchableOpacity>
 
      ))}

</ScrollView>



</View>




<TouchableOpacity  activeOpacity={0.2} style={styles.pagesMenuBottomProductsButtons} onPress={seeNextPage}><Text style={styles.TextBoldBlack}><AntDesign name="stepforward" size={24} color="grey" /></Text></TouchableOpacity>

</View>

):null}













 

    </View>

    <View style={styles.ResultCount}>

    {showTypeWriter2?(   
       <Typewriter text={`${SimilarProducts.length} ${TextBottom}`}
delay={10} />):null}


   
      
      
      
      </View>

    <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} style={styles.SimilarproductViewConatinerBody}>
    {SimilarProducts.length > 0  &&  SimilarProducts.map((item ,index) => (



<View key={index}>

      
<View style={styles.wrapperProductView} >


<View style={styles.productView} >

<Pressable style={styles.wrapperProductViewPressable} onPress={() => {triggerHapticFeedback();navigation.navigate('productPage', {propName: item});}} >


<Image source={{ uri: `https://3oula.tn/${item.product_image0}` }} style={styles.productImage} />


<View style={styles.productScoreView}>

<StarRating  score={item.product_score} />


</View>

<View style={styles.productnameView}>


<Text style={styles.productName}>{item.product_name}</Text>
</View>


<View style={styles.productDescriptionView}>

<Text style={styles.productDescritption}>{item.product_description.slice(0, 10)+ '...'}    </Text>
</View>

</Pressable>

{sellersArray.length > 0 && sellersArray.map((seller, indexSeller) => (


<View key={indexSeller}>
{seller.sku_seller===item.seller_sku? ( 

<View>
<Pressable
   //onPressIn={() => handlePressIn(product.product_sku)}
  // onPressOut={handlePressOut}
  
onPress={() => {
  const sellerToCart={
    [`${item.seller_sku}`]:{
      productSellerName: `${item.seller_name}`,
      productSellerSku: `${item.seller_sku}`,
      productSellerEmail: `${item.seller_email}`,
      productSellerScore:`${seller.seller_score}`,
      productSellerImage: `${item.seller_image}`,
      productQuantityProSeller:1,
      productWeightProSeller:`${item.product_weight}`,
      totalSeller: item.sale ?  `${((item.product_price / 100) * (100-item.sale)).toFixed(2)}`:`${item.product_price}`,
    }
  };

const productToCart = {
[`${item.product_sku}`]: {
productSku: `${item.product_sku}`,
productName: `${item.product_name}`,
productScore:`${item.product_score}`,
productImage: `${item.product_image0}`,
productSellerName: `${item.seller_name}`,
productSellerSku: `${item.seller_sku}`,
productSellerEmail: `${item.seller_email}`,
productSellerTel: `${item.seller_tel}`,
productSellerAdress: `${item.seller_adress}`,
productSellerImage: `${item.seller_image}`,
productPrice: item.sale ?  `${((item.product_price / 100) * (100-item.sale)).toFixed(2)}`:`${item.product_price}`,

productWeight: `${item.product_weight}`,
productQuantity:1,
}

}


; 

handleClickButtonsAddToCart(item.product_sku, item.seller_sku,item.product_weight, sellerToCart, productToCart);
}}


  

style={({pressed}) => [
  {
    opacity: pressed ? 0.5 : 1,
  },
  styles.productPriceButton,
]}
>

<Text style={styles.productPriceTextSaleOldPrice}>{item.sale?item.product_price +' Tnd ':null}</Text>
      <Text style={styles.productPriceText}>
 {item.sale?((item.product_price/100)*(100-item.sale)).toFixed(2)+' Tnd ':item.product_price+' Tnd '}<AntDesign name="shoppingcart" size={16} color="black" />
  </Text>
 
 
 
 </Pressable>

 </View>):(null)}
 </View>
 
))}


</View> 

<View style={styles.sellerNameAndLink}>
<Image source={{ uri: `https://3oula.tn/${item.seller_image}` }} style={styles.sellerImageProductView} ></Image>
<Pressable onPress={() => {triggerHapticFeedback();navigation.navigate('sellerPage', {propName:item});}}  style={styles.sellerNameProductView}>
  <Text style={styles.sellerNameProductViewText}>{item.seller_name}</Text></Pressable>
</View>
{item.sale?(<Pressable style={styles.productSale}><Text style={styles.productSaleText}>sale {item.sale}%</Text></Pressable>):null}

</View>


</View>
))}

</ScrollView>

<View style={styles.Footer}>







</View>















    </ScrollView>
 

    <Cart/>

  
    </SafeAreaView>

  );
};




    




const styles = StyleSheet.create({
  androidSafeArea: {
  

    paddingTop: Platform.OS === 'android' ? 25 : 0,
    backgroundColor:"#fcb103",

    flex: 1,


    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,



},
//only for web
WebSafeArea: {
  width:"100%", 
     paddingTop: Platform.OS === 'android' ? 25 : 0,
     backgroundColor:"#fcb103",
    flex:1,

},

root:{
 flex:1,
  zIndex:-2,
  backgroundColor:"#fcb103",

  




},
flatListContainer:{zIndex:-10},
Loadingview:{
  backgroundColor:"#fcb103",
  height:"100%", width:"100%", justifyContent:"center", alignItems:"center"


},



separatorScrollspyTop:{

  height:20,backgroundColor:"#fcb103", zIndex:-1,
},

sortByMenu :{
  flex:1, flexDirection:"row",
  zIndex:-1,
height:50,
width:"98%",
marginLeft:"1%",
marginRight:"1%",
backgroundColor:"white",
borderRadius:4,
borderWidth:1,
borderColor:"rgba(0, 0, 0, 0.4)",
backgroundColor:"#fffbe6",
overflow:"hidden"




},

productViewConatiner:{
paddingVertical:10,
  width:"98%",
marginLeft:"1%",
marginRight:"1%",
  borderRadius:4,
  height:"auto",
  borderWidth:1,
  borderColor:"rgba(0, 0, 0, 0.4)",
  backgroundColor:"#fffbe6", 
  overflow:"hidden"
},





productViewContainerHead:{
height:40,
borderRadius:4,
  backgroundColor:"#fffbe6",
  justifyContent: 'center',
  alignItems: 'center',

},




productView:{

  height:290,
  width:"100%",
  maxWidth:160,
  backgroundColor:"#C8C8C8",

  borderRadius:4,
  
  overflow:"hidden"
  },

 
  wrapperProductViewPressable:{
height:247,
marginBottom:3,
width:"100%",

backgroundColor:"white",
overflow:"hidden"},


wrapperProductView:{
margin:10,
borderRadius:4,
height:290,

  width:160,

  backgroundColor:"white",
   shadowColor: 'rgba(0, 0, 0, 0.8)',
shadowOffset: { width: 2, height: 4 },
shadowOpacity: 0.7,
shadowRadius: 4,
elevation: 5,},

wrapperProductViewGrid:{
  margin:"2%",
  borderRadius:4,
  height:290,
width:"46%",
    maxWidth:160,
 backgroundColor:"white",
     shadowColor: 'rgba(0, 0, 0, 0.8)',
  shadowOffset: { width: 2, height: 4 },
  shadowOpacity: 0.7,
  shadowRadius: 4,
  elevation: 5,},

productImage:{
height:160,
width:160,
},


productScoreView:{
  flex:1,
  flexDirection:"row",
height:20,
backgroundColor:"white",
paddingLeft:10,


},
productnameView:{
  backgroundColor:"white",
  height:30,


},
productName:{
fontWeight:"bold",
marginLeft:10,

},

productDescriptionView:{
  backgroundColor:"white",
  height:37,
  marginBottom:3,


  padding:5,
},
productPriceButton:{
  height:40,
backgroundColor:"#a7bf60",

width:"100%",


justifyContent: 'center',
alignItems: 'center',



},




productPriceText:{
fontWeight:"bold",

marginTop:-3,
},
Footer:{
  minHeight:500,
  backgroundColor:"#fcb103",
},

  menuLogin:{
    height:"100%",
    backgroundColor:"white",
    display:"none",

  
  
  
  },



  wrapperProductCartView:{
    backgroundColor:"white",
    width:"90%",
    marginLeft:"5%",
    marginRight:"5%",
marginTop:20,
borderRadius:4,
shadowColor: 'rgba(0, 0, 0, 0.7)',
shadowOffset: { width: 3, height: 4 },
shadowOpacity: 0.7,
shadowRadius: 10,
elevation: 5,
  },



sellerNameAndLink:{
 flexDirection:"row",

  alignItems:"space-between",
height:40,
width:"100%",
  backgroundColor:"rgba(0,0,0,0.3)",
marginTop:-270


},
sellerImageProductView:{
  height:60,
  width:60,
  borderRadius:60,
  marginLeft:5,
  marginTop:Platform.OS==="web"?-10:0,
  marginBottom:Platform.OS==="web"?0:-10,
  borderWidth:1,  borderColor: "rgba(0, 0, 0, 0.3)"
},
sellerNameProductViewButton:{
  flexWrap:"wrap",
  alignItems:"flex-start",
  overflow:"hiddden",

},
sellerNameProductView:{
  height:"100%", justifyContent:"center",alignItems:"center",  backgroundColor:"transparent",width:95,
},


sellerNameProductViewText:{
  fontSize:12,
  color:"white",
  fontWeight:"bold",
  margin:5,

},

textProductNameCart:{
  fontSize:17,
  fontWeight:"bold",

},
textProductQuantityCart:{
  marginTop:5,
  fontWeight:"bold",
fontSize:15,

},
textProductPriceCart:{
  marginTop:10,
  fontWeight:"bold",
  fontSize:15,

},







logoImage:{
height:60, width:60,margin:5,

},
loginButton :{
 
  justifyContent: 'center',
  alignItems: 'center',

backgroundColor:"#fcb103",
maxWidth:50,
height:50,


marginRight:20,
marginBottom:10,
marginTop:10,







},


logoAndButtonContainer:{
flex:1,
flexDirection:"row",

backgroundColor:"#fcb103",
minHeight:100,
maxHeight:100,
width:"100%",
justifyContent:"space-between"


},

logoImage:{

  height:60, width:60,margin:5,
  
    },
    loginButton :{

     
      justifyContent: 'center',
      alignItems: 'center',
  
  backgroundColor:"#fcb103",

  height:50,
  width:50,
  marginTop:10,
  marginBottom:10,
  marginRight:20,


  
  
    },
 




 wrapperCustom: {
    borderRadius: 8,
    padding: 6,
  },




  //menu login
  menuLoginButtonsContainer:{
flex:1, flexDirection:"row",  alignItems:"center", justifyContent: "space-between",marginTop:30

  },
  menuLoginButtons:{

    backgroundColor:"#0059ba", height:40, alignItems:"center",margin:10, width:150, borderRadius:4, justifyContent:"center"
 
  },
  menuLoginButtonsText:{

color:"white", fontWeight:"bold" 
  },
  userImageAndName:{
 flexDirection:"column",paddingHorizontal:20


  },
  userImage:{
    height:70, width:70,marginBottom:10, borderRadius:70
  },
  userName:{
fontWeight:"bold",
fontSize:18, alignSelf:"center"
  },
  BackButton:{

    height:50, width:50, backgroundColor:"#fcb103", alignItems:"center", justifyContent:"center", margin:15, borderRadius:50, 
  
   
  },

  renderContainer:{     flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: 'transparent',
    justifyContent: 'center', // Center content vertically
    alignItems: 'center',},

 
  ButtonSortByFirst:{
height:50, width:"auto", paddingHorizontal:10,
 justifyContent:"center", alignItems:"center", 
backgroundColor:"#fffbe6",   shadowOffset: { width: 1, height: 0 },
shadowOpacity: 0.6,
shadowRadius: 2,
elevation: 0,


  },
  sortByScrollView:{},

  ButtonSortBy:{
    height:36, marginTop:7, marginBottom:7, borderRadius:30, width:"auto", paddingHorizontal:14, marginRight:10,
    justifyContent:"center", alignItems:"center",  shadowColor: 'rgba(0, 0, 0, 0.8)',
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.7,
    shadowRadius: 3,
    
  },

  TextButtonSortBy:{
    fontWeight:"bold"
  },



  ResultCount:{width:"auto", marginHorizontal:"auto",
height:"auto",minHeight:70,backgroundColor:"#fcb103", justifyContent:"center", alignItems:"center",

  },
  ResultCountText: {
    textAlign: 'center',
    fontSize:14,
    fontWeight:"bold",
  },








  SimilarproductViewConatinerBody:{
    width:"98%",
    marginLeft:"1%",
    marginRight:"1%",
      borderRadius:4,
     marginBottom:15,
   
      borderWidth:1,
      borderColor:"rgba(0, 0, 0, 0.4)",
      backgroundColor:"#fffbe6", 
      paddingTop:20,paddingBottom:20,
      paddingLeft:10


  }
,


productPriceButtonSimilar:{
  height:50,
backgroundColor:"#a7bf60",

width:"100%",
marginBottom:0, marginTop:"auto",





justifyContent: 'center',
alignItems: 'center',



},

starRatingView:{
  backgroundColor:"white",paddingLeft:10, 
  
  },
  productSale:{

    backgroundColor:"rgba(218, 41, 28, 0.8)",width:80, height:30,marginLeft:10,marginTop:50,justifyContent:"center", alignItems:"center", borderRadius:20
  },
  productSaleText:{fontWeight:"bold", color:"white"},

  pagesMenuBottomProducts:{flex:1, flexDirection:"row", maxHeight:40,width:"100%",maxWidth:"100%",justifyContent:"space-between", backgroundColor:"#fffbe6"},
  pagesMenuBottomProductsButtons:{height:40, width:"10%", backgroundColor:"#fffbe6",justifyContent:"center", alignItems:"center"},

  
  pagesMenuBottomActualPageView:{justifyContent:"center", alignItems:"center",backgroundColor:"#fffbe6",width:"80%"},


  pagesMenuBottomActualPageIndexButtonsView:{height:40,backgroundColor:"#fffbe6", width:"auto",maxWidth:"100%"},




  PageIndexButtonNormal:{justifyContent:"center", alignItems:"center", height:30,marginVertical:5, width:40,borderRadius:40, backgroundColor:"#fffbe6"},
  PageIndexButtonHighlighted:{justifyContent:"center", alignItems:"center" ,height:30, marginVertical:5,width:40,borderRadius:40, backgroundColor:"grey"},
  TextBoldBlack:{fontWeight:"bold",color:"black"},
  TextBoldWhite:{fontWeight:"bold",color:"white"},
  productPriceTextSaleOldPrice:{color:"red",fontWeight:"bold",marginTop:-5,  textDecorationLine: "line-through",fontSize:10},


});
export default SearchPageScreen;