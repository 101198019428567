import React ,{ useEffect, useState,useRef, useContext} from 'react';
import { Modal,ActivityIndicator,StyleSheet, View, Text, Pressable, TextInput, ScrollView, SafeAreaView, Vibration,Platform, TouchableOpacity,  Image
} from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import { Fontisto } from '@expo/vector-icons';
import { Keyboard } from 'react-native';
import { KeyboardAvoidingView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useMyContext } from '../MyContext';
import * as ImagePicker from 'expo-image-picker';

import { Picker } from '@react-native-picker/picker';
import * as Haptics from 'expo-haptics';
import axios from 'axios';
import Typewriter from "../typeWriter";
import TermAndConditions from "../termAndConditions";
const CheckBox = ({ value1, value,readTermAndConditions, checked, onSelect }) => (
  <TouchableOpacity onPress={onSelect} style={{ flexDirection: 'row', alignItems: 'center' }}>
    <View style={{ height: 24, width: 24, borderRadius: 4, borderWidth: 3, borderColor: checked ? '#5cb85c' : 'black', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', marginLeft: '5%', marginTop: 20 }}>
      {checked ? <View style={{ height: 12, width: 12, borderRadius: 6, backgroundColor: '#5cb85c' }} /> : null}
    </View>
    <Text style={{ marginLeft: '5%', marginTop: 20 }}>{!readTermAndConditions ? (value1) : (checked ? 'lu et accepté' : value)
}</Text>
  </TouchableOpacity>
);






const AddProductSeller= ({ navigation, route }) => {

//hide and show modal for term and conditions
const[showTermAndConditions, setshowTermAndConditions]=useState(false);


const showAndHideTermAndCondition = () => {
  triggerHapticFeedback();
  setshowTermAndConditions(!showTermAndConditions);
};





   //cehckbox
   const [checked, setChecked] = useState(false);
   const [readTermAndConditions,setreadTermAndConditions]=useState(false);


   //show term and condtions first, than set checked and if clicked again rerstart the hole process
   const handleCheck = () => {

    if(!checked){
    if(readTermAndConditions){
    triggerHapticFeedback();
    setChecked(!checked);
  }
  else {
    setshowTermAndConditions(true);
    setreadTermAndConditions(true);

  }

    }
    else{
      setChecked(!checked);
      setreadTermAndConditions(false);

    }


  };



  const closeTermAndConditions=()=>{
triggerHapticFeedback();
    setshowTermAndConditions(false);
  }

    //trigger haptic feedback
    const triggerHapticFeedback=()=>{
      if(Platform.OS!=="web"){
      Haptics.notificationAsync(
        Haptics.NotificationFeedbackType.Success);}
    }
    const {SellerProp} = route.params;
const {contextTrigger, setcontextTrigger}= useMyContext();
//const while loading
const [loading, setLoading] = useState(false);
//functions to check Letters and numbers 

  //check only letters and numbers in the input
  const onlyLettersAndNumbers = (str) => {
    return /^[A-Za-z0-9'’èéäêöàùâêîôûçëïü\s.,?!]*$/.test(str);
  };
  
  
//check only letters in the  input
const onlyLetters = (str) => {
return /^[A-Za-z'’èéäêöàùâêîôûçëïü\s]*$/.test(str);
};

//check only numbers in the comment input
const onlyNumber = (str) => {
    return /^[0-9]+$/.test(str);
  };
//check price 
const onlyNumberPrice = (str) => {
    return /^[0-9,.]+$/.test(str);
  };


//Error holding and displaying vars
const [Error, setError]=useState();
//holdSuccess
const [Success, setSuccess]=useState();

// State variables to hold inputs values
const [productName, setproductName]=useState('');
const [productWeight, setproductWeight]=useState('');
const [productDescription, setproductDescription]=useState('');
const [productComponent, setproductComponent]=useState('');
const [productNote, setproductNote]=useState('');
const [productQuantity, setproductQuantity]=useState('');
const [productPrice, setproductPrice]=useState('');
// State variables to hold inputs check results if ok true esle false
const [everythingOkproductName, setEverythingOkproductName]=useState(false);
const [everythingOkproductCategory, setEverythingOkproductCategory]=useState(false);
const [everythingOkproductWeight, setEverythingOkproductWeight]=useState(false);
const [everythingOkproductDescription, setEverythingOkproductDescription]=useState(false);
const [everythingOkproductComponent, setEverythingOkproductComponent]=useState(false);
const [everythingOkproductNote, setEverythingOkproductNote]=useState(false);
const [everythingOkproductQuantity, setEverythingOkproductQuantity]=useState(false);
const [everythingOkproductPrice, setEverythingOkproductPrice]=useState(false);
const [everythingOkproductImages, setEverythingOkproductImages]=useState(false);
//functions to check each input individually

  //handle product name change
  const handleProductNameChange= (product_name) => {
   if (onlyLettersAndNumbers(product_name) && product_name.length>0&& product_name.length<30) {
        setEverythingOkproductName(true);
        setproductName(product_name);
        setError();
    }
    else {

        setEverythingOkproductName(false);
        setproductName(product_name);
        if(product_name.length){
        setError("le nom ne peut contenir que des lettres, min 1, et max 30 \u{1F621}.");
      }
    }
  };

     //handle product weight change
  const handleProductWeightChange= (product_weight) => {
    if (onlyNumber(product_weight)&& product_weight.length>0) {
         setEverythingOkproductWeight(true);
         setproductWeight(product_weight);
         setError();
     } else{
    setEverythingOkproductWeight(false);
    setproductWeight(product_weight);
    if(product_weight.length){
    setError("le poids ne peut contenir que des nombres \u{1F624}");}
}


   };

       //handle product description change
  const handleProductDescriptionChange= (product_Description) => {
    if (onlyLettersAndNumbers(product_Description) && product_Description.length>0&& product_Description.length<600) {
        setEverythingOkproductDescription(true);
        setproductDescription(product_Description);
        setError();
     }else {
        setEverythingOkproductDescription(false);
        setproductDescription(product_Description);
        if(product_Description.length){
        setError("la déscription ne peut contenir que des lettres, nombres points et vigules et ?!', min 1, et max 600 \u{1F623}. ");}
     }
   };

       //handle product component change
       const handleProductComponentChange= (product_Component) => {
        if (onlyLettersAndNumbers(product_Component)&& product_Component.length>0&& product_Component.length<600) {
            setEverythingOkproductComponent(true);
            setproductComponent(product_Component);
            setError();
         }else{ setEverythingOkproductComponent(false);
         setproductComponent(product_Component);
         if(product_Component.length){
         setError("les composition ne peut contenir que des lettres, nombres points et vigules et ?!',min 1, et max 600 \u{1F624}. ");}

        }
       };

    //handle product Note change
    const handleProductNoteChange= (product_Note) => {
        if (onlyLettersAndNumbers(product_Note)&& product_Note.length>0&& product_Note.length<600) {
            setEverythingOkproductNote(true);
            setproductNote(product_Note);
            setError();
         }
         else
          {  setEverythingOkproductNote(false);
         setproductNote(product_Note);
         if(product_Note.length){
         setError("la note ne peut contenir que des lettres, nombres points et vigules et ?!', min 1, et max 600 \u{1F625}. ");}

        }

       }
       ;

    //handle product Quantity change
    const handleProductQuantityChange= (product_Quantity) => {
        if (onlyNumber(product_Quantity)&& product_Quantity.length>0) {
            setEverythingOkproductQuantity(true);
            setproductQuantity(product_Quantity);
            setError();
         }else { setEverythingOkproductQuantity(false);
            setproductQuantity(product_Quantity);
            if(product_Quantity.length){
            setError("la quantité ne peut contenir que des nombres entiers, pas de virgules ou points \u{1F626}");}

        }

       };


    //handle product Price change
    const handleProductPriceChange= (product_Price) => {
        if (onlyNumberPrice(product_Price)&& product_Price.length>0) {
            setEverythingOkproductPrice(true);
            setproductPrice(product_Price);
            setError();
         }else {setEverythingOkproductPrice(false);
            setproductPrice(product_Price);
            if(product_Price.length){
            setError("la prix ne peut contenir que des nombres, virgules ou points \u{1F627}");}

        }
       };
       





//the fetch operation to add product if all inputs are ok



const insertProduct = async () => {
  triggerHapticFeedback();
  setLoading(true);

  const formData = new FormData();

  for (let index = 0; index < images.length; index++) {
    const imageUri = images[index];
    if (Platform.OS === 'web') {
      // On web, reading the image file as an ArrayBuffer
      const response = await fetch(imageUri);
      const buffer = await response.arrayBuffer();
      const blob = new Blob([buffer], { type: 'image/jpeg' });
      formData.append('images[]', blob, `image${index}.jpg`);
    } else {
      // On mobile (Expo), using the image URI directly
      formData.append('images[]', {
        uri: imageUri,
        type: 'image/jpeg',
        name: `image${index}.jpg`,
      });
    }
  }

  // Product details
  formData.append('product_name', productName);
  formData.append('product_category', selectedPickerValue);
  formData.append('product_weight', productWeight);
  formData.append('product_description', productDescription);
  formData.append('product_component', productComponent);
  formData.append('product_note', productNote);
  formData.append('product_quantity', productQuantity);
  formData.append('product_price', productPrice);

  // Seller details
  formData.append('seller_name', SellerProp[0].name);
  formData.append('seller_sku', SellerProp[0].sku_seller);
  formData.append('seller_tel', SellerProp[0].tel);
  formData.append('seller_email', SellerProp[0].email);
  formData.append('seller_adress', SellerProp[0].seller_adress);
  formData.append('seller_image', SellerProp[0].image);

  try {
    const response = await axios.post(
      'https://3oula.tn/appgate/addProductSeller.php?action=insertNewProduct',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    const responseData = response.data;


    if (response.status === 200) {



      // Handling successful response here
      if (responseData.error) {
        setLoading(false);
        setError('Product not added successfully error: ' + responseData.error);
      } else {
             // Handling successful response here
        setLoading(false);
        setcontextTrigger(true);
setSuccess("Le produit vient d'être correctement ajouté, rabbi yjib el 9asm w dima men ghir ma nwassik rodd belek mil qualité wil nthafa. Rahou client far7an yjib 3 akhow em client met8achech yharreb 10. hayya wamya nraj3el lil home page mte3ek tnajem tchouf les produits mte3ek lkoll \u{1F9D0}");

  
       
      }



    } 
    else 
    {
      setLoading(false);
      // Handling  error response here
      setError('Product not added successfully error: ' + responseData.error);
    }
  } catch (error) {
    setLoading(false);
    // Handling  network errors or other exceptions
    setError('Product not added successfully error: ' + error.message);
  }
};





    //picker section 




//variable to hide hold and show the values of picker
const [selectedPickerValue, setSelectedPickerValue] = useState('aucune');
const [pickerDisplayed, setpickerDisplayed]=useState(false);
//function to show picker
const showPicker=()=>{
setpickerDisplayed(!pickerDisplayed);
}
//funciton to set and hide picker
const setSelectedPickerValueAndPickerVidsibility=(itemValue)=>{
setSelectedPickerValue(itemValue);
setTimeout(() => {
setpickerDisplayed(!pickerDisplayed);
 }, 300);
 if(selectedPickerValue){
 setEverythingOkproductCategory(true);
}
else {setEverythingOkproductCategory(false);}
}










//images uploading section 

//array to hold uploaded images
    const [images, setImages] = useState([]);
//function to pick images 
const pickImage = async () => {
    const maxNumberOfImages = 6 - images.length; // Maximum number of images allowed to upload
  
    // No permissions request is necessary for launching the image library while using expo
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [4, 3],
      quality: 1,
      allowsMultipleSelection: true,
      maxNumberOfFiles: maxNumberOfImages, // Set the maximum number of files to be selected
    });
  
    if (!result.canceled) {
      const newImages = result.assets.map((asset) => asset.uri);
  
      // Check if the total number of images is more than 5
      if (images.length + newImages.length > 6) {
        setError("Vous pouvez ajouter seulement 5 images"); // Set the error message
        setEverythingOkproductImages(false); // Set the everythingOkproductImages to false
      } else {
        setError(null); // Reset the error if there was any
        setImages((prevImages) => [...prevImages, ...newImages.slice(0, maxNumberOfImages)]);
  
        // Check if the length of the newImages array is between 1 and 4
        if (newImages.length >= 1 && newImages.length <6) {
          setEverythingOkproductImages(true);
        } else {
          setEverythingOkproductImages(false);
        }
      }
    }
  };
  
  
//removing images one by one
    const removeImage = (index) => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
        if(images.length>1){
            setEverythingOkproductImages(true);

        }
        else{

            setEverythingOkproductImages(false);
        }
      };





  return (
 
<KeyboardAvoidingView     behavior={Platform.OS === 'ios' ? 'padding' : null}
        style={StyleAddProduct.keyboardAvoidingContainer}>




<SafeAreaView style={StyleAddProduct.androidSafeArea}>



<Modal
          animationType={'slide'}
          transparent={true}
          visible={showTermAndConditions}
          onRequestClose={() => {
         
          }}
>

<TermAndConditions  closeTermAndConditions={closeTermAndConditions} />
</Modal>













{/*the container for the logo and the button to show the login menu*/}
<View style={StyleAddProduct.HeadContainer}>

<Pressable style={StyleAddProduct.BackButton} onPress={() => { {triggerHapticFeedback();navigation.navigate('sellerHomePage'); }}}>
  <Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
  </Pressable>



</View>
<ScrollView vertical={true} showsHorizontalScrollIndicator={false}  style={StyleAddProduct.Container} >
      <View style={StyleAddProduct.HeadTextView}>
  
  <Text style={StyleAddProduct.HeadText}>Ajouter un nouveau produit</Text>
  </View>

     




      <Text style={StyleAddProduct.Label}>Le nom du produit *</Text>
  
  
   <TextInput
 style={productName.length===0 ? StyleAddProduct.inputText: everythingOkproductName? StyleAddProduct.inputTextSucess:StyleAddProduct.inputTextFail}
 placeholder="Ajouter le nom du produit ici"
onChangeText={handleProductNameChange}
value={productName}
 />

<View style={StyleAddProduct.lengthView}>
 <Text style={!everythingOkproductName?StyleAddProduct.lengthTextFail:StyleAddProduct.lengthTextSucess}>{productName.length} / 30</Text>
 </View>




<Text style={StyleAddProduct.Label}>Le cat&#233;gorie du produit *</Text>

<View

style={selectedPickerValue== "aucune"? StyleAddProduct.PickerContainer: selectedPickerValue!== "aucune" && everythingOkproductCategory? StyleAddProduct.PickerContainerSucess:StyleAddProduct.PickerContainerFail}
 >
<Pressable


 style={StyleAddProduct.SelectCategoryButton} 
onPress={showPicker}>
     <Text>Catégorie sélectionnée: {selectedPickerValue}</Text>
        </Pressable> 
      
      <Picker
        selectedValue={selectedPickerValue}
        onValueChange={(itemValue, itemIndex) => setSelectedPickerValueAndPickerVidsibility(itemValue)}
        style={{ width: '90%', marginHorizontal: '5%',display: pickerDisplayed ? 'flex' : 'none',
          }}
                  itemStyle={StyleAddProduct.pickerItem}
      >
                <Picker.Item label="Divers" value="Divers" />
        <Picker.Item label="couscous" value="couscous" />
        <Picker.Item label="Pâtes" value="Pâtes" />
     
     
        <Picker.Item label="tawabel" value="tawabel" />
        <Picker.Item label="harissa" value="hrissa" />
        <Picker.Item label="Malsouka" value="Malsouka" />
 
        <Picker.Item label="mousabbrat" value="mousabbrat" />


        <Picker.Item label="hlou arbi" value="hlou arbi" />
        <Picker.Item label="Pâtisserie" value="Pâtisserie" />




        <Picker.Item label="mekla diari" value="mekla diari" />

        <Picker.Item label="Fruits secs" value="Fruits secs" />
        <Picker.Item label="Confitures" value="Confitures" />

        <Picker.Item label="Jus" value="Jus" />
        <Picker.Item label="Degla" value="Degla" />




    

      </Picker>

      </View>







<Text style={StyleAddProduct.Label}>Le poids  pour le calcul des frais de livraisons (Svp en gramme même pour les liquides) *</Text>
   <TextInput
style={productWeight.length===0 ? StyleAddProduct.inputText: everythingOkproductWeight? StyleAddProduct.inputTextSucess:StyleAddProduct.inputTextFail}
onChangeText={handleProductWeightChange}
value={productWeight}
 placeholder="Ajouter le poids du produit ici"
 />




<Text style={StyleAddProduct.Label}>La description du produit *</Text>
   <TextInput
 style={productDescription.length===0 ? StyleAddProduct.inputTextBig: everythingOkproductDescription? StyleAddProduct.inputTextBigSucess:StyleAddProduct.inputTextBigFail}
 placeholder="Ajouter la d&#233;scription du produit ici"
onChangeText={handleProductDescriptionChange}
value={productDescription}
multiline={true}
 />

 <View style={StyleAddProduct.lengthView}>
 <Text style={!everythingOkproductDescription?StyleAddProduct.lengthTextFail:StyleAddProduct.lengthTextSucess}>{productDescription.length} / 600</Text>
 </View>


<Text style={StyleAddProduct.Label}>Les composants alimentaires du produit *</Text>
   <TextInput
 style={productComponent.length===0 ? StyleAddProduct.inputTextBig: everythingOkproductComponent? StyleAddProduct.inputTextBigSucess:StyleAddProduct.inputTextBigFail}
 placeholder="Ajouter les compostants alimentaires du produit ici"
              onChangeText={handleProductComponentChange}
value={productComponent}
multiline={true}
 />
 <View style={StyleAddProduct.lengthView}>
 <Text style={!everythingOkproductComponent? StyleAddProduct.lengthTextFail:StyleAddProduct.lengthTextSucess}>{productComponent.length} / 600</Text>
 </View>


<Text style={StyleAddProduct.Label}>Note pour les allergiques, si vous n'avez pas de note ou remarque insérez "pas de note" *</Text>
   <TextInput
 style={productNote.length===0 ? StyleAddProduct.inputTextBig: everythingOkproductNote? StyleAddProduct.inputTextBigSucess:StyleAddProduct.inputTextBigFail}
 placeholder="Ajouter une Note ici pour les allergiques"
 onChangeText={handleProductNoteChange}
value={productNote}
multiline={true}
 />
 <View style={StyleAddProduct.lengthView}>
 <Text style={!everythingOkproductNote?  StyleAddProduct.lengthTextFail:StyleAddProduct.lengthTextSucess}>{productNote.length} / 600</Text>
 </View>

<Text style={StyleAddProduct.Label}>Quantité disponible *</Text>
   <TextInput
 style={productQuantity.length===0 ? StyleAddProduct.inputText: everythingOkproductQuantity? StyleAddProduct.inputTextSucess:StyleAddProduct.inputTextFail}
 placeholder="Ajouter la quantit&#233; disponible ici"
onChangeText={handleProductQuantityChange}
 value={productQuantity}
 />


<Text style={StyleAddProduct.Label}>Le prix du produit *</Text>
 <TextInput
 style={productPrice.length===0 ? StyleAddProduct.inputText: everythingOkproductPrice? StyleAddProduct.inputTextSucess:StyleAddProduct.inputTextFail}
 placeholder="Ajouter le prix du produit ici"
onChangeText={handleProductPriceChange}
 value={productPrice}
 />






<Text style={StyleAddProduct.Label}>Les images (5 images max) *</Text>

<Pressable  
style={images.length===0 ? StyleAddProduct.AddImageButton: images.length>0 && images.length<6? StyleAddProduct.AddImageButtonSucess:StyleAddProduct.AddImageButtonFail}

onPress={pickImage}>
 <Text style={StyleAddProduct.AddImageButtonText}>Ajouter des images</Text></Pressable>
<View style={images.length>0 ? StyleAddProduct. ImageContainerDisplayed : StyleAddProduct.ImageContainerHidden}>
        {images.length>0 && images.map((imageUri, index) => (
           <View key={index} style={{ width:Platform.OS==="web"?200:"33%",height: Platform.OS==="web"?200:120, padding:"2%", margin:Platform.OS==="web"?20:10,}} >
          <Image
           
            source={{ uri: imageUri }}
            style={{position: 'absolute',borderRadius:4, top: 0, right: 0 , width: "100%", height:"100%", }}
          />
          <TouchableOpacity 
          style={StyleAddProduct.RemoveImage}
          onPress={() => removeImage(index)}
        >
          <Text><Fontisto name="trash" size={24} color="white" /></Text>
        </TouchableOpacity></View>
        ))}
 </View>

 <CheckBox value1="Lire les conditions d'utilisation" value="J'ai lu et j'accepte les conditions d'utilisation"  checked={checked} readTermAndConditions={readTermAndConditions} onSelect={handleCheck}/>



<View  style={StyleAddProduct.Bottom}></View>


</ScrollView>





</SafeAreaView>

{
  everythingOkproductName &&
  everythingOkproductCategory &&
  everythingOkproductWeight &&
  everythingOkproductDescription &&
  everythingOkproductNote &&
  everythingOkproductComponent &&
  everythingOkproductPrice &&
  everythingOkproductQuantity &&
  everythingOkproductImages &&checked? (
    <TouchableOpacity onPress={insertProduct} style={StyleAddProduct.insertButtonSuccess}>
      <Text style={StyleAddProduct.insertButtonSuccessText}>Ajouter le produit</Text>
    </TouchableOpacity>
  ) :(

    <TouchableOpacity style={StyleAddProduct.insertButtonFail}>
      <Text style={StyleAddProduct.insertButtonSuccessText}>Remplissez d'abord tous les champs</Text>
    </TouchableOpacity>

  )
}

{/*error and loading handling*/}
{Success?(
 <View style={StyleAddProduct.SuccessView}>
 <Typewriter text={Success} 
delay={10} color={"white"}/>
     <TouchableOpacity onPress={()=>{triggerHapticFeedback();setSuccess();}}style={StyleAddProduct.ErrorViewButton}><Text  style={StyleAddProduct.ErrorViewText}>OK</Text></TouchableOpacity>

   </View>):null
    }





   {Error?(    <View style={StyleAddProduct.ErrorView}>
  <Typewriter text={Error} 
delay={10} />
 
      <TouchableOpacity onPress={()=>{triggerHapticFeedback();setError();}}style={StyleAddProduct.ErrorViewButton}><Text  style={StyleAddProduct.ErrorViewText}>OK</Text></TouchableOpacity>

    </View>):null}

    {loading?(<View style={StyleAddProduct.LoadingView}>
      <ActivityIndicator size="large" color="white" style={{ alignSelf: 'center' }} />
  
  </View>):null}
</KeyboardAvoidingView>
  );
};
const StyleAddProduct = StyleSheet.create({
  androidSafeArea: { height: "100%",paddingTop: Platform.OS === 'android' ? 25 : 0, backgroundColor: "#fcb103" },
  Container: { height: "100%", backgroundColor: "#fcb103", paddingTop: 30 },
  Bottom:{height:300, backgroundColor:"#fcb103"},
  HeadContainer:{backgroundColor:"#fcb103", height:70},
  HeadTextView: { backgroundColor: "#fcb103", justifyContent: "center", alignItems: "center" },
  HeadText: { fontSize: 20, fontWeight: "bold", textAlign: "center" },
  Label: { marginLeft: "5%", marginBottom: 15, marginTop: 15, fontSize: 16, fontWeight: "bold" },




  inputText: { backgroundColor: "white", height: 50, width: "90%", 
  marginLeft: "5%", marginRight: "5%", borderWidth: 2, borderColor: "rgba(0, 0, 0, 0.4)", borderRadius: 4, paddingLeft: 10 },
  inputTextFail: { backgroundColor: "white", height: 50, width: "90%", 
  marginLeft: "5%", marginRight: "5%", borderWidth: 2, borderColor: "red", borderRadius: 4, paddingLeft: 10 },
  inputTextSucess: { backgroundColor: "white", height: 50, width: "90%", 
  marginLeft: "5%", marginRight: "5%", borderWidth: 2, borderColor: "#5cb85c", borderRadius: 4, paddingLeft: 10 },

 inputTextBig: { backgroundColor: "white", height: 220, width: "90%", 
  marginLeft: "5%", marginRight: "5%", borderWidth: 2, borderColor: "rgba(0, 0, 0, 0.4)", borderRadius: 4, paddingLeft: 10  },
  inputTextBigFail: { backgroundColor: "white", height: 220, width: "90%", 
  marginLeft: "5%", marginRight: "5%", borderWidth: 2, borderColor: "red", borderRadius: 4, paddingLeft: 10 },
  inputTextBigSucess: { backgroundColor: "white", height: 220, width: "90%", 
  marginLeft: "5%", marginRight: "5%", borderWidth: 2, borderColor: "#5cb85c", borderRadius: 4, paddingLeft: 10 },


  PickerContainer:{backgroundColor:"white", width:"90%", marginHorizontal:"5%",  borderWidth: 2, borderColor: "rgba(0, 0, 0, 0.4)", borderRadius: 4, },

  PickerContainerSucess:{backgroundColor:"white", width:"90%", marginHorizontal:"5%",  borderWidth: 2, borderColor: "#5cb85c", borderRadius: 4, },
  PickerContainerFail:{backgroundColor:"white", width:"90%", marginHorizontal:"5%",  borderWidth: 2, borderColor: "red", borderRadius: 4, },
 AddImageButton: { backgroundColor: "white", height: 50, width: "90%", marginLeft: "5%", marginRight: "5%",
borderWidth: 2, borderColor: "rgba(0, 0, 0, 0.4)", borderRadius: 4,justifyContent:"center", alignItems:"center" },
  SelectCategoryButton:{backgroundColor: "white", height: 50, width: "100%",
    justifyContent: 'center',paddingLeft:10
    },
    AddImageButtonFail: { backgroundColor: "white", height: 50, width: "90%", marginLeft: "5%", marginRight: "5%",
    borderWidth: 2, borderColor: "red", borderRadius: 4,justifyContent:"center", alignItems:"center" },
    
    AddImageButtonSucess: { backgroundColor: "white", height: 50, width: "90%", marginLeft: "5%", marginRight: "5%",
    borderWidth: 2, borderColor: "#5cb85c", borderRadius: 4,justifyContent:"center", alignItems:"center" },
     
    AddImageButtonText:{fontWeight:"bold"},
    ImageContainerDisplayed:{  width: "90%", marginLeft: "5%", marginRight: "5%", marginVertical:10, Flex:1, flexDirection:"row", flexWrap:"wrap", backgroundColor: "white",     
     borderWidth: 2, borderColor: "#5cb85c", borderRadius: 4,padding:10},

     ImageContainerHidden:{display:"none"},
     RemoveImage:{position: 'absolute', top: 0, right: 0 
     ,backgroundColor:"transparent", height:40, width:40, justifyContent:"center", alignItems:"center", borderRadius:40},
  BackButton:{height:50, width:50, marginVertical:10, justifyContent:"center", alignItems:"center"},
  insertButtoninitial:{
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  insertButtonSuccess:{   width: '100%',
  backgroundColor: '#5cb85c',
  height: 70,
  justifyContent: 'center',
  alignItems: 'center',
  position:Platform.OS==="web"?'fixed': 'absolute',
  bottom: 0,
  left: 0,},
  insertButtonSuccessText:{
    fontWeight:"bold", fontSize:18,color:"white"
  },


  insertButtonFail:{  
    width: '100%',
    backgroundColor: 'grey',
    minHeight:70,
    height: "auto",
    paddingHorizontal:20,
    paddingVertical:15,
    justifyContent: 'center',
    alignItems: 'center',
    position:Platform.OS==="web"?'fixed': 'absolute',
    bottom: 0,
    left: 0,
    },
  insertButtonFailText:{fontWeight:"bold", fontSize:14,color:"white"
},
  keyboardAvoidingContainer: {
    flex: 1,
  },

 //error and loading view

   //error and loading view
   LoadingView:{
    position:Platform.OS!=="web"?"absolute":"fixed",top:0,left:0,height:"100%",width:"100%", 
    backgroundColor:"rgba(0, 0, 0, 0.7)", justifyContent:"center", alignItems:"center"
  },
  ErrorView:{flex:1, flexDirection:"column",
  position:Platform.OS!=="web"?"absolute":"fixed", top:0,left:0,height:"auto",width:"100%", backgroundColor:"rgba(222, 44, 44, 0.92)", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:60,
  },
  ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
  ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
   marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",borderRadius:4,},
   SuccessView:{flex:1, flexDirection:"column",
   position:Platform.OS==="web"?"fixed":"absolute", top:0,left:0,height:"auto",width:"100%", backgroundColor:"#5cb85c", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:30,
  },




lengthView:{marginRight:"5%",marginLeft:"auto"},
lengthTextSucess:{fontSize:13, fontWeight:"bold", color:"#5cb85c"},
lengthTextFail:{fontSize:13, fontWeight:"bold", color:"red"},


});

export default  AddProductSeller;