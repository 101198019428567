import React, { useEffect, useState,useRef, useCallback} from 'react';
import {Modal, StyleSheet, Button,  Text, TextInput, View, ScrollView,SafeAreaView, Pressable,Image, Vibration,Platform,Animated, Dimensions, TouchableOpacity } from 'react-native';
import * as Haptics from 'expo-haptics';
import { useMyContext } from '../MyContext';
import { useNavigation } from '@react-navigation/native';
 import AsyncStorage from '@react-native-async-storage/async-storage';
import { AntDesign } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons'; 

import { Feather } from '@expo/vector-icons'; 
import { Ionicons } from '@expo/vector-icons';
import { Product } from '../myComponent';



const NotificationSeller = ({ Customnavigation, toggle, scrollToBottom, Data }) => {




//converting dates to eu format
function convertToEUDate(usDate) {
  const parts = usDate.split("-");
  return `${parts[2]}-${parts[1]}-${parts[0]}`;
}

//filter and  get product  to navigate to
const filterProduct = (product_sku) => {
  const filteredProducts = Data.filter(product => product.product_sku === product_sku);
  return filteredProducts[0]; // Return the object with index 0
};



  const {contextTrigger, setcontextTrigger}=useMyContext();
//trigger haptic feedback
const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
  Haptics.notificationAsync(
    Haptics.NotificationFeedbackType.Success);}
}
//user and seller Objects
const{Seller, setSeller}= useMyContext({});

//Notification seller
//seller notification
const {OrdersNotificationSeller , setOrdersNotificationSeller}= useMyContext({});
const {CommentNotificationSellerProduct , setCommentNotificationSellerProduct}= useMyContext({});
const {CommentNotificationSellerShop , setCommentNotificationSellerShop}= useMyContext({});
const {ReplyNotificationSellerProduct , setReplyNotificationSellerProduct}= useMyContext({});
const {ReplyNotificationSellerSeller , setReplyNotificationSellerSeller}= useMyContext({});
const {SellerNotificationCount , setSellerNotificationCount }= useMyContext();

const {OrdersNotificationsFramesSeller , setOrdersNotificationsFramesSeller}= useMyContext({});
const {likeOrDislikeProducts, setlikeOrDislikeProducts}= useMyContext({});
const {likeOrDislikeShop , setlikeOrDislikeShop}= useMyContext({});

return(
<SafeAreaView style={styles.androidSafeArea}>
<View style={styles.Container}>
<TouchableOpacity style ={styles.CloseNotificationButton} onPress={toggle}><Text><AntDesign name="close" size={28} color="black" /></Text></TouchableOpacity>

  <ScrollView  veritcal={true} showsVerticalScrollIndicator={false}  style={styles.ScrollViewContainer}>





{/*Orders Section */}
  {OrdersNotificationSeller!==undefined && OrdersNotificationSeller && OrdersNotificationSeller.length>0?(<>

<Text style={styles.label}>Actions Requises pour des commandes</Text><View style={styles.SectionSeparator}></View></>):null}


{/*Order Actions Section */}

{Object.entries(OrdersNotificationSeller).length > 0&&OrdersNotificationSeller && OrdersNotificationSeller.map((Order, index) => {




// Filter OrdersNotificationsFrames based on matching order_id
const filteredFramesForNavigation = OrdersNotificationsFramesSeller.filter(frame =>
  frame.order_id.includes(Order.order_id)
);
const NavigationUsedFrame = filteredFramesForNavigation[0];

    return (

<View key={index}>
<Text style={styles.notficationViewDate}>{convertToEUDate(Order.datum)}</Text>
  <TouchableOpacity style={styles.notficationView}
  onPress={() => {
    toggle(); // Hide the modal first
    triggerHapticFeedback();
    setcontextTrigger(true);
    Customnavigation.navigate('sellerOrder', { Orderdata: NavigationUsedFrame});

  }}>

  <Image source={{uri:`https://3oula.tn/${Order.product_image}`}} style={styles.notificationImage}></Image>
  <View style={styles.notficationViewTextWrapper} >



    <Text style={styles.notficationViewText}>Action Requise Commande Nr- {Order.order_id}{'\n'}

    {
//if  confirmed pyed and not sent 
    Order.Status==="confirmed"&& Order.sent==null? (
    <Text>Vous avez une nouvelle{Order.sent} commande confirmée pour le produit {Order.product_name} , appuyez ici pour voir la commande</Text>)
    :
//if confirmed , payed and the seller sent the package  and the user recieved
    Order.Status==="confirmed" && Order.sent!==null && Order.delivered_user!==null&& Order.sendbackask==null?(
    <Text>Le client <Text style={styles.TextBold}>{Order.user_name} </Text>vient de confirmer la réception du produit {Order.product_name} le {convertToEUDate(Order.delivered_user_date)}. En cas de demande de retour, vous serez notifié.</Text>)

//if confirmed , payed and the seller sent the package  and the user recieved and asked for retour
    :Order.Status==="confirmed" && Order.sent!==null && Order.delivered_user!==null&& Order.sendbackask!==null&& Order.sendbacknumber==null?(
    <Text>Le client <Text style={styles.TextBold}>{Order.user_name} </Text>vient de demander un retour pour le produit {Order.product_name} le {convertToEUDate(Order.sendbackask_date)}, appuyez ici pour voir la commande et accepter le retour</Text>)
//if confirmed , payed and the seller sent the package  and the user recieved and asked for retour
    :Order.Status==="confirmed" && Order.sent!==null && Order.delivered_user!==null&& Order.sendbackask!==null&& Order.sendbacknumber!==null?(
    <Text>Le client <Text style={styles.TextBold}>{Order.user_name} </Text>vient de de vous envoyer le produit {Order.product_name} le {convertToEUDate(Order.sendbacknumber_date)}. Vous pouvez suivre le colis avec ce numéro de tracking {Order.sendbacknumber}</Text>)




    :null}
</Text>






    </View>
  </TouchableOpacity>

</View>
    );

})}




{/*like and dislike shop section*/}
{Object.entries(likeOrDislikeShop).length > 0 && likeOrDislikeShop && likeOrDislikeShop.length>0?(

<><Text style={styles.label}>Nouveaux j'aime ou j'aime pas pour votre Shop</Text><View style={styles.SectionSeparator}></View></>):null}

{/*Reply to commented Products Section */}
{Object.entries(likeOrDislikeShop).length > 0&&likeOrDislikeShop && likeOrDislikeShop.map((item, index) => {

return (
  <View key={index}>
<Text style={styles.notficationViewDate}>{convertToEUDate(item.datum)}</Text>
  <TouchableOpacity style={styles.notficationView}  onPress={() => {toggle();
  triggerHapticFeedback();
   }} >
<Image   source={{ uri: `https://3oula.tn/${item.user_image}` }} style={styles.notificationImage}></Image>
  <View style={styles.notficationViewTextWrapper} >
  <Text style={styles.notficationViewText}><Text style={styles.TextBold}>{item.user_name}</Text> {item.liked!=="1"?"n'aime pas":"aime"} votre Shop</Text>

    </View>
  </TouchableOpacity>
  </View>
);

})}


{/*like and dislike products section*/}
{Object.entries(likeOrDislikeProducts).length > 0 && likeOrDislikeProducts && likeOrDislikeProducts.length>0?(

<><Text style={styles.label}>Nouveaux j'aime ou j'aime pas pour vos produits</Text><View style={styles.SectionSeparator}></View></>):null}

{/*Reply to commented Products Section */}
{Object.entries(likeOrDislikeProducts).length > 0&&likeOrDislikeProducts && likeOrDislikeProducts.map((item, index) => {

return (
  <View key={index}>
<Text style={styles.notficationViewDate}>{item.datum}</Text>
  <TouchableOpacity style={styles.notficationView}  onPress={() => {toggle();
  triggerHapticFeedback();
  Customnavigation.navigate('sellerProductPage', {propName: filterProduct(item.product_sku)});
   }} >
<Image   source={{ uri: `https://3oula.tn/${item.user_image}` }} style={styles.notificationImage}></Image>
  <View style={styles.notficationViewTextWrapper} >
  <Text style={styles.notficationViewText}><Text style={styles.TextBold}>{item.user_name}</Text> {item.liked!=="1"?"n'aime pas":"aime"} Votre produit {item.product_name} </Text>

    </View>
  </TouchableOpacity>
  </View>
);

})}






{/*New comments on Product */}

{Object.entries(CommentNotificationSellerProduct).length > 0&& CommentNotificationSellerProduct && CommentNotificationSellerProduct.length>0?(

<><Text style={styles.label}>Nouveaux commentaires sur votre produit</Text><View style={styles.SectionSeparator}></View></>):null}

{/*Reply to commented Products Section */}
{Object.entries(CommentNotificationSellerProduct).length > 0&&CommentNotificationSellerProduct && CommentNotificationSellerProduct.map((item, index) => {

return (
  <View key={index}>
<Text style={styles.notficationViewDate}>{item.datum}</Text>
  <TouchableOpacity style={styles.notficationView}  onPress={() => {toggle();
  triggerHapticFeedback();
  Customnavigation.navigate('sellerProductPage', {propName: filterProduct(item.product_sku), CommentId:item.comment_id, replyId:item.reply_id});}} >
<Image   source={{ uri: `https://3oula.tn/${item.user_foto}` }} style={styles.notificationImage}></Image>
  <View style={styles.notficationViewTextWrapper} >
  <Text style={styles.notficationViewText}><Text style={styles.TextBold}>{item.user_name} </Text>a commenté Votre Produit {item.product.product_name} le {convertToEUDate(item.datum)}  et il a écrit: </Text>
  <Text>{item.comment_text.slice(0,30)}...</Text>
    </View>
  </TouchableOpacity>
  </View>
);

})}
{/*New comments on Shop */}

{Object.entries(CommentNotificationSellerShop).length > 0&& CommentNotificationSellerShop && CommentNotificationSellerShop.length>0?(

<><Text style={styles.label}>Nouveaux commentaires sur votre Shop</Text><View style={styles.SectionSeparator}></View></>):null}

{/*Reply to commented Products Section */}
{Object.entries(CommentNotificationSellerShop).length > 0&&CommentNotificationSellerShop&& CommentNotificationSellerShop.map((item, index) => {

return (
  <View key={index}>
  <Text style={styles.notficationViewDate}>{convertToEUDate(item.datum)}</Text>
  <TouchableOpacity style={styles.notficationView} 
  onPress={() => {
    toggle();
    triggerHapticFeedback();
    scrollToBottom();
  }} 
  
  >




<Image   source={{ uri: `https://3oula.tn/${item.user_foto}` }} style={styles.notificationImage}></Image>
  <View style={styles.notficationViewTextWrapper} >
  <Text style={styles.notficationViewText}><Text style={styles.TextBold}>{item.user_name} </Text> a commenté Votre Shop le {item.datum}  et il a écrit: </Text>
  <Text>{item.comment_text.slice(0,30)}...</Text>
    </View>



  </TouchableOpacity>
  </View>
);

})}

{/*Reply to comments on Product */}



{Object.entries(ReplyNotificationSellerProduct).length > 0&& ReplyNotificationSellerProduct && ReplyNotificationSellerProduct.length>0?(

<><Text style={styles.label}>Nouvelles Réponses au commentaires sur votre produit</Text><View style={styles.SectionSeparator}></View></>):null}

{/*Reply to commented Products Section */}
{Object.entries(ReplyNotificationSellerProduct).length > 0&&ReplyNotificationSellerProduct && ReplyNotificationSellerProduct.map((item, index) => {

return (
  <View key={index}>
  <Text style={styles.notficationViewDate}>{convertToEUDate(item.date)}</Text>
  <TouchableOpacity style={styles.notficationView} onPress={() => {toggle();
  triggerHapticFeedback();
  Customnavigation.navigate('sellerProductPage', {propName: item.product, CommentId:item.comment_id, replyId:item.reply_id});}} >

  <Image   source={{ uri: `https://3oula.tn/${item.product.product_image0}` }} style={styles.notificationImage}></Image>
  <View style={styles.notficationViewTextWrapper} >
  <Text style={styles.notficationViewText}><Text style={styles.TextBold}>{item.user_name} </Text> a répondu le {convertToEUDate(item.date)} au commentaire sur Votre Produit {item.product.product_name} et il a écrit: </Text>
  <Text style={styles.notficationViewTextGrey}>{item.comment_text.slice(0,30)}...</Text>
    </View>
  </TouchableOpacity>
  </View>
);

})}




{/*Reply to comments on Shop */}

{Object.entries(ReplyNotificationSellerSeller).length > 0&& ReplyNotificationSellerSeller && ReplyNotificationSellerSeller.length>0?(

<Text style={styles.label}>Nouvelles Réponses au commentaires sur votre Shop</Text>):null}


{/*Reply to commented Shops Section */}
{Object.entries(ReplyNotificationSellerSeller).length > 0&&ReplyNotificationSellerSeller && ReplyNotificationSellerSeller.map((item, index) => {

return (
  <View key={index}>
  <Text style={styles.notficationViewDate}>{convertToEUDate(item.date)}</Text>
  <TouchableOpacity style={styles.notficationView} key={index} 
  onPress={() => {
    toggle();
    triggerHapticFeedback();
    scrollToBottom ();
  }} 
  
  >


  <Image source={{uri:`https://3oula.tn/${item.seller.image}`}} style={styles.notificationImage}></Image>
  <View style={styles.notficationViewTextWrapper} >
  <Text style={styles.notficationViewText}><Text style={styles.TextBold}>{item.user_name} </Text> a répondu le {convertToEUDate(item.date)} au commentaire sur Votre boutique et il a écrit:</Text>
  <Text>{item.comment_text.slice(0,30)}...</Text>

    </View>
  </TouchableOpacity>
  </View>
);

})}







  </ScrollView>
  </View>
</SafeAreaView>
    
    
    );
}

const styles = StyleSheet.create({
  androidSafeArea: {
        

    backgroundColor:"transparent",

    flex: 1,

    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,



},


Container:{
  flex:1,flexDirection:"column",
  width:"100%",
  height:Platform.OS==="web"?"100vw":"100%",

marginTop:Platform.OS==="web"?30:30,
backgroundColor:"#fcb103",
borderColor:"rgba(0, 0, 0, 0.4)",
borderTopWidth:1,
borderLeftWidth:1,
borderRightWidth:1,
borderBottomWidth:0,
borderTopLeftRadius:10,
borderTopRightRadius:10,
},



ScrollViewContainer:{
flex:1,
flexDirection:"column",
width:"98%",
marginHorizontal:"1%",
height:"100%",
backgroundColor:"#fffbe6",
borderWidth:1,
borderRadius:4,
borderColor: "rgba(0, 0, 0, 0.5)"
},




CloseNotificationButton:{  
marginRight:"2%", marginLeft:"auto",
backgroundColor:"#fcb103",
width:60,
height:60,
justifyContent:"center",
alignItems: 'center',
},
//section separator
SectionSeparator:{
width:Platform.OS==="web"?"90vw":"90%", backgroundColor:"rgba(0, 0, 0, 0.1)", height:2,marginBottom:10,marginHorizontal:"5%"

},
//labesls
label:{fontWeight:"bold",fontSize:13,alignSelf:"center",
fontFamily:Platform.OS!=="android"?"Chalkduster":"Roboto",paddingVertical:10,paddingHorizontal:10,color:"rgba(0, 0, 0, 0.7)",},

//notification View
notficationView: {
maxHeight:100,
minHeight: 100,
width: "98%",
marginHorizontal:"1%",
marginVertical: 2,
borderRadius:4,
flex: 1,
flexDirection: "row",
backgroundColor: "white",
overflow: "hidden",


},

notificationImage:{height:100,width:100,},

notficationViewTextWrapper:{padding:10,flex:1,},
TextBold:{color:"black", fontWeight:"bold"},
notficationViewText:{fontSize:13,color:"black", 

},
notficationViewDate:{fontSize:12,   fontFamily:Platform.OS!=="android"?"Chalkduster":"Roboto",fontWeight:"bold", 
color:"black",alignSelf:"center"},
      
      });

export default NotificationSeller;