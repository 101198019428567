import React ,{ useEffect, useState,useRef, useContext} from 'react';
import {Modal,ActivityIndicator, StyleSheet, View, Text, Pressable, TextInput, ScrollView, SafeAreaView,Animated ,Platform,TouchableOpacity,Image} from 'react-native';
import AutocompleteCity from './AutocompleteCity';
import { useNavigation } from '@react-navigation/native';
import { useMyContext } from './MyContext';
import { Feather } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import NetInfo from '@react-native-community/netinfo';
import MenuLoginUser from './menuLoginForUser';
import Checkbox from 'expo-checkbox';
import * as Haptics from 'expo-haptics';
import axios from 'axios';
import Typewriter from "./typeWriter";


const PayementGate = ({ navigation, route}) => {

//contain product details
const { PayementData } = route.params;
//hold the mandat number
const [TextMandatNumber, setTextMandatNumber]=useState('');
const [EveryThingOKTextMandatNumber, setEveryThingOKTextMandatNumber]=useState(false);
//user object 
const{User, setUser}= useMyContext({});
const{UserIsLoggedIn, setUserIsLoggedIn}=useMyContext();
  //var to trigger a fetch for notification when back to homepage
  const {contextTrigger, setcontextTrigger}=useMyContext();



//calculate mandat costs
  const calculateMandatCost=(price)=>{
    let cost =0;
    if(price<=250){
  
  cost=5;
  
    }
    else if(price>250&&price<=1000){
      cost=10;
    }
  
    else if(price>1000&&price<=2000){
      cost=15;
    }
    else if(price>2000&&price<=3000){
      cost=10;
    }
  
  
      return cost;
    }


///check only numbers
    const onlyNumber = (str) => {
      return /^[0-9]+$/.test(str);
    };

//handle mandat input text change
const handleTextMandatChange=(text)=>{
  setTextMandatNumber(text);
if(onlyNumber(text)){
setEveryThingOKTextMandatNumber(true);
setError('');
}
else {
  if(text.length>0){
 
setError("le numéro secret ne doit contenir que des chiffres !");
}
setEveryThingOKTextMandatNumber(false);

}


}




//handle insert Mandat
const insertMandat=()=>{
  const user_name=PayementData.user_name;
  const user_id=PayementData.user_id;
const order_id=PayementData.orderId;
const total=(PayementData.TotalToPay).toFixed(2);


const number=TextMandatNumber;
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded', // Set the header to application/x-www-form-urlencoded

  };


  // Construct the form data
  const formData = new FormData();
  formData.append('user_id', user_id);
  formData.append('user_name', user_name);
  formData.append('order_id', order_id);
  formData.append('total_to_pay', total);
  formData.append('number', number);

  axios.post('https://3oula.tn/appgate/userOrderActions.php?action=InsertM', formData, {
    headers: headers, 
  })
  

  .then((response) => {
    if (response.data.success) {
    setSuccess(response.data.message);


    } else {
      setError('An error occurred:', response.data.error);
      // Handling the error case
    }
  })
  .catch((error) => {
    setError('An error occurred:', error);
    // Handling the fetch error
  });

}



//navigate
const navigateBack=()=>{

  navigation.navigate('OrdersUserAll', {propName:User});

}

      //const while loading
const [loading, setLoading] = useState(false);
//Error holding and displaying vars
const [Error, setError]=useState('');
//hold success
const [Success, setSuccess]=useState('');
    //trigger haptic feedback
//trigger haptic feedback
const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
  Haptics.notificationAsync(
    Haptics.NotificationFeedbackType.Success);}
}


//text for payement methods
const [textD17, settextD17]=useState(
  `Vous avez choisi le paiement par l'application D17 ya ${User[0].name} Voici notre code 3D. Veuillez le scanner et effectuer le virement du montant ${(PayementData.TotalToPay).toFixed(2)} TND.  Après réception du paiement, vous recevrez une notification et votre commande sera expédiée immédiatement.`
  );

  const [textVirement, settextVirement]=useState(`Vous avez choisi le paiement par virement bancaire  ya ${User[0].name}. Voici le numéro de notre compte à La Poste. Veuillez virer le montant ${(PayementData.TotalToPay).toFixed(2)} TND sur ce compte \n\nCompte courant Chez la Poste 17503000000323940235 \nNom de la companie: GUASMIS NETZ SARL.\n\nDès réception du paiement, vous recevrez une notification et votre commande sera expédiée immédiatement.`);

  const [textMandatMin, settextMandatMin]=useState(`Vous avez choisi le paiement par mandat minute ya ${User[0].name}. Voici les coordonnées de notre agent \n\n- Nom de l'agent: Mohamed Amine Gasmi  \n- Téléphone de l'agent:22141799 \n\nEnvoyez le mandat avec le montant ${(PayementData.TotalToPay).toFixed(2)} TND et payer les Frais du mandat ${calculateMandatCost(PayementData.TotalToPay).toFixed(2)} TND et entrez le code ci-dessous. Notre agent retirera l'argent, Après la réception du paiement, vous recevrez une notification et votre commande sera expédiée immédiatement.
`);
  return (

    <>
  <SafeAreaView style={styles.androidSafeArea}>
      <View style={styles.headContainer}>
      <View  style={styles.logoAndButtonContainer}>

<Pressable style={styles.BackButton} onPress={() =>{triggerHapticFeedback();navigateBack();} }>
<Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
</Pressable>

</View>
</View>


<ScrollView  veritcal={true} showsVerticalScrollIndicator={false} style={styles.root} scrollEventThrottle={20} 
 keyboardShouldPersistTaps='handled'>
{/*payement method bank trnasfert */}

{PayementData&&PayementData.PayementMethod==="virement bancaire"?

(
<View style={styles.payementInstructionsContainer}>
  
 <View style={styles.FloomyContainer}>
  <Typewriter text={textVirement} delay={10} color={"black"}/>
 </View>
  </View>




):null


}
{/*payement method d17 */}

{PayementData&&PayementData.PayementMethod==="d17"?

(
<View style={styles.payementInstructionsContainer}>
<View style={styles.FloomyContainer}>
  <Typewriter text={textD17} delay={10} color={"black"}/>
 </View>


<View style={styles.shadowContainer}>
 <Image source={{uri:'https://3oula.tn/codelogo.jpg'}} style={styles.D17Logo}></Image></View>
 <Text></Text>
</View>




):null


}

{/*payement method mandat minute*/}


{PayementData&&PayementData.PayementMethod==="mandat minute"?

(
<View style={styles.payementInstructionsContainer}>
<View style={styles.FloomyContainer}>
  <Typewriter text={textMandatMin} delay={10} color={"black"}/>
 </View>
  


 <TextInput
   

   style={[
    {
      borderColor:textMandatMin.length<1?"rgba(0, 0, 0, 0.4)":(EveryThingOKTextMandatNumber? "#5cb85c" : "red"),
    },
    styles.inputText,
  ]}

              placeholder="le numéro secret du mandat"
              onChangeText={handleTextMandatChange}
              value={TextMandatNumber}
   />
  <TouchableOpacity  onPress={insertMandat} style={[ {backgroundColor:EveryThingOKTextMandatNumber? "#5cb85c" : "grey"},styles.SendMandatButton]}>
    <Text style={{color:"white",fontWeight:"bold"}}>Ajouter le numéro secret </Text></TouchableOpacity >
  </View>




):null


}

</ScrollView>

















    </SafeAreaView>










      {/*error and loading handling*/}
      {Error?(    <View style={styles.ErrorView}>
  <Typewriter text={Error} 
delay={10} />
      <TouchableOpacity onPress={()=>{triggerHapticFeedback();setError();}}style={styles.ErrorViewButton}><Text  style={styles.ErrorViewText}>OK</Text></TouchableOpacity>

    </View>):null}


    {Success?(
 <View style={styles.SuccessView}>
 <Typewriter text={Success} 
delay={10} color={"white"}/>
     <TouchableOpacity onPress={()=>{triggerHapticFeedback();setSuccess();}}style={styles.ErrorViewButton}><Text  style={styles.ErrorViewText}>OK</Text></TouchableOpacity>

   </View>):null
    }

    {loading?(<View style={styles.LoadingView}>
    <ActivityIndicator size="large" color="white" style={{ alignSelf: 'center' }} />

</View>):null}
    </>
    
  );
};


const styles=StyleSheet.create({
  androidSafeArea: {
paddingTop: Platform.OS === 'android' ? 25 : 0,
    backgroundColor:"#fcb103",

    flex: 1,

    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

},

root:{flex:1,zIndex:-2,backgroundColor:"#fcb103",
},

headContainer:{height:70,
},
logoAndButtonContainer:{ flex:1, flexDirection:"row",justifyContent:"space-between",backgroundColor:"#fcb103",height:80,marginBottom:20
},
 BackButton:{height:50, width:50, backgroundColor:"#fcb103", alignItems:"center", justifyContent:"center", margin:15, borderRadius:50, 
},


//payement instructions
payementInstructionsContainer:{height:"auto",minHeight:"100%", width:Platform.OS==="wed"?"98vw":"98%",
 marginHorizontal:Platform.OS==="wed"?"1vw":"1%"
,backgroundColor:"white",borderWidth:1, borderRadius:4,borderColor:"rgba(0, 0, 0, 0.4)",padding:20,paddingBottom:400,
},
FloomyContainer:{backgroundColor:"white"},

D17Logo:{
  width: 300,
  height: 300,
  alignSelf: 'center',


},


shadowContainer: {
  marginTop:30,
  padding:10,
  width: 310,
  height: 310,
  alignSelf:"center",
  shadowColor: 'rgba(0, 0, 0, 0.5)',
  shadowOffset: { width: 0, height: 2 },
  shadowOpacity: 0.8,
  shadowRadius: 4,
  elevation: 4, // For Android
},



inputText: { backgroundColor: "white", height: 50, width: "90%", marginLeft: "5%", marginRight: "5%", borderWidth: 2, borderRadius: 4, paddingLeft: 10,marginTop:30, },


SendMandatButton:{height: 50, width: "90%", marginLeft: "5%", marginRight: "5%",
justifyContent:"center", alignItems:"center", marginTop:20,borderRadius:4
},





















 //error and loading view
 LoadingView:{
  position:Platform.OS==="web"?"fixed":"absolute", top:0,left:0,height:Platform.OS==="web"?"100vh":"100%",width:"100%", zIndex:99,
  backgroundColor:"rgba(0, 0, 0, 0.7)", justifyContent:"center", alignItems:"center"
},
ErrorView:{flex:1, flexDirection:"column",
position:Platform.OS==="web"?"fixed":"absolute",  top:0,left:0,height:"auto",width:"100%", backgroundColor:"#DE2C2C", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:30,
},
SuccessView:{flex:1, flexDirection:"column",
position:Platform.OS==="web"?"fixed":"absolute",  top:0,left:0,height:"auto",width:"100%", backgroundColor:"#5cb85c", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:30,
},
ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
 marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",borderRadius:4,},


})
export default PayementGate;

 

