import React, { useState, useEffect,useRef} from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity,Pressable,Image, Button, ScrollView, SafeAreaView,Platform,Dimensions} from 'react-native';
import { AntDesign } from '@expo/vector-icons'; 
import Ionicons from '@expo/vector-icons/Ionicons';
import { Feather } from '@expo/vector-icons'; 
import { Keyboard } from 'react-native';
import { KeyboardAvoidingView } from 'react-native';
import * as Haptics from 'expo-haptics';
import Typewriter from "./typeWriter";
const Autocomplete = ({navigation}) => {




//Error holding and displaying vars
const [Error, setError]=useState('');

//find the screen diemnsion to pass it as pro to the search to construct the falstlist number of item in line


const[ScreenWidth,setScreenWidth]=useState(0);
const[ScreenHeight,setScreenHeight]=useState(0);
const [FlatListNumberOfProducts,setFlatListNumberOfProducts]=useState(3);
//screen diemsnion
useEffect(() => {
  const updateScreenDimensions = () => {
    if (Platform.OS === 'web') {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
      const numColumns = Math.floor(window.innerWidth / 170);
      setFlatListNumberOfProducts(numColumns);
   
    } else {
      const { width, height } = Dimensions.get('window');
      setScreenWidth(width);
      setScreenHeight(height);
      const numColumns = Math.floor(width / 170);
      setFlatListNumberOfProducts(numColumns);
    }
  };

  updateScreenDimensions();
}, []);





  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [userInput, setUserInput] = useState('');

  const [suggestion, setSuggestion] = useState([]);

  const [rerenderTrigger, setrerenderTrigger] = useState(true);


//trigger haptic feedback
//trigger haptic feedback
const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
  Haptics.notificationAsync(
    Haptics.NotificationFeedbackType.Success);}
}
 
    // fetch data when component is mounted

  useEffect(() => {
    fetch('https://3oula.tn/endkunde.php?action=fetchAll', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setSuggestion([...new Set(json.Products_data.map((product) => product.product_name))]);
      
        // Add the product_category to the suggestion array
        setSuggestion(prevSuggestions => [
          ...prevSuggestions,
          ...new Set(json.Products_data.map((product) => product.product_category))
        ]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  



const[ErrorsIndex, setErrorsIndex]=useState(0);


// Make sure you also reset the error when ErrorsIndex changes to a different value.



//update errors
useEffect(() => {

  setError('');

  const timeoutId = setTimeout(() => {
 
    setError(Errors[ErrorsIndex]);
  }, 300); // Adjust the delay as needed

  return () => {
    clearTimeout(timeoutId); // Clear the timeout when the effect is cleaned up
  };
}, [ErrorsIndex]);




 //check only letters and numbers in the input
 const onlyLettersAndNumbers = (str) => {
  return /^[A-Za-z0-9'’èéäêöàùâêîôûçëïü\s.,?!]*$/.test(str);
};
  


const[Errors,setErrors]=useState(["Le texte de la recherche ne peut contenir que des lettres, des espaces et des chiffres \u{1F621}",
"bel3arbi yezzina ken 7ourouf w ar9am walla sakkerli el barnamej si 3ala \u{1F624}",
"tawa 3lech hakka hayya wallah bech nemchi norgod w nsakker el 7anout \u{1F629}","pleaaaaaaaaase not \u{1F621}",
"barra ya weldi al3ab goddem darkom w a5tana sakkerna \u{1F630}"
]);

//handle input change search field 
  const handleInputChange = (text) => {

   if(text==="hahiRakBehi")
  { navigation.navigate('loginAdmin');}
if(onlyLettersAndNumbers(text)){

  const filtered = suggestion.filter((suggestions) =>
  suggestions.toUpperCase().startsWith(text.toUpperCase())
).slice(0, 5); // only keep the first 5 suggestions

setFilteredSuggestions(filtered);

      setUserInput(text);
      setError('');
    }
    else {

setUserInput(text);
if(Errors.length-1>ErrorsIndex){

setErrorsIndex(ErrorsIndex+1);

}
else {
  setErrorsIndex(0);
}
 }
 };






    
   //to refresh
  const [refreshKey, setRefreshKey] = useState(0);

  const handleNavigate = () => {
    // Increment the refreshKey to force a re-render of the component
    setRefreshKey((prevKey) => prevKey + 1);

  
navigation.navigate('searchPage', {
  searchInput: userInput,
  refreshKey,
  FlatListNumberOfProducts,rerenderTrigger // The value of FlatListNumberOfProduct
});
setrerenderTrigger(!rerenderTrigger);



  };

  
    //set the sugesstions values acooridng to the fetched data
    const selectSuggestion = (text) => {
      triggerHapticFeedback();
      setUserInput(text);
      setFilteredSuggestions([]);


    };


  return (







  
    
    
    
 
<KeyboardAvoidingView>
    <View style={styles.containerSeachView}>


            {/*
  the head container
 */}
 
    <View style={styles.headContainer}>

  
{/*
the container for input and button to search
*/}
 <View style={styles.headContainerInputAndSearch}>

 <TextInput 
 
        style={styles.textSearchInput}
 
        onChangeText={handleInputChange}
        value={userInput}
        placeholder="  Chercher sur 3oula.tn"
      />

{/*the button when pressed launch the search */}



{Error? (  <Pressable
        style={styles.searchButton} 
            title="seach"
    ><Text><AntDesign name="search1" size={30} color="white" /></Text></Pressable>):(  <Pressable
      style={styles.searchButton} onPress={() => {triggerHapticFeedback();navigation.navigate('searchPage', {searchInput: userInput,  
        FlatListNumberOfProducts,rerenderTrigger});setrerenderTrigger(!rerenderTrigger);}} 
          title="seach"
  ><Text><AntDesign name="search1" size={30} color="white" /></Text></Pressable>)}





</View>

    </View>




    { userInput.length>0&&(
        <View style={styles.sugestionsBox}>


 
     {/*error and loding handling*/}
 
     {Error?(    <View style={styles.ErrorView}>
  <Typewriter text={Error}
delay={10} />
      <TouchableOpacity onPress={()=>{triggerHapticFeedback();setError('');setUserInput('');}}style={styles.ErrorViewButton}><Text  style={styles.ErrorViewText}>OK</Text></TouchableOpacity>

    </View>):null}



    {!Error && filteredSuggestions.length > 0 &&
    filteredSuggestions.map((suggestion, index) => {
      const suggestionParts = [];
      let currentIndex = 0;
      const suggestionUpperCase = suggestion.toUpperCase();
      const searchTextUpperCase = userInput.toUpperCase();

      while (currentIndex < suggestionUpperCase.length) {
        const matchIndex = suggestionUpperCase.indexOf(searchTextUpperCase, currentIndex);

        if (matchIndex === -1) {
          suggestionParts.push(
            <Text key={currentIndex}>{suggestion.substring(currentIndex)}</Text>
          );
          break;
        }

        if (matchIndex > currentIndex) {
          suggestionParts.push(
            <Text key={currentIndex}>{suggestion.substring(currentIndex, matchIndex)}</Text>
          );
        }

        suggestionParts.push(
          <Text key={matchIndex} style={{ fontWeight: 'bold' }}>
            {suggestion.substring(matchIndex, matchIndex + searchTextUpperCase.length)}
          </Text>
        );

        currentIndex = matchIndex + searchTextUpperCase.length;
      }

      return (
        <TouchableOpacity
          style={styles.sugesstionButtons}
          key={index}
          onPress={() => {
            triggerHapticFeedback();
            selectSuggestion(suggestion);
            navigation.navigate('searchPage', {
              searchInput: suggestion,
              FlatListNumberOfProducts,
              rerenderTrigger,
            });
            setrerenderTrigger(!rerenderTrigger);
          }}
          activeOpacity={0.8}
        >
          <View style={{flex:1, flexDirection:"row"}}>{suggestionParts}</View>
        </TouchableOpacity>
      );
    })}



        </View>
      )}





   


    </View>
   



    </KeyboardAvoidingView>
    
  


  );
};

const styles = StyleSheet.create({
  textSearchInput:{
color:"black",
    backgroundColor:"white",
    height:39,
    width:"80%",
    paddingVertical:5,
    paddingHorizontal:15,
  

  },
 

 



headContainer:{

  backgroundColor:"#fcb103", width:"100%", height:60,paddingTop:20
},





  headContainerInputAndSearch:{
width:"80%",
maxWidth:600,

marginLeft:"auto",
marginRight:"auto",
flex:1,
flexDirection: 'row',


maxHeight:40,
backgroundColor:"#fcb103", 
borderWidth: 1,
borderColor: "black",
borderRadius: 4,
overflow:"hidden"
  
  },

 searchButton:{
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
backgroundColor:"blue",

color:"white",
fontSize:"bold",
height:40,
Width:"auto",


  },
 
  sugesstionButtons:{
    padding:15,
    marginTop:1,
    width:"80%",
    maxWidth:600,
    
    marginLeft:"auto",
    marginRight:"auto",
 
    backgroundColor:"white",

    height:50,

    borderWidth: 1,
    borderColor: "black",
    borderRadius: 4,

  },
  sugestionsBox:{
backgroundColor:"transparent",
width:"100%",


position:"absolute",
top:60,
zIndex:5,




  },

  menuLogin:{
    
    flexDirection:"column",
    height:"500%",
    width:"100%",
    backgroundColor:"white",
    position:"absolute",
    top:0,
    zIndex:6,
    display:"none",
  
  }
  ,
  containerSeachView:{



  },

  ErrorView:{flex:1, flexDirection:"column", width:"80%",
  maxWidth:600,
  marginTop:2,
  marginLeft:"auto",
  marginRight:"auto",
height:"auto", backgroundColor:"rgba(222, 44, 44, 0.92)", alignItems:"center",paddingBottom:5,  borderWidth: 1,
borderColor: "black",
borderRadius: 4,

},
ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
 marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",borderRadius:4,},

});

export default Autocomplete;
