import React from 'react';
import {StyleSheet, Pressable,Image,View} from 'react-native';

import { createStackNavigator } from '@react-navigation/stack';


import HomeScreen from './HomeScreen';
import ProductPageScreen from './productPage';
import SellerPageScreen from './sellerPage';
import SearchPageScreen from './searchPage';
import OrdersUserAll from './ordersUserAll';
import OrderUser  from './orderUser';
import LoginUser from './loginUser';
import UserOrSeller from './UserOrSeller';
import UserOrSellerLogin from './userOrSellerLogin';



import Checkout from './checkout';

import PayementGate from './payementGate.js';


//admin part

import LoginAdmin from './Admin/AdminLogin';
import AdminHomePage from './Admin/adminHomePage';

import AdminProducts from './Admin/adminProducts';

import AdminSellers from './Admin/adminSellers';

import AdminOrders from './Admin/adminOrders';
import AdminUsers from './Admin/adminUsers';




//selelr part 
import LoginSeller from './seller/seller_login';

import SellerRegister from './seller/seller_register';
import ConfirmSellerMail from './seller/confirmSellerMail';
import SellerHomePage from './seller/seller_home_page';
import SellerProductPage from './seller/seller_product_page';
import AddProductSeller from './seller/addProductSeller';
import MenuLoginForSeller from './seller/menuLoginForSeller';
import SellerOrdersAll from './seller/seller_orders';
import SellerOrder from './seller/seller_order';
import Cart from './cart';

import RegisterUser from './registerUser';
import ConfirmUserMail from './confirmUserMail';
import { Ionicons } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons'; 


const Stack = createStackNavigator();

const StackNavigator = () => {
  return (
<Stack.Navigator
  screenOptions={{
    headerStyle: {
      backgroundColor: '#fcb103',
    },
   
    headerTintColor: 'black',
    headerTitleStyle: {
      fontWeight: 'bold',
    },
    headerTitle: "", // set headerTitle to null to hide it

  }}
>

<Stack.Screen
  name="home"
  component={HomeScreen}
  options={({ navigation }) => ({ headerShown: false})}
/>

<Stack.Screen name="productPage" component={ProductPageScreen}
 options={({ navigation }) => ({ headerShown: false})}
/>


<Stack.Screen name="sellerPage" component={SellerPageScreen}
 options={({ navigation }) => ({ headerShown: false})}
          />
<Stack.Screen name="searchPage" component={SearchPageScreen}
  options={({ navigation }) => ({ headerShown: false})}
/>
<Stack.Screen name="loginUser" component={LoginUser}
 options={({ navigation }) => ({ headerShown: false})}
/>
<Stack.Screen name="userOrSeller" component={UserOrSeller}
 options={({ navigation }) => ({ headerShown: false})}
/>
<Stack.Screen name="userOrSellerLogin" component={UserOrSellerLogin}
 options={({ navigation }) => ({ headerShown: false})}
/>




<Stack.Screen name="registerUser" component={RegisterUser}
 options={({ navigation }) => ({ headerShown: false})}
/>
<Stack.Screen name="confirmUserMail" component={ConfirmUserMail}
 options={({ navigation }) => ({ headerShown: false})}
/>


<Stack.Screen name="OrdersUserAll" component={OrdersUserAll}
 options={({ navigation }) => ({ headerShown: false})}
/>


<Stack.Screen name="OrderUser" component={OrderUser}
 options={({ navigation }) => ({ headerShown: false})}
/>

<Stack.Screen name="Checkout" component={Checkout}
 options={({ navigation }) => ({ headerShown: false})}
/>

<Stack.Screen name="PayementGate" component={PayementGate}
 options={({ navigation }) => ({ headerShown: false})}
/>


<Stack.Screen name="loginSeller" component={LoginSeller}
 options={({ navigation }) => ({ headerShown: false})}
/>

<Stack.Screen name="sellerRegister" component={SellerRegister}
 options={({ navigation }) => ({ headerShown: false})}
/>



<Stack.Screen name="confirmSellerMail" component={ConfirmSellerMail}
 options={({ navigation }) => ({ headerShown: false})}
/>

<Stack.Screen name="sellerHomePage" component={SellerHomePage}
 options={({ navigation }) => ({ headerShown: false})}
/>
<Stack.Screen name="sellerProductPage" component={SellerProductPage}
 options={({ navigation }) => ({ headerShown: false})}
/>

<Stack.Screen name="addProductSeller" component={AddProductSeller}
 options={({ navigation }) => ({ headerShown: false})}
/>
<Stack.Screen name="menuLoginForSeller" component={MenuLoginForSeller}
 options={({ navigation }) => ({ headerShown: false})}
/>


<Stack.Screen name="sellerOrdersAll" component={SellerOrdersAll}
 options={({ navigation }) => ({ headerShown: false})}
/>




<Stack.Screen name="sellerOrder" component={SellerOrder}
 options={({ navigation }) => ({ headerShown: false})}
/>


{/*Admin Part */}
<Stack.Screen name="loginAdmin" component={LoginAdmin}
 options={({ navigation }) => ({ headerShown: false})}
/>
<Stack.Screen name="adminHomePage" component={AdminHomePage}
 options={({ navigation }) => ({ headerShown: false})}
/>
    

<Stack.Screen name="adminSellers" component={AdminSellers}
 options={({ navigation }) => ({ headerShown: false})}
/>

<Stack.Screen name="adminUsers" component={AdminUsers}
 options={({ navigation }) => ({ headerShown: false})}
/>

<Stack.Screen name="adminProducts" component={AdminProducts}
 options={({ navigation }) => ({ headerShown: false})}
/>



<Stack.Screen name="adminOrders" component={AdminOrders}
 options={({ navigation }) => ({ headerShown: false})}
/>


    </Stack.Navigator>
  );
};
const styleStack = StyleSheet.create({


loginButton :{
 
  justifyContent: 'center',
  alignItems: 'center',

backgroundColor:"#fcb103",
maxWidth:50,
height:50,


marginRight:20,
marginBottom:10,
marginTop:10,







},


loginButtonTransparent:{
  justifyContent: 'center',
  alignItems: 'center',

backgroundColor:"transparent",
maxWidth:50,
height:50,


marginRight:20,
marginBottom:10,
marginTop:10,



},

logoImage:{
  height:60, width:60,margin:5,
  
  },
});


export default StackNavigator;
