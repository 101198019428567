import React, { useState } from 'react';
import { TouchableOpacity, Image, StyleSheet } from 'react-native';

const StarRatinginput = ({ onRatingChange }) => {
  const [defaultRating, setDefaultRating] = useState(1);
  const maxRating = 5;

  const starFilled = 'https://raw.githubusercontent.com/AboutReact/sampleresource/master/star_filled.png';
  const starWithBorder = 'https://raw.githubusercontent.com/AboutReact/sampleresource/master/star_corner.png';

  const updateRating = (key) => {
    setDefaultRating(key);
    onRatingChange(key); // Call the onRatingChange prop function with the new rating value
  };

  const renderRatingBar = () => {
    const ratingBar = [];
    for (let i = 1; i <= maxRating; i++) {
      ratingBar.push(
        <TouchableOpacity
          activeOpacity={0.7}
          key={i}
          onPress={() => updateRating(i)}>
          <Image
            style={styles.StarImage}
            source={i <= defaultRating ? { uri: starFilled } : { uri: starWithBorder }}
          />
        </TouchableOpacity>
      );
    }
    return ratingBar;
  };

  return <>{renderRatingBar()}</>;
};

const styles = StyleSheet.create({
  StarImage: {
    width: 20,
    height: 20,
    resizeMode: 'cover',
  },
});

export default StarRatinginput;
