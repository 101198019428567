import React, {useRef} from 'react';


import { ActivityIndicator,View, Text, Pressable,StyleSheet, ScrollView,SafeAreaView,Platform, TouchableOpacity} from "react-native";
import { Ionicons } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons'; 
import * as Haptics from 'expo-haptics';
import { useNavigation } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons';

export const Product = props => {
    return (
      <><Text>{props.name}</Text><Text>{props.description}</Text><Text>{props.price}</Text></>
    

    );
  };



  export const Category = props => {
    return (

        <Text style={stylesComponent.categoryText}>{props.name}</Text>

    );
  };

  
//fucnction to hide and show ScrollviewCart



 
export const CartButton = (props) => {
  
   
  return (
    <>
      <Pressable onPress={toggleScrollViewCart} style={stylesComponent.cartButtonStyle}>
        <Text style={stylesComponent.ProductCountText}>
          <FontAwesome5 name="shopping-bag" size={24} color="white" />  {props.text}
        </Text>
      </Pressable>
    </>
  );
};

export const LoadingView = (props) => {
  const navigation = useNavigation();
  //trigger haptic feedback
  const triggerHapticFeedback = () => {
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
  };

  return (
    <View style={stylesComponent.LoadingView}>

      <View style={stylesComponent.LoadingViewTextAndLoadingView}>
      <Text style={stylesComponent.LoadingViewText}>Loading ...</Text>
      <ActivityIndicator size="large" color="black" style={{ alignSelf: 'center'}} />
      </View>
    </View>
  );
};








export const SocialMediaContainer = props => {
  return (

 <View style={stylesComponent.SocialMediaContainer}>

  <TouchableOpacity style={stylesComponent.SocialMediaContainerChildButton}> 
  <Text style={stylesComponent.SocialMediaContainerChildText}> <AntDesign name="facebook-square" size={40} color="white" /></Text>
  </TouchableOpacity>

  <TouchableOpacity style={stylesComponent.SocialMediaContainerChildButton}> 
  <Text style={stylesComponent.SocialMediaContainerChildText}>   <AntDesign name="instagram" size={40} color="white" /></Text>
  </TouchableOpacity>

  <TouchableOpacity style={stylesComponent.SocialMediaContainerChildButton}> 
  <Text style={stylesComponent.SocialMediaContainerChildText}>   <AntDesign name="youtube" size={40} color="white" /></Text>
  </TouchableOpacity>

  <TouchableOpacity style={stylesComponent.SocialMediaContainerChildButton}> 
  <Text style={stylesComponent.SocialMediaContainerChildText}>  <AntDesign name="twitter" size={40} color="white" /></Text>
  </TouchableOpacity>



 </View>

  );
};



 export const stylesComponent = StyleSheet.create({



//social media container

SocialMediaContainer:{
  width:"100%", height:70,flex:1, flexDirection:"row", backgroundColor:"#fcb103",marginBottom:60,
},
SocialMediaContainerChildButton:{
  width:"25%", height:70, justifyContent:"center", alignItems:"center"
},
SocialMediaContainerChildText:{
  
},

        //loading and errors
        LoadingView:{
          position:"absolute", top:0,left:0,height:Platform.OS==="web"?"110vh":"110%",width:Platform.OS==="web"?"100vw":"100%", 
          backgroundColor:"#fcb103", justifyContent:"center", alignItems:"center"
        },


        LoadingViewHead:{backgroundColor:"#fcb103",width:Platform.OS==="web"?"100vw":"100%",height:"auto",
        position:"absolute", top:0,paddingVertical:20,paddingHorizontal:20},


        LoadingViewTextAndLoadingView:{backgroundColor:"#fcb103",
        width:Platform.OS==="web"?"100vw":"100%",height:"auto",justifyContent:"center",alignItems:"center",marginTop:Platform.OS==="web"?-100:0,



        },
        LoadingViewText:{fontWeight:"bold", fontSize:18, marginBottom:20},
  cartButtonStyle:{
    flex:1,
    flexDirection:"row",
 
    backgroundColor:"#fcb103",
    width:"100%",
      height:60,
 
      alignItems: 'center',
      position:"absolute",
bottom:0,

   left:0,
      zIndex:5,
    },

    categoryText:{
      fontSize:16, fontWeight:"bold"


    },
    ProductCountText:{
      textAlign: "center",
      flex: 1,
      color:"white",
      fontWeight:"bold",

      fontSize:20,
 

    },

    
  });