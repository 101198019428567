import React ,{ useEffect, useState,useRef, useContext} from 'react';
import { ActivityIndicator,StyleSheet, View, Text, Pressable, TextInput, ScrollView, SafeAreaView,Platform,Animated,TouchableOpacity} from 'react-native';

import { KeyboardAvoidingView } from 'react-native';
import { useMyContext } from '../MyContext';
import { useNavigation } from '@react-navigation/native';
import * as Haptics from 'expo-haptics';
import { Ionicons } from '@expo/vector-icons';
import Typewriter from "../typeWriter";
import axios from 'axios';
const ConfirmSellerMail = ({ navigation, route }) => {
    const {SellerMail} = route.params;
  //handle loading
  const [loading, setLoading] = useState(false);
   //delay type writer
   const [showTypewriter, setShowTypewriter] = useState(true);

  //trigger haptic feedback

  const triggerHapticFeedback=()=>{

    if(Platform.OS!=="web"){


      Haptics.notificationAsync(
        Haptics.NotificationFeedbackType.Success);
    }
   
  }

 //check only numbers in the comment input
 const onlyNumber = (str) => {
  return /^[0-9]+$/.test(str);
};

//color floomy
const [colorFloopmyBackground, setcolorFloopmyBackground]=useState("rgba(0, 0, 0, 0.7)");
const [TextFloomy, setTextFloomy]=useState(`je vient de vous envoyer le code de la confirmation a vote adresse Email ${SellerMail} , S'il vous plaît, entrez le code ci-dessus et appuiyez sur envoyer pour vérifier votre Email. bel3arbi b3aththoulek w ken ma l9itouch fil boite de réception  ma tebkich \u{1F62D} juste chouf fil junk mail walla spam taw talgani metsadder \u{1F92A}`);

  const [Error, setError] = useState('');
  //unset error 
const unsetError = () => {
  setError();
};
  const [ConfirmationCode, setConfirmationCode] = useState('');
  const [everThingOkConfirmationCode, seteverThingOkConfirmationCode] = useState(false);
  const handleConfirmationCodeChange=(text)=>{


    if(onlyNumber(text)){
 
      setConfirmationCode(text);
seteverThingOkConfirmationCode(true);
setError();






}
else {
  setConfirmationCode(text);
  seteverThingOkConfirmationCode(false);
  setShowTypewriter(false);
  if(text!==''){
 setError('le code ne peut contenir que des chiffres, pas de lettres ou symboles \u{1F621}');
}

}



  }




  const [Message, setMessage]=useState('');
//confirm the email adress

  const confirmEmailUser=()=>{

    setLoading(true);
    triggerHapticFeedback();

    const seller_email = SellerMail;
    const verification_code =ConfirmationCode;
   

    setShowTypewriter(false);

    axios.post('https://3oula.tn/appgate/verifiySellerMail.php?action=verifySellerMail', {
 
      seller_email,
      verification_code,

    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(response => {
      const data = response.data;

      if (data.hasOwnProperty('error')) {
        
       setError(data.error);
      }

      if (data.hasOwnProperty('message')) {
        setcolorFloopmyBackground("#5cb85c");
        setTextFloomy("Votre adresse e-mail a été vérifiée avec succès, marhbe bik zamil :)  hani bech nraj3ek lil login, wamya ...\u{1F607}");
        setShowTypewriter(true);
     
      setTimeout(() => {
        navigation.navigate('loginSeller');
      }, 9000);
      }

 
 
      setLoading(false);

    })
    .catch(error => {
      setLoading(false);
      setError(error.message);
    });


        // go to confirmation page and pass user id as prop

     



  }














  
    return (
      <KeyboardAvoidingView>
      <SafeAreaView style={StyleConfirmMail.androidSafeArea}>

<ScrollView  stickyHeaderIndices={[0]}
 veritcal={true} showsVerticalScrollIndicator={false} style={StyleConfirmMail.Container} >

{/*head */}

  {/*the container for the logo and the button to go to the login menu*/}
  <View  style={StyleConfirmMail.logoAndButtonContainer}>

  <Pressable style={StyleConfirmMail.BackButton} onPress={() => {navigation.navigate('home') }}>
  <Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
  </Pressable>

</View>









{/*body */}

<View style={{width:Platform.OS==="web"?"100vw":"100%",maxWidth:Platform.OS==="web"?450:"100%", marginTop:150,alignSelf:"center"}}>

         <Text style={StyleConfirmMail.Label}>Entrez le code de confirmation ici</Text>

<TextInput
        style={StyleConfirmMail.inputText}
   placeholder={"par exemple 987675"}
        onChangeText={handleConfirmationCodeChange}
        value={ConfirmationCode}
/>
</View>
</ScrollView>



{everThingOkConfirmationCode?(


<Pressable onPress={confirmEmailUser}
style={StyleConfirmMail.confirmButtonStyleSuccess}>
   <Text style={StyleConfirmMail.confirmButtonTextSuccess}>
   Vérifier mon Email
   </Text>
 </Pressable>
):


(


<Pressable
style={StyleConfirmMail.confirmButtonStyleFail}>
   <Text style={StyleConfirmMail.confirmButtonTextFail}>
   Vérifier mon Email
   </Text>
 </Pressable>
)}
















          {/*error and loding handling*/}

          {Error? (
  <View style={StyleConfirmMail.ErrorView}>
 <Typewriter text={Error} 
delay={10} />
    <TouchableOpacity onPress={unsetError} style={StyleConfirmMail.ErrorViewButton}>
      <Text style={StyleConfirmMail.ErrorViewText}>OK</Text>
    </TouchableOpacity>

  </View>
) : null}

    {loading?(<View style={StyleConfirmMail.LoadingView}>
    <ActivityIndicator size="large" color="white" style={{ alignSelf: 'center' }} />

</View>):null}



{/*type writer */}

{showTypewriter?(


<View style={{position:Platform.OS==="web"?"fixed":"absolute", top:0,backgroundColor:colorFloopmyBackground,width:Platform.OS==="web"?"100vw":"100%",
paddingTop:Platform.OS==="web"?0:50, paddingHorizontal:20,paddingBottom:10}}>

<Typewriter text={TextFloomy}
delay={10} color={"white"}/>
<Pressable style={StyleConfirmMail.ErrorViewButton} onPress={()=>{setShowTypewriter(false)}}><Text style={StyleConfirmMail.ErrorViewText}>ok</Text></Pressable>

</View>

):null}




      </SafeAreaView>
      </KeyboardAvoidingView>
    );
  };
  const StyleConfirmMail = StyleSheet.create({
    androidSafeArea: { paddingTop: Platform.OS === 'android' ? 25 : 0, backgroundColor: "#fcb103" },
    Container: { height: Platform.OS === 'web'?"100vh":"100%", width: Platform.OS === 'web'?"100vw":"100%",backgroundColor: "#fcb103",
     },   logoAndButtonContainer:{
    backgroundColor:"#fcb103",
    height:80,
    width:"100%", 
    },

    BackButton:{
height:50, width:50, backgroundColor:"#fcb103", alignItems:"center", justifyContent:"center", margin:15, borderRadius:50, },



    Label: { marginLeft: "5%", marginBottom: 15, marginTop: 15, fontSize: 16, fontWeight: "bold",alignSelf:"center",    fontFamily: "Chalkduster" },

    inputText: { backgroundColor: "white", height: 50, width: "90%", marginLeft: "5%", marginRight: "5%", borderWidth: 2, borderColor: "rgba(0, 0, 0, 0.4)", borderRadius: 4, paddingLeft: 10 },




 confirmButtonStyleFail :{

        
 
  height: Platform.OS === 'ios' ? 90 : 60,
  flex:1,
  flexDirection:"row",

  backgroundColor:"grey",
  width:"100%",

    alignItems: 'center',
    position: Platform.OS === "web" ?"fixed":"absolute",
bottom:0,

 left:0,
    zIndex:5,
    borderTopWidth:1,
    borderColor:"rgba(0, 0, 0, 0.4)",

},

confirmButtonTextFail:{

  textAlign: "center",
  flex: 1,
  color:"white",
  fontWeight:"bold",

  fontSize:16,
}
,


confirmButtonStyleSuccess :{

        
 
  height: Platform.OS === 'ios' ? 90 : 60,
  flex:1,
  flexDirection:"row",

  backgroundColor:"#5cb85c",
  width:"100%",

    alignItems: 'center',
    position: Platform.OS === "web" ?"fixed":"absolute",
bottom:0,

 left:0,
    zIndex:5,
    borderTopWidth:1,
    borderColor:"rgba(0, 0, 0, 0.4)",

},

confirmButtonTextSuccess:{

  textAlign: "center",
  flex: 1,
  color:"white",
  fontWeight:"bold",

  fontSize:16,
},
 //error and loading view
 LoadingView:{
  position:Platform.OS!=="web"?"absolute":"fixed",top:0,left:0,height:"100%",width:"100%", 
  backgroundColor:"rgba(0, 0, 0, 0.7)", justifyContent:"center", alignItems:"center"
},
ErrorView:{flex:1, flexDirection:"column",
position:Platform.OS!=="web"?"absolute":"fixed", top:0,left:0,height:"auto",width:"100%", backgroundColor:"rgba(222, 44, 44, 0.92)", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:60,
},
ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
 marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",borderRadius:4,},




  });
  
export default  ConfirmSellerMail;
