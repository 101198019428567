import React ,{ useEffect, useState,useRef, useContext} from 'react';
import { Modal, ActivityIndicator,StyleSheet, View, Text, Pressable, TextInput, ScrollView, SafeAreaView,Platform,Animated,TouchableOpacity} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { KeyboardAvoidingView } from 'react-native';
import AutocompleteCity from '../AutocompleteCity';
import Typewriter from "../typeWriter";
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import * as Haptics from 'expo-haptics';
import TermAndConditions from "../termAndConditions";




const CheckBox = ({ value1, value,readTermAndConditions, checked, onSelect }) => (
  <TouchableOpacity onPress={onSelect} style={{ flexDirection: 'row', alignItems: 'center' }}>
    <View style={{ height: 24, width: 24, borderRadius: 4, borderWidth: 3, borderColor: checked ? '#5cb85c' : 'black', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', marginLeft: '5%', marginTop: -10 }}>
      {checked ? <View style={{ height: 12, width: 12, borderRadius: 6, backgroundColor: '#5cb85c' }} /> : null}
    </View>
    <Text style={{ marginLeft: '5%', marginTop: -15 }}>{!readTermAndConditions ? (value1) : (checked ? 'lu et accepté' : value)
}</Text>
  </TouchableOpacity>
);



const SellerRegister = ({ navigation, route }) => {


//terms and conditions 
//hide and show modal for term and conditions
const[showTermAndConditions, setshowTermAndConditions]=useState(false);


const showAndHideTermAndCondition = () => {
  triggerHapticFeedback();
  setshowTermAndConditions(!showTermAndConditions);
};





   //cehckbox
   const [checked, setChecked] = useState(false);
   const [readTermAndConditions,setreadTermAndConditions]=useState(false);


   //show term and condtions first, than set checked and if clicked again rerstart the hole process
   const handleCheck = () => {

    if(!checked){
    if(readTermAndConditions){
    triggerHapticFeedback();
    setChecked(!checked);
  }
  else {
    setshowTermAndConditions(true);
    setreadTermAndConditions(true);

  }

    }
    else{
      setChecked(!checked);
      setreadTermAndConditions(false);

    }


  };



  const closeTermAndConditions=()=>{
triggerHapticFeedback();
    setshowTermAndConditions(false);
  }






















  //handle loading
  const [loading, setLoading] = useState(false);
 


  //trigger haptic feedback
  const triggerHapticFeedback=()=>{
    if(Platform.OS!=="web"){
    Haptics.notificationAsync(
      Haptics.NotificationFeedbackType.Success);}
  }
   
    const [Error, setError] = useState('');

const NameInputRef=useRef();
  const emailInputRef=useRef();
  const TelInputRef=useRef();
  const AdressInputRef=useRef();
  const CityInputRef=useRef();

  const PasswordInputRef1=useRef();
  const PasswordInputRef2=useRef();
const  ScrollViewRef=useRef();
const BottomViewRef=useRef();

  const [Name, setName]=useState('');
  const [Email, setEmail]=useState('');
  const [Adress, setAdress]=useState('');
  const [City, setCity]=useState('');
  const [Tel, setTel]=useState('');

  const [password1, setPassword1]=useState('');
  const [password2, setPassword2]=useState('');
  const [everyThingOkName, seteveryThingOkName]=useState(0);
  const [everyThingOkEmail, seteveryThingOkEmail]=useState(0);
  const [everyThingOkPassword1, seteveryThingOkPassword1]=useState(0);
  const [everyThingOkPassword2, seteveryThingOkPassword2]=useState(0);
  const [everyThingOkAdress, seteveryThingOkAdress]=useState(0);
  const [everyThingOkTel, seteveryThingOkTel]=useState(0);
  
 //check only letters and numbers in the input
  const onlyLettersAndNumbers = (str) => {
    return /^[A-Za-z0-9'’èéäêöàùâêîôûçëïü\s\-.,?!]*$/.test(str);
  };
  
  
//check only letters in the  input
const onlyLetters = (str) => {
return /^[A-Za-z'’èéäêöàùâêîôûçëïü\s]*$/.test(str);
};

//check only numbers in the comment input
 //check only numbers in the comment input
 const onlyNumber = (str) => {
  return /^[0-9]{8}$/.test(str);
};



  //chek MailFormat
    const onlyMailFormat = (str) => {
      const mailFormatRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  
      
      return mailFormatRegex.test(str);
    };
  
  //Name Change
   const handleNameChange= (name) => {
    
    if (onlyLetters(name)  && name.length>0&& name.length<30) {
      setName(name);
      seteveryThingOkName(1);
      NameInputRef.current.setNativeProps({
    
        style: {
          borderColor:"#5cb85c"
        }
      });
      setError();
    }
    else
    {
      seteveryThingOkName(0);
      setName(name);
      NameInputRef.current.setNativeProps({
    
        style: {
          borderColor:"red"
        }
      });
      setError("le nom ne peut contenir que des lettres et des espace, min 1 et max 30  \u{1F625}");

    }
  };









//handle email blur
const handleEmailBlur = () => {
  if (!everyThingOkEmail) {
    setError("L'adresse Email n'est pas encore valide  \u{1F628}");
  }
};

  //Email change
      const handleEmailChange= (mail) => {
    
        if (onlyMailFormat(mail)) {
          setEmail(mail);
          seteveryThingOkEmail(1);
          emailInputRef.current.setNativeProps({
        
            style: {
              borderColor:"#5cb85c"
            }
          });
          setError();
        }
        else
        {
          seteveryThingOkEmail(0);
          setEmail(mail);
          emailInputRef.current.setNativeProps({
        
            style: {
              borderColor:"red"
            }
          });
      
    
        }
      };


      //handle adress change
      const handleAdressChange= (adress) => {
    
        if (onlyLettersAndNumbers(adress)&& adress.length>0&& adress.length<300) {
          setAdress(adress);
          seteveryThingOkAdress(1);
          AdressInputRef.current.setNativeProps({
        
            style: {
              borderColor:"#5cb85c"
            }
          });
          setError();
        }
        else
        {
          seteveryThingOkAdress(0);
          setAdress(adress);
          AdressInputRef.current.setNativeProps({
        
            style: {
              borderColor:"red"
            }
          });

          setError("l'adresse ne peut contenir que des lettres, espace, nombres, points et virgules, min 1 et max 300  \u{1F624}");
        }
      };



      // handle tel change

//handle Tel blur
const handleTelBlur = () => {
  if (!everyThingOkTel) {
    setError("le numéro de téléphone doit contenir 8 chiffres  \u{1F622}");
  }
};
   const handleTelChange = (tel) => {


        if (onlyNumber(tel)) {
          setTel(tel);
          seteveryThingOkTel(1);

          TelInputRef.current.setNativeProps({
      
            style: {
              borderColor:"#5cb85c"
            }
          });
          setError();
    
        } else {
          seteveryThingOkTel(0);
          setTel(tel);
          TelInputRef.current.setNativeProps({
            style: {
              borderColor: "red",
            },
          });

     

        }
      };
      
      

     //Password 1 change
   const handlePassword1Change= (password) => {
    
      if (onlyLettersAndNumbers(password)) {
        setPassword1(password);
        seteveryThingOkPassword1(1);
        PasswordInputRef1.current.setNativeProps({
      
          style: {
            borderColor:"#5cb85c"
          }
        });
    
        setError();
      }
      else
      {
          seteveryThingOkPassword1(0);
        setPassword1(password);
        PasswordInputRef1.current.setNativeProps({
      
          style: {
            borderColor:"red"
          }
    
        });
        setError("le mot de passe  ne peut contenir que des chiffres et des lettres et ? !  min 1 et max 30 \u{1F625}");
  
      }
    };


      //Password 2 change
   const handlePassword2Change= (password) => {
    
    if (onlyLettersAndNumbers(password) && password===password1) {
      setPassword2(password);
      seteveryThingOkPassword2(1);
      PasswordInputRef2.current.setNativeProps({
    
        style: {
          borderColor:"#5cb85c"
        }
      });
      setError();
    
    }
    else
    {
        seteveryThingOkPassword2(0);
      setPassword2(password);
      PasswordInputRef2.current.setNativeProps({
    
        style: {
          borderColor:"red"
        }
      });
  

    }
  };
    //handle Tel blur
    const handlePassword2Blur = () => {
      if (!everyThingOkPassword2) {
        setError("le deux mots de passe ne sont pas encore compatibles  \u{1F62D}");
      }
    };
  
  // insert User 





//the fetch function to insert comments
const insertUser = () => {
  setLoading(true);
triggerHapticFeedback();
const user_name= Name;
const user_email=Email;
const user_adress=Adress;
const user_tel=Tel;
const user_city=City;
const user_password1=password1;
const user_password2=password2;


  fetch('https://3oula.tn/appgate/sellerRegisterApp.php?action=insertNewSeller', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `user_name=${user_name}&user_email=${user_email}&user_adress=${user_adress}&user_tel=${user_tel}&user_city=${user_city}&user_password1=${user_password1}&user_password2=${user_password2}`,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.success) {
        setLoading(false);
    
        //go to confirnmation page and pass user id as prop
       // confirmUserMail
       navigation.navigate('confirmSellerMail', {SellerMail:Email});

      } else {
        setLoading(false);
       setError(data.errorInsert);
       
      }
    })
    .catch((error) => {
      setLoading(false);
      setError(data.error);
      console.error('There was a problem with the fetch operation:', error);
    });






  



};

  
    
  
  
//unset error 
const unsetError = () => {
  setError();
};
  
  
  
  
  
  
  
   
  
    return (
      <KeyboardAvoidingView>
      <SafeAreaView style={StyleLoginUser.androidSafeArea}>




        {/*term and condiotions */}

<Modal
          animationType={'slide'}
          transparent={true}
          visible={showTermAndConditions}
          onRequestClose={() => {
         
          }}
>

<TermAndConditions  closeTermAndConditions={closeTermAndConditions} />
</Modal>
      
{/*the container for the backbutton*/}
<View  style={StyleLoginUser.BackButtonContainer}>

<Pressable style={StyleLoginUser.BackButton} onPress={() => { triggerHapticFeedback();navigation.navigate('home'); }}>
  <Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
  </Pressable></View>
  
        
      <ScrollView ref={ScrollViewRef} vertical={true} showsHorizontalScrollIndicator={false}  style={StyleLoginUser.Container} >
      <View style={StyleLoginUser.HeadTextView}>
  
  <Text style={StyleLoginUser.HeadText}>Cr&#233;er un compte Vendeur</Text>
  </View>

  <View style={{width:Platform.OS==="web"?"100vw":"100%",maxWidth:Platform.OS==="web"?450:"100%", alignSelf:"center" }}>

      <Text style={StyleLoginUser.Label}>Votre Nom et pr&#233;nom</Text>
   <TextInput
   
   ref={NameInputRef}

              style={StyleLoginUser.inputText}
              placeholder="Votre nom"
              onChangeText={handleNameChange}
              value={Name}
   />

   <Text style={StyleLoginUser.Label}>Votre adresse Email *</Text>
   <TextInput
   
   ref={emailInputRef}

              style={StyleLoginUser.inputText}
              placeholder="email"
              onChangeText={handleEmailChange}
              onBlur={handleEmailBlur}
              value={Email}
   />


<Text style={StyleLoginUser.Label}>Votre adresse adresse *</Text>
   <TextInput
   
   ref={AdressInputRef}

              style={StyleLoginUser.inputText}
              placeholder="Votre adresse"
              onChangeText={handleAdressChange}
              value={Adress}
   />




<Text style={StyleLoginUser.Label}>Votre num&#233;ro de Tel *</Text>
   <TextInput
   
   ref={TelInputRef}


              style={StyleLoginUser.inputText}
              placeholder="Votre Tel"
              onChangeText={handleTelChange}
              onBlur={handleTelBlur}
              value={Tel}
   />

<Text style={StyleLoginUser.Label}>Votre ville *</Text>
<View   ref={CityInputRef} style={StyleLoginUser.CityView}
>
<AutocompleteCity onCitySelected={setCity}/></View>










  
  
  
   <Text style={StyleLoginUser.Label}>Votre mot de passe *</Text>
   <TextInput
   
   ref={PasswordInputRef1}

   style={StyleLoginUser.inputText}
   secureTextEntry={true}
   placeholder="Enter password"
   onChangeText={handlePassword1Change}
   value={password1}
   />
   <TextInput/>



   <Text style={StyleLoginUser.Label}>Repetez votre mot de passe *</Text>
   <TextInput
   
   ref={PasswordInputRef2}

   style={StyleLoginUser.inputText}
   secureTextEntry={true}
   placeholder="Enter password"
   onChangeText={handlePassword2Change}
   onBlur={handlePassword2Blur}
   value={password2}
   />




<Text style={StyleLoginUser.Label}>Veuillez accepter les conditions d'utilisation *</Text>
<View style={StyleLoginUser.checkboxView}>
<CheckBox value1="Lire les conditions d'utilisation" value="J'ai lu et j'accepte les conditions d'utilisation"  checked={checked} readTermAndConditions={readTermAndConditions} onSelect={handleCheck}/>
</View>




  
   {everyThingOkPassword1 && everyThingOkPassword2 && everyThingOkEmail && everyThingOkName && onlyLettersAndNumbers(City)&&checked? (
   <Pressable onPress={insertUser} style={StyleLoginUser.loginButtonSuccess}>
   <Text style={StyleLoginUser.loginButtonText}>Créer un compte</Text>
  </Pressable>
  ) : (   <Pressable  style={StyleLoginUser.loginButtonFail}>
      <Text style={StyleLoginUser.loginButtonText}>remplir tous les champs</Text>
    </Pressable>)}
   
    <Pressable style={StyleLoginUser.goToRegisterPage} onPress={() => navigation.navigate('loginSeller')} >
    <Text style={StyleLoginUser.goToRegisterPageText} >Vous avez déjà un compte?</Text>
    </Pressable>
</View>
    <View ref={BottomViewRef} style={StyleLoginUser.bottomView}></View>
   
      </ScrollView>

             {/*error and loding handling*/}

      {Error? (
  <View style={StyleLoginUser.ErrorView}>
  
  <Typewriter text={Error} 
delay={10} />
    <TouchableOpacity onPress={unsetError} style={StyleLoginUser.ErrorViewButton}>
      <Text style={StyleLoginUser.ErrorViewText}>OK</Text>
    </TouchableOpacity>

  </View>
) : null}

    {loading?(<View style={StyleLoginUser.LoadingView}>
    <ActivityIndicator size="large" color="white" style={{ alignSelf: 'center' }} />

</View>):null}

      </SafeAreaView>
      </KeyboardAvoidingView>
    );
  };
  const StyleLoginUser = StyleSheet.create({
    androidSafeArea: { paddingTop: Platform.OS === 'android' ? 25 : 0, backgroundColor: "#fcb103" },
    Container: {  height: Platform.OS === 'web' ? '100vh' : '100%', backgroundColor: "#fcb103", paddingTop: 30 },
    inputText: { backgroundColor: "white", height: 50, width: "90%", marginLeft: "5%", marginRight: "5%", borderWidth: 2, borderColor: "rgba(0, 0, 0, 0.4)", borderRadius: 4, paddingLeft: 10 },
    HeadTextView: { backgroundColor: "#fcb103", justifyContent: "center", alignItems: "center" },
    HeadText: { fontSize: 20, fontWeight: "bold", textAlign: "center" },
    Label: { marginLeft: "5%", marginBottom: 15, marginTop: 15, fontSize: 16, fontWeight: "bold" },
    loginButtonFail: { width: "90%", marginLeft: "5%", marginRight: "5%", backgroundColor: "grey", height: 50, justifyContent: "center", alignItems: "center", borderRadius: 4, marginTop: 10 },
    loginButtonSuccess: { width: "90%", marginLeft: "5%", marginRight: "5%", backgroundColor: "#5cb85c", height: 50, justifyContent: "center", alignItems: "center", borderRadius: 4, marginTop: 10 },
    loginButtonText: { fontSize: 18, fontWeight: "bold", color: "white" },
    goToRegisterPage: { width: "100%", justifyContent: "center", alignItems: "center", padding: 10 },
    goToRegisterPageText: { fontSize: 18, textDecorationLine: 'underline', textDecorationStyle: 'solid' },
    CityView: { position: "relative", zIndex: 1 },

    bottomView: { width: "100%", minHeight: 600 },
    BackButtonContainer: { height: 70, backgroundColor: "#fcb103" },
    BackButton: { height: 30, marginVertical: 20, marginLeft: 20 },
    ErrorView:{flex:1, flexDirection:"column",
  position:"absolute", top:0,left:0,height:"auto",minHeight:100,width:"100%", backgroundColor:"red", alignItems:"center",paddingBottom:20,paddingTop:60,
},
 //error and loading view
 LoadingView:{
  position:Platform.OS!=="web"?"absolute":"fixed", top:0,left:0,height:"100%",width:"100%", 
  backgroundColor:"rgba(0, 0, 0, 0.7)", justifyContent:"center", alignItems:"center"
},
ErrorView:{flex:1, flexDirection:"column",
position:Platform.OS!=="web"?"absolute":"fixed",top:0,left:0,height:"auto",width:"100%", backgroundColor:"rgba(222, 44, 44, 0.92)", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:60,
},
ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
 marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",borderRadius:4,},
   //checkbox
   checkboxView:{

   },

   checkbox: {
     marginLeft: "5%",backgroundColor:"white", height:20, width:20
   },
   checkboxText:{
     marginLeft:10
   },
  });
  
export default  SellerRegister;
