import React , {useState, useEffect,useRef,useContext} from 'react';
import {ActivityIndicator, Modal,ScrollView,StyleSheet, View, Text,TextInput, Pressable, Image, Dimensions ,Button,TouchableOpacity, Platform,Animated, KeyboardAvoidingView} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Audio} from 'expo-av'; 
import { LoadingView} from '../myComponent';
import { AntDesign } from '@expo/vector-icons';
import { useMyContext } from '../MyContext';
import { Ionicons } from '@expo/vector-icons';
import { Octicons } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
import { SimpleLineIcons } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { Entypo } from '@expo/vector-icons';
import MenuLoginForSeller from './menuLoginForSeller';
import { Fontisto } from '@expo/vector-icons';
import { Foundation } from '@expo/vector-icons';
import StarRatinginput from '../starRatingInput';
import { SafeAreaView } from 'react-native-safe-area-context';
import * as Haptics from 'expo-haptics';
import Typewriter from "../typeWriter";
import ProductPageScreen from '../productPage';
import axios from 'axios';
const SellerProductPage = ({ navigation, route }) => {


//context trigger used to update
const {contextTrigger, setcontextTrigger}= useMyContext();
  // seller Objects
const {Seller, setSeller } = useMyContext([]);
 //check only numbers in input for sale and stock change

 const onlyNumber = (str) => {
  return /^[0-9]+$/.test(str);
};
//var to hold stock and sale updates 
const [Stock, setStock]=useState('');
const [EveryThingOKStock, setEveryThingOKStock]=useState(false);

const [Sale, setSale]=useState('');
const [EveryThingOKSale, setEveryThingOKSale]=useState(false);
  //var to control modal menu
  const [showMenu, setshowMenu] = useState(false);
//hold Eroor
const [Error,setError]=useState('');
//holdSuccess
const [Success, setSuccess]=useState();
//function to show menu
const toggleMenu= () => {
  triggerHapticFeedback();

setshowMenu(!showMenu);

};


  //trigger haptic feedback
  const triggerHapticFeedback=()=>{
    if(Platform.OS!=="web"){
    Haptics.notificationAsync(
      Haptics.NotificationFeedbackType.Success);}
  }
 
  const {sellersArray, setsellersArray}=useMyContext();

  const{userLikeProduct, setuserLikeProduct}= useMyContext([]);
  const{userDislikeProduct, setuserDislikeProduct}= useMyContext([]);

//setting the user stars input into state, use it later to insert to db
  const [ratingValue, setRatingValue] = useState(0);
  //comment text to database
  const [commentTextToDb, setCommentTextToDb] = useState('');
  const [replyText, setreplyText]=useState('');
  const commentInputRef= useRef();
  const replyInputRef = useRef();

  const commentBoxRef = useRef();
//star rating score user input getting
  const handleRatingChange = (value) => {
  
    setRatingValue(value);

  };
//check only letters and numbers in the comment input
//check only letters and numbers in the comment input
 //check only letters and numbers in the input
 const onlyLettersAndNumbers = (str) => {
  return /^[A-Za-z0-9'’èéäêöàùâêîôûçëïü\s.,?!]*$/.test(str);
};
  
  



//handle focus on inputs 



const handleInputFocus = (myRef) => {
      
  myRef.current.measureLayout(
    scrollViewRef.current,
    (x, y) => {
  
      scrollViewRef.current.scrollTo({y:y+200, animated: true});
    }
  );
};
const commentLengthRef = useRef();
//comment text handling
  const handleCommentTextChange = (text) => {




    if(text.length>600){

      commentLengthRef.current.setNativeProps({
    
        style: {
          color:"red"
        }
      });
    }
    else {

      commentLengthRef.current.setNativeProps({
    
        style: {
          color:"#5cb85c"
        }
      });
    }

    if (onlyLettersAndNumbers(text)) {
      setCommentTextToDb(text);
      commentInputRef.current.setNativeProps({
    
        style: {
          borderColor:"#5cb85c"
        }
      });
    }
    else
    {
 commentInputRef.current.setNativeProps({
    
        style: {
          borderColor:"red"
        }
      });

    }
  };


  //rplytext handling
  const handleReplyTextChange = (text) => {

    if (onlyLettersAndNumbers(text)) {
      setreplyText(text);
      replyInputRef.current.setNativeProps({
        style: {
          borderColor:"#5cb85c"
        }
      });
    }
    else
    {
      replyInputRef.current.setNativeProps({
    
        style: {
          borderColor:"red"
        }
      });

    }
  };




//adjust input height

useEffect(() => {
  adjustInputHeight();
}, [commentTextToDb]);

const adjustInputHeight = () => {
  if (commentInputRef.current) {
    commentInputRef.current.setNativeProps({
      height: commentInputRef.current.scrollHeight,
    });
  }
};







//
const insertReply=(commentId, user_id)=>{
  triggerHapticFeedback();
  const sku_product = propName.product_sku;
  const user_review_number = propName.user_review_number;
  const product_score = propName.product_score;
  const sku_seller = propName.seller_sku;
  const seller_image =propName.seller_image;
  const seller_name=propName.seller_name;
  const comment_id=commentId;

  let user_name, user_image;

 
    user_name = 1;
    user_image = 1;



  fetch('https://3oula.tn/appgate/likeandcommentappForSeller.php?action=reply', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `sku_product=${sku_product}&sku_seller=${sku_seller}&reply_text=${replyText}&user_name=${user_name}&user_id=${user_id}&user_image=${user_image}&comment_id=${comment_id}&seller_image=${seller_image}&seller_name=${seller_name}&product_score=${product_score}&user_review_number=${user_review_number}`,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.success) {
        myFetchDataFunction();
      } else {
        console.error('Update failed');
        console.log(data.message);
      }
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error);
    });


};








//updating stock and sale
const handleInputStockChange=(text)=>{
  setStock(text);
if(onlyNumber(text)&&parseFloat(text)>0&&parseFloat(text)<=(5000-propName.stock)){
 
  setEveryThingOKStock(true);
  setError();

}
else {
  setEveryThingOKStock(false);

  if(text.length>0){
  setError('le stock ne peut contenir que des chiffres et ne peut pas depasser 5000');
}
}
}



//handle sale change
const handleInputSaleChange=(text)=>{
  setSale(text);
  if(onlyNumber(text)&&parseFloat(text)<99&&parseFloat(text)>0){

    setError();
 setEveryThingOKSale(true);
    
    }
    else {
      setEveryThingOKSale(false);
      if(text.length>0){
      setError('le solde ne peut contenir que des chiffres et ne peut pas depasser 60%');
      }
    }
}

//the fetch function to insert comments
const UpdateStock = (order_id, product_sku, product_name,product_image, price, Qty, email_seller) => {
  setSuccess();
  setLoading(true);
triggerHapticFeedback();
    fetch('https://3oula.tn/appgate/sellerStockAndSaleUpdate.php?action=AddSTock', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
     body:`new_stock=${Stock}&product_sku=${propName.product_sku}&seller_sku=${Seller[0].sku_seller}`,

    })
    .then((response) => response.json())
    .then((json) => {

      if (json.hasOwnProperty("error")) {
    setLoading(false);
    setError(json.error); // Output the error
    } else {
      setLoading(false);
      setSuccess(`Votre stock vient d'être actualisé, mriguel tawa walla 3nadek ${Stock} unités min ${propName.product_name}, el mouhem dima rodd belek mil qualité 5ater mak ta3ref client far7an yjib 3 w client met8achech yharreb 10. ras melek el qualité w thi9a`);
      myFetchDataFunction();
  
  
    }

    })
   
    .catch((error) => {
      setLoading(false);
      console.error(error);
    });
  

  };





const updateSale = () => {

let new_price= ((propName.product_price/100)*(100-Sale)).toFixed(2);
setSuccess();
  setLoading(true);
triggerHapticFeedback();
    fetch('https://3oula.tn/appgate/sellerStockAndSaleUpdate.php?action=UpdateSale', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
     body:`new_sale=${Sale}&product_sku=${propName.product_sku}&seller_sku=${Seller[0].sku_seller}`,
    

    })
    .then((response) => response.json())
    .then((json) => {

      if (json.hasOwnProperty("error")) {
    setLoading(false);
    setError(json.error); // Output the error
    } else {
      setLoading(false);
      myFetchDataFunction();
  
      setSuccess(`Votre solde pour ce produit ${propName.product_name} vient d'être actualisé, tawa el prix jdid soldé walla ${new_price} Tnd, rabbi yjib el 9asm. El mouhem dima rodd belek mil qualité n7ebbou el client elli ya9thi min 3andek ya3mel avis behi bech yarja3lek dima`);
    }

    })
   
    .catch((error) => {
      setLoading(false);
      console.error(error);
    });
  

  };








//contain product details
  const { propName } = route.params;


//product object
  const [ProductData, setProductData] = useState();
//comments And Reply
const[Comments, setComments]=useState();
const[CommentsCopie, setCommentsCopie]=useState();

const[CommentsInitial, setCommentsInitial]=useState(5);
const[Reply, setReply]=useState();
const ReplyBoxRef=useRef([]);
const [ShownReply, setShownReply]=useState(0);


//show reply and set on the ball to no one

const showReplyAndInsertSeenSeller = (commentid) => {
  triggerHapticFeedback();
  ReplyBoxRef.current[commentid].setNativeProps({ style: { display: 'flex' } });

setShownReply(commentid);

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded', // Set the header to application/x-www-form-urlencoded
  // Add any other headers you need
};

// Construct the form data
const formData = new FormData();
formData.append('comment_id', commentid);

// Use Axios to call the API
axios.post('https://3oula.tn/appgate/sellerNotification.php?action=SetSeenSellerReplyProduct', formData, {
  headers: headers, // Add headers here
})

  .then(response => {
    if (response.data.success) {
      setcontextTrigger(true);
    }
  
  })
  .catch(error => {
    console.error('API error:', error);
  });


}

const hideReply = (commentid) => {
  ReplyBoxRef.current[commentid].setNativeProps({ style: { display: 'none' } });

setShownReply(0);

}





const handleCommentShowMore=()=>{

  setCommentsInitial(CommentsInitial+5);
}
//similar product
const[SimilarProduct, setSimilarProduct]=useState();

 //function to fetch data from 3oula.tn
 const myFetchDataFunction= () => {
  const product_sku = propName.product_sku; 
const product_name=propName.product_name; 
const product_category=propName.product_category; 
  // fetch data when component is mounted
  fetch('https://3oula.tn/appgate/likeandcommentapp.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `sku_product_to_show=${product_sku}&product_name=${product_name}&product_category=${product_category}`,

  })
    .then((response) => response.json())
    .then((json) => {
      setProductData(json.Products_data); // set product data state
      setComments(json.comments);
      setCommentsCopie(json.comments);
      setSimilarProduct(json.similarProduct);
      setReply(json.reply);
      setcontextTrigger(false);
 
    })
   
    .catch((error) => {
      console.error(error);
    });
    
  };


  useEffect(() => {
    myFetchDataFunction();

  }, [contextTrigger]);


////set comments to seen seller without causing a rerender
useEffect(() => {

  const seller_sku = Seller[0].sku_seller;

  // Create a FormData object and append the data
  const formData = new FormData();
  formData.append('sku_seller', seller_sku);

  // Update comments to seen for shop using Axios and FormData
  axios.post(
    'https://3oula.tn/appgate/sellerCommentSeenUpdate.php?action=setCommentsToSeenProduct',
    formData
  )
  .then((response) => {
    if (response.data.success) {
     
      //  actions here if
    } else {
      console.error(response.data.error);
      // Handle error case 
    }
  })
  .catch((error) => {
    console.error('An error occurred:', error);
    // Handle the fetch error
  });
}, [contextTrigger]);





  
  const {sellersSku, setsellersSku} = useMyContext([]);
  const{totalPrice, settotalPrice} = useMyContext();
  //images array constructing 
  const images = [];

  for (let i = 0; i <= 4; i++) {
    const image =propName[`product_image${i}`];
    if (image) {
      images.push(`https://3oula.tn/${image}`);
    }
  }

//images array length


const[Length, setLength] = useState(images.length);
const [position, setPosition] = useState(1);

// handle image scroll to show index from full length while scrolling 
const screenWidth = Dimensions.get('window').width;
const handleScroll = (event) => {
  
  const currentScrollPosition = event.nativeEvent.contentOffset.x;
  const halfScreenWidth = screenWidth / 2;
  const newPosition = Math.floor((currentScrollPosition + halfScreenWidth) / screenWidth) + 1;
  setPosition(newPosition);
};
  




// Function that construct the stars for rating 
function StarRating({ score }) {
  const filledStars = Array(parseInt(score)).fill().map((_, index) => (
    <AntDesign key={`filled-${index}`} name="star" size={18} color="#fcb103" />
  ));
  const emptyStars = Array(5 - parseInt(score)).fill().map((_, index) => (
    <AntDesign key={`empty-${index}`} name="star" size={16} color="grey" />
  ));
  const stars = [...filledStars, ...emptyStars];
  return <Text>{stars}</Text>;
}











//sorting comments by 




//sorting comments by 

//show more comment
const [Loading, setLoading]=useState(false);
const [sortedBy, setSortedBy]=useState('');
const handleShowMoreComment=()=>{
  setCommentsInitial(CommentsInitial+5);
}

const SortByDateDsd=(SortedByWhat)=>{
  triggerHapticFeedback();
  setSortedBy(SortedByWhat);
setLoading(true);
  //setCommentsInitial(CommentsInitial+5);
  const sortedData = [...CommentsCopie].sort((a, b) => {
    const dateA = new Date(a.datum); // Assuming the date property is in a valid date format
    const dateB = new Date(b.datum); // Assuming the date property is in a valid date format
    
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  });


  setCommentsInitial(sortedData.length);
    // Update the state or variable holding the sorted data
    setComments(sortedData);

setTimeout( ()=>{setLoading(false) }, 200);
   
}

const SortByDateAsd=(SortedByWhat)=>{
  triggerHapticFeedback();
  setSortedBy(SortedByWhat);
  setLoading(true);
  //setCommentsInitial(CommentsInitial+5);
  const sortedData = [...CommentsCopie].sort((a, b) => {
    const dateA = new Date(a.datum); // Assuming the date property is in a valid date format
    const dateB = new Date(b.datum); // Assuming the date property is in a valid date format
    
    if (dateA < dateB) {
      return 1;
    }
    if (dateA > dateB) {
      return -1;
    }
    return 0;
  });


  setCommentsInitial(sortedData.length);
    // Update the state or variable holding the sorted data
    setComments(sortedData);
    setTimeout( ()=>{setLoading(false) }, 200);

}





//sort by score
const SortByScoreDsd=(SortedByWhat)=>{
  setSortedBy(SortedByWhat);
  triggerHapticFeedback();
  setLoading(true);
  const sortedData = [...CommentsCopie].sort((a, b) => {
    const ratingA = parseFloat(a.user_stars);
    const ratingB = parseFloat(b.user_stars);
    
    if (ratingA < ratingB) {
      return 1;
    }
    if (ratingA > ratingB) {
      return -1;
    }
    return 0;
  });

  setCommentsInitial(sortedData.length);
    // Update the state or variable holding the sorted data
    setComments(sortedData);
    setTimeout( ()=>{setLoading(false) }, 200);
  };


//sort by score
const SortByScoreAsd=(SortedByWhat)=>{
  setSortedBy(SortedByWhat);

  triggerHapticFeedback();
  setLoading(true);
  const sortedData = [...CommentsCopie].sort((a, b) => {
    const ratingA = parseFloat(a.user_stars);
    const ratingB = parseFloat(b.user_stars);
    
    if (ratingA < ratingB) {
      return -1;
    }
    if (ratingA > ratingB) {
      return 1;
    }
    return 0;


  });

  setCommentsInitial(sortedData.length);
    // Update the state or variable holding the sorted data
    setComments(sortedData);
    setTimeout( ()=>{setLoading(false) }, 200);
  };





///scrolling to comment box

const handleScrollToRefComment = () => {
  triggerHapticFeedback();
  commentBoxRef.current.measureLayout(

    scrollViewRef.current,
    (x, y) => {
      scrollViewRef.current.scrollTo({y: y-100, animated: true});
    }
  );
};











  //like is liked
 const [isLiked, setIsLiked] = useState(false);

 const[isDisliked, setisDisliked]=useState(false);
//handle like   const [isLiked, setIsLiked] = useState(false);
const handleLike = () => {
  const product_sku = ProductData[0].product_sku; // replace with your desired SKU
const user_like_number= ProductData[0].user_like_number;




if(!userLikeProduct.includes(product_sku)){
userLikeProduct.push(product_sku);

  // Insert user into MySQL database
  fetch('https://3oula.tn/appgate/likeandcommentapp.php?action=like', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `sku_product=${product_sku}&user_like_number=${user_like_number}`,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.success) {
      
        setIsLiked(true);
        myFetchDataFunction();
     
      } else {
        console.error('Update failed');
      }
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error);
    });
  }
    else{


      console.log("already liked");
    }
  




};

//handle like   const [isLiked, setIsLiked] = useState(false);
const handleDisLike = () => {
  const product_sku = ProductData[0].product_sku; // replace with your desired SKU
const user_dislike_number= ProductData[0].user_dislike_number;

if(!userDislikeProduct.includes(product_sku)){

userDislikeProduct.push(product_sku);
  // Insert user into MySQL database
  fetch('https://3oula.tn/appgate/likeandcommentapp.php?action=dislike', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `sku_product=${product_sku}&user_dislike_number=${user_dislike_number}`,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.success) {
        myFetchDataFunction();
        //setisDisliked(true);
      } else {
        console.error('Update failed');
      }
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error);
    });
  }
  else{


    console.log("already disliked");
  }


};


//handle scroll to comment section
const scrollViewRef = useRef();

  const handleScrollToBottom = () => {
    scrollViewRef.current.scrollToEnd({ animated: true });
  };

//when aDD to button is pressed feedback
const [pressedButton, setPressedButton] = useState(null);










if (ProductData === undefined || Comments === undefined  || SimilarProduct === undefined || Reply === undefined) {
  return <LoadingView/>
}
  return (
<>
<SafeAreaView   style={StyleProduct.androidSafeArea}>
<KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 100}
      style={{ flex: 1 }}
    >
      {/*menu login */}

<Modal
          animationType={'slide'}
          transparent={true}
          visible={showMenu}
          onRequestClose={() => {
            console.log('Modal has been closed.');
          }}
>

<MenuLoginForSeller  Customnavigation={navigation}  toggle={toggleMenu} />

</Modal>



    <ScrollView   showsVerticalScrollIndicator={false}
   ref={scrollViewRef} style={{backgroundColor:"#fcb103"}} stickyHeaderIndices={[0]}>
    <View>
    <View style={StyleProduct.Head}>
<Pressable style={StyleProduct.BackButton} onPress={() => { {triggerHapticFeedback();navigation.navigate('sellerHomePage');setcontextTrigger(true);} }}>

  <Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
  </Pressable>
  <Pressable  onPress={() => {triggerHapticFeedback();toggleMenu();}}  style={StyleProduct.loginButton}><Text><Feather name="menu" size={40} color="black" /></Text></Pressable>

 </View></View>




      <ScrollView horizontal={true} onScroll={handleScroll} scrollEventThrottle={100} decelerationRate={"fast"} snapToInterval={screenWidth}
 snapToAlignment={"center"} contentInset={{top: 0,left: 0,bottom: 0,right: 0,}} showsHorizontalScrollIndicator={false}  style={StyleProduct.SlideShowGallery}>



    {images.map((image, index) => (
 image!=='https://3oula.tn/null'?(
 <View key={index} ><Image source={{uri: image}} style={{ height:Platform.OS==="web"?400:320, width:Platform.OS==="web"?400:screenWidth}}/></View>
 )
 :(null)))}
    </ScrollView>

{/*The View where the nuber of picture is */}
 <View style={StyleProduct.SlideShowGalleryIndex} ><Text style={StyleProduct.SlideShowGalleryIndexText} >{position +" / "+Length}</Text></View>


{/*The View where the product details are */}
<View style={StyleProduct.DescriptionView}>
<View style={StyleProduct.ProductPageProductDetail} >

 {/*The View where the product title, scroe and review number  are */} 
  <View style={StyleProduct.ProductDetailtitleView}>
  <Text style={StyleProduct.title} > {ProductData[0].product_name}</Text>
<View style={StyleProduct.starRatingView}>
<StarRating  score={ProductData[0].product_score} /></View>
<Text style={StyleProduct.RestTitleText}>Nombre des Avis: {ProductData[0].user_review_number}</Text>

    </View>  
    
    <View style={StyleProduct.ProductDetailSellerView}>
      <Image source={{ uri: `https://3oula.tn/${propName.seller_image}` }} style={StyleProduct.sellerImage} />
      <Pressable style={StyleProduct.SellerLinkDetailView}><Text>{ProductData[0].seller_name} shop</Text></Pressable>

    </View>

    </View>
    <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}  style={StyleProduct.containerLikeDislike}>

<Pressable style={StyleProduct.LikeDislikeButton}>
<Text style={{ color: 'black',fontSize:16}}><Text>{ProductData[0].user_like_number}  <Feather name="thumbs-up" size={24} color="black" /></Text>
</Text>
</Pressable>
 <Pressable style={StyleProduct.LikeDislikeButton}>
 <Text style={{ color: 'black',fontSize:16}}>
<Text>{ProductData[0].user_dislike_number}  <Feather name="thumbs-down" size={24} color="black" /></Text>
 </Text>
</Pressable>
<Pressable style={StyleProduct.LikeDislikeButton}>
 <Text style={{ color: 'black',fontSize:16 }}>
 <AntDesign name="sharealt" size={24} color="black" />
 </Text>
</Pressable>
<Pressable onPress={handleScrollToRefComment} style={StyleProduct.LikeDislikeButton}>
 <Text style={{ color: 'black',fontSize:16}}>
 {ProductData[0].user_review_number} {ProductData[0].user_review_number>1? " commentaires ":"commentaire"} <FontAwesome5 name="comment-dots" size={24} color="black" /> </Text>
</Pressable>
</ScrollView>

<Text style={StyleProduct.boldText}>Votre produit en chiffre</Text>

<View>
<View style={StyleProduct.infoContainerProductView}>




<View style={StyleProduct.infoContainerProduct}>
<Text style={StyleProduct.boldText}>Prix <Entypo name="price-tag" size={18} color="black" /></Text>
{!ProductData[0].sale?
(<Text>{ProductData[0].product_price} Tnd</Text>)
:
(<Text> {((ProductData[0].product_price/100)*(100-ProductData[0].sale)).toFixed(2)} Tnd</Text>)
}


</View>


<View style={StyleProduct.infoContainerProduct}>
<Text style={StyleProduct.boldText}>Poids   <FontAwesome5 name="weight-hanging" size={18} color="black" /></Text><Text>{ProductData[0].product_weight} gramme</Text>  
</View>

<View style={StyleProduct.infoContainerProduct}>
<Text style={StyleProduct.boldText}>J'aime   <SimpleLineIcons name="like" size={16} color="black" /></Text><Text>{ProductData[0].user_like_number?ProductData[0].user_like_number:0}</Text>

</View>


<View style={StyleProduct.infoContainerProduct}>
<Text style={StyleProduct.boldText}>J'aime pas   <SimpleLineIcons name="dislike" size={16} color="black"/></Text><Text>{ProductData[0].user_dislike_number?ProductData[0].user_dislike_number:0}</Text>

</View>

<View style={StyleProduct.infoContainerProduct}>
<Text style={StyleProduct.boldText}>Vu   <Ionicons name="eye-outline" size={18} color="black" /></Text><Text>{ProductData[0].seen?ProductData[0].seen:0}</Text>

</View>


<View style={StyleProduct.infoContainerProduct}>
<Text style={StyleProduct.boldText}>comment&#233;  <FontAwesome5 name="comment-dots" size={16} color="black" /></Text><Text>{ProductData[0].user_review_number?ProductData[0].user_review_number:0}</Text>

</View>


<View style={StyleProduct.infoContainerProduct}>
<Text style={StyleProduct.boldText}>Vendu  <FontAwesome5 name="cash-register" size={18} color="black" /></Text><Text>{ProductData[0].sold?ProductData[0].sold:0}</Text>

</View>


<View style={StyleProduct.infoContainerProduct}>
<Text style={StyleProduct.boldText}>Stock  <FontAwesome5 name="boxes" size={18} color="black" /></Text><Text>{ProductData[0].stock}</Text>

</View>
<View style={StyleProduct.infoContainerProduct}>
<Text style={StyleProduct.boldText}>Sale   <Fontisto name="shopping-sale" size={18} color="black" /></Text><Text>{ProductData[0].sale>0?ProductData[0].sale:0 }%</Text>

</View>
</View>
</View>
<View style={StyleProduct.infoContainerProductBigText}>
<Text style={StyleProduct.boldText}>Description:</Text>
<Text>{ProductData[0].product_description}</Text>
</View>

<View style={StyleProduct.infoContainerProductBigText}>

  <Text style={StyleProduct.boldText}>Composants alimentaires:</Text>
  <Text>{ProductData[0].composants}</Text>

  </View>

  <View style={StyleProduct.infoContainerProductBigText}>

  <Text style={StyleProduct.boldText}>Note:</Text>
<Text>{ProductData[0].note}</Text>

</View>



 
{/**slae and update stock view */}
<View style={StyleProduct.SaleAndStockUpdateView}>
<View style={StyleProduct.infoContainerProductBigText}>
<Text style={StyleProduct.boldText}>Ajouter au Stock max {5000-propName.stock} unités:</Text></View>
<View  style={StyleProduct.SaleAndStockUpdateViewkidContainer}>





<View style={StyleProduct.SaleAndStockUpdateViewkid}>
  
<TextInput

style={[
  {
    borderColor:Stock.length<1 ?"rgba(0, 0, 0, 0.4)": EveryThingOKStock? "#5cb85c" : "red",
  },
  StyleProduct.InputText,
]}
   placeholder="Nouveau stcok disponible"
   onChangeText={handleInputStockChange}
   value={Stock}
   />
</View>





<TouchableOpacity onPress={UpdateStock} style={[ {backgroundColor:EveryThingOKStock? "#5cb85c" : "grey"},StyleProduct.SaleAndStockUpdateViewkidButton]}><Text style={{color:"white",fontWeight:"bold"}}>Ajouter</Text></TouchableOpacity >

</View>






{/**watcher view to show the update of Sale */}

{Stock ? (
  <>
    <View style={StyleProduct.WatcherView}>
      <View style={StyleProduct.WatcherViewKids}>
        <Text style={StyleProduct.boldText}>Stock actuel: </Text>
      </View>
      <View style={StyleProduct.WatcherViewKids}>
        <Text>{propName.stock} Unités</Text>
      </View>
    </View>

    <View style={StyleProduct.WatcherView}>
      <View style={StyleProduct.WatcherViewKids}>
        <Text style={StyleProduct.boldText}>Nouveau Stock:</Text>
      </View>
      <View style={StyleProduct.WatcherViewKids}>
        <Text> {Stock ? parseFloat(propName.stock) + parseFloat(Stock) : 0} Unités</Text>
      </View>
    </View>
  </>
) : null}





{/**watcher view end for sale*/}











<View style={StyleProduct.infoContainerProductBigText}>
<Text style={StyleProduct.boldText}>Ajouter un solde min 5% et max 90%:</Text></View>
<View  style={StyleProduct.SaleAndStockUpdateViewkidContainer}>


<View style={StyleProduct.SaleAndStockUpdateViewkid}>


<TextInput
style={[
  {
    borderColor:Sale.length<1 ?"rgba(0, 0, 0, 0.4)": EveryThingOKSale? "#5cb85c" : "red",
  },
  StyleProduct.InputText,
]}
   placeholder="Solde par exepnle 50%"
   onChangeText={handleInputSaleChange}
   value={Sale}
   />

</View>

<TouchableOpacity onPress={updateSale}  style={[ {backgroundColor:EveryThingOKSale? "#5cb85c" : "grey"},StyleProduct.SaleAndStockUpdateViewkidButton]}><Text style={{color:"white",fontWeight:"bold"}}>Ajouter</Text></TouchableOpacity>
</View>




{/**watcher view to show the update of Sale */}


{Sale?(<><View style={StyleProduct.WatcherView}>

<View style={StyleProduct.WatcherViewKids}>
<Text style={StyleProduct.boldText}>Prix actuel sans solde: </Text>
</View>

<View style={StyleProduct.WatcherViewKids}>
<Text>{propName.product_price} Tnd</Text>
</View>

</View>


<View style={StyleProduct.WatcherView}>

<View style={StyleProduct.WatcherViewKids}>
<Text style={StyleProduct.boldText}>Prix avec solde {Sale?Sale:0}%:</Text>
</View>

<View style={StyleProduct.WatcherViewKids}>
<Text> {((propName.product_price/100)*(100-Sale)).toFixed(2)} Tnd</Text>
</View>

</View></>):null}

{/**watcher view end for sale*/}





















</View>



</View>




   






     

{/*Sorting commenst by date score */}


<ScrollView ref={commentBoxRef} horizontal={true} showsHorizontalScrollIndicator={false} style={StyleProduct.SortByComments}>
<Pressable onPress={()=>{SortByScoreDsd('ScoreDsd')}} 
 style={() => [  {backgroundColor:sortedBy==="ScoreDsd"?"#E8E8E8":"white"}, StyleProduct.SortByButton, ]}>
  <Text style={StyleProduct.SortByButtonText}> Meilleurs avis  <SimpleLineIcons name="like" size={14} color="black" /></Text>
  </Pressable>
  <Pressable onPress={()=>{SortByScoreAsd('ScoreAsd')}}
   style={() => [  {backgroundColor:sortedBy==="ScoreAsd"?"#E8E8E8":"white"}, StyleProduct.SortByButton, ]}>
  <Text style={StyleProduct.SortByButtonText}> Plus mauvais avis  <SimpleLineIcons name="dislike" size={14} color="black" /></Text>
  </Pressable>
 
  <Pressable onPress={()=>{SortByDateDsd('DateDsd')}} 
   style={() => [  {backgroundColor:sortedBy==="DateDsd"?"#E8E8E8":"white"}, StyleProduct.SortByButton, ]}>
  <Text style={StyleProduct.SortByButtonText}>Plus anciens  <Octicons name="sort-asc" size={14} color="black" /></Text>
  </Pressable>
  <Pressable onPress={()=>{SortByDateAsd('DateAsd')}} 
   style={() => [  {backgroundColor:sortedBy==="DateAsd"?"#E8E8E8":"white"}, StyleProduct.SortByButton, ]}>
  <Text style={StyleProduct.SortByButtonText}>Plus nouveau  <Octicons name="sort-asc" size={14} color="black" /></Text>
  </Pressable>


  </ScrollView>

  {Comments.length >0 &&  Comments.slice(0,CommentsInitial).map((item ,index) => (

<View key={index}>
<View style={[{ backgroundColor: item.seen_seller==="not seen" ? "#D3D3D3" : "white" }, StyleProduct.CommentsContainerBox]}>
{item.seen_seller==="not seen" ?(<Text>Nouveau commentaire</Text>):null}
<View style={StyleProduct.commentBoxNameFotoAndStars}>
  <View style={StyleProduct.commentBoxNameFotoAndStarsViews1}>


<Image source={{uri:`https://3oula.tn/${item.user_foto}`}} style={StyleProduct.userImage}></Image>
<Text style={StyleProduct.username}>{item.user_name}</Text></View>
<View style={StyleProduct.commentBoxNameFotoAndStarsViews2}>
<Text>{item.datum}</Text>
<View >
<StarRating score={item.user_stars} /></View>
</View>
</View >

<View style={StyleProduct.CommentTextView}>
<Text style={StyleProduct.CommentText}>{item.comment_text.trim()}</Text>
</View>
{ShownReply!==item.comment_id? (
<Pressable style={StyleProduct.ShowReplyButton} onPress={() => showReplyAndInsertSeenSeller(item.comment_id)}>
   <Text>
  {Reply.filter(reply => reply.comment_id === item.comment_id).length>0?
  (<Text> voir {Reply.filter(reply => reply.comment_id === item.comment_id).length} {item.new_reply==="yes"?"nuovelles ":null}{Reply.filter(reply => reply.comment_id === item.comment_id).length>1?"réponses":"réponse"} </Text>):(<Text>r&#233;pondre</Text>)}
  </Text>
</Pressable>)
:
(
<Pressable style={StyleProduct.ShowReplyButton} onPress={() => hideReply(item.comment_id)}><Text>masquer</Text></Pressable>
)}



</View>

  




<View  ref={(ref) => {ReplyBoxRef.current[item.comment_id] = ref}}  style={StyleProduct.replyBox}>


<View style={StyleProduct.ReplyContainerBoxInputInputAndReply}>

  <TextInput ref={replyInputRef}  
//onFocus={() => handleInputFocus(replyInputRef)}
            style={StyleProduct.ReplyInput}
            placeholder="entrez votre r&#233;ponse ici"
            onChangeText={handleReplyTextChange}
            value={replyText}
          />
          <Pressable onPress={() => insertReply(item.comment_id, item.user_id)} style={StyleProduct.commenterButton}>
            <Text style={StyleProduct.commenterButtonText}>reply</Text>
            </Pressable>
</View>





{Reply.length > 0 &&  Reply.map((reply, indexReply) => (
reply.comment_id===item.comment_id? ( 
<View key={indexReply} style={StyleProduct.ReplyContainerBoxInputInputAndReply}>
<View style={StyleProduct.commentBoxNameFotoAndStars}>
{reply.user_name!=="1"?(<View style={StyleProduct.commentBoxNameFotoAndStarsViews1}><Image source={{uri:`https://3oula.tn/${reply.user_image}`}} style={StyleProduct.userImage}></Image>
<Text style={StyleProduct.username}>{reply.user_name}</Text></View>):(
  <View style={StyleProduct.commentBoxNameFotoAndStarsViews1}><Image source={{uri:`https://3oula.tn/${reply.seller_image}`}} style={StyleProduct.userImage}></Image>
  <Text style={StyleProduct.username}>{reply.seller_name} shop</Text></View>
)}
<View style={StyleProduct.commentBoxNameFotoAndStarsViews2}>
<Text>{reply.date}</Text>

</View>
</View >

<View style={StyleProduct.CommentTextView}>
<Text style={StyleProduct.CommentText}>{reply.comment_text.trim()}</Text>
</View>
</View>

):
(null)
))}





</View>
</View>





))}





{Comments.length-CommentsInitial>0?(
<Pressable onPress={handleShowMoreComment} style={StyleProduct.ShowMoreCommentButton}>
  <Text style={StyleProduct.ShowMoreCommentButtonText}> voir plus {Comments.length-CommentsInitial}</Text>
</Pressable> 
  ):null}











    </ScrollView>
    </KeyboardAvoidingView>
 
    </SafeAreaView>
      {/*error and loading handling*/}



   {Success?(
 <View style={StyleProduct.SuccessView}>
 <Typewriter text={Success} 
delay={10} color={"white"}/>
     <TouchableOpacity onPress={()=>{triggerHapticFeedback();setSuccess();}}style={StyleProduct.ErrorViewButton}><Text  style={StyleProduct.ErrorViewText}>OK</Text></TouchableOpacity>

   </View>):null
    }


   {Error?(    <View style={StyleProduct.ErrorView}>
  <Typewriter text={Error} 
delay={10} />
 
      <TouchableOpacity onPress={()=>{triggerHapticFeedback();setError();}}style={StyleProduct.ErrorViewButton}><Text  style={StyleProduct.ErrorViewText}>OK</Text></TouchableOpacity>

    </View>):null}

    {Loading?(<View style={StyleProduct.LoadingView}>
      <ActivityIndicator size="large" color="white" style={{ alignSelf: 'center' }} />
  
  </View>):null}
</>


  );
};
const StyleProduct =StyleSheet.create({
  androidSafeArea: {
  

    paddingTop: Platform.OS === 'android' ? 30 : 0,
    backgroundColor:"#fcb103",
height:Platform.OS!=="web"?"100%": "100vh",
    flex: 1,

    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,



},

Loadingview:{
  backgroundColor:"#fcb103",
  height:"100%", width:"100%", justifyContent:"center", alignItems:"center"


},




pressab:{
  backgroundColor:"#fcb103",
  height:60, marginTop:100,
  
  },
  loginButton:{
    height:50, width:50, backgroundColor:"white", alignItems:"center", justifyContent:"center", margin:15, borderRadius:50, 

    shadowColor: 'rgba(0, 0, 0, 0.5)',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 1,
    shadowRadius: 2,
    elevation: 2,
    justifyContent:"center", alignItems:"center",  
    paddingVertical: 0,
    paddingHorizontal: 5,
  },
  SlideShowGallery:{
    height:Platform.OS==="web"?400:320,
    width:Platform.OS==="web"?400:"100%",
    flexDirection:"row",
    alignSelf:"center",
    backgroundColor:"#fcb103",
    
    zIndex:1,
    marginTop:-70
    
      },
      SlideShowGalleryIndex:{
    height:30,
    width:50,
    justifyContent:"center", alignItems:"center",
    backgroundColor:"rgba(0,0,0,0.7)",
    borderRadius:9,
    zIndex:2,
    marginTop:-40,
    alignSelf:"center",marginRight:-300
    
      },
  SlideShowGalleryIndexText:{
fontSize:14,

color:"white"
  },
  Head:{
flex:1, flexDirection:"row",justifyContent:"space-between",
    height:70,width:"100%",backgroundColor:"transparent"
  },
  BackButton:{

    height:50, width:50, backgroundColor:"white", alignItems:"center", justifyContent:"center", margin:15, borderRadius:50, 

    shadowColor: 'rgba(0, 0, 0, 0.5)',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 1,
    shadowRadius: 2,
    elevation: 2,
    justifyContent:"center", alignItems:"center",  
    paddingVertical: 0,
    paddingHorizontal: 5,
  }
,
ProductPageProductDetail:{
  width:"100%",



marginTop:30,
flex:1, 
flexDirection:"row"


},

ProductDetailtitleView :{
backgroundColor:"white",
width:"50%", justifyContent:"center", alignItems:"center", 


},
ProductDetailSellerView:{

  width:"50%",justifyContent:"center", alignItems:"center"
},


containerLikeDislike:{
height:60,

flex:1,
flexDirection:'row',


},
LikeDislikeButton:{
  height: 40,
  width: "auto",
  backgroundColor: 'white',
  borderRadius: 4,
margin:5,
  shadowColor: 'rgba(0, 0, 0, 0.5)',
  shadowOffset: { width: 0, height: 1 },
  shadowOpacity: 1,
  shadowRadius: 2,
  elevation: 2,
  justifyContent:"center", alignItems:"center",  
  paddingVertical: 0,
  paddingHorizontal: 5,

},

sellerImage:{

height:60,width:60, borderRadius:60,

},
SellerLinkDetailView:{

height:40,width:"100%", backgroundColor:"white", justifyContent:"center", alignItems:"center"

},

title:{

  fontWeight:"bold", fontSize:18,
  marginBottom:10
},
RestTitleText:{

  fontSize:14,
  marginTop:10

},

DescriptionView:{height:"auto",
backgroundColor:"white", width:"99%", marginLeft:"0.5%", marginRight:"0.5%", padding:20, borderRadius:4, borderWidth:1,
marginTop:20,marginBottom:30

}
,boldText:{

  fontWeight:"bold",paddingBottom:10
},
productPriceButton:{


  height:40,
backgroundColor:"#a7bf60",

width:"100%",





justifyContent: 'center',
alignItems: 'center',
borderRadius:4,
shadowColor: 'rgba(0, 0, 0, 0.5)',
shadowOffset: { width: 0, height: 2 },
shadowOpacity: 1,
shadowRadius: 2,
elevation: 3,

},

productPriceText:{
  fontWeight:"bold",

  },

  CommentsContainerBox:{
height: "auto",width:"90%", marginLeft:"1%", marginRight:"9%", borderRadius: 30, borderWidth:1,
borderColor:"rgba(0, 0, 0, 0.4)",
justifyContent:"center", alignItems:"center", overflow:"hidden",paddingTop:20, paddingBottom:20, marginBottom:10,
  },


  replyBox:{flex:1,
    height: "auto", marginBottom:20, width:"98%", marginLeft:"1%", marginRight:"1%",
 
    justifyContent:"center", alignItems:"center", paddingTop:10, paddingBottom:20,
display:"none"

  },
  CommentsContainerBoxInput: {
    height: 'auto',
    backgroundColor: 'white',
    marginBottom: 10,
    width: '90%',
    marginLeft: '1%',
    marginRight: '9%',
    borderRadius: 30,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    paddingTop: 0,
    paddingBottom: 10,
  },

 ReplyContainerBoxInputInputAndReply: {
    height: 'auto',
    backgroundColor: "rgb(224, 230, 207)",
    width: '90%',
    marginLeft: '9%',
    marginRight: '1%',
    borderRadius: 30,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
paddingVertical:20,
    marginBottom:10,

  },







  ratingInput:{

    width:"100%", flex:1, flexDirection:"row",paddingLeft:20,paddingTop:10
     },
     commentInput:{
    borderWidth:1,width:"90%",marginTop:10,borderRadius:10, padding:10,borderColor:"rgba(0, 0, 0, 0.4)",
    
      
     },

     ReplyInput:{

      borderWidth:1,width:"90%",marginTop:10,borderRadius:10, padding:10,borderColor:"rgba(0, 0, 0, 0.4)",backgroundColor:"white"

     },

  

     commenterButton:{

backgroundColor:"#a7bf60", height:35, justifyContent:"center", alignItems:"center" ,paddingHorizontal:10, marginTop:10, borderRadius:10, marginLeft:"auto",
marginRight:20
     },
     commenterButtonText:{
fontWeight:"bold", color:"white"

     },







  commentBoxNameFotoAndStars: {
    width: '90%',
    maxHeight: 50,
    backgroundColor: 'transparent',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  commentBoxNameFotoAndStarsViews1: {
    width: 'auto',
    flex: 1,
    flexDirection: 'row',
  },
  commentBoxNameFotoAndStarsViews2: {
    width: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  
  userImage:{

    height:40, width:40, borderRadius:40, justifyContent:"center", alignItems:"center"
  },
  username:{
fontWeight:"bold", marginTop:10, marginLeft:5

  },

  CommentTextView:{

    width:"100%", paddingLeft:20,paddingTop:10, paddingRight:20
      },
  CommentText:{
  
  },


  SortByComments:{
height:52, marginBottom:10,
backgroundColor:"#fcb103", marginLeft:"1%", marginRight:"1%", 


  },

  ShowMoreCommentButton:{
    margin:5,
    height:40, width:"auto", paddingHorizontal:10, 
    backgroundColor:"white",justifyContent:"center", 
    alignItems:"center", borderRadius:15,
  
    
    shadowColor: 'rgba(0, 0, 0, 0.8)',
    shadowOffset: { width: 2, height: 4 },
    shadowOpacity: 0.7,
    shadowRadius: 4,
    elevation: 5
  },

  ShowReplyButton:{

    height:40, width:"auto", paddingHorizontal:10, 
    backgroundColor:"#a7bf60",justifyContent:"center", 
    alignItems:"center", borderRadius:10, marginLeft:"auto",marginRight:20,
  
    
    shadowColor: 'rgba(0, 0, 0, 0.8)',
    shadowOffset: { width: 2, height: 4 },
    shadowOpacity: 0.7,
    shadowRadius: 4,
    elevation: 5

  },
  ShowMoreCommentButtonText:{
    color:"black", fontWeight:"bold"
  },







  SimilarproductViewConatinerBody:{
    width:"98%",
    marginLeft:"1%",
    marginRight:"1%",
      borderRadius:4,
     marginBottom:15,
   
      borderWidth:1,
      borderColor:"rgba(0, 0, 0, 0.4)",
      backgroundColor:"white", 
      paddingTop:20,paddingBottom:20,
      paddingLeft:10


  }
,








  wrapperProductView:{ 
    borderRadius:4,
    height:290,
  
marginLeft:10,
    width:160,
    backgroundColor:"white",
     shadowColor: 'rgba(0, 0, 0, 0.8)',
  shadowOffset: { width: 2, height: 4 },
  shadowOpacity: 0.7,
  shadowRadius: 4,
  elevation: 5},
productImage:{
height:160,
width:160,

},
productView:{
height:290,overflow:"hidden",borderRadius:4, backgroundColor:"#C8C8C8",
},
starRatingView:{
backgroundColor:"white",paddingLeft:10, 

},


productnameView:{
  backgroundColor:"white",
  height:30,
  paddingLeft:10,paddingTop:10, paddingBottom:5


},
productName:{
fontWeight:"bold",

},

productDescriptionView:{
  backgroundColor:"white",
  height:37,
  marginBottom:3,



  padding:10,
},

productPriceButtonSimilar:{
  height:50,
backgroundColor:"#a7bf60",

width:"100%",
marginBottom:0, marginTop:"auto",





justifyContent: 'center',
alignItems: 'center',



},




productPriceText:{
fontWeight:"bold",


},

sellerNameAndLink:{
  flexDirection:"row",
 
   alignItems:"space-between",
 height:40,
 width:"100%",
   backgroundColor:"rgba(0,0,0,0.3)",
 marginTop:-250,
 },
 sellerImageProductView:{
   height:60,
   width:60,
   borderRadius:60,
   marginLeft:5,
   marginBottom:-10,
 },
 sellerNameProductViewButton:{
   flexWrap:"wrap",
   alignItems:"flex-start",
 
 
 },
 sellerNameProductView:{
 fontSize:12,
 color:"white",
 fontWeight:"bold",
 margin:5,
 
 },
 commentLength:{
  fontSize:12, fontWeight:"bold", marginLeft:20, marginRight:"auto"
 },


 infoContainerProductView:{
flex:1, flexDirection:"row", flexWrap:"wrap", borderTopWidth:2, borderTopColor:"grey"


 },
 infoContainerProduct:{

paddingVertical:10,width:"33%", justifyContent:"center",alignItems:"center"

 },

 infoContainerProductBigText:{

    paddingVertical:20,width:"100%", borderTopWidth:2, borderTopColor:"grey"


 },
     
 SortByButton:{
  margin:5,
  height:40, width:"auto", paddingHorizontal:10, 
justifyContent:"center", 
  alignItems:"center", borderRadius:15,

  
  shadowColor: 'rgba(0, 0, 0, 0.8)',
  shadowOffset: { width: 2, height: 4 },
  shadowOpacity: 0.7,
  shadowRadius: 4,
  elevation: 5},
  
  SortByButtonText:{
    color:"black", fontWeight:"bold"
  },




   //error and loading view
 LoadingView:{
  position:Platform.OS!=="web"?"absolute":"fixed",top:0,left:0,height:"100%",width:"100%", 
  backgroundColor:"rgba(0, 0, 0, 0.7)", justifyContent:"center", alignItems:"center"
},
ErrorView:{flex:1, flexDirection:"column",
position:Platform.OS!=="web"?"absolute":"fixed", top:0,left:0,height:"auto",width:"100%", backgroundColor:"rgba(222, 44, 44, 0.92)", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:60,
},
ErrorViewText:{fontWeight:"bold",color:"white",paddingHorizontal:20},
ErrorViewButton:{ height:30,width:100,alignItems:"center", justifyContent:"center",
 marginRight:20,marginLeft:"auto",backgroundColor:"#003d6b",borderRadius:4,},
 SuccessView:{flex:1, flexDirection:"column",
 position:"absolute", top:0,left:0,height:"auto",width:"100%", backgroundColor:"#5cb85c", alignItems:"center",paddingBottom:5,paddingTop:Platform.OS==="web"?20:30,
},
 



//sale view
SaleAndStockUpdateView:{ width:"100%", backgroundColor:"white", 
},
SaleAndStockUpdateViewkidContainer:{width:300,flex:1,borderRadius:4, borderWidth:1,overflow:"hidden",
   flexDirection:"row", height:52, minHeight:52,  marginBottom:20,borderWidth:1,},


InputText:{

  borderWidth:1,width:200, padding:10,backgroundColor:"white",height:50, minHeight:50,

 },

SaleAndStockUpdateViewkid:{width:200, height:50, maxHeight:50},
SaleAndStockUpdateViewkidButton:{width:100, height:50, maxHeight:50, justifyContent:"center",alignItems:"center",},


//watcher view to watch sale and stock updates 
WatcherView:{flex:1, flexDirection:"row"},
WatcherViewKids:{width:"50%",}
})
export default SellerProductPage;