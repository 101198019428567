import React ,{ useEffect, useState,useRef, useContext} from 'react';
import { ActivityIndicator, StyleSheet, View, Text, Pressable, TextInput, ScrollView, SafeAreaView,Platform,Animated,TouchableOpacity,Image} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons'; 
import { KeyboardAvoidingView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import * as Haptics from 'expo-haptics';
import { useMyContext } from './MyContext';
import * as ImagePicker from 'expo-image-picker';
import axios from 'axios';
import Typewriter from "./typeWriter";
const MenuLoginUser= ({ Customnavigation, route ,toggle }) => {


//kill typewriter when navigateing
const killTypeWriter=()=>{

setshowTypeWriter(false);
  
}

const [TextSolde,setTextSolde]=useState("Vous êtes nouveau ici, donc je vais vous expliquer comment cela fonctionne. Le solde est actuellement à 0,00 TND car vous n'avez pas encore retourné de produit. Chaque fois que vous retournez un produit et que l'acheteur confirme la réception, nous créditons automatiquement votre compte, ce qui augmente votre solde. Vous pourrez retirer l'argent à tout moment en appuyant sur le bouton de retrait, qui apparaîtra dès que votre solde ne sera plus à 0,00 TND, ou l'utiliser pour d'autres achats. Pour changer la photo de votre profil, appuyez sur le bouton Changer ma photo. Pour voir vos commandes, appuyez sur Mes commandes. Et lorsque vous souhaitez vous déconnecter, appuyez sur Logout \u{1F607} w mar7be bik.");
const [showTypeWriter,setshowTypeWriter]=useState(true);

  //Notification user 
//user notification
//Notification user 
//user notification
const {OrdersNotificationUser , setOrdersNotificationUser}= useMyContext({});
const {ReplyNotificationUserProduct , setReplyNotificationUserProduct}= useMyContext({});
const {ReplyNotificationUserSeller , setReplyNotificationUserSeller}= useMyContext({});
const {userNotificationCount , setuserNotificationCount }= useMyContext();

const {OrdersNotificationsFrames , setOrdersNotificationsFrames}= useMyContext({});
  //trigger haptic feedback

//trigger haptic feedback
const triggerHapticFeedback=()=>{
  if(Platform.OS!=="web"){
  Haptics.notificationAsync(
    Haptics.NotificationFeedbackType.Success);}
}
 
//user and seller Objects
const{User, setUser}= useMyContext({});
const{UserIsLoggedIn, setUserIsLoggedIn}=useMyContext();
const [image, setImage] = useState(null);


//const while loading
const [loading, setLoading] = useState(false);

//loggin the user out
const handleLogout=()=>{
 toggle();
   triggerHapticFeedback();
   setUser({});
   setUserIsLoggedIn(false);
setOrdersNotificationUser();
setReplyNotificationUserProduct();
setReplyNotificationUserSeller();
setOrdersNotificationsFrames();
setuserNotificationCount(0);
//navigation.navigate('home');

  }
  









//pick image 
    const pickImage = async () => {
setshowTypeWriter(false);
     triggerHapticFeedback();
        // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.All,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });
    
    
    
        if (!result.canceled) {
          setImage(result.assets[0].uri);
        }
      };
    
     const DeleteImage=()=>{
     
triggerHapticFeedback();
      setImage(null);
    
     }
    
   
    //upload image
  const handleUploadImage = async () => {
    setLoading(true);
    triggerHapticFeedback();

    const user_id = User[0].user_id;
    const user_image=User[0].user_image;
    let imageUri = image;

    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('user_image', user_image);

    try {
      if (Platform.OS === 'web') {
        // On web, read the image file as an ArrayBuffer
        const response = await fetch(imageUri);
        const buffer = await response.arrayBuffer();
        imageUri = new Blob([buffer], { type: 'image/jpeg' });
        formData.append('image', imageUri, 'image.jpg');
      } else {
        // On mobile (Expo), use the image URI directly
        formData.append('image', {
          uri: imageUri,
          type: 'image/jpeg',
          name: 'image.jpg',
        });
      }

      const response = await axios.post(
        'https://3oula.tn/appgate/userPhotoUploadApp.php',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const responseText = response.data;

      if (response.status === 200) {
        // Handle the successful response from the PHP API
        //const newUser = [{ user_image: 'new_value.jpg' }];
        //setUser(newUser);
        alert('Image uploaded successfully!');
    
        setLoading(false);

        setUser(responseText.user);

    

      } else {
        // Handle any errors or unsuccessful response
        alert('Image upload failed.');
        setLoading(false);
      }
    } catch (error) {
      // Handle network errors or other exceptions

      setLoading(false);
    }

  };



  
    return (
      <KeyboardAvoidingView>
      <SafeAreaView style={styles.androidSafeArea}>
 




   {/*menu login */}
 

    
   
   <ScrollView vertical={true} showsHorizontalScrollIndicator={false} style={styles.menuLogin}  >
    
   <View  style={styles.logoAndButtonContainer}>

{User&& User[0] !== undefined? (  <View style={styles.userImageAndName}>

  {image && image.length>0? (<Image source={{ uri: image }} style={{ width: 70, height: 70, borderRadius:70, marginBottom:10}} />):
  (<Image style={styles.userImage} source={{ uri: `https://3oula.tn/${User[0].user_image}` }}
  />)}


<Text style={styles.userName}>{User[0].name}</Text></View>):(<Pressable style={styles.BackButton} onPress={toggle}>
  <Text><Ionicons name="arrow-back" size={30} color="black" /></Text>
  </Pressable>)}


    <Pressable  onPress={toggle}  style={styles.loginButton}><Text>
      <Feather name="menu" size={40} color="black" />
      </Text></Pressable>

</View>

{User&& User[0] !== undefined?
(

<View>

<View style={styles.menuLoginButtonsContainer}>

<Pressable style={styles.menuLoginButtonsScrollView} onPress={pickImage}>
        <Text style={styles.menuLoginButtonsText}>changer ma photo</Text></Pressable>
        <Pressable style={styles.menuLoginButtonsScrollView} 

        onPress={() => {
              triggerHapticFeedback();
              toggle();
              Customnavigation.navigate('OrdersUserAll', {propName:User});}} >
  <Text  style={styles.menuLoginButtonsText}>mes commandes</Text>
  </Pressable>
<Pressable style={styles.menuLoginButtonsScrollView} onPress={handleLogout} >
  <Text  style={styles.menuLoginButtonsText}>logout</Text>
  </Pressable>

      
    </View>



    
    <View style={styles.guthabenView}>

<Text style={styles.guthabenViewText}>Votre Solde actuel: {parseFloat(User[0].guthaben).toFixed(2)} Tnd</Text>
     

{showTypeWriter&&parseFloat(User[0].guthaben)!==0?(

<Typewriter text={TextSolde} 
delay={10} color={"black"}/>

):null}

   </View>
   {image && image.length>0? (
    <View style={styles.photoUpdateViewMEnuLogin}>
   <Pressable style={styles.menuLoginButtonsScrollViewred} onPress={DeleteImage}>
        <Text style={styles.menuLoginButtonsText}> <Ionicons name="md-close" size={30} color="white" /> </Text></Pressable>
      <Image source={{ uri: image }} style={{ width: 300, height: 300, alignSelf:"center"}} />
 
  <Pressable style={styles.menuLoginButtonsScrollViewgreen} onPress={handleUploadImage }>
      <Text style={styles.menuLoginButtonsText}><AntDesign name="upload" size={30} color="white" /> </Text></Pressable>    
        </View>
        ):(null)} 
    </View>
  
    
    
    )
:(
    <View style={styles.menuLoginButtonsContainer}>

<Pressable style={styles.menuLoginButtons}   onPress={() => {
   
 triggerHapticFeedback();
    toggle();
    Customnavigation.navigate('userOrSellerLogin', { propsName: "login" });
    }}
  >
  <Text  style={styles.menuLoginButtonsText}>login</Text>
  </Pressable>

  <Pressable style={styles.menuLoginButtons} onPress={() => {
   
   triggerHapticFeedback();
   toggle();
   Customnavigation.navigate('userOrSeller', {propsName:"register"})}} >
  <Text  style={styles.menuLoginButtonsText}>cr&#233;er un compte</Text>
  </Pressable>


    </View>
    
    
    
    
    
    
    
    
    )}





    </ScrollView>












  





    {loading?(<View style={styles.LoadingView}>
    <ActivityIndicator size="large" color="white" style={{ alignSelf: 'center' }} />

</View>):null}

      </SafeAreaView>
      </KeyboardAvoidingView>
    );
  };
  const styles = StyleSheet.create({
    androidSafeArea: { paddingTop: Platform.OS === 'android' ? 25 : 0, backgroundColor: "transparent"},
  //menu login

  logoAndButtonContainer:{
    flex:1,
    flexDirection:"row",
    
    backgroundColor:"#fcb103",
    height:"auto",
    paddingVertical:20,
    width:"100%",
    justifyContent:"space-between"
  
    },


    loginButton :{
 
        justifyContent: 'center',
        alignItems: 'center',
      
      backgroundColor:"#fcb103",
      maxWidth:50,
      height:50,
      
      
      marginRight:20,
      marginBottom:10,
      marginTop:10,
      
      
      
      
      
      
      
      },



      menuLogin:{
        height:Platform.OS==="web"?"100vh":"100%",
        minHeight:Platform.OS==="web"?"100vh":"100%",
        width:Platform.OS==="web"?"100vw":"100%",
        maxWidth:Platform.OS==="web"?450:"100%",

        backgroundColor:"white",
        marginRight:0,marginLeft:"auto"
      },



  menuLoginButtonsContainer:{
    flex:1,flexDirection:"row",  justifyContent:"space-between", alignItems:"center" ,backgroundColor: "white" ,
    
      },
      menuLoginButtonsContainerScrollView:{},
    
      photoUpdateViewMEnuLogin:{
        marginTop:0,width:300, height:"auto",alignSelf:"center",
         borderRadius:10, overflow:"hidden",paddingTop:10,marginTop:20
      },
    
      menuLoginButtonsScrollView:{
        backgroundColor:"#0059ba", height:40, alignItems:"center",
        width:"auto", borderRadius:4, justifyContent:"center",marginVertical:10,paddingHorizontal:10, marginHorizontal:3
      },
      menuLoginButtonsScrollViewred:{
        backgroundColor:"rgba(0, 0, 0, 0.5)", height:60, alignItems:"center",
        width:60,  justifyContent:"center",zIndex:2,marginBottom:-70,borderRadius:60,marginLeft:230,
     
      },
      menuLoginButtonsScrollViewgreen:{
        backgroundColor:"#5cb85c", height:50, alignItems:"center",
        width:300, justifyContent:"center",zIndex:2,
     
      },
    
    
    
      menuLoginButtons:{
        backgroundColor:"#0059ba", height:40, alignItems:"center",
        margin:10, width:150, borderRadius:4, justifyContent:"center"
     
      },
      menuLoginButtonsText:{
    
    color:"white", fontWeight:"bold" 
      },
      userImageAndName:{
     flexDirection:"column",paddingHorizontal:20
    
    
      },
      userImage:{
        height:70, width:70,marginBottom:10, borderRadius:70
      },
      userName:{
    fontWeight:"bold",
    fontSize:18, alignSelf:"center"
      },
      LoadingView:{
        position:"absolute", top:0,left:0,height:"115%",width:"100%", 
        backgroundColor:"rgba(0, 0, 0, 0.7)", justifyContent:"center", alignItems:"center"
      },


      BackButton:{

        height:50, width:50, backgroundColor:"#fcb103", alignItems:"center", justifyContent:"center", margin:15, borderRadius:50, 
      
       
      },

      guthabenView:{  paddingVertical:20,},
      guthabenViewText:{fontWeight:"bold", fontSize:16, color:"grey", alignSelf:"center"},
  });
  
export default  MenuLoginUser;
